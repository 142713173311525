import { FC, useEffect, useState } from "react";

import { Text, Image } from "theme-ui";

import { Editor } from "src/components/editor";
import PlaceholderSVG from "src/components/explore/placeholder.svg";
import { QueryBuilder } from "src/components/explore/query-builder";
import * as analytics from "src/lib/analytics";
import {
  VisualQueryFilter,
  OrCondition,
  RootCondition,
  Condition,
  ConditionType,
  Audience,
  AudienceParent,
} from "src/types/visual";
import { Row, Column, Wrap } from "src/ui/box";
import { Button } from "src/ui/button";
import { Spinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { UseSourceResult } from "src/utils/sources";

import { AudienceResults } from "./audience-results";

type Source = NonNullable<UseSourceResult["data"]>;

export type AudienceExploreProps = {
  audience?: Audience;
  parentModel?: AudienceParent | null;
  transformedSql: string | undefined | null;
  runQuery: (limit: boolean) => Promise<any>;
  cancelQuery: () => void;
  visualQueryFilter?: VisualQueryFilter;
  onVisualQueryFilterChange?: (filter: VisualQueryFilter) => void;
  source: Source | undefined | null;
  rowsPerPage?: number;
  error: string | undefined;
  rows?: any;
  numRowsWithoutLimit?: number | null;
  columns?: any;
  loading?: boolean;
  reset?: () => void;
  onBack?: () => void;
  onSave: ((data?: { rows: any; columns: any }) => Promise<void>) | undefined;
  getSchema: () => Promise<any>;
  saveLabel: string;
};

export const AudienceExplore: FC<Readonly<AudienceExploreProps>> = ({
  audience,
  parentModel,
  numRowsWithoutLimit,
  rows,
  columns,
  loading,
  saveLabel,
  error,
  source,
  transformedSql,
  visualQueryFilter,
  getSchema,
  runQuery,
  cancelQuery,
  reset,
  onVisualQueryFilterChange,
  onBack,
  onSave,
}) => {
  const [hasQueryColumns, setHasQueryColumns] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const supportsResultSchema = source?.definition?.supportsResultSchema;

  const saveDisabled = supportsResultSchema ? false : !hasQueryColumns;
  const saveTooltip = saveDisabled ? "This source requires previewing your query results before saving the model" : undefined;

  const handleSave = async () => {
    setSaveLoading(true);
    if (supportsResultSchema && !hasQueryColumns) {
      const { data } = await getSchema();

      if (data?.columns?.length && typeof onSave === "function") {
        await onSave(data);
      }
    } else if (typeof onSave === "function") {
      await onSave();
    }
    setSaveLoading(false);
  };

  useEffect(() => {
    setHasQueryColumns(false);
  }, [visualQueryFilter]);

  useEffect(() => {
    if (columns?.length && !error) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  const [showTransformedSql, setShowTransformedSql] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      analytics.track("Model Query Error", {
        model_type: source?.definition?.type,
        query_mode: "visual",
        error,
      });
    }
  }, [error]);

  useEffect(() => {
    const handler = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        runQuery(true);
      }
    };
    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [runQuery]);

  useEffect(() => {
    return reset;
  }, []);

  return (
    <>
      <Row sx={{ flex: 1 }}>
        <Column sx={{ flex: 2 }}>
          <QueryBuilder
            audience={audience}
            conditions={toOrConditions(visualQueryFilter?.conditions)}
            parent={parentModel}
            setConditions={(conditions) => {
              if (typeof onVisualQueryFilterChange === "function") {
                onVisualQueryFilterChange({ ...(visualQueryFilter || {}), conditions: conditions as RootCondition[] });
              }
            }}
          />
        </Column>

        <Column sx={{ flex: 1, position: "sticky", top: 24, height: "70vh" }}>
          <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 4 }}>
            {onBack && (
              <Button variant="secondary" onClick={onBack}>
                Back
              </Button>
            )}
            <Wrap spacing={2} sx={{ flex: 1, alignItems: "center", justifyContent: "flex-end" }}>
              {(rows || error) && !loading && (
                <Row>
                  <Button label="View SQL" variant="secondary" onClick={() => setShowTransformedSql(true)} />
                </Row>
              )}

              <Button
                loading={loading}
                variant="secondary"
                onClick={async () => {
                  await runQuery(true);
                  analytics.track("Model Query Previewed", {
                    model_type: source?.definition?.type,
                    query_mode: "visual",
                  });
                }}
              >
                Preview
              </Button>

              {onSave && (
                <Button disabled={saveDisabled} loading={saveLoading} tooltip={saveTooltip} onClick={handleSave}>
                  {saveLabel || "Save"}
                </Button>
              )}
            </Wrap>
          </Row>
          <Column sx={{ flex: 1, overflow: "hidden" }}>
            {(rows || error) && !loading ? (
              <AudienceResults
                columns={columns}
                error={error}
                numRowsWithoutLimit={numRowsWithoutLimit ?? 0}
                parentModel={parentModel}
                rows={rows}
              />
            ) : (
              <Column
                sx={{
                  flex: 1,
                  border: "small",
                  borderStyle: "dashed",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 4,
                  bg: "base.1",
                  borderRadius: 1,
                }}
              >
                {loading ? (
                  <>
                    <Spinner size={64} />
                    <Button size="small" sx={{ mt: 6 }} variant="secondary" onClick={cancelQuery}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Image src={PlaceholderSVG} />
                    <Text sx={{ fontSize: 2, fontWeight: "semi", mb: 2, mt: 4, textAlign: "center" }}>
                      Ready to test this query?
                    </Text>
                    <Text sx={{ color: "base.5", textAlign: "center" }}>A preview of the resulting rows will appear here</Text>
                  </>
                )}
              </Column>
            )}
          </Column>
        </Column>
      </Row>

      <Modal
        info
        bodySx={{ p: 0 }}
        isOpen={showTransformedSql}
        sx={{ maxWidth: "800px", width: "90%", height: "90%" }}
        title="Transformed SQL"
        onClose={() => setShowTransformedSql(false)}
      >
        <Editor readOnly language="sql" value={transformedSql ?? ""} />
      </Modal>
    </>
  );
};

function toOrConditions(conditions: Condition[] | undefined): OrCondition[] | undefined {
  if (!conditions) {
    return undefined;
  }

  return conditions.map((condition) => {
    if (condition.type !== ConditionType.Or) {
      return {
        type: ConditionType.Or,
        conditions: [condition],
      };
    }
    return condition;
  });
}
