import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ModelIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M16.676 2H7.333C3.93 2 2 3.929 2 7.333v9.334C2 20.07 3.929 22 7.333 22h9.343C20.08 22 22 20.071 22 16.667V7.333C22 3.93 20.08 2 16.676 2z"
        opacity={0.4}
      />
      <path d="M7.369 9.369a.833.833 0 00-.827.836v6.87a.83.83 0 001.662 0v-6.87a.835.835 0 00-.835-.836zM12.035 6.089a.833.833 0 00-.826.836v10.15a.83.83 0 001.662 0V6.926a.835.835 0 00-.836-.836zM16.64 12.996a.835.835 0 00-.836.835v3.245a.83.83 0 001.662 0V13.83a.833.833 0 00-.826-.835z" />
    </Icon>
  );
};
