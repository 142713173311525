import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const OnboardingIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M21.2511 3.58314C21.0852 3.4172 20.8362 3.33423 20.5873 3.33423L17.8493 3.74908C15.3603 4.08095 12.9541 5.32549 11.1288 7.06785L9.80131 8.39536C8.97162 8.31239 6.64847 6.98488 4.74017 8.89318L2.74891 10.9674C2.58297 11.1334 2.5 11.2993 2.5 11.5482C2.5 11.7971 2.58297 11.963 2.74891 12.129C2.83188 12.212 7.3952 16.7753 7.14629 16.5264L9.71834 13.9543C10.0502 13.6224 10.548 13.6224 10.8799 13.9543C11.2118 14.2862 11.2118 14.784 10.8799 15.1159L8.30786 17.6879C8.39083 17.7709 12.9541 22.4172 12.7052 22.0853C12.8712 22.2513 13.1201 22.3342 13.286 22.3342C13.5349 22.3342 13.7009 22.2513 13.8668 22.0853L15.8581 20.0941C16.7707 19.1814 17.1026 17.8539 16.8537 16.6923L16.4389 15.0329L17.7664 13.7054C19.5917 11.8801 20.7533 9.55694 21.0852 6.98488L21.5 4.32986C21.5 3.99798 21.417 3.74908 21.2511 3.58314ZM5.56987 12.6268L4.49127 11.5482L5.90175 10.1377C6.89738 9.14209 8.22489 9.80584 8.4738 9.80584L5.56987 12.6268ZM14.6965 18.9325L13.286 20.343L12.2074 19.2644L15.1114 16.3604L15.2773 17.0242C15.3603 17.6879 15.1943 18.4347 14.6965 18.9325ZM17.1856 10.0548C16.5218 10.7185 15.3603 10.7185 14.6965 10.0548C14.0328 9.391 14.0328 8.31239 14.6965 7.56567C15.3603 6.90191 16.4389 6.90191 17.1856 7.56567C17.8493 8.31239 17.8493 9.391 17.1856 10.0548Z" />
      <path
        d="M2.73077 16.2331L3.42308 15.538C3.73077 15.3063 4.19231 15.2291 4.5 15.538C4.80769 15.8469 4.80769 16.3103 4.5 16.6192L3.80769 17.3143C3.5 17.6232 3.03846 17.6232 2.73077 17.3143C2.42308 17.0054 2.42308 16.542 2.73077 16.2331Z"
        fillOpacity="0.4"
      />
      <path
        d="M9.26923 21.4075L8.57692 22.1025C8.26923 22.4115 7.80769 22.4115 7.5 22.1025C7.19231 21.7936 7.19231 21.3302 7.5 21.0213L8.19231 20.3262C8.5 20.0173 8.96154 20.0173 9.26923 20.3262C9.57692 20.6352 9.57692 21.0985 9.26923 21.4075Z"
        fillOpacity="0.4"
      />
      <path
        d="M6.88484 19.0273L5.39125 20.5268C5.08356 20.8357 4.6219 20.8357 4.3142 20.5268C4.00651 20.2179 4.00664 19.7545 4.31433 19.4456L5.80792 17.946C6.11561 17.6371 6.57715 17.6371 6.88484 17.946C7.19253 18.255 7.19253 18.7183 6.88484 19.0273Z"
        fillOpacity="0.4"
      />
    </Icon>
  );
};
