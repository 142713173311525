import { VFC, useState } from "react";

import { capitalize } from "lodash";

import { ModelColumnsOrderBy, useUpdateModelColumnMutation } from "src/graphql";
import { ColumnType } from "src/types/visual";
import { Badge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { SearchInput } from "src/ui/input";
import { Select } from "src/ui/select";
import { Table, TableColumn } from "src/ui/table";

type Props = {
  columns: any;
  loading: boolean;
  modelId: string | undefined;
  orderBy: ModelColumnsOrderBy | undefined;
  onSort(sortKey: string): void;
};

const typeOptions = [
  {
    value: ColumnType.Boolean,
    label: "Boolean",
  },
  {
    value: ColumnType.Number,
    label: "Number",
  },
  {
    value: ColumnType.String,
    label: "String",
  },
  {
    value: ColumnType.Timestamp,
    label: "Timestamp",
  },
  {
    value: ColumnType.Date,
    label: "Date",
  },
  {
    value: ColumnType.Json,
    label: "Object / Array",
  },
];

export const ColumnSettings: VFC<Readonly<Props>> = ({ columns, loading, modelId, orderBy, onSort }) => {
  const [search, setSearch] = useState<string>("");

  const { mutateAsync: updateColumn } = useUpdateModelColumnMutation();

  const tableColumns: TableColumn[] = [
    {
      key: "name",
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort("name"),
    },
    {
      name: "Type",
      sortDirection: orderBy?.type,
      onClick: () => onSort("type"),
      max: "400px",
      cell: ({ name, type, custom_type }) => {
        return (
          <Select
            options={typeOptions}
            tip={custom_type ? `The warehouse type is ${capitalize(type)}.` : undefined}
            value={custom_type || type}
            onChange={(option) => {
              if (!modelId) {
                return;
              }

              updateColumn({ id: modelId, name, input: { custom_type: option?.value } });
            }}
          />
        );
      },
    },
  ];

  const filteredColumns = columns.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));

  return (
    <Column sx={{ overflow: "hidden" }}>
      <Row sx={{ alignItems: "center", mb: 4, justifyContent: "space-between" }}>
        <Row sx={{ alignItems: "center" }}>
          <Heading sx={{ mr: 2 }}>Columns</Heading>
          <Badge sx={{ mr: 4 }} variant="base">
            {columns?.length}
          </Badge>
        </Row>
        <SearchInput placeholder="Search columns..." value={search} onChange={setSearch} />
      </Row>
      <Table
        columns={tableColumns}
        data={filteredColumns}
        disabled={({ disable }: { disable: boolean }) => disable}
        loading={loading}
        rowHeight={50}
      />
    </Column>
  );
};
