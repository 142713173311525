import { FC, useState } from "react";

import { Text, Grid } from "theme-ui";

import { Button } from "src/ui/button";
import { Editor } from "src/ui/editor";
import { Field } from "src/ui/field";
import { Modal } from "src/ui/modal";

type Props = {
  look: {
    id: string;
    title: string;

    // description is optional on a Look
    description: string | null;

    sql: string | null;
  };
};

export const LookerQuery: FC<Readonly<Props>> = ({ look }) => {
  const [showSql, setShowSql] = useState<boolean>(false);

  return (
    <Grid gap={3}>
      <Field inline label="Title">
        <Text>{look?.title}</Text>
      </Field>

      <Field inline label="ID">
        <Text>{look?.id}</Text>
      </Field>

      {look?.description && (
        <Field inline label="Description">
          <Text>{look?.description}</Text>
        </Field>
      )}

      {look?.sql && <Button label="View SQL" variant="secondary" onClick={() => setShowSql(true)} />}

      <Modal
        info
        bodySx={{ p: 0 }}
        isOpen={showSql}
        sx={{ maxWidth: "800px", width: "90%", height: "90%" }}
        title="SQL"
        onClose={() => setShowSql(false)}
      >
        <Editor code={look?.sql ?? ""} language="sql" sx={{ height: "100%", width: "100%" }} />
      </Modal>
    </Grid>
  );
};
