import { VFC } from "react";

import { Box, Flex } from "theme-ui";

import { colors } from "../../../../design/colors";
import { ChangeButton, Popover, Subtitle, Title } from "./index";
import { popoverContent } from "./popover";

type TileDestinationProps = {
  popoverContent: keyof typeof popoverContent;
  popoverDisabled: boolean;
  popoverIsOpen: boolean;
  active: boolean;
  disable: boolean;
  icon: JSX.Element;
  title: string;
  subtitle: string | undefined;
  showChange: boolean;
  onChangeClick?: () => void;
  onClick: () => void;
};

export const TileDestination: VFC<TileDestinationProps> = ({
  popoverContent,
  popoverDisabled,
  popoverIsOpen,
  active,
  disable,
  icon,
  title,
  subtitle,
  showChange,
  onClick,
  onChangeClick,
}) => {
  return (
    <Popover content={popoverContent} disabled={popoverDisabled} isOpen={popoverIsOpen}>
      <Box
        role="button"
        sx={{
          ".changeButton": {
            display: "none",
          },
          ":hover": {
            ".changeButton": {
              display: showChange ? "inline" : "none",
            },
            bg: active ? colors.primaries[1] : "",
          },
          height: "162px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          cursor: active ? "pointer" : "auto",
        }}
        onClick={onClick}
      >
        <Flex
          sx={{
            alignItems: "center",
            px: 4,
            height: "96px",
            flex: "1",
          }}
        >
          <Box sx={{ flex: "1" }}>
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>{icon}</Box>
            <Box sx={{ mt: 4, color: active ? colors.primary : disable ? colors.base[3] : "inherit" }}>
              {subtitle && <Subtitle text={subtitle} />}
              <Title title={title} />
            </Box>
          </Box>
          {showChange && typeof onChangeClick === "function" && <ChangeButton onClick={onChangeClick} />}
        </Flex>
      </Box>
    </Popover>
  );
};
