import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";

type Props = {
  sx?: ThemeUIStyleObject;
  spacing: number;
};

export const Wrap: FC<Readonly<Props>> = ({ children, sx = {}, spacing }) => {
  return <Row sx={{ ...sx, m: -spacing, "& > *:not(style)": { m: spacing }, flexWrap: "wrap" }}>{children}</Row>;
};
