import { VFC } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Box } from "theme-ui";

import { PageHeader } from "src/components/layout";
import { useOnboardingData } from "src/components/onboarding/onboarding-reducer";
import { useUser } from "src/contexts/user-context";
import { Row, Column } from "src/ui/box";

import { Footer, ProgressNav, Diagram } from "../../components/onboarding/index";
import { OnboardingPage as OldOnboardingPage } from "./onboarding-old";

const OnboardingPage: VFC = () => {
  const { onboarding, workspace } = useUser();

  const [onboardingData, setOnboardingData, onboardingLoading] = useOnboardingData(workspace!, onboarding);

  return (
    <>
      <Column sx={{ width: "100%", alignItems: "center", minHeight: "100vh" }}>
        <PageHeader
          crumbs={[{ label: "Setup", link: "/onboarding" }]}
          outsideTopbar={onboardingData.currentStep > 0 && <ProgressNav currentStep={onboardingData.currentStep} />}
        />

        <Row sx={{ width: "100%", flexGrow: "1", mt: 4, px: 6 }}>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Diagram
              currentStep={onboardingData.currentStep}
              loading={onboardingLoading}
              onboardingData={onboardingData}
              setOnboardingData={setOnboardingData}
            />
          </Box>
        </Row>
        <Footer />
      </Column>
    </>
  );
};

export const Onboarding = () => {
  const { appNewOnboarding } = useFlags();

  return appNewOnboarding ? <OnboardingPage /> : <OldOnboardingPage />;
};
