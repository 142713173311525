import { orderBy } from "lodash";
import { Text, Grid } from "theme-ui";

import { SourceDefinition } from "src/graphql";
import { AnimatedBox } from "src/ui/animations";
import { Heading } from "src/ui/heading";

import { Source } from "./source";

export const SourceCategory = ({
  categoryName,
  categoryShortDescription,
  sources,
  selection,
  onSelect,
}: {
  categoryName: string;
  categoryShortDescription: string;
  sources: SourceDefinition[];
  selection?: SourceDefinition;
  onSelect: (definition: SourceDefinition) => void;
}) => (
  <AnimatedBox key="samples" sx={{ mb: 11 }} variants={childVariants}>
    <Heading variant="h3">{categoryName}</Heading>
    <Text sx={{ mt: 2, color: "base.5", mb: 6 }}>{categoryShortDescription}</Text>
    <Grid columns={["1fr", "repeat(2, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"]} gap={4}>
      {orderBy(sources, ["name"], ["asc"]).map((source) => (
        <Source key={source.name} definition={source} selected={source.type === selection?.type} onSelect={onSelect} />
      ))}
    </Grid>
  </AnimatedBox>
);

const childVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};
