import { FC } from "react";

import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import {
  useExternalSegmentsQuery,
  useSfmcBusinessUnitsQuery,
  useSfmcColumnsQuery,
  useSfmcDataExtensionFieldsQuery,
  useSfmcDataExtensionsQuery,
  useSfmcDataFoldersQuery,
  useSfmcJourneysQuery,
} from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";
import { TypeField } from "../type-field";

export const validation = Yup.object().shape({
  type: Yup.string().required().default("objects"),
  mode: Yup.string().when("type", {
    is: "journeys",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required().default("upsert"),
  }),
  object: Yup.string().when("type", {
    is: "journeys",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required().default("contacts"),
  }),
  journeyKey: Yup.string().when("type", {
    is: "journeys",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  externalIdMapping: COMMON_SCHEMAS.externalIdMapping,
  mappings: COMMON_SCHEMAS.mappings,
  businessUnit: Yup.string().notRequired(),
  dataFolder: Yup.string().notRequired(),
  autoCreateDataExtension: Yup.boolean().notRequired(),
  customDataExtensionName: Yup.string().notRequired(),
  deleteMode: Yup.string().notRequired(),
});

const OBJECTS = [{ label: "Contacts", value: "contacts" }];

const OBJECT_MODES = [
  {
    label: "Upsert",
    value: "upsert",
    description: "Push new records and update records that change in your source.",
  },
];

const DATA_EXTENSION_MODES = [
  { label: "Upsert", value: "upsert", description: "Push new records and update records that change in your source." },
];

const TYPES = [
  {
    label: "Marketing Cloud Objects",
    value: "objects",
    description: "Sync contacts from records to Marketing Cloud Objects in your destination.",
  },
  {
    label: "Journeys",
    value: "journeys",
    description: "Add and remove contacts from journeys.",
  },
  {
    label: "Data Extensions",
    value: "dataExtensions",
    description: "Sync records to a new or existing Data Extension.",
  },
];

const STATIC_EXTERNAL_ID_OPTIONS = [{ label: "Contact Key", value: "contactKey" }];

//https://developer.salesforce.com/docs/marketing/marketing-cloud/guide/dataextensionfieldtype.html
const DATA_EXTENSION_FIELD_TYPES = [
  { label: "Boolean", value: "Boolean" },
  { label: "Date", value: "Date" },
  { label: "Decimal (Precision 18, Scale 2)", value: "Decimal" },
  { label: "Decimal (Precision 27, Scale 2)", value: "Decimal27_2" },
  { label: "Decimal (Precision 27, Scale 10)", value: "Decimal27_10" },
  { label: "Email Address", value: "EmailAddress" },
  { label: "Locale", value: "Locale" },
  { label: "Number", value: "Number" },
  { label: "Phone", value: "Phone" },
  { label: "Text", value: "Text" },
  { label: "Text - 256 characters", value: "Text256" },
];

export const SfmcForm: FC = () => {
  const { sync, config, setConfig, errors, model, destination } = useDestinationForm();

  const syncId = sync?.id;

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = useSfmcColumnsQuery(
    {
      destinationId: String(destination?.id),
      object: config?.object,
    },
    { enabled: Boolean(config?.object) },
  );

  const columns = columnsData?.sfmcListAttributeSets?.fields;

  const columnOptions = columns?.map((a) => ({ label: a?.name, value: a?.id, type: a?.standardType })) || [];

  const {
    data: journeysData,
    error: journeysError,
    isFetching: loadingJourneys,
    refetch: getJourneys,
  } = useSfmcJourneysQuery({
    destinationId: String(destination?.id),
  });

  const journeys = journeysData?.sfmcListJourneys?.journeys;

  const journeyOptions = journeys?.map((j) => ({ label: j?.name, value: j?.id })) || [];

  const {
    data: businessUnitsData,
    error: businessUnitsError,
    isFetching: loadingBusinessUnits,
    refetch: getBusinessUnits,
  } = useSfmcBusinessUnitsQuery({
    destinationId: String(destination?.id),
  });

  const businessUnits = businessUnitsData?.sfmcListBusinessUnits?.businessUnits;

  const businessUnitOptions = businessUnits?.map((bu) => ({ label: bu?.name, value: bu?.id })) || [];

  const {
    data: dataFoldersData,
    error: dataFoldersError,
    isFetching: loadingDataFolders,
    refetch: getDataFolders,
  } = useSfmcDataFoldersQuery({
    destinationId: String(destination?.id),
    businessUnit: config?.businessUnit,
  });

  const dataFolders = dataFoldersData?.sfmcListDataFolders?.dataFolders;

  // TODO build path with parents for each folder
  const dataFolderOptions = dataFolders?.map((df) => ({ label: df?.name, value: df?.id })) || [];

  const {
    data: dataExtensionsData,
    error: dataExtensionsError,
    isFetching: loadingDataExtensions,
    refetch: getDataExtensions,
  } = useSfmcDataExtensionsQuery({
    destinationId: String(destination?.id),
    businessUnit: config?.businessUnit,
    dataFolder: config?.dataFolder,
  });

  const dataExtensions = dataExtensionsData?.sfmcListDataExtensions?.dataExtensions;

  const dataExtensionOptions = dataExtensions?.map((de) => ({ label: de?.name, value: de?.id })) || [];

  const {
    data: dataExtensionFieldsData,
    error: dataExtensionFieldsError,
    isFetching: loadingDataExtensionFields,
    refetch: getDataExtensionFields,
  } = useSfmcDataExtensionFieldsQuery(
    {
      destinationId: String(destination?.id),
      dataExtensionId: config?.object,
      businessUnit: config?.businessUnit,
    },
    { enabled: Boolean(config?.type === "dataExtensions" && config?.object) },
  );

  const dataExtensionFields = dataExtensionFieldsData?.sfmcListDataExtensionFields?.fields;

  const dataExtensionFieldOptions =
    dataExtensionFields?.map((f) => ({
      label: f?.name,
      value: f?.name,
      type: f?.standardType,
      // We decided not to mark fields as required because we only support
      // `upsert` mode right now, and the user may want to sync partial data
      // that lack required fields to existing records that already have values
      // for those required fields. Leaving the field available in case we
      // support other modes later.
      // required: f?.isRequired,
      isPrimaryKey: f?.isPrimaryKey,
    })) || [];

  // External segment exists if the sync has already run and created a Data Extension
  const { data: externalSegmentIdData } = useExternalSegmentsQuery(
    {
      syncId,
    },
    { enabled: !(!syncId || config?.type !== "dataExtensions" || !config?.autoCreateDataExtension) },
  );

  const externalSegmentId = externalSegmentIdData?.external_segments?.[0]?.external_id;

  // If the external segment already exists, don't allow the user to change its name or toggle whether to create it
  const allowAutoCreateConfigChanges = !externalSegmentId;

  if (config?.type === "dataExtensions" && externalSegmentId && config?.object !== externalSegmentId) {
    setConfig({
      ...config,
      object: externalSegmentId,
    });
  }

  return (
    <>
      <TypeField options={TYPES} onChange={(type) => setConfig({ ...config, type })} />

      {config?.type === "objects" && (
        <>
          <ObjectField
            options={OBJECTS}
            onChange={(object) => {
              setConfig({ ...config, object });
            }}
          />
          <ModeField
            options={OBJECT_MODES}
            onChange={(mode) => {
              setConfig({
                ...config,
                mode,
              });
            }}
          />
          <Section>
            <IdMappingField options={STATIC_EXTERNAL_ID_OPTIONS} path={[config?.type]} />
          </Section>
          <Section>
            <MappingsField error={columnsError?.message} loading={loadingColumns} options={columnOptions} reload={getColumns} />
          </Section>
        </>
      )}

      {config?.type === "journeys" && (
        <>
          <Section>
            <Field
              description="When a record enters the query results, Hightouch will trigger the API event to add the contact to the journey. When a record leaves the query results, Hightouch will make a request for the contact to exit the journey."
              error={errors?.journeyKey || journeysError?.message}
              label={`Which journey would you like to add contacts to?`}
              size="large"
            >
              <Select
                isError={errors?.workspaceId}
                isLoading={loadingJourneys}
                options={journeyOptions}
                placeholder="Select a Marketing Cloud journey..."
                reload={getJourneys}
                value={config?.journeyKey ? journeyOptions?.find((s) => config?.journeyKey === s.value) : null}
                width="340px"
                onChange={(selected) => setConfig({ ...config, journeyKey: selected?.value })}
              />
            </Field>
          </Section>
          <Section>
            <IdMappingField options={STATIC_EXTERNAL_ID_OPTIONS} path={[config?.type]} />
          </Section>
          <Section>
            <MappingsField />
          </Section>
        </>
      )}

      {config?.type === "dataExtensions" && (
        <>
          <Section>
            <Field
              description="If this is not specified, the default Business Unit as configured in SFMC will be used."
              error={errors?.businessUnit || businessUnitsError?.message}
              label="Which Business Unit would you like to sync data to?"
              size="large"
            >
              <Select
                disabled={!allowAutoCreateConfigChanges}
                isError={errors?.businessUnit}
                isLoading={loadingBusinessUnits}
                options={businessUnitOptions}
                placeholder="Select a Business Unit..."
                reload={getBusinessUnits}
                value={config?.businessUnit ? businessUnitOptions?.find((s) => config?.businessUnit === s.value) : null}
                width="340px"
                onChange={(selected) => setConfig({ ...config, businessUnit: selected?.value })}
              />
            </Field>
          </Section>
          <Section>
            <Field
              description="If this is not specified, the default Data Folder as configured in SFMC will be used."
              error={errors?.dataFolder || dataFoldersError?.message}
              label="Which Data Folder would you like to sync data to?"
              size="large"
            >
              <Select
                disabled={!allowAutoCreateConfigChanges}
                isError={errors?.dataFolder}
                isLoading={loadingDataFolders}
                options={dataFolderOptions}
                placeholder="Select a Data Folder..."
                reload={getDataFolders}
                value={config?.dataFolder ? dataFolderOptions?.find((s) => config?.dataFolder === s.value) : null}
                width="340px"
                onChange={(selected) => setConfig({ ...config, dataFolder: selected?.value })}
              />
            </Field>
          </Section>

          {allowAutoCreateConfigChanges && (
            <Section>
              <Field
                description="The Data Extension will be created the first time the sync runs."
                label="Would you like Hightouch to automatically create a Data Extension for you?"
                size="large"
              >
                <RadioGroup
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: undefined },
                  ]}
                  value={config?.autoCreateDataExtension}
                  onChange={(autoCreateDataExtension) => {
                    setConfig({
                      ...config,
                      autoCreateDataExtension: autoCreateDataExtension,
                      object: externalSegmentId || "Custom",
                    });
                  }}
                />
              </Field>
            </Section>
          )}
          {!config?.autoCreateDataExtension && (
            <ObjectField
              error={dataExtensionsError?.message}
              loading={loadingDataExtensions}
              options={dataExtensionOptions}
              reload={getDataExtensions}
              onChange={(object) => {
                setConfig({ ...config, object });
              }}
            />
          )}
          {config?.autoCreateDataExtension && (
            <Section>
              <Field
                optional
                description="If this name is not specified, Hightouch defaults
                to using the model name to create the Data Extension. This value
                will also be used as the Data Extension's Customer Key, so it
                must be unique. It cannot be changed after the first sync run
                creates the Data Extension."
                label="Custom Data Extension Name"
                size="large"
              >
                <Input
                  defaultValue={config?.customDataExtensionName}
                  disabled={!allowAutoCreateConfigChanges}
                  error={errors?.customDataExtensionName}
                  placeholder={model?.name}
                  sx={{ width: "300px" }}
                  onChange={(customDataExtensionName) => setConfig({ ...config, customDataExtensionName })}
                />
              </Field>
            </Section>
          )}
          <Section>
            <Field
              description="If you select FTP, Hightouch will send files of
              100,000 rows each to SFMC via FTP. Then, Hightouch will
              automatically create and run an Import Definition to load the data
              into to the Data Extension. If you select SOAP, batches of 1,000
              rows are sent via SOAP to SFMC with full visibility in Hightouch
              of all requests made. In either case, the SOAP API is used. With
              FTP, you need both FTP credentials and SOAP API credentials
              configured for this SFMC destination."
              label="Would you like to use SOAP or FTP to import the data?"
              size="large"
            >
              <RadioGroup
                options={[
                  { label: "SOAP", value: undefined },
                  { label: "FTP", value: true },
                ]}
                value={config?.useFtp}
                onChange={(useFtp) => {
                  setConfig({
                    ...config,
                    useFtp,
                    deleteMode: undefined,
                  });
                }}
              />
            </Field>
          </Section>
          <ModeField
            options={DATA_EXTENSION_MODES}
            onChange={(mode) => {
              setConfig({ ...config, mode });
            }}
          />
          {config?.object && (
            <>
              <Section>
                <IdMappingField
                  creatableTypes={DATA_EXTENSION_FIELD_TYPES}
                  error={dataExtensionFieldsError?.message}
                  isCreatable={config?.autoCreateDataExtension}
                  loading={loadingDataExtensionFields}
                  options={dataExtensionFieldOptions.filter((f) => f.isPrimaryKey)}
                  path={[config?.type]}
                  reload={getDataExtensionFields}
                />
              </Section>
              <Section>
                <MappingsField
                  creatableTypes={DATA_EXTENSION_FIELD_TYPES}
                  createTypes={config?.autoCreateDataExtension}
                  error={dataExtensionFieldsError?.message}
                  isCreatable={config?.autoCreateDataExtension}
                  loading={loadingDataExtensionFields}
                  options={dataExtensionFieldOptions.filter((f) => !f.isPrimaryKey)}
                  reload={getDataExtensionFields}
                />
              </Section>
            </>
          )}
          {!config?.useFtp && <DeleteField modes={["delete"]} />}
        </>
      )}
    </>
  );
};

export default {
  form: SfmcForm,
  validation,
};
