import { VFC } from "react";

import { Grid, Text, Image } from "theme-ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Checkbox } from "src/ui/checkbox";
import { Heading } from "src/ui/heading";

type Props = {
  selected: Set<string>;
  setSelected: (selected: Set<string>) => void;
  syncs: any;
  definitions: DestinationDefinition[] | undefined;
};

export const Destinations: VFC<Readonly<Props>> = ({ setSelected, selected, syncs, definitions }) => {
  return (
    <>
      <Heading sx={{ mb: 2 }} variant="h3">
        Connect destinations
      </Heading>
      <Text sx={{ mb: 8, fontWeight: "semi", color: "base.5" }}>
        Select all the destinations you'd like to sync to. Cloned syncs are disabled by default, review the cloned configuration
        before enabling.
      </Text>

      <Row sx={{ mb: 2, justifyContent: "flex-end" }}>
        <Button
          variant="text-primary"
          onClick={() => {
            setSelected(new Set());
          }}
        >
          Deselect all
        </Button>
      </Row>
      <Grid gap={2}>
        {syncs?.map(({ id, destination }) => {
          const definition = definitions?.find(({ type }) => type === destination.type);
          const isSelected = selected.has(String(id));

          return (
            <Row
              key={id}
              sx={{
                alignItems: "center",
                py: 1,
                px: 2,
                border: "small",
                bg: "base.0",
                justifyContent: "space-between",
                cursor: "pointer",
                ":hover": {
                  bg: "base.1",
                },
                borderRadius: 1,
              }}
              onClick={() => {
                if (isSelected) {
                  selected.delete(String(id));
                  setSelected(new Set(selected));
                } else {
                  setSelected(new Set(selected.add(String(id))));
                }
              }}
            >
              <Row sx={{ alignItems: "center", minHeight: "40px" }}>
                <Checkbox sx={{ flexShrink: 0 }} value={isSelected} />
                <Image alt={definition?.name} src={definition?.icon} sx={{ mx: 2, flexShrink: 0 }} width="32px" />
                <Text sx={{ fontWeight: "bold", userSelect: "none" }}>{destination.name}</Text>
              </Row>
            </Row>
          );
        })}
      </Grid>
    </>
  );
};
