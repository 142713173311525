import { FC } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import { Page } from "src/components/layout";
import { useUser } from "src/contexts/user-context";
import { ResourcePermissionGrant } from "src/graphql";
import { Tabs } from "src/ui/tabs";
import { useNavigate } from "src/utils/navigate";

type Props = {
  route: string;
};

const UNAUTHORIZED_TABS = [
  {
    label: "Members",
    route: "members",
  },
];

const AUTHORIZED_TABS = [
  {
    label: "Workspace",
    route: "workspace",
  },
  {
    label: "Members",
    route: "members",
  },
  {
    label: "Integrations",
    route: "integrations",
  },
  { label: "Alerts", route: "alerts" },
  { label: "API Keys", route: "api-keys" },
  { label: "Storage", route: "storage" },
  { label: "Tunnels", route: "tunnels" },
  { label: "Git Sync", route: "git-sync" },
];

export const Settings: FC<Readonly<Props>> = ({ children, route }) => {
  const { featureFlags, hasPermissions } = useUser();
  const { appShowUsageTab, appAllowCheckout } = useFlags();

  const navigate = useNavigate();

  const authorized = hasPermissions([{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }]);

  const authorizedTabs = [...AUTHORIZED_TABS];

  if (authorized) {
    if (appAllowCheckout) {
      authorizedTabs.splice(3, 0, { label: "Billing", route: "billing" });
    } else if (appShowUsageTab) {
      authorizedTabs.splice(3, 0, { label: "Usage", route: "usage" });
    }
    if (featureFlags?.git_sync) {
      authorizedTabs.push({ label: "Version Control", route: "version-control" });
    }
  }

  const TABS = authorized ? authorizedTabs : UNAUTHORIZED_TABS;

  const tabs = TABS.map(({ label }) => label);

  return (
    <Page crumbs={[{ label: "Settings" }]} size="medium">
      <Tabs
        setTab={(newTab) => {
          const tab = TABS.find((tab) => tab.label === newTab);

          if (tab) {
            navigate(`/settings/${tab.route}`);
          }
        }}
        sx={{ mb: 9, position: "sticky", top: 0 }}
        tab={TABS.find((tab) => tab.route === route)?.label}
        tabs={tabs}
      />
      {children}
    </Page>
  );
};
