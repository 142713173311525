import { FC } from "react";

import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { Section } from "../../../ui/section";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";

export const validation = Yup.object().shape({
  mode: Yup.string().required().default("insert"),
  customMappings: COMMON_SCHEMAS.mappings,
});

const MODES = [{ label: "Insert", value: "insert" }];

export const TradedeskPostbackForm: FC = () => {
  const { config, setConfig } = useDestinationForm();

  return (
    <>
      <Section>
        <ModeField
          options={MODES}
          onChange={(mode) => {
            setConfig({
              mode,
            });
          }}
        />
      </Section>
      {config?.mode && (
        <Section>
          <MappingsField isCustom />
        </Section>
      )}
    </>
  );
};

export default {
  validation,
  form: TradedeskPostbackForm,
};
