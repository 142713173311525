import { VFC } from "react";

import immutableUpdate from "immutability-helper";
import { Text } from "theme-ui";

import { initialPropertyCondition, PropertyCondition, isRelatedColumn, isTraitColumn } from "src/types/visual";
import { Row } from "src/ui/box";

import { FilterProps, PlusButton } from "./condition";
import { PropertyFilter } from "./property-filter";

export type TraitFilterProps = FilterProps<PropertyCondition>;

export const TraitFilter: VFC<Readonly<TraitFilterProps>> = (props) => {
  const { condition, relationships, onChange } = props;

  const columns = relationships?.find(({ id }) => isRelatedColumn(condition.property) && id === condition.property.path[0])
    ?.to_model.filterable_audience_columns;

  const addCondition = () => {
    const update = immutableUpdate(condition, {
      property: { column: { conditions: { $push: [initialPropertyCondition] } } },
    });
    onChange(update);
  };

  const updateCondition = (index: number) => (updates: any) => {
    onChange(
      immutableUpdate(condition, {
        property: { column: { conditions: { [index]: { $merge: updates } } } },
      }),
    );
  };

  const removeCondition = (index: number) => () => {
    onChange(
      immutableUpdate(condition, {
        property: { column: { conditions: { $splice: [[index, 1]] } } },
      }),
    );
  };

  if (isRelatedColumn(condition.property) && isTraitColumn(condition.property.column)) {
    return (
      <>
        {condition.property.column.conditions.map((condition, index) => (
          <Row key={index} sx={{ alignItems: "center", pl: 12 }}>
            <Text sx={{ color: "base.4", width: "3.5rem" }}>{index === 0 ? "WHERE" : "AND"}</Text>
            <PropertyFilter
              {...props}
              columns={columns}
              condition={condition}
              traits={[]}
              onChange={updateCondition(index)}
              onRemove={removeCondition(index)}
            />
          </Row>
        ))}
        <Row sx={{ pl: 12 }}>
          <PlusButton onClick={() => addCondition()}>property</PlusButton>
        </Row>
      </>
    );
  }

  return null;
};
