import { BaseFormkitNode, FormkitNode, NodeType } from "./nodes";

export type BaseFormkitLayout = BaseFormkitNode & {
  type: NodeType.Layout;
  layout: LayoutType;
  children: FormkitNode[];
};

export enum LayoutType {
  Form = "form",
  Section = "section",
}

export type FormkitSection = BaseFormkitLayout & {
  layout: LayoutType.Section;
  heading?: string;
  subheading?: string;
  optional?: boolean;
  children: FormkitNode[];
  size?: "small" | "large";
};

export type FormkitForm = BaseFormkitLayout & {
  layout: LayoutType.Form;
  // Optional array of key-key edges that should not be topographically
  // sorted before resolving (Yup-specific thing that you should avoid using
  // unless you see "cyclic dependency errors")
  noSortEdges?: [string, string][];
};

export type FormkitLayout = FormkitSection | FormkitForm;
