import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";

import { Indices } from "../../../../design";

export interface BannerProps {
  sx?: ThemeUIStyleObject;
}

export const Banner: FC<Readonly<BannerProps>> = ({ children, sx }) => {
  return (
    <Row
      sx={{
        width: "100%",
        position: "sticky",
        zIndex: Indices.Popup,
        top: 0,
        px: 4,
        py: 2,
        bg: "red",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      {children}
    </Row>
  );
};

Banner.displayName = "Banner";
