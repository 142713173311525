import { VFC, useCallback } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";
import { Button } from "src/ui/button";

import { ChevronLeftIcon, ChevronRightIcon } from "../icons";

export const BasicPagination: VFC<
  Readonly<{
    setPage: (page: number) => void;
    page: number;
    disableNextPage: boolean;
    sx?: ThemeUIStyleObject;
  }>
> = ({ setPage, page, sx = {}, disableNextPage }) => {
  const next = useCallback(() => {
    setPage(page + 1);
  }, [setPage, page]);

  const prev = useCallback(() => {
    setPage(page - 1);
  }, [setPage, page]);

  if (disableNextPage && page === 0) {
    return null;
  }

  return (
    <Row sx={{ mt: 4, alignSelf: "flex-end", ...sx }}>
      <Button
        disabled={page === 0}
        iconBefore={<ChevronLeftIcon size={18} />}
        size="small"
        variant="secondary"
        onClick={() => prev()}
      >
        Previous
      </Button>
      <Button
        disabled={disableNextPage}
        iconAfter={<ChevronRightIcon size={18} />}
        size="small"
        sx={{ ml: 2 }}
        variant="secondary"
        onClick={() => next()}
      >
        Next
      </Button>
    </Row>
  );
};
