import { VFC, useEffect, useState } from "react";

import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import { Flex, Grid } from "theme-ui";

import { Container } from "src/ui/box";

import { useLookerCredentialsQuery, useCreateLookerCredentialMutation, useUpdateLookerCredentialMutation } from "../../graphql";
import { Button } from "../../ui/button";
import { Field } from "../../ui/field";
import { Input } from "../../ui/input";
import { Section } from "../../ui/section";
import { Help } from "../help";
import { TunnelSelect } from "../tunnels/tunnel-select";

interface LookerCreds {
  id?: number;
  tunnel_id?: string;
  url?: string | null;
  client_id?: string;
  client_secret?: string;
}

export const LookerCredentials: VFC = () => {
  const { addToast } = useToasts();

  const [saveLoading, setSaveLoading] = useState(false);
  const [lookerCredentials, setLookerCredentials] = useState<LookerCreds | undefined>();

  const { mutateAsync: createCredential } = useCreateLookerCredentialMutation();
  const { mutateAsync: updateCredential } = useUpdateLookerCredentialMutation();

  const { data: lookerCredentialsData, isLoading: lookerCredentialsLoading } = useLookerCredentialsQuery();

  const initialLookerCredentials = lookerCredentialsData?.looker_credentials?.[0];

  useEffect(() => {
    if (lookerCredentialsData?.looker_credentials?.[0]) {
      setLookerCredentials(lookerCredentialsData.looker_credentials[0]);
    }
  }, [lookerCredentialsData]);

  const save = async () => {
    setSaveLoading(true);
    try {
      if (lookerCredentials?.id) {
        await updateCredential({
          id: lookerCredentials.id,
          credentials: {
            url: lookerCredentials.url || null,
            tunnel_id: lookerCredentials.tunnel_id || null,
            client_id: lookerCredentials.client_id,
            client_secret: lookerCredentials.client_secret,
          },
        });
      } else if (lookerCredentials) {
        const { insert_looker_credentials_one } = await createCredential({
          credentials: {
            url: lookerCredentials.url,
            tunnel_id: lookerCredentials.tunnel_id,
            client_id: lookerCredentials.client_id,
            client_secret: lookerCredentials.client_secret,
          },
        });

        if (insert_looker_credentials_one) {
          setLookerCredentials({
            ...lookerCredentials,
            id: insert_looker_credentials_one.id,
          });
        }
      }

      addToast("Your Looker integration configuration has been saved.", {
        appearance: "success",
      });
    } catch (err) {
      addToast("There was an error saving your Looker configuration.", {
        appearance: "error",
      });
    } finally {
      setSaveLoading(false);
    }
  };

  const dirty = typeof lookerCredentials !== "undefined" && !_.isEqual(lookerCredentials, initialLookerCredentials);

  return (
    <>
      <Section
        divider
        footer={
          <Flex sx={{ justifyContent: "flex-end" }}>
            <Button disabled={!dirty} label={"Save"} loading={saveLoading || lookerCredentialsLoading} onClick={save} />
          </Flex>
        }
      >
        <Container center={false} size="small">
          <Field
            description={`Hightouch can leverage Looker to define models. In order to enable this feature across your Hightouch workspace, please enter your Looker credentials.`}
            label="Looker credentials"
            size="large"
          >
            <Grid gap={8}>
              <TunnelSelect
                value={lookerCredentials?.tunnel_id ? { id: lookerCredentials?.tunnel_id } : undefined}
                onChange={(tunnel) => {
                  setLookerCredentials((previousLookerCredentials) => ({
                    ...previousLookerCredentials,
                    tunnel_id: tunnel?.id,
                  }));
                }}
              />
              <Field label="URL">
                <Input
                  disabled={!!lookerCredentials?.tunnel_id}
                  value={lookerCredentials?.tunnel_id ? "Using tunnel" : lookerCredentials?.url}
                  onChange={(url) => {
                    setLookerCredentials((previousLookerCredentials) => ({
                      ...previousLookerCredentials,
                      url,
                    }));
                  }}
                />
              </Field>
              <Field label="Client ID">
                <Input
                  value={lookerCredentials?.client_id}
                  onChange={(client_id) => {
                    setLookerCredentials((previousLookerCredentials) => ({
                      ...previousLookerCredentials,
                      client_id,
                    }));
                  }}
                />
              </Field>
              <Field label="Client Secret">
                <Input
                  type="password"
                  value={lookerCredentials?.client_secret}
                  onChange={(client_secret) => {
                    setLookerCredentials((previousLookerCredentials) => ({
                      ...previousLookerCredentials,
                      client_secret,
                    }));
                  }}
                />
              </Field>
            </Grid>
          </Field>
        </Container>

        {<Help docs={`${import.meta.env.VITE_DOCS_URL}/models/looker-models`} label={"Looker"} sx={{ mt: 6 }} />}
      </Section>
    </>
  );
};
