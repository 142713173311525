import { FormkitReference } from "./references";

export type FormkitBoolean = UnaryBoolean | BinaryBoolean | boolean;

type BaseBoolean = {
  type: BooleanType;
};

export enum BooleanType {
  Unary = "unary",
  Binary = "binary",
}

export type UnaryBoolean = BaseBoolean & {
  type: BooleanType.Unary;
  operand: BooleanOperand | FormkitBoolean;
  operator?: UnaryBooleanOperator;
};

export type BinaryBoolean = BaseBoolean & {
  type: BooleanType.Binary;
  leftOperand: BooleanOperand;
  operator: BinaryBooleanOperator;
  rightOperand: BooleanOperand;
};

export type BooleanOperand = FormkitReference | string | number | boolean;

export enum UnaryBooleanOperator {
  Not = "not",
}

export enum BinaryBooleanOperator {
  Equals = "equals",
  NotEquals = "notEquals",
  GreaterThan = "greaterThan",
  LessThan = "lessThan",
  GreaterThanOrEqual = "greaterThanOrEqual",
  LessThanOrEqual = "lessThanOrEqual",
  And = "and",
  Or = "or",
}
