import { VFC } from "react";

import { IconProps, Icon } from "./icon";

export const ClockIcon: VFC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" />
      <path d="M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z" />
    </Icon>
  );
};
