import { VFC, useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Grid } from "theme-ui";

import { Destinations } from "src/components/clone/destinations";
import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { Slug } from "src/components/slug";
import { useUser } from "src/contexts/user-context";
import { useCreateModelMutation, useModelQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Select } from "src/ui/select";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { useDestinations } from "src/utils/destinations";
import { QueryType, useModelRun, useModelState, useQueryState } from "src/utils/models";
import { generateSlug } from "src/utils/slug";
import { useSource } from "src/utils/sources";

export const CloneModel: VFC = () => {
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { user } = useUser();
  const [step, setStep] = useState<number>(0);
  const [selectedSyncs, setSelectedSyncs] = useState<Set<string>>(new Set());
  const { queryState, setSQL, setTable, setDBTModel, initQueryState, setCustomQuery } = useQueryState();
  const { modelState, setName, setPrimaryKey, initModelState, setSlug } = useModelState();
  const [type, setType] = useState<QueryType | undefined>();

  const { data: modelData, isLoading: modelLoading } = useModelQuery({ id: id ?? "" }, { enabled: Boolean(id) });

  const model = modelData?.segments_by_pk;

  const syncs = model?.syncs;

  const { mutateAsync: createModel, isLoading: creating } = useCreateModelMutation();

  const {
    data: { definitions },
    loading: destinationsLoading,
    error: destinationsError,
  } = useDestinations();
  const { data: source, loading: sourcesLoading, error: sourcesError } = useSource(model?.connection?.id);

  const create = async () => {
    await createModel({
      input: {
        query_type: type,
        name: modelState?.name,
        primary_key: modelState?.primaryKey,
        connection_id: source?.id,
        slug: modelState?.slug,
        created_by: user?.id != null ? String(user?.id) : undefined,
        query_dbt_model_id: queryState?.dbtModel?.id,
        query_looker_look_id: queryState?.lookerLook?.id,
        query_table_name: queryState?.table,
        query_raw_sql: queryState?.sql,
        custom_query: queryState?.customQuery,
        columns: { data: columns.map(({ name, type }) => ({ name, type })) },
        git_sync_metadata:
          type === "dbt"
            ? {
                git_sync_config_id: queryState?.dbtModel?.git_sync_config?.id,
                file_path: queryState?.dbtModel?.original_file_path,
                dbt_model_id: queryState?.dbtModel?.id,
              }
            : null,
        destination_instances: {
          data: selectedSyncs.size
            ? syncs
                ?.filter(({ id }) => selectedSyncs.has(String(id)))
                ?.map(({ destination, config, schedule, sync_alerts, row_threshold_attempted, row_threshold_total }) => ({
                  destination_id: destination?.id,
                  config,
                  schedule,
                  slug: generateSlug(`${modelState?.name}-to-${destination?.name}`),
                  schedule_paused: true,
                  created_by: user?.id != null ? String(user?.id) : undefined,
                  row_threshold_attempted,
                  row_threshold_total,
                  alert_instances: {
                    data: sync_alerts.map((alert) => ({
                      alert_id: alert.id,
                      fatal_error: alert.fatal_error,
                      row_error: alert.row_error,
                    })),
                  },
                })) ?? []
            : [],
        },
      },
    });

    addToast(`Cloned ${model!.name} and ${selectedSyncs?.size} syncs!`, {
      appearance: "success",
    });

    navigate("/models");
  };

  const {
    runQuery,
    getSchema,
    cancelQuery,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(type, undefined, {
    modelId: model?.id != null ? String(model?.id) : undefined,
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  useEffect(() => {
    if (model) {
      initQueryState(model);
      initModelState(model);
      setName("");
      setType(model?.query_type as QueryType);
    }
  }, [model]);

  useEffect(() => {
    if (syncs) {
      setSelectedSyncs(new Set(syncs.map((sync) => String(sync.id))));
    }
  }, [syncs]);

  if (modelLoading || destinationsLoading || sourcesLoading) {
    return <PageSpinner />;
  }

  const steps: Step[] = [
    {
      pageSize: "xlarge",
      title: "Change model",
      hideContinue: true,
      render: () => (
        <ExploreWithSave
          cancelQuery={cancelQuery}
          columns={columns}
          getSchema={getSchema}
          isResultTruncated={Boolean(isResultTruncated)}
          numRowsWithoutLimit={numRowsWithoutLimit}
          rows={rows}
          runQuery={runQuery}
          saveLabel="Continue"
          source={source}
          type={type}
          onSave={async () => {
            setStep(step + 1);
          }}
          {...queryState}
          error={queryError || destinationsError?.message || sourcesError?.message}
          errorAtLine={queryErrorAtLine}
          loading={queryLoading || destinationsLoading || sourcesLoading}
          rowsPerPage={15}
          onCustomQueryChange={setCustomQuery}
          onDBTModelChange={setDBTModel}
          onSQLChange={setSQL}
          onTableChange={setTable}
          onTypeChange={setType}
        />
      ),
    },
  ];

  if (syncs?.length) {
    steps.push({
      pageSize: "small",
      title: "Connect destinations",
      render: () => (
        <Destinations definitions={definitions} selected={selectedSyncs} setSelected={setSelectedSyncs} syncs={syncs} />
      ),
    });
  }

  steps.push({
    pageSize: "small",
    title: "Finish",
    disabled: !modelState?.name || !modelState?.primaryKey || !modelState?.slug || !modelState?.available,
    loading: creating,
    render: () => {
      const columnOptions = columns?.map(({ name }) => ({ value: name, label: name }));
      return (
        <>
          <Heading sx={{ mb: 8 }} variant="h3">
            Finalize your settings
          </Heading>
          <Grid gap={12}>
            <Field label="Name">
              <Input placeholder={model?.name} value={modelState?.name} onChange={setName} />
            </Field>
            <Field label="Slug">
              <Slug
                available={Boolean(modelState.available)}
                loading={modelState.loadingSlug}
                placeholder={model?.slug ?? ""}
                value={modelState.slug}
                onChange={setSlug}
              />
            </Field>
            <Field
              help="A primary key is column that contains unique values to identify each of the rows (e.g, email, id)."
              label="Primary key"
            >
              <Select
                options={columnOptions}
                placeholder="Select a column as the primary key (usually id)"
                value={modelState?.primaryKey}
                onChange={({ value }) => {
                  setPrimaryKey(value);
                }}
              />
            </Field>
          </Grid>
        </>
      );
    },
  });

  return (
    <Wizard
      setStep={setStep}
      step={step}
      steps={steps}
      title="Clone a model"
      onCancel={() => {
        navigate(`/models/${id}`);
      }}
      onSubmit={create}
    />
  );
};
