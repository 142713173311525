import { ReactNode, FC } from "react";

import { Popout } from "src/ui/popout";

import { HStack } from "./condition";

type Props = {
  content: ReactNode;
};

export const Filter: FC<Readonly<Props>> = ({ content, children }) => {
  return (
    <Popout
      content={
        <HStack gap={4} sx={{ overflowX: "auto" }}>
          {content}
        </HStack>
      }
      contentSx={{ p: 2 }}
      minHeight={32}
      sx={{ flex: 1, maxWidth: "fit-content" }}
    >
      <HStack
        gap={1}
        sx={{
          border: "small",
          height: "32px",
          alignItems: "center",
          bg: "white",
          borderRadius: 1,
          px: 2,
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </HStack>
    </Popout>
  );
};
