import { VFC, useState, useEffect } from "react";

import { Text } from "theme-ui";

import { useDbtSyncModelsQuery } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Box, Row, Column, Wrap } from "src/ui/box";
import { ReloadButton } from "src/ui/button";
import { DBTIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";
import { UseSourcesResult, SourceTile } from "src/utils/sources";

import { Editor } from "../editor";
import { DbtQuery } from "./preview/dbt-query";
import { SelectorRow } from "./selector-row";

type Source = UseSourcesResult["data"][0];

type Props = {
  dbtModelId: any;
  onChange: (dbtModel: any) => void;
  source: Source;
};

export const DBTModelSelector: VFC<Readonly<Props>> = ({ dbtModelId, onChange, source }) => {
  const [search, setSearch] = useState<string>("");

  const {
    data,
    isFetching: loading,
    refetch,
  } = useDbtSyncModelsQuery(
    {
      sourceId: source?.id,
    },
    { enabled: Boolean(source?.id) },
  );

  const noDBTModels = !loading && !data?.dbt_sync_models?.length;

  const dbtModel = data?.dbt_sync_models?.find(({ id }) => id === dbtModelId);
  const available = data?.dbt_sync_models?.filter((model) => model?.removed !== true);

  useEffect(() => {
    if (noDBTModels && !loading) {
      analytics.track("Add Model Query Mode Error", {
        source_type: source?.type,
        source_name: source?.name,
        query_mode: "dbt_model",
        error: "No dbt models were synced",
      });
    }
  }, [loading, noDBTModels]);

  if (noDBTModels) {
    return (
      <Column sx={{ pt: 10, justifyContent: "center", alignItems: "center", width: "100%" }}>
        <Text sx={{ color: "red" }}>No dbt models were synced from the git repository associated to this source.</Text>
        <Text sx={{ color: "red" }}>
          Please add your git credentials in the <Link to="/settings/integrations">settings page</Link> and select a repository
          in your <Link to={`/sources/${source?.id}`}>source</Link>.
        </Text>
      </Column>
    );
  }

  const models = search
    ? available?.filter(({ schema, name, data }) => {
        const lowercaseSearch = search.toLowerCase();
        return (
          schema?.toLowerCase()?.includes(lowercaseSearch) ||
          name?.toLowerCase()?.includes(lowercaseSearch) ||
          data?.alias?.toLowerCase()?.includes(lowercaseSearch)
        );
      })
    : available;

  return (
    <Column sx={{ flex: 1, overflow: "hidden" }}>
      <Row sx={{ pb: 2, borderBottom: "small", width: "100%" }}>
        <Wrap spacing={2} sx={{ alignItems: "center", flexShrink: 0 }}>
          <SourceTile iconSx={{ width: "24px" }} source={source} />
          <ReloadButton loading={loading} onClick={() => refetch()} />
          <SearchInput placeholder="Search dbt models..." sx={{ width: "unset" }} value={search} onChange={setSearch} />
        </Wrap>
      </Row>

      {loading ? (
        <Column sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
          <Spinner size={64} />
        </Column>
      ) : (
        <Row sx={{ overflow: "hidden" }}>
          <Column sx={{ overflow: "auto", flex: 1 }}>
            {models?.map((model) => {
              const active = model?.id === dbtModelId;
              return (
                <SelectorRow key={model?.id} icon={<DBTIcon size={16} />} selected={active} onClick={() => onChange(model)}>
                  {`${model?.schema}.${model?.data?.alias || model?.name}`}
                </SelectorRow>
              );
            })}
            {search && models?.length === 0 && (
              <Column sx={{ flex: 1, alignItems: "center", mt: 4, p: 4 }}>
                <Text sx={{ fontSize: 3, fontWeight: "semi" }}>
                  {search ? "No dbt models match your search" : "No dbt models found"}
                </Text>
                <Text sx={{ color: "base.5", mt: 2 }}>Try refreshing to populate new dbt models</Text>
              </Column>
            )}
          </Column>
          {dbtModel && (
            <Column sx={{ overflow: "auto", pt: 2, flex: 1, ml: 4 }}>
              <DbtQuery model={dbtModel} />
              <Box sx={{ width: "100%", flex: "1", minHeight: 0, marginTop: "24px", border: "small", borderRadius: 1 }}>
                <Editor readOnly language="sql" value={dbtModel?.data?.compiled_sql} />
              </Box>
            </Column>
          )}
        </Row>
      )}
    </Column>
  );
};
