import { VFC } from "react";

import _ from "lodash";
import { Text, Grid } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { Editor } from "src/ui/editor";
import { VariableIcon } from "src/ui/icons";
import { flattenOptions } from "src/ui/select/select";

import { useFormkitContext } from "./formkit-context";
import { FormProps } from "./types";

export const TemplateInput: VFC<Readonly<FormProps>> = ({ value, onChange, templates }) => {
  const { columns } = useFormkitContext();
  // TODO: Properly support merged columns
  const normalizedColumns = _.uniqBy(
    flattenOptions(columns).map((option) => ({ label: option.label, value: option.label })),
    "value",
  );
  const sortedColumns = _.orderBy(normalizedColumns, "label");
  const columnsWithContext = [
    ...sortedColumns,
    { label: "Model Name", value: "context['model_name']" },
    { label: "Timestamp", value: "context['timestamp']" },
  ];
  const unique = _.uniq(columnsWithContext);
  const sortedTemplates = _.orderBy(templates, "name");

  const insertVar = (val: string) => {
    let template = value.template;
    const isContext = val === "context['model_name']" || val === "context['timestamp']";
    if (!template || template.length === 0) {
      template = isContext ? `{{ ${val} }}` : `{{ row['${val}'] }}`;
      onChange({ ...value, template: template });
      return;
    }

    // if template already has closing braces, insert variable on its own
    template = template.trimRight();
    if (template.endsWith("}}")) {
      template = isContext ? `${template} {{ ${val} }}` : `${template} {{ row['${val}'] }}`;
      onChange({ ...value, template: template });
      return;
    }

    //if template ends with row, append field
    if (template.endsWith("row")) {
      template = isContext ? `${template.substring(0, template.length - 3)}${val} }}` : `${template}['${val}'] }}`;
    } else if (template.endsWith("row.")) {
      template = isContext
        ? `${template.substring(0, template.length - 4)}${val} }}`
        : template.substring(0, template.length - 1);
      template = `${template}['${val}'] }}`;
    } else if (template.endsWith("{{")) {
      template = isContext ? `${template} ${val} }}` : `${template} row['${val}'] }}`;
    } else {
      //add closing braces and append field
      template = isContext ? `${template} }} {{ ${val} }}` : `${template} }} {{ row['${val}'] }}`;
    }
    onChange({ ...value, template: template });
  };

  const insertFunction = (val: string, placeholders?: string[]) => {
    let template = value.template;
    //function with placeholders i.e. includes: 'substring'
    const insertVal = placeholders ? `${val} : ${placeholders.join(", ")}` : `${val}`;

    //if template editor is empty fill with placeholder variable and append function
    if (!template || template.length === 0) {
      template = `{{ row['<field>'] | ${insertVal} }}`;
      onChange({ ...value, template: template });
      return;
    }
    template = template.trimRight();

    //if template ends with opening brackets, add placeholder var and append function
    if (template.endsWith("{{")) {
      template = `${template} row['<field>'] | ${insertVal} }}`;
      onChange({ ...value, template: template });
      return;
    }

    //if template is closed, pipe function at end
    if (template.endsWith("}}")) template = template.substring(0, template.length - 2);
    template = template.trimRight();
    if (!template.endsWith("|")) {
      template = `${template} |`;
    }
    template = `${template} ${insertVal} }}`;
    onChange({ ...value, template: template });
  };

  return (
    <Grid gap={2} sx={{ flex: "1 1 0", width: "100%", overflow: "hidden", p: 4 }}>
      <Row>
        <Editor
          code={value.template || ""}
          language="liquid"
          sx={{ height: "120px" }}
          onChange={(val) => onChange({ ...value, template: val })}
        />
      </Row>
      <Row sx={{ flex: 1, overflow: "hidden" }}>
        <Column sx={{ mr: 2, width: "140px" }}>
          <Row
            sx={{
              borderBottom: "medium",
              alignItems: "center",
              fontWeight: "semi",
              fontSize: 1,
            }}
            onClick={() => onChange({ type: "template" })}
          >
            <Text>Variables</Text>
          </Row>
          <Column sx={{ overflow: "auto", scrollbarWidth: "none" }}>
            {unique.map(({ label, value }) => {
              return (
                <Row
                  key={`template-${label}`}
                  sx={{
                    minHeight: "36px",
                    alignItems: "center",
                    fontSize: 0,
                    fontWeight: "semi",
                    cursor: "pointer",
                    px: 1,
                    borderBottom: "small",
                    pointerEvents: undefined,
                    ":hover": { bg: "base.0" },
                  }}
                  onClick={() => {
                    insertVar(value);
                  }}
                >
                  <VariableIcon color="base.5" size={18}></VariableIcon>
                  <Text sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{label}</Text>
                </Row>
              );
            })}
          </Column>
        </Column>
        <Column>
          <Row
            sx={{
              fontWeight: "semi",
              borderBottom: "medium",
              cursor: "default",
              fontSize: 1,
              alignItems: "center",
            }}
            onClick={() => onChange({ type: "template" })}
          >
            <Text>Functions</Text>
          </Row>
          <Column sx={{ overflow: "auto", scrollbarWidth: "none" }}>
            {sortedTemplates.map((template) => {
              return (
                <Row
                  key={template.name}
                  sx={{
                    minHeight: "36px",
                    alignItems: "center",
                    fontSize: 0,
                    cursor: "pointer",
                    p: 1,
                    borderBottom: "small",
                    pointerEvents: undefined,
                    ":hover": { bg: "base.0" },
                  }}
                  onClick={() => {
                    insertFunction(template.name, template.placeholders);
                  }}
                >
                  <Text
                    sx={{
                      fontWeight: "semi",
                      pr: 2,
                      flex: 1,
                      minWidth: "80px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {template.name}
                  </Text>
                  <Text sx={{ color: "base.5", flex: 3, pl: 2 }}>{template.description}</Text>
                </Row>
              );
            })}
          </Column>
        </Column>
      </Row>
    </Grid>
  );
};
