import { FC, useEffect, useMemo, useState } from "react";

import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";

import { useUser } from "./user-context";

const clientSideID = import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID?.toString() ?? "";

const Container: FC = ({ children }) => <>{children}</>;

const Loader = ({ children }) => {
  const { user, workspace } = useUser();

  if (!user) {
    return children;
  }

  return (
    <FeatureFlagProvider user={user} workspace={workspace}>
      {children}
    </FeatureFlagProvider>
  );
};

export const FeatureFlagProvider = ({ user, workspace, children }) => {
  const Provider = useMemo(
    () =>
      withLDProvider({
        clientSideID,

        // Configure user session.
        user: {
          key: `user-${user.id.toString()}`,
          name: user.name,
          email: user.email,
          custom: {
            workspaceId: workspace?.id,
          },
        },
      })(Container),
    [user.id, user.name, user.email, workspace?.id], // We only need to change if the workspace.id changes, other wise any change to workspace e.g. onboarding will cause a complete rerender
  );

  return (
    <Provider>
      <FeatureFlagLoader>{children}</FeatureFlagLoader>
    </Provider>
  );
};

const FeatureFlagLoader = ({ children }) => {
  const launchDarkly = useLDClient();
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (launchDarkly) {
      launchDarkly.waitUntilReady().then(() => setReady(true));
    }
  }, [launchDarkly]);

  if (ready) {
    return <>{children}</>;
  }

  return null;
};

export default Loader;
