import { Checkbox } from "src/ui/checkbox";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { SensitiveField } from "src/ui/sensitive-field";

export const DatabricksForm = ({ config, setConfig, error, hideSecret = false }) => {
  return (
    <>
      <Field label="Server Hostname">
        <Input
          placeholder="*.cloud.databricks.com"
          value={config?.host || ""}
          onChange={(value) => setConfig({ ...config, host: value })}
        />
      </Field>

      <Field label="Port">
        <Input placeholder="443" value={config?.port || ""} onChange={(value) => setConfig({ ...config, port: value })} />
      </Field>

      <Field label="HTTP Path">
        <Input
          placeholder="sql/protocolv1/*"
          value={config?.httpPath || ""}
          onChange={(value) => setConfig({ ...config, httpPath: value })}
        />
      </Field>

      <SensitiveField
        hideSecret={hideSecret}
        label="Access Token"
        value={config?.token || ""}
        onChange={(token) => setConfig({ ...config, token })}
      />

      <Field label="Default Schema">
        <Input
          placeholder="default"
          value={config?.defaultSchema || ""}
          onChange={(value) => setConfig({ ...config, defaultSchema: value })}
        />
      </Field>

      <Field label="Use only HiveSQL native queries (required for Warehouse planning)">
        <Checkbox
          value={config?.useNativeQuery || false}
          onChange={(value) => setConfig({ ...config, useNativeQuery: Boolean(value) })}
        />
      </Field>

      <FieldError error={error} />
    </>
  );
};
