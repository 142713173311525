import { VFC } from "react";

import { IconProps, Icon } from "./icon";

export const ExtensionsIcon: VFC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 20 21">
      <path
        clipRule="evenodd"
        d="M8.70435 1.76898C8.03402 1.76898 7.46373 2.2348 7.30365 2.86265H12.6863C12.5263 2.2348 11.956 1.76898 11.2856 1.76898H8.70435ZM14.2071 2.86264H16.1881C18.2891 2.86264 20 4.59428 20 6.72085C20 6.72085 19.94 7.6211 19.92 8.87477C19.918 8.97401 19.8699 9.07122 19.7909 9.12996C19.3097 9.4854 18.8694 9.77907 18.8294 9.79932C17.1686 10.9132 15.2386 11.697 13.1826 12.0869C13.0485 12.1132 12.9165 12.0434 12.8484 11.9239C12.2721 10.9254 11.1956 10.2753 9.995 10.2753C8.8024 10.2753 7.71586 10.9183 7.12256 11.9178C7.05353 12.0353 6.92346 12.1031 6.7904 12.0778C4.75138 11.6869 2.82141 10.9041 1.17059 9.80945L0.210105 9.14109C0.130065 9.09046 0.08004 8.99932 0.08004 8.89806C0.050025 8.38161 0 6.72085 0 6.72085C0 4.59428 1.71086 2.86264 3.81191 2.86264H5.78289C5.97299 1.3943 7.2036 0.25 8.70435 0.25H11.2856C12.7864 0.25 14.017 1.3943 14.2071 2.86264ZM19.6598 11.0652L19.6198 11.0855C17.5988 12.4424 15.1676 13.3437 12.6163 13.7184C12.2561 13.769 11.8959 13.5361 11.7959 13.1716C11.5758 12.3412 10.8654 11.7943 10.015 11.7943H10.005H9.98499C9.13457 11.7943 8.42421 12.3412 8.2041 13.1716C8.10405 13.5361 7.74387 13.769 7.38369 13.7184C4.83242 13.3437 2.4012 12.4424 0.38019 11.0855C0.370185 11.0754 0.270135 11.0146 0.190095 11.0652C0.10005 11.1159 0.10005 11.2374 0.10005 11.2374L0.170085 16.4019C0.170085 18.5285 1.87094 20.25 3.97199 20.25H16.018C18.1191 20.25 19.8199 18.5285 19.8199 16.4019L19.9 11.2374C19.9 11.2374 19.9 11.1159 19.8099 11.0652C19.7599 11.0349 19.6999 11.045 19.6598 11.0652ZM10.7454 15.3083C10.7454 15.7336 10.4152 16.0677 9.995 16.0677C9.58479 16.0677 9.24462 15.7336 9.24462 15.3083V14.0019C9.24462 13.5867 9.58479 13.2424 9.995 13.2424C10.4152 13.2424 10.7454 13.5867 10.7454 14.0019V15.3083Z"
        fillRule="evenodd"
      />
    </Icon>
  );
};
