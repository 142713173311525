import { FC, useMemo } from "react";

import { Text, Image, ThemeUIStyleObject } from "theme-ui";

import { QueryColumn, SuccessfulQueryResponse } from "src/graphql";
import { Column } from "src/ui/box";
import { Link } from "src/ui/link";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import ErrorSrc from "src/ui/table/error.svg";

export interface ResultsProps {
  columns: QueryColumn[] | undefined;
  rows: SuccessfulQueryResponse["rows"] | undefined;
  rowsPerPage?: number;
  numRowsWithoutLimit?: number;
  isResultTruncated: boolean;
  error?: string;
  sx?: ThemeUIStyleObject;
}

export type SortInput = { property: string } | { aggregation: number };

export const Results: FC<Readonly<ResultsProps>> = ({
  columns,
  rows,
  rowsPerPage,
  numRowsWithoutLimit,
  isResultTruncated,
  error,
  sx,
}) => {
  const { offset, limit, page, setPage } = useTableConfig({ limit: rowsPerPage });
  const count = rows?.length ?? 0;

  const tableColumns = useMemo(
    () =>
      (columns || []).map(({ name }) => ({
        key: name,
        name,
        cell: (value) =>
          typeof value === "object" || typeof value === "boolean" ? (
            <Text
              sx={{
                ":first-letter": {
                  textTransform: typeof value === "boolean" ? "capitalize" : undefined,
                },
              }}
            >
              {JSON.stringify(value)}
            </Text>
          ) : (
            value
          ),
      })),
    [columns],
  );

  if (!rows && !error) {
    return null;
  }

  let numTotalResults: number | undefined;
  let isNumTotalResultsExact: boolean | undefined;

  if (rows) {
    if (numRowsWithoutLimit != null) {
      numTotalResults = numRowsWithoutLimit;
      isNumTotalResultsExact = true;
    } else {
      // If we don't have an explicit count for the total number of rows, we
      // only know the exact total row count if the results aren't truncated
      // (since if there were more rows in the result, they would've been
      // returned by the preview).
      numTotalResults = rows.length;
      isNumTotalResultsExact = !isResultTruncated;
    }
  }

  return (
    <Column sx={{ flex: 1, overflow: "hidden", ...sx }}>
      <Table
        columns={tableColumns}
        data={rows?.slice(offset, offset + limit)}
        error={Boolean(error)}
        placeholder={{
          customError: (
            <Column sx={{ overflow: "hidden", flex: 1, p: 4, pt: 0 }}>
              <Text sx={{ fontWeight: "semi", fontSize: 2, color: "red", mb: 4, textAlign: "center" }}>Error</Text>
              <Column sx={{ flex: 1, overflow: "auto" }}>
                <Text sx={{ whiteSpace: "pre-line" }}>{error}</Text>
              </Column>
            </Column>
          ),
          custom: (
            <Column sx={{ alignItems: "center", flex: 1 }}>
              <Image src={ErrorSrc} sx={{ mb: 4 }} />
              <Text variant="h2">No results</Text>
              <Text sx={{ mt: 2, maxWidth: "500px", textAlign: "center" }}>
                Modify the query to return at least one row, or{" "}
                <Link
                  onClick={() => {
                    if (typeof (window as any)?.Intercom !== "undefined") {
                      (window as any)?.Intercom(
                        "showNewMessage",
                        `Could you help me create a model for a query with no results?`,
                      );
                    }
                  }}
                >
                  message us on Intercom
                </Link>{" "}
                if your query cannot return results.
              </Text>
            </Column>
          ),
        }}
      />

      <Pagination
        count={count}
        disabledTooltip={isResultTruncated ? `Preview is limited to ${rows?.length} rows` : undefined}
        page={page}
        rowsPerPage={limit}
        setPage={setPage}
        text={`${numTotalResults}${isNumTotalResultsExact ? "" : "+"} row${
          typeof numTotalResults === "number" && numTotalResults > 1 ? "s" : ""
        }`}
      />
    </Column>
  );
};
