import { useState, VFC } from "react";

import moment from "moment";
import pluralize from "pluralize";
import { Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";
import { Banner } from "src/ui/banner";
import { Button } from "src/ui/button";
import { Link } from "src/ui/link";
import { Modal } from "src/ui/modal";

export const TrialBanner: VFC = () => {
  const { workspace } = useUser();
  const [showContactModal, setShowContactModal] = useState<boolean>(false);

  if (!workspace || !workspace?.trial_ends_at) {
    return null;
  }

  const hasTrialExpired = moment(workspace.trial_ends_at, "YYYY-MM-DD").isBefore(moment());

  analytics.track("Trial Banner Shown", {
    workspace_id: workspace.id,
    has_trial_expired: hasTrialExpired,
  });

  const onContactClick = () => {
    analytics.track("Trial Banner CTA Clicked", {
      workspace_id: workspace.id,
      has_trial_expired: hasTrialExpired,
    });
    setShowContactModal(true);
  };

  const closeModal = () => {
    setShowContactModal(false);
  };

  const daysLeft = workspace.trial_ends_at ? moment(workspace.trial_ends_at, "YYYY-MM-DD").diff(moment(), "days") + 1 : 0;

  return (
    <Banner sx={{ py: 3, bg: "indigos.3" }}>
      <Text sx={{ fontWeight: "bold", color: "white", fontSize: 1, mx: 4 }}>
        {hasTrialExpired ? (
          <span>
            Your free trial of the Team plan has expired.{" "}
            <Link
              sx={{ color: "yellow", "&:hover": { color: "yellow", textDecoration: "underline" } }}
              onClick={onContactClick}
            >
              Contact us to upgrade.
            </Link>
          </span>
        ) : (
          <span>
            You have {daysLeft} {pluralize("day", daysLeft > 1)} left on your free trial of the Team plan.{" "}
            <Link
              sx={{ color: "yellow", "&:hover": { color: "yellow", textDecoration: "underline" } }}
              onClick={onContactClick}
            >
              Contact us to upgrade.
            </Link>
          </span>
        )}
      </Text>
      <Modal
        footer={
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        }
        isOpen={showContactModal}
        title="Thank you"
        onClose={closeModal}
      >
        <Text>A member of our team will reach out to you in the next business day to help upgrade your workspace.</Text>
      </Modal>
    </Banner>
  );
};
