import { VFC } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { object, string } from "yup";

import { useUpdateTunnelMutation } from "src/graphql";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";

type Props = {
  onClose: () => void;
  tunnel: any;
};

const validationSchema = object().shape({
  name: string().required("A name is required."),
});

export const EditTunnelForm: VFC<Readonly<Props>> = ({ onClose, tunnel }) => {
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: tunnel?.name,
    },
  });

  const { addToast } = useToasts();

  const { isLoading: updating, mutateAsync: updateTunnel } = useUpdateTunnelMutation();

  const onSubmit = async ({ name }) => {
    await updateTunnel({ id: tunnel?.id, input: { name, updated_at: "now()" } });
    onClose();
    addToast("Tunnel succesfully updated.", { appearance: "success" });
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={updating} onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </>
      }
      sx={{ maxWidth: "500px", width: "100%" }}
      title="Edit Tunnel"
      onClose={onClose}
    >
      <Field label="Name">
        <Input {...register("name")} />
      </Field>
    </Modal>
  );
};
