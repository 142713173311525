import { ReactElement, VFC, useEffect } from "react";

import { Text, Image, ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { Selectable } from "src/ui/selectable";
import { QueryType } from "src/utils/models";
import { UseSourcesResult } from "src/utils/sources";

import { QUERY_TYPE_OPTIONS } from "./query-type-menu";

type Source = UseSourcesResult["data"][0];

type Props = {
  source: Source;
  selected: QueryType | undefined;
  onChange: (value: QueryType | undefined) => void;
};

export const QueryTypeSelect: VFC<Readonly<Props>> = ({ source, selected, onChange }) => {
  const supportedQueries = source.definition?.supportedQueries.filter((type) => {
    // Demo sources don't allow use Table models.
    return QUERY_TYPE_OPTIONS[type] && !(source.is_demo && type === QueryType.Table);
  }) as QueryType[];

  useEffect(() => {
    if (!selected) {
      onChange(supportedQueries.length === 1 ? supportedQueries[0] : undefined);
    }
  }, [selected]);

  return (
    <>
      <Heading sx={{ color: "base.9", mt: 4 }}>Select a modeling method</Heading>
      <Text sx={{ mt: 2, mb: 12, color: "base.4", fontSize: 2 }}>We support various methods for querying your source</Text>
      <Row sx={{ flexWrap: "wrap", gap: 6 }}>
        {supportedQueries.map((type: QueryType) => {
          return <SelectQueryType key={type} type={type} onChange={onChange} />;
        })}
      </Row>
    </>
  );
};

function SelectQueryType({
  type,
  onChange,
}: {
  type: QueryType;
  onChange: (type: QueryType | undefined) => void;
}): ReactElement<any, any> {
  const { label, description, image, imageHover } = QUERY_TYPE_OPTIONS[type];

  return (
    <Selectable selected={false} sx={selectStyles} onSelect={() => onChange(type)}>
      <Heading
        sx={{
          fontSize: 3,
          fontWeight: "bold",
          mb: 4,
        }}
        variant="h3"
      >
        {label}
      </Heading>
      {image && <Image alt={image.alt} src={image.src} />}
      {imageHover && <Image alt={imageHover.alt} src={imageHover.src} />}
      <Text
        sx={{
          mt: 4,
          mb: 8,
          lineHeight: 1.75,
        }}
      >
        {description}
      </Text>
    </Selectable>
  );
}

const selectStyles: ThemeUIStyleObject = {
  width: "300px",
  p: 6,
  color: "base.6",
  bg: "white",
  border: "small",
  borderColor: "#D4D9DF",
  borderRadius: 2,
  img: {
    width: "100%",
    height: "auto",
    // hide hover img
    "&:last-of-type": {
      display: "none",
    },
  },
  // hover
  ":hover:not(disabled)": {
    bg: "secondaries.0",
    borderColor: "primaries.9",
    boxShadow: "none",
    color: "base.9",
    cursor: "pointer",
    img: {
      // hide default img
      display: "none",
      // show hover img
      "&:last-of-type": {
        display: "inline-block",
      },
    },
  },
};
