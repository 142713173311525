import { flatten, merge, uniqBy } from "lodash";
import { Image, Text } from "theme-ui";

import { ConnectionsBoolExp, DestinationsBoolExp, SegmentsBoolExp, SyncsBoolExp, SyncsQuery } from "src/graphql";
import { ObjectBadge } from "src/ui/badge";
import { Row } from "src/ui/box";
import { Circle } from "src/ui/circle";
import { QueryTypeDictionary } from "src/utils/models";
import { getObjectName, SyncStatus } from "src/utils/syncs";

import { Filter, FilterDefinitions, FilterOperator, FilterType } from "./types";

export const syncFilterDefinitions: FilterDefinitions<SyncsBoolExp> = {
  [FilterType.Status]: {
    default: true,
    property: "Sync status",
    value: "status",
    props: {
      placeholder: "Status...",
    },
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: () => [
      {
        render: () => (
          <>
            <Circle color="green" radius="8px" sx={{ mr: 2 }} />
            Healthy
          </>
        ),
        value: SyncStatus.SUCCESS,
      },
      {
        render: () => (
          <>
            <Circle color="red" radius="8px" sx={{ mr: 2 }} />
            Failed
          </>
        ),
        value: SyncStatus.FAILED,
      },
      {
        render: () => (
          <>
            <Circle color="base.4" radius="8px" sx={{ mr: 2 }} />
            Disabled
          </>
        ),
        value: SyncStatus.DISABLED,
      },
    ],
    query: ({ value, operator }) => ({
      status: { [operator]: value },
    }),
  },
  [FilterType.CreatedBy]: {
    property: "Created by",
    value: "user",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: (syncs) => {
      return uniqBy(
        syncs
          ?.filter(({ created_by_user }) => Boolean(created_by_user))
          ?.map(({ created_by_user: { name } }) => ({ label: name, value: name })),
        "value",
      );
    },
    query: ({ value, operator }) => ({
      created_by_user: { name: { [operator]: value } },
    }),
    props: {
      placeholder: "User...",
      searchable: true,
      width: 300,
    },
  },
  [FilterType.Destination]: {
    property: "Destination",
    value: "destination",
    props: {
      placeholder: "Destination...",
      searchable: true,
      width: 400,
    },
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: (syncs: SyncsQuery["syncs"]) => {
      return uniqBy(
        flatten(
          syncs.map(({ config, destination }) => {
            const { id, name, definition } = destination!;

            return {
              value: id,
              label: name || definition?.name,
              render: () => {
                return (
                  <Row sx={{ alignItems: "center" }}>
                    <Image
                      alt={definition?.name}
                      src={definition?.icon}
                      sx={{ width: "18px", flexShrink: 0, maxHeight: "100%", objectFit: "contain", mr: 2 }}
                    />
                    <Text sx={{ fontWeight: "semi", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                      {name || definition?.name}
                    </Text>
                    {config?.object && (
                      <ObjectBadge sx={{ textTransform: "capitalize", ml: 2 }}>{getObjectName(config.object)}</ObjectBadge>
                    )}
                  </Row>
                );
              },
            };
          }),
        ),
        "value",
      );
    },
    query: ({ value, operator }) => ({
      destination_id: { [operator]: value },
    }),
  },
  [FilterType.Label]: {
    property: "Label",
    value: "label",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.IncludesKeys,
      },
      {
        label: "Excludes",
        value: FilterOperator.ExcludesKeys,
      },
    ],
    query: ({ value, operator }) => {
      const keys = value.filter((val) => !val.includes(":"));
      const pairs = value
        .filter((val) => val.includes(":"))
        .map((val) => {
          const [key, value] = val.split(":");
          return {
            [key]: value,
          };
        });

      let conditions: Array<Record<string, unknown>> = [];

      if (keys.length) {
        conditions = [
          {
            tags: {
              [FilterOperator.IncludesKeys]: keys,
            },
          },
        ];
      }
      if (pairs.length) {
        conditions = [
          ...conditions,
          ...pairs.map((pair) => ({
            tags: {
              [FilterOperator.Contains]: pair,
            },
          })),
        ];
      }

      const exp = {
        _or: conditions,
      };

      if (operator === FilterOperator.IncludesKeys) {
        return exp;
      } else if (operator === FilterOperator.ExcludesKeys) {
        return {
          _not: exp,
        };
      }

      return undefined;
    },
  },
};

export const modelFilterDefinitions: FilterDefinitions<SegmentsBoolExp> = {
  [FilterType.Type]: {
    default: true,
    property: "Type",
    value: "query_type",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: () => Object.entries(QueryTypeDictionary).map(([value, label]) => ({ label, value })),
    query: ({ value, operator }) => ({
      query_type: { [operator]: value },
    }),
    props: {
      placeholder: "Type...",
      searchable: true,
      width: 300,
    },
  },
  [FilterType.CreatedBy]: {
    property: "Created by",
    value: "user",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: (models) => {
      return uniqBy(
        models
          ?.filter(({ created_by_user }) => Boolean(created_by_user))
          ?.map(({ created_by_user: { name } }) => ({ label: name, value: name })),
        "value",
      );
    },
    query: ({ value, operator }) => ({
      created_by_user: { name: { [operator]: value } },
    }),
    props: {
      placeholder: "User...",
      searchable: true,
      width: 300,
    },
  },
  [FilterType.Label]: {
    property: "Label",
    value: "label",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.IncludesKeys,
      },
      {
        label: "Excludes",
        value: FilterOperator.ExcludesKeys,
      },
    ],
    query: ({ value, operator }) => {
      const keys = value.filter((val) => !val.includes(":"));
      const pairs = value
        .filter((val) => val.includes(":"))
        .map((val) => {
          const [key, value] = val.split(":");
          return {
            [key]: value,
          };
        });

      let conditions: Array<Record<string, unknown>> = [];

      if (keys.length) {
        conditions = [
          {
            tags: {
              [FilterOperator.IncludesKeys]: keys,
            },
          },
        ];
      }
      if (pairs.length) {
        conditions = [
          ...conditions,
          ...pairs.map((pair) => ({
            tags: {
              [FilterOperator.Contains]: pair,
            },
          })),
        ];
      }

      const exp = {
        _or: conditions,
      };

      if (operator === FilterOperator.IncludesKeys) {
        return exp;
      } else if (operator === FilterOperator.ExcludesKeys) {
        return {
          _not: exp,
        };
      }

      return undefined;
    },
  },
};

export const sourceFilterDefinitions: FilterDefinitions<ConnectionsBoolExp> = {
  [FilterType.Type]: {
    default: true,
    property: "Type",
    value: "type",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: (sources) => {
      return uniqBy(
        sources
          ?.filter(({ type }) => Boolean(type))
          ?.map(({ config, definition, type }) => ({
            label: type,
            value: type,
            render: () => (
              <Row sx={{ alignItems: "center" }}>
                <Image
                  alt={definition?.name}
                  src={definition?.icon}
                  sx={{ width: "18px", flexShrink: 0, maxHeight: "100%", objectFit: "contain", mr: 2 }}
                />
                <Text>{type}</Text>
                {config?.object && (
                  <ObjectBadge sx={{ textTransform: "capitalize", ml: 2 }}>{getObjectName(config.object)}</ObjectBadge>
                )}
              </Row>
            ),
          })),
        "value",
      );
    },
    query: ({ value, operator }) => ({
      type: { [operator]: value },
    }),
    props: {
      placeholder: "Type...",
      searchable: true,
      width: 300,
    },
  },
  [FilterType.CreatedBy]: {
    property: "Created by",
    value: "user",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: (sources) => {
      return uniqBy(
        sources
          ?.filter(({ created_by_user }) => Boolean(created_by_user))
          ?.map(({ created_by_user: { name } }) => ({ label: name, value: name })),
        "value",
      );
    },
    query: ({ value, operator }) => ({
      created_by_user: { name: { [operator]: value } },
    }),
    props: {
      placeholder: "User...",
      searchable: true,
      width: 300,
    },
  },
  [FilterType.Label]: {
    property: "Label",
    value: "label",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.IncludesKeys,
      },
      {
        label: "Excludes",
        value: FilterOperator.ExcludesKeys,
      },
    ],
    query: ({ value, operator }) => {
      const keys = value.filter((val) => !val.includes(":"));
      const pairs = value
        .filter((val) => val.includes(":"))
        .map((val) => {
          const [key, value] = val.split(":");
          return {
            [key]: value,
          };
        });

      let conditions: Array<Record<string, unknown>> = [];

      if (keys.length) {
        conditions = [
          {
            tags: {
              [FilterOperator.IncludesKeys]: keys,
            },
          },
        ];
      }
      if (pairs.length) {
        conditions = [
          ...conditions,
          ...pairs.map((pair) => ({
            tags: {
              [FilterOperator.Contains]: pair,
            },
          })),
        ];
      }

      const exp = {
        _or: conditions,
      };

      if (operator === FilterOperator.IncludesKeys) {
        return exp;
      } else if (operator === FilterOperator.ExcludesKeys) {
        return {
          _not: exp,
        };
      }

      return undefined;
    },
  },
};

export const destinationFilterDefinitions: FilterDefinitions<DestinationsBoolExp> = {
  [FilterType.Type]: {
    default: true,
    property: "Type",
    value: "type",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: (destinations) => {
      return uniqBy(
        destinations
          ?.filter(({ type }) => Boolean(type))
          ?.map(({ config, definition, type }) => ({
            label: type,
            value: type,
            render: () => (
              <Row sx={{ alignItems: "center" }}>
                <Image
                  alt={definition?.name}
                  src={definition?.icon}
                  sx={{ width: "18px", flexShrink: 0, maxHeight: "100%", objectFit: "contain", mr: 2 }}
                />
                <Text>{type}</Text>
                {config?.object && (
                  <ObjectBadge sx={{ textTransform: "capitalize", ml: 2 }}>{getObjectName(config.object)}</ObjectBadge>
                )}
              </Row>
            ),
          })),
        "value",
      );
    },
    query: ({ value, operator }) => ({
      type: { [operator]: value },
    }),
    props: {
      placeholder: "Type...",
      searchable: true,
      width: 300,
    },
  },
  [FilterType.CreatedBy]: {
    property: "Created by",
    value: "user",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: (destinations) => {
      return uniqBy(
        destinations
          ?.filter(({ created_by_user }) => Boolean(created_by_user))
          ?.map(({ created_by_user: { name } }) => ({ label: name, value: name })),
        "value",
      );
    },
    query: ({ value, operator }) => ({
      created_by_user: { name: { [operator]: value } },
    }),
    props: {
      placeholder: "User...",
      searchable: true,
      width: 300,
    },
  },
  [FilterType.Label]: {
    property: "Label",
    value: "label",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.IncludesKeys,
      },
      {
        label: "Excludes",
        value: FilterOperator.ExcludesKeys,
      },
    ],
    query: ({ value, operator }) => {
      const keys = value.filter((val) => !val.includes(":"));
      const pairs = value
        .filter((val) => val.includes(":"))
        .map((val) => {
          const [key, value] = val.split(":");
          return {
            [key]: value,
          };
        });

      let conditions: Array<Record<string, unknown>> = [];

      if (keys.length) {
        conditions = [
          {
            tags: {
              [FilterOperator.IncludesKeys]: keys,
            },
          },
        ];
      }
      if (pairs.length) {
        conditions = [
          ...conditions,
          ...pairs.map((pair) => ({
            tags: {
              [FilterOperator.Contains]: pair,
            },
          })),
        ];
      }

      const exp = {
        _or: conditions,
      };

      if (operator === FilterOperator.IncludesKeys) {
        return exp;
      } else if (operator === FilterOperator.ExcludesKeys) {
        return {
          _not: exp,
        };
      }

      return undefined;
    },
  },
};

export const audienceFilterDefinitions: FilterDefinitions<SegmentsBoolExp> = {
  [FilterType.Status]: {
    default: true,
    property: "Sync status",
    value: "status",
    props: {
      placeholder: "Status...",
    },
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: () => [
      {
        render: () => (
          <>
            <Circle color="green" radius="8px" sx={{ mr: 2 }} />
            Healthy
          </>
        ),
        value: SyncStatus.SUCCESS,
      },
      {
        render: () => (
          <>
            <Circle color="red" radius="8px" sx={{ mr: 2 }} />
            Failed
          </>
        ),
        value: SyncStatus.FAILED,
      },
      {
        render: () => (
          <>
            <Circle color="base.4" radius="8px" sx={{ mr: 2 }} />
            Disabled
          </>
        ),
        value: SyncStatus.DISABLED,
      },
    ],
    query: ({ value, operator }) => ({
      destination_instances: { status: { [operator]: value } },
    }),
  },
  // [FilterType.Size]: {
  //   label: "Audience size",
  //   query: ({ operator, value }) => ({
  //     row_count: { [operator]: value },
  //   }),
  // },
  [FilterType.CreatedBy]: {
    property: "Created by",
    value: "user",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: (audiences) => {
      return uniqBy(
        audiences
          ?.filter(({ created_by_user }) => Boolean(created_by_user))
          ?.map(({ created_by_user: { name } }) => ({ label: name, value: name })),
        "value",
      );
    },
    query: ({ value, operator }) => ({
      created_by_user: { name: { [operator]: value } },
    }),
    props: {
      placeholder: "User...",
      searchable: true,
      width: 300,
    },
  },
  [FilterType.Destination]: {
    property: "Destination",
    value: "destination",
    props: {
      placeholder: "Destination...",
      searchable: true,
      width: 400,
    },
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.Includes,
      },
      {
        label: "Excludes",
        value: FilterOperator.Excludes,
      },
    ],
    valueOptions: (audiences) => {
      return uniqBy(
        flatten(
          audiences?.map(({ syncs }) =>
            syncs.map(({ config, destination: { id, name, definition } }) => ({
              value: id,
              label: name || definition?.name,
              render: () => {
                return (
                  <Row sx={{ alignItems: "center" }}>
                    <Image
                      alt={definition?.name}
                      src={definition?.icon}
                      sx={{ width: "18px", flexShrink: 0, maxHeight: "100%", objectFit: "contain", mr: 2 }}
                    />
                    <Text sx={{ fontWeight: "semi", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                      {name || definition?.name}
                    </Text>
                    {config?.object && (
                      <ObjectBadge sx={{ textTransform: "capitalize", ml: 2 }}>{getObjectName(config.object)}</ObjectBadge>
                    )}
                  </Row>
                );
              },
            })),
          ),
        ),
        "value",
      );
    },
    query: ({ value, operator }) => ({
      destination_instances: { destination_id: { [operator]: value } },
    }),
  },
  [FilterType.Label]: {
    property: "Label",
    value: "label",
    operatorOptions: [
      {
        label: "Includes",
        value: FilterOperator.IncludesKeys,
      },
      {
        label: "Excludes",
        value: FilterOperator.ExcludesKeys,
      },
    ],
    query: ({ value, operator }) => {
      const keys = value.filter((val) => !val.includes(":"));
      const pairs = value
        .filter((val) => val.includes(":"))
        .map((val) => {
          const [key, value] = val.split(":");
          return {
            [key]: value,
          };
        });

      let conditions: Array<Record<string, unknown>> = [];

      if (keys.length) {
        conditions = [
          {
            tags: {
              [FilterOperator.IncludesKeys]: keys,
            },
          },
        ];
      }
      if (pairs.length) {
        conditions = [
          ...conditions,
          ...pairs.map((pair) => ({
            tags: {
              [FilterOperator.Contains]: pair,
            },
          })),
        ];
      }

      const exp = {
        _or: conditions,
      };

      if (operator === FilterOperator.IncludesKeys) {
        return exp;
      } else if (operator === FilterOperator.ExcludesKeys) {
        return {
          _not: exp,
        };
      }

      return undefined;
    },
  },
};

export const getValidFilters = (filters: Filter[] | undefined): Filter[] => {
  if (filters) {
    return filters.filter(({ type, operator, value }) =>
      type && operator && Array.isArray(value) ? value.length > 0 : Boolean(value),
    );
  }

  return [];
};

export const getHasuaExpFromFilters = (
  filterDefinitions: FilterDefinitions<SegmentsBoolExp>, // TODO: Use union type here?
  filters: Filter[] | undefined,
) => {
  if (filters) {
    return filters.reduce((exp, filter) => {
      const { type, operator, value } = filter;
      const { query } = filterDefinitions[type]!;
      return merge(exp, query({ value, operator }));
    }, {});
  }

  return {};
};

export const getPropertyOptions = (filterDefinitions: FilterDefinitions<SegmentsBoolExp>) =>
  Object.entries(filterDefinitions).map(([type, { property }]) => ({ label: property, value: type }));
