import { FC } from "react";

import { Text } from "theme-ui";

type Props = {
  table: string;
};

export const TableQuery: FC<Readonly<Props>> = ({ table }) => (
  <Text sx={{ fontWeight: "semi" }}>
    Select all rows from <strong>{table}</strong>
  </Text>
);
