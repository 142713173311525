import { useEffect, useState, VFC } from "react";

import { Select } from "src/ui/select";
import { useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";

type Props = {
  model: any;
  value: string;
  onChange: (value: string) => void;
};

export const ColumnSelect: VFC<Readonly<Props>> = ({ model, onChange, value }) => {
  const { queryState, initQueryState } = useQueryState();
  const [loading, setLoading] = useState<boolean>(false);

  const type = model?.query_type;
  const source = model?.connection;
  const columns = model?.columns;
  const columnOptions = columns?.map(({ name }) => ({ value: name, label: name }));

  const update = useUpdateQuery({ logUpdate: false });

  const { runQuery } = useModelRun(type, undefined, {
    modelId: model?.id,
    variables: { sourceId: source?.id, ...queryState },
    onCompleted: async ({ columns }, error) => {
      if (!error) {
        await update({ model, columns });
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    initQueryState(model);
  }, [model]);

  return (
    <Select
      isLoading={loading}
      options={columnOptions}
      reload={async () => {
        setLoading(true);
        await runQuery(true);
      }}
      value={value}
      onChange={({ value }) => {
        onChange(value);
      }}
    />
  );
};
