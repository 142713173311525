import { FC, useState, VFC, useEffect } from "react";

import { orderBy } from "lodash";
import { Grid, Image, Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { DestinationDefinitionFragment as DestinationDefinition, DestinationDefinitionFragment } from "src/graphql";
import { Row, Column, Box } from "src/ui/box";
import { CheckCircleIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { Section } from "src/ui/section";
import { Placeholder } from "src/ui/table/placeholder";

import { Destination } from "./destination";

type DestinationsCatalogProps = {
  destinationDefinitions: DestinationDefinition[];
  error: any;
  selection: any;
  onSelect: (destination: any) => void;
  sidebarTop: number;
};

export const DestinationsCatalog: FC<Readonly<DestinationsCatalogProps>> = ({
  destinationDefinitions,
  error,
  selection,
  onSelect,
  sidebarTop,
}) => {
  const { featureFlags } = useUser();
  const [category, setCategory] = useState<string>("all");
  const [search, setSearch] = useState<string>("");

  if (error || destinationDefinitions?.length === 0) {
    return (
      <Placeholder
        content={{
          title: "No Destinations",
          body: "Create a destination to get started",
          error: "Destinations failed to load, please try again.",
        }}
        error={Boolean(error)}
      />
    );
  }

  const availableDestinations: DestinationDefinitionFragment[] = [];
  const categories = {
    all: [],
  };

  for (const definition of destinationDefinitions) {
    if (search && !definition.name.toLowerCase().includes(search.toLocaleLowerCase())) {
      // Skip destinations that doesn't match the search
      continue;
    }

    if (
      definition.type === "hubspotLegacy" &&
      !featureFlags?.hubspot_multi_merge_rule &&
      !featureFlags?.hubspot_legacy_destination
    ) {
      continue;
    }

    const firstCategory = definition.categories[0];

    if (firstCategory) {
      categories[firstCategory.name] = [...(categories[firstCategory.name] || []), definition];
    }

    availableDestinations.push(definition);
  }

  useEffect(() => {
    if (search) {
      setCategory("all");
    }
  }, [search]);

  return (
    <Row sx={{ width: "100%", justifyContent: "space-between" }}>
      <Sidebar
        categories={categories}
        category={search.length > 0 ? "all" : category}
        search={search}
        setCategory={setCategory}
        setSearch={setSearch}
        top={sidebarTop}
      />
      <Grid gap={8} sx={{ mx: 6, flex: 1 }}>
        {availableDestinations?.length === 0 && (
          <Section sx={{ height: "max-content" }}>
            <Text sx={{ color: "dark.2", fontWeight: "bold" }}>No destinations match your search.</Text>
          </Section>
        )}
        {Object.keys(categories)
          ?.sort()
          ?.filter((cat) => cat !== "all")
          ?.filter((cat) => category === "all" || category === cat)
          ?.map((category) => (
            <Column key={category}>
              <Text sx={{ fontSize: 2, fontWeight: "bold", textTransform: "capitalize", mb: 4 }}>{category}</Text>
              <Grid
                gap={4}
                sx={{
                  gridTemplateColumns: ["1fr", "1fr", "1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"],
                  height: "max-content",
                }}
              >
                {orderBy(categories[category], ["name"], ["asc"]).map((destination) => (
                  <Destination
                    key={destination.type}
                    definition={destination}
                    selected={destination.type === selection?.type}
                    onSelect={onSelect}
                  />
                ))}
              </Grid>
            </Column>
          ))}
      </Grid>

      <Details definition={selection} />
    </Row>
  );
};

const Sidebar = ({ categories, category, setCategory, search, setSearch, top }) => {
  return (
    <Column sx={{ maxWidth: "200px", width: "100%", position: "sticky", top: `${top}px`, height: "max-content" }}>
      <Box sx={{ flex: 0, mb: 6 }}>
        <SearchInput placeholder="Filter destinations" sx={{ width: "100%" }} value={search} onChange={setSearch} />
      </Box>

      <Grid gap={1}>
        <Text sx={{ color: "base.4", textTransform: "uppercase", fontWeight: "semi", mb: 2 }}>Categories</Text>
        {Object.keys(categories).map((value) => {
          const selected = value === category;

          return (
            <Row
              key={value}
              sx={{
                textTransform: "capitalize",
                px: 2,
                py: 1,
                borderRadius: 1,
                fontWeight: "bold",
                cursor: "pointer",
                color: selected ? "primary" : "base.5",
                bg: selected ? "primaries.0" : undefined,
                ":hover": {
                  color: selected ? undefined : "secondary",
                },
              }}
              onClick={() => setCategory(value)}
            >
              {value}
            </Row>
          );
        })}
      </Grid>
    </Column>
  );
};

const Details: VFC<Readonly<{ definition: DestinationDefinition }>> = ({ definition }) => {
  return (
    <Column
      sx={{
        visibility: definition ? "visible" : "hidden",
        border: "small",
        bg: "base.1",
        borderColor: "base.2",
        borderRadius: 1,
        height: "max-content",
        position: "sticky",
        top: "96px",
        minWidth: "min-content",
        maxWidth: "320px",
      }}
    >
      <Column sx={{ p: 4, maxHeight: "70vh", height: "100%", overflow: "auto" }}>
        <Row sx={{ alignItems: "center", mb: 8 }}>
          <Image src={definition?.icon} sx={{ mr: 2, minWidth: "24px", flex: 0 }} />
          <Text sx={{ fontSize: 3, fontWeight: "bold" }}>{definition?.name}</Text>
        </Row>
        <Grid gap={4}>
          {definition?.shortPitch && (
            <Column>
              <Text sx={{ fontSize: 0, color: "dark.1", fontWeight: "bold", mb: 2 }}>About</Text>
              <Text sx={{ fontWeight: "semi" }}>{definition?.shortPitch}</Text>
            </Column>
          )}
          {definition?.longPitch && (
            <Column>
              <Text sx={{ fontSize: 0, color: "dark.1", fontWeight: "bold", mb: 2 }}>Why?</Text>
              <Text sx={{ fontWeight: "semi" }}>{definition?.longPitch}</Text>
            </Column>
          )}
          {definition?.features?.length > 0 && (
            <Column>
              <Text sx={{ fontSize: 0, color: "dark.1", fontWeight: "bold", mb: 2 }}>Use cases</Text>
              <Grid gap={3}>
                {definition?.features.map((value) => (
                  <Row key={value}>
                    <CheckCircleIcon color="primary" sx={{ flexShrink: 0, mr: 2 }} />
                    <Text sx={{ fontWeight: "semi" }}>{value}</Text>
                  </Row>
                ))}
              </Grid>
            </Column>
          )}
        </Grid>
      </Column>
    </Column>
  );
};
