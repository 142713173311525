import { InlineWidget } from "react-calendly";
import { Text, Link } from "theme-ui";

import { PageContainer } from "src/components/layout";
import { Logo } from "src/ui/brand";
import { Button } from "src/ui/button";

function ApprovalPage() {
  return (
    <PageContainer sx={{ height: "100vh", justifyContent: "center" }}>
      <Logo color="secondary" size="200px" sx={{ mb: 16 }} />
      <Text sx={{ fontWeight: "bold", fontSize: 4, textAlign: "center", mb: 4 }}>
        We'd love to get your team onboarded to Hightouch!
      </Text>
      <Text sx={{ mb: 8, color: "base.6", fontSize: "bold" }}>Arrange a meeting below:</Text>

      <InlineWidget styles={{ height: "400px", width: "600px" }} url="https://calendly.com/hightouchio/20-minute-meeting" />

      <Text sx={{ mt: 8, mb: 4 }}>
        Or email <Link href="mailto:hello@hightouch.io">hello@hightouch.io</Link> for more information.
      </Text>

      <Button
        label={"Sign in with a different account"}
        variant="dark"
        onClick={() => {
          window.location.href = `${import.meta.env.VITE_API_BASE_URL}/auth/logout`;
        }}
      />
    </PageContainer>
  );
}

export default ApprovalPage;
