import { useCallback, useState } from "react";

import { useDropzone } from "react-dropzone";
import { Grid, Text } from "theme-ui";

import { useCreateGoogleServiceAccountMutation } from "../../graphql";
import { Column } from "../../ui/box";
import { Button } from "../../ui/button";
import { Field } from "../../ui/field";
import { Input } from "../../ui/input";
import { RadioGroup } from "../../ui/radio";
import { Permission } from "../permission";

export const gcpConfigValidator = (config): boolean => {
  return config.client_email && config.private_key;
};

/**
 * GCP Form
 * @param config
 * @param setConfig
 * @constructor
 */
export const GCPForm = ({ config, setConfig }) => {
  const [accessType, setAccessType] = useState<string>("managedServiceAccount");

  const getAccessTypeForm = () => {
    if (accessType === "managedServiceAccount") {
      return <ManagedServiceAccountSetup config={config} setConfig={setConfig} />;
    } else if (accessType === "customServiceAccount") {
      return <CustomServiceAccountSetup config={config} setConfig={setConfig} />;
    } else {
      return null;
    }
  };

  return (
    <>
      <Field label={"Access Type"}>
        <RadioGroup
          options={[
            {
              label: "Managed service account (recommended)",
              value: "managedServiceAccount",
            },
            {
              label: "Your service account",
              value: "customServiceAccount",
            },
          ]}
          value={accessType}
          onChange={(type) => {
            setAccessType(type as string);
            // Clear out the other config when we switch.
            setConfig({});
          }}
        />
      </Field>

      {getAccessTypeForm()}
    </>
  );
};

const ManagedServiceAccountSetup = ({ config, setConfig }) => {
  const {
    isLoading: creatingServiceAccount,
    error: serviceAccountError,
    mutateAsync: createServiceAccount,
  } = useCreateGoogleServiceAccountMutation();
  const serviceAccountErrorMessage = parseError(serviceAccountError);

  return (
    <>
      <Text>
        Hightouch can automatically create a GCP service account for you. All you need to do is bind IAM policies to this
        service account.
      </Text>

      <Field error={serviceAccountErrorMessage} label={""}>
        {!config.client_email ? (
          <Permission>
            <Button
              label="Create a new service account"
              loading={creatingServiceAccount}
              variant="secondary"
              onClick={async () => {
                const { createGoogleServiceAccount } = await createServiceAccount({});
                const credentialsStr = createGoogleServiceAccount?.serviceAccount?.credentials;
                const credentials = JSON.parse(credentialsStr);
                setConfig(credentials);
              }}
            />
          </Permission>
        ) : (
          <>
            <Field label={"Service Account Email"}>
              <Input disabled value={config.client_email} />
            </Field>
          </>
        )}
      </Field>
    </>
  );
};

const parseError = (graphqlError: any) => {
  if (graphqlError) {
    const index = graphqlError.message.indexOf("{");
    const json = JSON.parse(graphqlError.message.slice(index));
    const error = json?.errors?.[0]?.message;

    return error;
  }
};

// keys required in private key upload
const requiredKeys = ["client_email", "private_key", "token_uri"];

const CustomServiceAccountSetup = ({ config, setConfig }) => {
  const [error, setError] = useState<string>("");

  const parseCredentials = (credentials) => {
    const missingKeys: string[] = [];
    for (const key of requiredKeys) {
      if (!credentials[key]) {
        missingKeys.push(key);
      }
    }
    if (missingKeys.length > 0) {
      setError(`Missing keys from credential file: ${missingKeys.join(", ")}`);
      return;
    }
    setConfig(credentials);
  };

  // Create file upload drop zone.
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback(async (acceptedFiles) => {
      setError("");
      if (acceptedFiles.length === 0) {
        return;
      }

      const fileContents = await acceptedFiles[0].text();
      try {
        parseCredentials(JSON.parse(fileContents));
      } catch (err) {
        setError("Could not parse private key file.");
      }
    }, []),
    accept: ".json",
  });

  return (
    <>
      <Text>
        Hightouch can also use a service account that you manage. Upload your private key and we'll securely store it and handle
        the rest.
      </Text>
      <Field error={error} label={""}>
        {config.client_id ? (
          <Grid gap={8}>
            <Field label="Client ID">
              <Input disabled type={"text"} value={config.client_id} />
            </Field>

            <Field label="Client Email">
              <Input disabled type={"text"} value={config.client_email} />
            </Field>

            <Field label="Private Key">
              <Input disabled type={"textarea"} value={"<REDACTED>"} />
            </Field>
          </Grid>
        ) : (
          <Column
            sx={{
              alignItems: "center",
              padding: "20px",
              borderWidth: 1,
              borderRadius: 2,
              borderColor: "base.3",
              borderStyle: "dashed",
              backgroundColor: "base.2",
              color: "base.5",
              outline: "none",
              transition: "border .24s ease-in-out",
            }}
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag or drop your private key here, or click here to select a file</p>
            </div>
          </Column>
        )}
      </Field>
    </>
  );
};
