import { useSnapchatOrganizationsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Select } from "src/ui/select";

export const SnapchatAccountSelector = ({ destination, config, setConfig }) => {
  const organizationId = config?.organizationId;

  const setOrganizationId = (id) => {
    setConfig({ ...config, organizationId: id });
  };

  const {
    data: organizationsData,
    isLoading: organizationsLoading,
    error: organizationsError,
    refetch: organizationsReload,
  } = useSnapchatOrganizationsQuery({ destId: String(destination?.id) });

  const organizations = organizationsData?.snapchatListOrganizations?.organizations;
  const organizationOptions = organizations?.map((a) => ({ label: a.name, value: a.id })) || [];

  return (
    <>
      <Field error={organizationsError} label="Organization">
        <Select
          isLoading={organizationsLoading}
          options={organizationOptions}
          placeholder="Select an organization..."
          reload={organizationsReload}
          value={organizationId ? organizationOptions.find((s) => organizationId === s.value) : null}
          width="300px"
          onChange={(selected) => setOrganizationId(selected.value)}
        />
      </Field>
    </>
  );
};
