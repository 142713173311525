import { FC } from "react";

import { Text } from "theme-ui";

import { SelectComponentProps } from "../types";
import { Container } from "./container";

export const Placeholder: FC<SelectComponentProps> = ({ children, ...props }) => {
  return (
    <Container {...props}>
      <Text
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: "base.4",
        }}
      >
        {children}
      </Text>
    </Container>
  );
};
