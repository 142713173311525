import { ThemeUICSSObject } from "theme-ui";

const base: ThemeUICSSObject = {
  display: "flex",
  alignItems: "center",
  fontSize: "10px",
  letterSpacing: ".5px",
  textTransform: "uppercase",
  height: "24px",
  px: 2,
  textOverflow: "ellipsis",
  overflow: "hidden",
  fontWeight: "bold",
  width: "max-content",
  borderRadius: 1,
  border: "small",
  flexShrink: 0,
};

export default {
  primary: {
    ...base,
    borderColor: "base.2",
    color: "base.5",
    bg: "base.0",
  },
  blue: {
    // TODO: hookup to color variables once the blue is updated throughout the app.
    ...base,
    borderColor: "#81D3F8",
    color: "#0087CD",
    bg: "#E1F5FD",
  },
  yellow: {
    ...base,
    borderColor: "yellow",
    color: "amber",
    bg: "yellows.0",
  },
  green: {
    ...base,
    borderColor: "green",
    color: "#1B5E20",
    bg: "greens.0",
  },
  red: {
    ...base,
    borderColor: "red",
    color: "red",
    bg: "reds.0",
  },
  base: {
    ...base,
    borderColor: "base.3",
    color: "base.5",
    bg: "base.1",
  },
  white: {
    ...base,
    borderColor: "black",
    color: "black",
    bg: "white",
  },
  baseCircle: {
    ...base,
    borderColor: "base.3",
    color: "base.5",
    bg: "base.1",
    width: "24px",
    borderRadius: "50%",
    justifyContent: "center",
  },
  baseDark: {
    ...base,
    border: "none",
    color: "white",
    bg: "base.4",
  },
  indigo: {
    // @TODO: once app indigos are updated, replace this with the indigo variables.
    ...base,
    borderColor: "#CACCF7",
    color: "#666EE8",
    bg: "#F4F4FF",
  },
  orange: {
    ...base,
    borderColor: "oranges.2",
    color: "oranges.7",
    bg: "oranges.0",
  },
  purple: {
    ...base,
    borderColor: "purples.2",
    color: "purples.7",
    bg: "purples.0",
  },
  pink: {
    ...base,
    borderColor: "pinks.1",
    color: "pinks.6",
    bg: "pinks.0",
  },
};
