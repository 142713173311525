import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";

export interface SquareBadgeProps {
  sx?: ThemeUIStyleObject;
}

export const SquareBadge: FC<Readonly<SquareBadgeProps>> = ({ children, sx = {} }) => (
  <Row
    sx={{
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
      border: "small",
      height: "18px",
      px: "6px",
      fontSize: 0,
      fontWeight: "semi",
      ...sx,
    }}
  >
    {children}
  </Row>
);
