import { Grid } from "theme-ui";

import { GitActivity } from "src/components/git/git-activity";
import { Settings, DbtGitSync } from "src/components/settings";
import { Section } from "src/ui/section";

export const VersionControl = () => {
  return (
    <Settings route="version-control">
      <Grid gap={12}>
        <DbtGitSync />

        <Section title="Git Activity">
          <GitActivity />
        </Section>
      </Grid>
    </Settings>
  );
};
