export const LOOKBACK_WINDOWS = [
  { label: "1 minute", value: 1 * 60 },
  { label: "5 minutes", value: 5 * 60 },
  { label: "30 minutes", value: 30 * 60 },
  { label: "1 hour", value: 1 * 60 * 60 },
  { label: "3 hours", value: 3 * 60 * 60 },
  { label: "6 hours", value: 6 * 60 * 60 },
  { label: "12 hours", value: 12 * 60 * 60 },
  { label: "24 hours", value: 24 * 60 * 60 },
];
