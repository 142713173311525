import { createContext, useContext, FC, useState } from "react";

export type CommandBarContextType = {
  isFetching: boolean;
  setIsFetching: (isFetching: boolean) => void;
};

export const CommandBarContext = createContext<CommandBarContextType>({
  isFetching: false,
  setIsFetching: () => {},
});

export const useCommandBar = () => useContext(CommandBarContext);

export const CommandBarProvider: FC = ({ children }) => {
  const [isFetching, setIsFetching] = useState(false);
  return <CommandBarContext.Provider value={{ isFetching, setIsFetching }}>{children}</CommandBarContext.Provider>;
};
