import { VFC, MouseEventHandler } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { LabelIcon } from "../icons";
import { Button } from "./button";

export interface LabelButtonProps {
  sx?: ThemeUIStyleObject;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const LabelButton: VFC<Readonly<LabelButtonProps>> = ({ onClick, sx }) => {
  return (
    <Button iconBefore={<LabelIcon color={null} size={16} />} sx={sx} variant="soft" onClick={onClick}>
      Label
    </Button>
  );
};
