import { VFC } from "react";

import { IconProps, Icon } from "./icon";

export const ChevronUpIcon: VFC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z" />
    </Icon>
  );
};
