import { ReactElement } from "react";

import * as Yup from "yup";

import { useGoogleAdsConversionActionsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { RadioGroup } from "src/ui/radio/radio-group";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { ColumnOrConstantField } from "../../column-or-constant-field";
import { MappingsField } from "../../mappings-field";

const MAPPINGS = [
  {
    label: "GCLID",
    value: "gclid",
    type: StandardFieldType.STRING,
  },
  {
    label: "Conversion time",
    value: "time",
    type: StandardFieldType.DATETIME,
  },
  {
    label: "Adjustment time",
    value: "adjustmentTime",
    type: StandardFieldType.DATETIME,
  },
  {
    label: "Order Id",
    value: "orderId",
    type: StandardFieldType.STRING,
  },
];

const RESTATEMENT_MAPPINGS = [
  {
    label: "Restatement value",
    value: "value",
    type: StandardFieldType.NUMBER,
    required: true,
  },
  {
    label: "Restatement currency",
    value: "currency",
    type: StandardFieldType.STRING,
  },
];

const ENHANCEMENT_MAPPINGS = [
  {
    label: "Enhancement User Agent",
    value: "userAgent",
    type: StandardFieldType.STRING,
  },
  { label: "Email", value: "email", type: StandardFieldType.STRING },
  { label: "Phone", value: "phone", type: StandardFieldType.STRING },
  { label: "First Name", value: "firstName", type: StandardFieldType.STRING },
  { label: "Last Name", value: "lastName", type: StandardFieldType.STRING },
  { label: "Street Address", value: "streetAddress", type: StandardFieldType.STRING },
  { label: "Country", value: "country", type: StandardFieldType.STRING },
  { label: "Zip", value: "zip", type: StandardFieldType.STRING },
  { label: "State", value: "state", type: StandardFieldType.STRING },
  { label: "City", value: "city", type: StandardFieldType.STRING },
];

export function ClickConversionAdjustmentsForm({
  destinationId,
  config,
  setConfig,
}: Readonly<{
  destinationId: string;
  config: Record<string, unknown>;
  setConfig: (configuration: Record<string, unknown>) => void;
}>): ReactElement<any, any> {
  const {
    data: conversionActionsData,
    error: conversionActionsError,
    isFetching: loadingConversionActions,
    refetch: getConversionActions,
  } = useGoogleAdsConversionActionsQuery({
    destinationId: String(destinationId),
    mode: config?.mode as string,
    accountId: config?.accountId as string,
    loginAccountId: config?.loginAccountId as string,
  });

  const conversionActions = conversionActionsData?.googleListConversionActions?.actions;

  const conversionActionsOptions = conversionActions?.map((a) => ({
    label: a.name,
    value: a.resourceName,
  }));

  // Mappings depend on the adjustment type.
  const columnOptions = [...MAPPINGS];
  if (config?.adjustmentType && typeof config?.adjustmentType === "object" && config.adjustmentType["column"]) {
    columnOptions.push(...RESTATEMENT_MAPPINGS, ...ENHANCEMENT_MAPPINGS);
  } else if (config?.adjustmentType === "RESTATEMENT") {
    columnOptions.push(...RESTATEMENT_MAPPINGS);
  } else if (config?.adjustmentType === "ENHANCEMENT") {
    columnOptions.push(...ENHANCEMENT_MAPPINGS);
  }

  return (
    <>
      <Section>
        <ColumnOrConstantField
          columnLabel="Which column contains the conversion action name, resource name or ID?"
          constantDescription="This is the action to each conversion of this sync"
          constantInput={
            <Select
              isError={Boolean(conversionActionsError)}
              isLoading={loadingConversionActions}
              options={conversionActionsOptions}
              placeholder="Select a conversion action..."
              reload={getConversionActions}
              value={config.actionId ? conversionActionsOptions?.find((a) => config?.actionId === a.value) : null}
              width="240px"
              onChange={(action) => setConfig({ ...config, actionId: action.value })}
            />
          }
          constantLabel="What is the conversion action?"
          error={conversionActionsError?.message}
          fieldProps={{ size: "small" }}
          property="actionId"
        />
      </Section>
      <Section>
        <ColumnOrConstantField
          columnLabel="Which column contains the adjustment type? (The values must be 'RETRACTION', 'RESTATEMENT' or 'ENHANCEMENT')"
          constantDescription="This is the type for each adjustment of this sync"
          constantInput={
            <Select
              options={[
                { label: "Retraction", value: "RETRACTION" },
                { label: "Restatement", value: "RESTATEMENT" },
                { label: "Enhancement", value: "ENHANCEMENT" },
              ]}
              placeholder="Select an adjustment type..."
              value={config.adjustmentType}
              width="240px"
              onChange={(type) => setConfig({ ...config, adjustmentType: type.value })}
            />
          }
          constantLabel="What is the adjustment type?"
          fieldProps={{ size: "small" }}
          property="adjustmentType"
        />
      </Section>
      {config?.adjustmentType && (
        <>
          {((config.adjustmentType && typeof config.adjustmentType === "object" && config.adjustmentType["column"]) ||
            config.adjustmentType === "ENHANCEMENT") && (
            <Section>
              <Field label="Is this a third party data upload?">
                <RadioGroup
                  options={[
                    { label: "First party data", value: false },
                    {
                      label: "Third party data",
                      value: true,
                    },
                  ]}
                  value={config?.isThirdParty as boolean}
                  onChange={(isThirdParty) => {
                    setConfig({ ...config, isThirdParty });
                  }}
                />
              </Field>
            </Section>
          )}
          <Section>
            <MappingsField options={columnOptions} />
          </Section>
        </>
      )}
    </>
  );
}

export const Schema = Yup.object().shape({
  accountId: Yup.string().required(),
  loginAccountId: Yup.string().nullable().notRequired(),
  mode: Yup.string().required().oneOf(["ClickConversionAdjustments"]),
  mappings: COMMON_SCHEMAS.mappings,
  actionId: COMMON_SCHEMAS.columnOrConstant,
  adjustmentType: COMMON_SCHEMAS.columnOrConstant,
  isThirdParty: Yup.boolean().notRequired(),
  disablePIIHashing: Yup.boolean().notRequired(),
});
