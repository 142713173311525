import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { useCloseContactColumnsQuery, useCloseCustomActivitiesQuery, useCloseLeadColumnsQuery } from "src/graphql";
import { Arrow } from "src/ui/arrow";
import { Field } from "src/ui/field";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { MappingsHeader } from "../mappings-header";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape({
  type: Yup.string().required().default("objects"),
  mode: Yup.string().required().default("upsert"),
  object: Yup.string().required(),
  leadLookupMapping: Yup.object().when("object", {
    is: "contact",
    then: COMMON_SCHEMAS.externalIdMapping,
    otherwise: Yup.object().notRequired(),
  }),
  customActivity: Yup.string().when("object", {
    is: "custom_activity",
    then: Yup.string(),
    otherwise: Yup.string().notRequired(),
  }),
  externalIdMapping: COMMON_SCHEMAS.externalIdMapping,
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
});

const OBJECTS = [
  { label: "Lead", value: "lead" },
  { label: "Contact", value: "contact" },
  { label: "Opportunity", value: "opportunity" },
  { label: "Note Activity", value: "note_activity" },
  { label: "Custom Activity", value: "custom_activity" },
];

const MODES = [
  { label: "Upsert", value: "upsert" },
  { label: "Update", value: "update" },
];

export const CloseIOForm: FC = () => {
  const { config, setConfig, errors, hightouchColumns, destination, reloadModel, loadingModel } = useDestinationForm();

  const {
    data: contactColumnsData,
    error: contactColumnsError,
    isFetching: loadingContactColumns,
    refetch: getContactColumns,
  } = useCloseContactColumnsQuery(
    {
      destinationId: String(destination?.id),
      object: "contact",
    },
    { enabled: config?.object === "contact" },
  );

  const {
    data: customActivitesData,
    error: customActivitiesError,
    isFetching: loadingCustomActivities,
    refetch: getCustomActivites,
  } = useCloseCustomActivitiesQuery(
    {
      destinationId: String(destination?.id),
    },
    { enabled: config?.object === "custom_activity" },
  );

  const customActivities = (customActivitesData?.closeioCustomActivities?.activities || []).map((a) => ({
    label: a.name,
    value: a.id,
    fields: a.fields,
  }));

  const contactFields = contactColumnsData?.closeioDescribeObject?.fields;

  const {
    data: leadColumnsData,
    error: leadColumnsError,
    isFetching: loadingLeadColumns,
    refetch: getLeadColumns,
  } = useCloseLeadColumnsQuery({
    destinationId: String(destination?.id),
    object: "lead",
  });

  const customActivityFields = (customActivities.find((a) => a.value === config?.customActivity)?.fields || []).map((f) => ({
    label: f?.name,
    value: `custom.${f?.id}`,
    type: f?.standardType,
  }));

  const leadFields = leadColumnsData?.closeioDescribeObject?.fields;

  const columnsLoading = config?.object === "lead" ? loadingLeadColumns : loadingContactColumns;
  const columnsError = config?.object === "lead" ? leadColumnsError : contactColumnsError;

  const fields = config?.object === "lead" ? leadFields : contactFields;

  const customFields =
    fields?.map((a) => ({
      label: a?.name,
      value: a?.id,
      type: a?.standardType,
    })) || [];

  const customLeadFields =
    leadFields?.map((a) => ({
      label: a?.name,
      value: a?.id,
      type: a?.standardType,
    })) || [];

  const FIELDS = {
    lead: [
      { label: "Name", value: "name" },
      { label: "Description", value: "description" },
      { label: "URL", value: "url" },
      { label: "Addresses", value: "addresses" },
    ],
    contact: [
      { label: "Name", value: "name" },
      { label: "Title", value: "title" },
      { label: "Phone", value: "phone" },
      { label: "Email", value: "email" },
    ],
    opportunity: [
      { label: "Note", value: "note" },
      { label: "Confidence", value: "confidence" },
      { label: "Status ID", value: "status_id" },
      { label: "Date Won", value: "date_won" },
      { label: "Value", value: "value" },
      { label: "Value Period", value: "value_period" },
    ],
    note_activity: [
      { label: "Note", value: "note" },
      { label: "Contact ID", value: "contact_id" },
      { label: "User ID", value: "user_id" },
    ],
    custom_activity: customActivityFields,
  };

  const lookupFields = [
    ...FIELDS.lead,
    { label: "ID", value: "id" },
    ...customLeadFields.map(({ label, value }) => ({ label: `${label} (Custom)`, value: `custom.${value}` })),
  ];

  const idMappingOpts = config?.object
    ? {
        lead: [
          ...FIELDS[config?.object],
          { label: "ID", value: "id" },
          ...customFields.map(({ label, value }) => ({ label: `${label} (Custom)`, value: `custom.${value}` })),
        ],
        contact: [
          { label: "Email", value: "email" },
          { label: "ID", value: "id" },
          ...customFields.map(({ label, value }) => ({ label: `${label} (Custom)`, value: `custom.${value}` })),
        ],
        opportunity: [
          ...FIELDS[config?.object],
          { label: "ID", value: "id" },
          ...customFields.map(({ label, value }) => ({ label: `${label} (Custom)`, value: `custom.${value}` })),
        ],
        note_activity: [{ label: "ID", value: "id" }],
        custom_activity: customActivityFields,
      }
    : {};

  return (
    <>
      <ObjectField options={OBJECTS} onChange={(object) => setConfig({ type: config?.type, object })} />

      {config?.object === "custom_activity" && (
        <Section>
          <Field
            error={customActivitiesError?.message || errors?.customActivity}
            label="Which custom activity would you like to sync data to?"
          >
            <Select
              isError={errors?.customActivity}
              isLoading={loadingCustomActivities}
              options={customActivities}
              placeholder="Select an Activity..."
              reload={getCustomActivites}
              value={customActivities?.find((o) => o.value === config?.customActivity)}
              width="240px"
              onChange={(selected) => {
                const val = selected?.value;
                setConfig({ ...config, customActivity: val });
              }}
            />
          </Field>
        </Section>
      )}

      {config?.object && (
        <ModeField
          options={MODES}
          onChange={(mode) =>
            setConfig({
              ...config,
              mode,
              type: config?.type,
              object: config?.object,
            })
          }
        />
      )}

      {config?.object && (
        <Section>
          <Grid gap={8}>
            <IdMappingField error={columnsError?.message} options={idMappingOpts[config?.object]} />

            {["contact", "opportunity", "custom_activity", "note_activity"].includes(config?.object) && (
              <Field
                description={
                  "Hightouch allows you to lookup the associated lead based on it's name, description, url, email, or ID. If the associated lead is not found, the row will throw an error."
                }
                error={errors?.leadLookupMapping || errors?.["leadLookupMapping.from"] || errors?.["leadLookupMapping.to"]}
                label="How should we look up the associated lead?"
              >
                <MappingsHeader object={"leads"} />

                <Grid sx={{ gridAutoColumns: "max-content", gridAutoFlow: "column", alignItems: "center" }}>
                  <Select
                    isError={errors?.leadLookupMapping || errors?.["leadLookupMapping.from"]}
                    isLoading={loadingModel}
                    options={hightouchColumns}
                    placeholder="Select a column..."
                    reload={reloadModel}
                    value={config?.leadLookupMapping?.from}
                    width="240px"
                    onChange={(selected) => {
                      const val = selected?.value;
                      setConfig({ ...config, leadLookupMapping: { ...config?.leadLookupMapping, from: val } });
                    }}
                  />
                  <Arrow />
                  <Select
                    isError={errors?.leadLookupMapping || errors?.["leadLookupMapping.to"]}
                    options={lookupFields}
                    placeholder="Select a field..."
                    value={lookupFields?.find((o) => o.value === config?.leadLookupMapping?.to)}
                    width="240px"
                    onChange={(selected) => {
                      const val = selected?.value;
                      setConfig({ ...config, leadLookupMapping: { ...config?.leadLookupMapping, to: val } });
                    }}
                  />
                </Grid>
              </Field>
            )}
          </Grid>
        </Section>
      )}

      {config?.object && (
        <>
          <Section>
            <MappingsField options={FIELDS[config?.object]} />
          </Section>
          {["lead", "contact"].includes(config?.object) && (
            <Section>
              <MappingsField
                isCustom
                error={columnsError?.message}
                loading={columnsLoading}
                options={customFields}
                reload={config?.object === "lead" ? getLeadColumns : getContactColumns}
              />
            </Section>
          )}
        </>
      )}
    </>
  );
};

export default { form: CloseIOForm, validation };
