import { FC, ReactNode } from "react";

import { Text, ThemeUIStyleObject } from "theme-ui";

import { CheckCircleLightIcon } from "src/ui/icons";

export interface CheckListProps {
  children?: ReactNode;
}

export interface CheckListItemProps {
  children?: ReactNode;
  sx?: ThemeUIStyleObject;
}

export const CheckList: FC<Readonly<CheckListProps>> = ({ children }) => {
  return <ul style={{ listStyle: "none", padding: 0, margin: "24px auto" }}>{children}</ul>;
};

export const CheckListItem: FC<Readonly<CheckListItemProps>> = ({ children, sx = {}, ...props }) => {
  return (
    <li style={{ display: "flex", alignItems: "flex-start", gap: "12px", marginBottom: "16px" }} {...props}>
      <CheckCircleLightIcon size={18} />
      <Text sx={{ color: "base.9", fontWeight: "semi", ...sx }}>{children}</Text>
    </li>
  );
};
