import { VFC } from "react";

import { Grid } from "theme-ui";

import {
  Settings,
  DbtCredentials,
  Notifications,
  GitCredentials,
  LookerCredentials,
  CloudProviders,
  DatadogCredentials,
} from "src/components/settings";

export const Integrations: VFC = () => {
  return (
    <Settings route="integrations">
      <Grid gap={12}>
        <CloudProviders />
        <DbtCredentials />
        <GitCredentials />
        <LookerCredentials />
        <Notifications />
        <DatadogCredentials />
      </Grid>
    </Settings>
  );
};
