import { VFC } from "react";

import { Box, Image, Heading, Paragraph } from "theme-ui";

import BrandLogoLight from "src/components/logos/hightouch-light.svg";
import { Button } from "src/ui/button";
import { CheckList, CheckListItem } from "src/ui/checklist";
import { Confetti } from "src/ui/confetti";
import { Link } from "src/ui/link";
import { Modal } from "src/ui/modal";

type Props = {
  plan: string;
  checkList: string[];
  isOpen?: boolean;
  onClose: () => void;
};

export const PlanWelcomeModal: VFC<Readonly<Props>> = ({ plan, checkList, isOpen, onClose }) => {
  return (
    <Modal
      bodySx={{ backgroundColor: "white", p: 6 }}
      footerContent={Footer(onClose)}
      footerSx={{ border: "none", flexDirection: "column", pt: 0, pb: 6, px: 6 }}
      header={Header(plan)}
      headerSx={{ border: "none", px: 6 }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box>
        <Confetti showConfetti={true} />
        <Paragraph id="plan-welcome-modal--supports" sx={{ color: "base.5", fontSize: 2 }}>
          Your Hightouch plan now supports...
        </Paragraph>
        <CheckList aria-labelledby="plan-welcome-modal--supports">
          {checkList?.map((listItem, index) => {
            return (
              <CheckListItem key={index} sx={{ color: "base.9" }}>
                {listItem}
              </CheckListItem>
            );
          })}
        </CheckList>
      </Box>
    </Modal>
  );
};

const Header = (plan) => {
  return (
    <>
      <Box>
        <Image alt="Hightouch." src={BrandLogoLight} />
        <Heading sx={{ fontWeight: "extrabold", fontSize: 7, mt: 4 }} variant="h2">
          Welcome to the {plan} Plan!
        </Heading>
      </Box>
    </>
  );
};
const Footer = (onClose) => {
  return (
    <>
      <Paragraph sx={{ color: "base.5" }}>
        If you have additional questions, check out our&nbsp;
        <span>
          <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/workspaces/pricing/`} variant="subtle">
            plans and pricing docs
          </Link>
        </span>
        &nbsp;for guidance.
      </Paragraph>
      <Button size="large" sx={{ width: "100%", mt: 6 }} type="button" variant="primary" onClick={onClose}>
        Continue working in Hightouch
      </Button>
    </>
  );
};
