import { VFC, useEffect, useState } from "react";

import _ from "lodash";
import { useToasts } from "react-toast-notifications";
import { Flex, Grid } from "theme-ui";

import { Help } from "src/components/help";
import { useCreateDbtCredentialMutation, useDbtCredentialsQuery, useUpdateDbtCredentialMutation } from "src/graphql";
import { Container } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";

export const DbtCredentialsForm: VFC = () => {
  const { addToast } = useToasts();
  const [error, setError] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [dbtCredentials, setDBTCredentials] = useState<any>();

  const { mutateAsync: createCredential } = useCreateDbtCredentialMutation();
  const { mutateAsync: updateCredential } = useUpdateDbtCredentialMutation();

  const { data: dbtCredentialsData } = useDbtCredentialsQuery();

  const initialDBTCredentials = dbtCredentialsData?.dbt_credentials?.[0];

  useEffect(() => {
    if (dbtCredentialsData?.dbt_credentials?.[0]) {
      setDBTCredentials(dbtCredentialsData.dbt_credentials[0]);
    }
  }, [dbtCredentialsData]);

  const save = async () => {
    setSaveLoading(true);
    setError("");
    try {
      if (dbtCredentials?.id) {
        await updateCredential({
          id: dbtCredentials.id,
          api_key: dbtCredentials.api_key,
          subdomain: dbtCredentials.subdomain,
        });
      } else {
        const { createDBTCredential } = await createCredential({
          api_key: dbtCredentials.api_key,
          subdomain: dbtCredentials.subdomain,
        });

        setDBTCredentials(createDBTCredential);
      }

      addToast("Your dbt integration configuration has been saved.", {
        appearance: "success",
      });
    } catch (err) {
      setError(err?.message);
    } finally {
      setSaveLoading(false);
    }
  };

  const dirty = typeof dbtCredentials !== "undefined" && !_.isEqual(dbtCredentials, initialDBTCredentials);

  return (
    <>
      <Section
        divider
        footer={
          <Flex sx={{ justifyContent: "flex-end" }}>
            <Button disabled={!dirty} label={"Save"} loading={saveLoading} onClick={save} />
          </Flex>
        }
      >
        <Container center={false} size="small">
          <Field
            description={`Hightouch syncs can be triggered by the successful completion of a dbt cloud job. In order to enable this feature across your hightouch workspace, please enter your dbt Cloud API Key.`}
            label="Trigger syncs with dbt Cloud"
            size="large"
          >
            <Grid gap={8}>
              <Field error={error?.includes("API Key") && error} label="dbt Cloud API Key">
                <Input
                  error={error?.includes("API Key")}
                  type="password"
                  value={dbtCredentials?.api_key}
                  onChange={(api_key) => setDBTCredentials({ ...dbtCredentials, api_key })}
                />
              </Field>
              <Field
                optional
                description='If left empty, Hightouch will default to "cloud" as the subdomain.'
                error={error?.includes("subdomain") && error}
                label="Custom Subdomain"
              >
                <Input
                  error={error?.includes("subdomain")}
                  value={dbtCredentials?.subdomain}
                  onChange={(subdomain) => setDBTCredentials({ ...dbtCredentials, subdomain })}
                />
              </Field>
            </Grid>
          </Field>
        </Container>

        <Help docs={`${import.meta.env.VITE_DOCS_URL}/integrations/dbt-cloud`} label={"dbt Cloud"} sx={{ mt: 6 }} />
      </Section>
    </>
  );
};
