import { VFC, useEffect, useState } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual } from "lodash";
import { useToasts } from "react-toast-notifications";
import { Grid } from "theme-ui";

import { Settings } from "src/components/settings";
import { Slug } from "src/components/slug";
import { useUser } from "src/contexts/user-context";
import {
  useIsWorkspaceSlugAvailableQuery,
  useUpdateWorkspaceDomainsMutation,
  useUpdateWorkspaceMutation,
  useVerifiedDomainsQuery,
  useWorkspaceQuery,
} from "src/graphql";
import { Container } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Message } from "src/ui/message";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { useNavigate } from "src/utils/navigate";

import { DisplayRegion } from "../../components/workspaces/select-region";

export const Workspace: VFC = () => {
  return (
    <Settings route="workspace">
      <Container center={false} size="small">
        <Grid gap={12}>
          <General />
          <Authorized />
        </Grid>
      </Container>
    </Settings>
  );
};

const General: VFC = () => {
  const { multiRegionEnabled } = useFlags();
  const { workspace } = useUser();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const [name, setName] = useState<string>(workspace?.name ?? "");
  const [slug, setSlug] = useState<string>(workspace?.slug ?? "");
  const [defaultRole, setDefaultRole] = useState<string | undefined>(workspace?.default_role_id);

  const roleOptions = workspace?.roles.map(({ id, name }) => {
    return { label: name, value: id };
  });

  const { data: slugData, isLoading: loadingSlug } = useIsWorkspaceSlugAvailableQuery(
    {
      slug,
    },
    { enabled: Boolean(slug) },
  );
  const { isLoading: updating, mutateAsync: updateWorkspace } = useUpdateWorkspaceMutation();

  const available = workspace?.slug === slug || slugData?.isWorkspaceSlugAvailable;

  const update = async () => {
    await updateWorkspace({
      id: workspace?.id,
      input: { name, slug, default_role_id: defaultRole },
    });

    if (slug !== workspace?.slug) {
      navigate(`/${slug}/settings/workspace`, { replace: true, slug: false });
    }

    addToast(`Workspace updated!`, {
      appearance: "success",
    });
  };

  const dirty =
    name !== workspace?.name || slug !== workspace?.slug || String(defaultRole) !== String(workspace?.default_role_id);

  return (
    <Section
      divider
      footer={
        <Button disabled={!dirty || !available} loading={updating} onClick={update}>
          Save
        </Button>
      }
      title="Workspace settings"
    >
      <Grid gap={8}>
        <Field label="Workspace name">
          <Input value={name} onChange={setName} />
        </Field>

        <Field description="app.hightouch.io/" label="Workspace URL">
          <Slug
            available={Boolean(available)}
            loading={loadingSlug}
            placeholder={"your-workspace-slug"}
            value={slug}
            onChange={setSlug}
          />
        </Field>

        {workspace?.organization && (
          <Field label="Billing organization">
            <Input disabled value={workspace.organization.name} />
          </Field>
        )}

        {multiRegionEnabled && (
          <Field label="Workspace region">
            <DisplayRegion region={workspace?.region} />
          </Field>
        )}

        <Field description="The role assigned to people that join this workspace" label="Default role">
          <Select
            options={roleOptions}
            placeholder="Role"
            value={defaultRole}
            onChange={({ value }) => setDefaultRole(value)}
          />
        </Field>
      </Grid>
    </Section>
  );
};

const Authorized: VFC = () => {
  const { workspace: _workspace } = useUser();
  const { data: workspaceData } = useWorkspaceQuery({ workspaceId: _workspace?.id }, { enabled: Boolean(_workspace) });
  const { data: domainData, isLoading: domainLoading } = useVerifiedDomainsQuery();

  const workspace = workspaceData?.workspaces_by_pk;
  const domains = domainData?.getVerifiedDomains;

  const domainOptions = domains?.map((d) => ({ label: d, value: d }));

  const [visibleTo, setVisibleTo] = useState<string[]>(workspace?.visible_to);
  const [joinableTo, setJoinableTo] = useState<string[]>(workspace?.joinable_to);

  const { authDisableNonSso } = useFlags();

  useEffect(() => {
    setVisibleTo(workspace?.visible_to);
    setJoinableTo(workspace?.joinable_to);
  }, [workspace]);

  const { isLoading: updating, mutateAsync: updateDomains } = useUpdateWorkspaceDomainsMutation();

  const dirty = !isEqual(visibleTo, workspace?.visible_to) || !isEqual(joinableTo, workspace?.joinable_to);

  if (authDisableNonSso) {
    return (
      <Section title="Authorized domains">
        <Message>
          Joining the workspace by email domain is disabled in this workspace. Please use the SSO identity provider connected to
          your organization.
        </Message>
      </Section>
    );
  }

  return (
    <Section
      footer={
        <Button
          disabled={!dirty}
          loading={updating}
          onClick={() => {
            updateDomains({ visibleTo: visibleTo || [], joinableTo: joinableTo || [] });
          }}
        >
          Save
        </Button>
      }
      title="Authorized domains"
    >
      <Grid gap={8}>
        <Field
          description="Users with these domains will be able to see this workspace and request to join."
          label="Which domains can see this workspace?"
        >
          <Select
            isMulti
            isLoading={domainLoading}
            options={domainOptions}
            size="large"
            value={visibleTo}
            onChange={(v) => {
              setVisibleTo(v?.map((v) => v.value));
            }}
          />
        </Field>
        <Field
          description="Users with these domains will be able to see and join this workspace without approval."
          label="Which domains can auto-join this workspace?"
        >
          <Select
            isMulti
            isLoading={domainLoading}
            options={domainOptions}
            size="large"
            value={joinableTo}
            onChange={(v) => {
              setJoinableTo(v?.map((v) => v.value));
            }}
          />
        </Field>
      </Grid>
    </Section>
  );
};
