import { forwardRef } from "react";

import { Row } from "src/ui/box";
import { CheckCircleIcon } from "src/ui/icons";

export const Option = forwardRef<HTMLDivElement, any>(({ option, onClick, disabled, selected, multi = false }, ref) => {
  return (
    <Row
      ref={ref}
      sx={{
        alignItems: "center",
        py: "2px",
        px: 1,
        cursor: disabled ? "not-allowed" : "pointer",
        ":hover": {
          "> div": { bg: disabled || selected ? undefined : "base.1" },
        },
      }}
      onClick={() => onClick(option)}
    >
      <Row
        sx={{
          py: 2,
          px: 2,
          flex: 1,
          alignItems: "center",
          fontSize: 1,
          borderRadius: 1,
          transition: "100ms color, 100ms background-color",
          color: disabled ? "dark.0" : selected ? "primary" : undefined,
          bg: selected ? "primaries.1" : undefined,
          ...(option.sx || {}),
        }}
      >
        {option.render ? option.render({}) : option.label}
        {selected && multi && <CheckCircleIcon color="primary" size={16} sx={{ ml: "auto" }} />}
      </Row>
    </Row>
  );
});

Option.displayName = "Option";
