import { FC, useMemo } from "react";

import { times } from "lodash";
import { Grid, Text } from "theme-ui";

import { Column } from "src/ui/box";
import { Skeleton } from "src/ui/loading";
import { Section } from "src/ui/section";
import { Selectable } from "src/ui/selectable";
import { Placeholder } from "src/ui/table/placeholder";
import { UseSourcesResult, SourceBadges, SourceIcon } from "src/utils/sources";

type Source = UseSourcesResult["data"][0];

type Props = {
  onSelect: (source: Source) => void;
  selection: string;
  sources: Source[];
  error: Error | undefined | null;
};

export const SourcesGrid: FC<Readonly<Props>> = ({ sources, onSelect, selection, error }) => {
  if (!sources) {
    return (
      <Grid columns="repeat(3, 260px)" gap={4}>
        {times(3).map((i) => (
          <Skeleton key={i} height="78px" />
        ))}
      </Grid>
    );
  }

  const usingDemoSource = useMemo(() => window.localStorage.getItem("useDemoSource"), []);

  // We only allow to select demo sources if there are no other sources available.
  const nonDemoSources = sources.filter((source) => !source.is_demo);
  const availableSources = nonDemoSources.length > 0 || !usingDemoSource ? nonDemoSources : sources;

  if (!availableSources?.length) {
    return (
      <Section sx={{ width: "100%", maxWidth: "100%" }}>
        <Placeholder
          content={{
            title: "No sources",
            body: "Add a source to get started",
            error: "Sources failed to load, please try again.",
          }}
          error={Boolean(error)}
        />
      </Section>
    );
  }

  return (
    <Grid
      gap={4}
      sx={{
        gridTemplateColumns: "repeat(3, 260px)",
        gridAutoRows: "1fr",
      }}
    >
      {availableSources.map((source) => (
        <Selectable
          key={source.id}
          selected={source.id === selection}
          sx={{ flexDirection: "row", alignItems: "center" }}
          onSelect={() => onSelect(source)}
        >
          <SourceIcon source={source} sx={{ width: "36px", mr: 4, flexShrink: 0 }} />
          <Column>
            <Text sx={{ fontWeight: "bold", textOverflow: "ellipsis", overflow: "hidden" }}>{source.name}</Text>
            <Text sx={{ fontSize: 0, color: "dark.0", textOverflow: "ellipsis", overflow: "hidden" }}>
              {source.definition?.name}
            </Text>
            <SourceBadges source={source} sx={{ mt: 1, ml: 0 }} />
          </Column>
        </Selectable>
      ))}
    </Grid>
  );
};
