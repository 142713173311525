import { VFC } from "react";

import { Box, Card, Heading, Text } from "theme-ui";

import { WarningIcon } from "src/ui/icons";

import { colors } from "../../../../design/colors";
import backgroundImage from "./background.svg";

type Props = {
  title: string;
  subtitle?: string;
  fullScreen?: boolean;
};

export const Warning: VFC<Props> = ({ title, subtitle, fullScreen }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: fullScreen ? `url(${backgroundImage})` : "none",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center -80px",
        pt: fullScreen ? 30 : 10,
      }}
    >
      <Text sx={{ fontWeight: 700, fontSize: 3, color: colors.base[4], mb: 10 }}>hightouch</Text>
      <Card
        sx={{
          p: 8,
          width: "628px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <WarningIcon color={colors.base[4]} size={48} />
        <Heading sx={{ fontWeight: "bold", my: 8, fontSize: "32px" }}>{title}</Heading>
        <Text sx={{ fontWeight: "semi", fontSize: 2, color: colors.base[4] }}>{subtitle || "It may have been deleted"}</Text>
      </Card>
    </Box>
  );
};
