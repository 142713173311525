import { FC, VFC, useState, MouseEventHandler } from "react";

import { Image, Text, ThemeUIStyleObject, Grid, Box, Flex, Divider } from "theme-ui";

import { LogoHeader } from "src/components/layout";
import CheckIcon from "src/components/personalization/check.svg";
import ConsultationImage from "src/components/personalization/consultation.svg";
import CustomerServiceImage from "src/components/personalization/customer-service.svg";
import DataImage from "src/components/personalization/data-professional.svg";
import DemoImage from "src/components/personalization/demo.svg";
import EngineerImage from "src/components/personalization/engineer.svg";
import ITImage from "src/components/personalization/it-professional.svg";
import MarketerImage from "src/components/personalization/marketer.svg";
import OtherImage from "src/components/personalization/other.svg";
import { useUser } from "src/contexts/user-context";
import { useSaveUserPersonalizationMutation } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { TextArea, Input } from "src/ui/input";
import { useNavigate } from "src/utils/navigate";

enum Role {
  Marketer = "marketer",
  Engineer = "engineer",
  CustomerService = "customer-service",
  ITProfessional = "IT-professional",
  DataProfessional = "data-professional",
  Other = "other",
}

const steps = [
  {
    title: "What describes you best?",
    subtitle: "We personalize your setup experience based on this",
  },
  {
    title: "How did you hear about Hightouch?",
    subtitle: "Your input is voluntary and appreciated",
  },
  {
    title: "Would you like to speak to a data specialist?",
    subtitle: "See a demo of Hightouch in action, or jump right in!",
  },
];

const HelloPage: VFC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [role, setRole] = useState<Role | undefined>();
  const [customRole, setCustomRole] = useState<string | undefined>();
  const [showCustomRole, setShowCustomRole] = useState(false);
  const [referrer, setReferrer] = useState<string | undefined>();
  const [step, setStep] = useState(0);

  const { mutateAsync: saveUserPersonalization } = useSaveUserPersonalizationMutation();

  const submit = () => {
    navigate("/", { slug: false });

    analytics.identify(String(user?.id), { role, custom_role: customRole, hdyhau: referrer });
    analytics.track("User Personalization Saved");

    if (user?.id) {
      saveUserPersonalization({
        userId: String(user.id),
        role,
        customRole,
        referrer,
      });
    }
  };

  return (
    <Column sx={{ width: "100%", alignItems: "center", minHeight: "100vh" }}>
      <LogoHeader />
      <Column
        as="form"
        sx={{ alignItems: "center", flexGrow: "1", mt: 4, px: 6 }}
        onSubmit={(event) => {
          event.preventDefault();
          setStep((step) => step + 1);
        }}
      >
        <Box mb={12} mt={3}>
          <Row sx={{ alignItems: "center" }}>
            <StepDot active={step === 0} complete={step > 0} disabled={step === 0} />
            <Box sx={{ width: "24px", height: "1px", bg: "secondary" }} />
            <StepDot active={step === 1} complete={step > 1} disabled={step < 1} />
            <Box sx={{ width: "24px", height: "1px", bg: "secondary" }} />
            <StepDot disabled active={step === 2} complete={step > 2} />
          </Row>
        </Box>
        <Heading
          sx={{
            textAlign: "center",
            fontSize: 6,
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 5,
          }}
          variant="h1"
        >
          {steps[step]?.title}
        </Heading>
        <Text sx={{ color: "base.5", fontSize: "16px", textAlign: "center", mb: 24 }}>{steps[step]?.subtitle}</Text>
        <Column
          sx={{
            alignItems: "center",
            flexGrow: "1",
            fontSize: 3,
            width: "100%",
            maxWidth: step === 1 ? "448px" : undefined,
          }}
        >
          {step === 0 && (
            <Grid columns={[1, 2, 3]} gap={6}>
              <Selectable
                active={role === Role.Marketer}
                image={MarketerImage}
                label="Marketer"
                onClick={() => {
                  setRole(Role.Marketer);
                  setShowCustomRole(false);
                }}
              />
              <Selectable
                active={role === Role.DataProfessional}
                image={DataImage}
                label="Data Professional"
                onClick={() => {
                  setRole(Role.DataProfessional);
                  setShowCustomRole(false);
                }}
              />
              <Selectable
                active={role === Role.CustomerService}
                image={CustomerServiceImage}
                label="Sales or RevOps"
                onClick={() => {
                  setRole(Role.CustomerService);
                  setShowCustomRole(false);
                }}
              />
              <Selectable
                active={role === Role.ITProfessional}
                image={ITImage}
                label="IT Professional"
                onClick={() => {
                  setRole(Role.ITProfessional);
                  setShowCustomRole(false);
                }}
              />
              <Selectable
                active={role === Role.Engineer}
                image={EngineerImage}
                label="Engineer"
                onClick={() => {
                  setRole(Role.Engineer);
                  setShowCustomRole(false);
                }}
              />
              {!showCustomRole && (
                <Selectable
                  active={role === Role.Other}
                  image={OtherImage}
                  label="Something Else"
                  onClick={() => {
                    setRole(Role.Other);
                    setShowCustomRole(true);
                  }}
                />
              )}
              {role === Role.Other && showCustomRole && (
                <Card
                  sx={{
                    cursor: "pointer",
                    transition: "150ms border-color background-color",
                    borderColor: "primaries.4",
                    bg: "base.0",
                  }}
                >
                  <Image src={OtherImage} />
                  <Box mt={4} mx={6}>
                    <Input
                      autoFocus
                      placeholder="I'm a ..."
                      sx={{ flexGrow: 0, fontSize: "14px" }}
                      value={customRole}
                      onChange={setCustomRole}
                    />
                  </Box>
                </Card>
              )}
            </Grid>
          )}

          {step === 1 && (
            <TextArea
              autoFocus
              placeholder="Type your answer..."
              sx={{ width: "100%", resize: "none", maxHeight: "120px", fontSize: "14px" }}
              value={referrer}
              onChange={(event) => setReferrer(event.target.value)}
            />
          )}

          {step === 2 && (
            <Column sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}>
              <Flex sx={{ alignItems: "center", width: "100%" }}>
                <img src={ConsultationImage} />
                <Box mx={4} sx={{ flex: "1 1 auto" }}>
                  I would like a general consultation
                </Box>
                <Button
                  size="large"
                  variant="secondary"
                  onClick={() => {
                    const win = window.open("https://calendly.com/hightouch-experts/consultation", "_blank");
                    win?.focus();
                    submit();
                  }}
                >
                  Book
                </Button>
              </Flex>
              <Divider my={4} sx={{ borderColor: "base.2", width: "100%" }} />
              <Flex sx={{ alignItems: "center", width: "100%" }}>
                <img src={DemoImage} />
                <Box mx={4} sx={{ flex: "1 1 auto" }}>
                  I would like a demo of the app
                </Box>
                <Button
                  size="large"
                  variant="secondary"
                  onClick={() => {
                    const win = window.open("https://calendly.com/hightouch-experts/30min", "_blank");
                    win?.focus();
                    submit();
                  }}
                >
                  Book
                </Button>
              </Flex>
            </Column>
          )}
        </Column>
      </Column>
      <Divider my={4} sx={{ borderColor: "base.2", width: "100%" }} />
      <Flex mb={4} px={4} sx={{ justifyContent: "flex-end", width: "100%", maxWidth: "800px" }}>
        {step < 2 && (
          <Box mr={4}>
            <Button
              size="large"
              type="button"
              variant="text-secondary"
              onClick={() => {
                setStep((step) => step + 1);
              }}
            >
              {`Skip`}
            </Button>
          </Box>
        )}
        <Button
          disabled={step === 0 ? !role || (role === "other" && customRole === "") : step === 1 ? !referrer : false}
          size="large"
          type="button"
          variant="primary"
          onClick={() => {
            step === 2 ? submit() : setStep((step) => step + 1);
          }}
        >
          {step === 2 ? "Go to Hightouch" : "Continue"}
        </Button>
      </Flex>
    </Column>
  );
};

const Card: FC<Readonly<{ onClick?: MouseEventHandler; sx?: ThemeUIStyleObject }>> = ({ children, onClick, sx = {} }) => (
  <Box
    sx={{
      width: "240px",
      height: "180px",
      border: "small",
      borderRadius: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
  </Box>
);

const Selectable: VFC<
  Readonly<{
    active: boolean;
    image: string;
    label: string;
    onClick: () => void;
  }>
> = ({ image, label, onClick, active }) => (
  <Card
    sx={{
      cursor: "pointer",
      transition: "150ms border-color background-color",
      borderColor: active && "primaries.4",
      bg: active && "base.0",
      ":hover": {
        bg: "base.0",
        borderColor: "primaries.4",
      },
    }}
    onClick={onClick}
  >
    <Image src={image} />
    <Text sx={{ fontWeight: "bold", mt: 6 }}>{label}</Text>
  </Card>
);

const StepDot: VFC<Readonly<{ complete: boolean; active: boolean; disabled: boolean }>> = ({ complete, active, disabled }) => {
  return (
    <Box
      sx={{
        width: complete ? "24px" : "12px",
        height: complete ? "24px" : "12px",
        borderRadius: "50%",
        bg: active || complete ? "secondary" : "white",
        mx: 2,
        border: "small",
        borderColor: "secondary",
        cursor: disabled ? "default" : "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {complete && <Image src={CheckIcon} />}
    </Box>
  );
};

export default HelloPage;
