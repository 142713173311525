import { VFC } from "react";

import { Card } from "theme-ui";

import * as analytics from "src/lib/analytics";
import { OnboardingCreateDestination } from "src/pages/onboarding/onboarding-create-destination";
import { OnboardingCreateModel } from "src/pages/onboarding/onboarding-create-model";
import { OnboardingCreateSource } from "src/pages/onboarding/onboarding-create-source";
import { OnboardingCreateSync } from "src/pages/onboarding/onboarding-create-sync";
import { Modal } from "src/ui/modal";

import { OnboardingActions, OnboardingData } from "./onboarding-reducer";

type Props = {
  openModal: boolean;
  onboardingModal:
    | "declareSource"
    | "declareDestination"
    | "connectSource"
    | "connectDestination"
    | "changeSource"
    | "changeDestination"
    | "setupModel"
    | "changeModel"
    | "createSync"
    | undefined;
  closeModal: (clearOAuth?: boolean) => void;
  onboardingData: OnboardingData;
  onSetOnboardingData: (action: OnboardingActions) => void;
};

export const OnboardingModal: VFC<Props> = ({
  openModal,
  onboardingModal,
  onboardingData,
  onSetOnboardingData,
  closeModal,
}) => {
  return (
    <>
      <Modal card={false} fullscreen={true} isOpen={openModal} onClose={closeModal}>
        <Card sx={{ minHeight: "100%", width: "100%" }}>
          {onboardingModal === "declareSource" && (
            <OnboardingCreateSource
              sourceSetup="declaration"
              onCancel={closeModal}
              onSubmit={({ definition }) => {
                onSetOnboardingData({ type: "set.declareSource", source: definition });
                closeModal();
                analytics.track("Source Declared", {
                  source_type: definition?.name,
                  is_demo: false, //Todo: Needs to be updated when demo data is added to onboarding flow
                });
              }}
            />
          )}
          {onboardingModal === "declareDestination" && (
            <OnboardingCreateDestination
              destinationSetup="declaration"
              onCancel={closeModal}
              onSubmit={({ definition }) => {
                onSetOnboardingData({ type: "set.declareDestination", destination: definition });
                closeModal();
                analytics.track("Destination Declared", {
                  destination_type: definition.name,
                });
              }}
            />
          )}
          {onboardingModal === "connectSource" && (
            <OnboardingCreateSource
              definition={onboardingData.declareSource}
              sourceSetup="connect"
              onCancel={() => {
                closeModal(true);
              }}
              onSubmit={({ id, definition }) => {
                onSetOnboardingData({ type: "set.onboardingSource", sourceId: id, definition });
                closeModal(true);
                analytics.track("Source Config Completed", {
                  source_type: definition?.type,
                  source_id: id,
                });
              }}
            />
          )}
          {onboardingModal === "connectDestination" && (
            <OnboardingCreateDestination
              definition={onboardingData.declareDestination}
              destinationSetup="connect"
              onCancel={() => {
                closeModal(true);
              }}
              onSubmit={({ id, definition }) => {
                onSetOnboardingData({ type: "set.onboardingDestination", destinationId: id, definition });
                closeModal(true);
                analytics.track("Destination Config Completed", {
                  destination_type: definition.type,
                  destination_id: id,
                });
              }}
            />
          )}
          {onboardingModal === "changeSource" && (
            <OnboardingCreateSource
              sourceSetup="all"
              onCancel={() => {
                closeModal(true);
              }}
              onSubmit={({ id, definition }) => {
                onSetOnboardingData({ type: "set.onboardingSource", sourceId: id, definition });
                closeModal(true);
                analytics.track("Change Source Completed", {
                  source_type: definition?.type,
                  source_id: id,
                });
              }}
            />
          )}
          {onboardingModal === "changeDestination" && (
            <OnboardingCreateDestination
              destinationSetup="all"
              onCancel={() => {
                closeModal(true);
              }}
              onSubmit={({ id, definition }) => {
                onSetOnboardingData({ type: "set.onboardingDestination", destinationId: id, definition });
                closeModal(true);
                analytics.track("Change Destination Completed", {
                  destination_type: definition.type,
                  destination_id: id,
                });
              }}
            />
          )}
          {onboardingModal === "setupModel" && (
            <OnboardingCreateModel
              source={onboardingData.onboardingSource}
              onCancel={closeModal}
              onSubmit={({ id, type }) => {
                onSetOnboardingData({ type: "set.onboardingModel", modelId: id });
                analytics.track("Model Config Completed", {
                  source_type: onboardingData.onboardingSource?.type,
                  source_id: onboardingData.onboardingSource?.id,
                  model_id: id,
                  query_type: type,
                });
                closeModal();
              }}
            />
          )}
          {onboardingModal === "changeModel" && (
            <OnboardingCreateModel
              model={onboardingData.onboardingModel}
              source={onboardingData.onboardingSource}
              onCancel={closeModal}
              onSubmit={({ id, type }) => {
                onSetOnboardingData({ type: "set.onboardingModel", modelId: id });
                analytics.track("Change Model Completed", {
                  source_type: onboardingData.onboardingSource?.type,
                  source_id: onboardingData.onboardingSource?.id,
                  model_id: id,
                  query_type: type,
                });
                closeModal();
              }}
            />
          )}
          {onboardingModal === "createSync" && (
            <OnboardingCreateSync
              destination={onboardingData.onboardingDestination}
              destinationDefinition={onboardingData.declareDestination}
              model={onboardingData.onboardingModel}
              onCancel={closeModal}
              onSubmit={({ id }) => {
                onSetOnboardingData({ type: "set.onboardingSync", syncId: id });
                analytics.track("Sync Config Completed", {
                  source_type: onboardingData.onboardingSource?.type,
                  source_id: onboardingData.onboardingSource?.id,
                  model_id: onboardingData.onboardingModel?.id,
                  query_type: onboardingData.onboardingModel?.query_type,
                  destination_type: onboardingData.onboardingDestination?.type,
                  destination_id: onboardingData.onboardingDestination?.id,
                  sync_id: id,
                });
                closeModal();
              }}
            />
          )}
        </Card>
      </Modal>
    </>
  );
};
