import { FC, useState } from "react";

import immutableUpdate from "immutability-helper";
import { useToasts } from "react-toast-notifications";
import { Text, ThemeUIStyleObject } from "theme-ui";

import { AudienceTraitForm } from "src/components/audiences/audience-trait-form";
import { useUpdateAudienceMutation } from "src/graphql";
import {
  OrCondition,
  Condition,
  ConditionType,
  initialPropertyCondition,
  initialEventCondition,
  initialSetCondition,
  PropertyCondition,
  initialNumberOfCondition,
  NumberOfCondition,
  EventCondition,
  SegmentSetCondition,
  isTraitCondition,
  AdditionalColumn,
  TraitCondition,
  Audience,
  AudienceParent,
} from "src/types/visual";
import { Column, Row, Box } from "src/ui/box";
import { Button } from "src/ui/button";
import { SegmentIcon, PlusIcon, PropertyIcon, UsersIcon, EventIcon } from "src/ui/icons";
import { Menu } from "src/ui/menu";

import { AudienceEventTraitForm } from "../audiences/audience-event-trait-form";
import { ConditionField } from "./visual/condition";

export interface QueryBuilderProps {
  audience?: Audience;
  parent: AudienceParent | undefined | null;
  conditions: OrCondition[] | undefined;
  setConditions: (conditions: OrCondition[]) => void;
  limit?: number;
  setLimit?: (limit: number) => void;
}

export const QueryBuilder: FC<Readonly<QueryBuilderProps>> = ({ audience, parent, conditions = [], setConditions }) => {
  const { addToast } = useToasts();
  const { mutateAsync: updateAudience } = useUpdateAudienceMutation({
    onSuccess: () => {
      // prevents audience query from invalidating
    },
  });

  // Used for adding additional columns
  const [selectedCondition, setSelectedCondition] = useState<TraitCondition | EventCondition | null>(null);

  const relationships = parent?.relationships;
  const traits = parent?.traits;
  const columns = parent?.filterable_audience_columns;
  const events = relationships?.filter(({ to_model: { event } }) => Boolean(event));

  const addAdditionalColumn = async (additionalColumn: AdditionalColumn) => {
    if (!audience) {
      return;
    }

    await updateAudience({
      id: audience.id,
      input: {
        visual_query_filter: {
          ...(audience.visual_query_filter || {}),
          additionalColumns: [additionalColumn, ...(audience.visual_query_filter?.additionalColumns || [])],
        },
      },
    });
    addToast("Trait added", { appearance: "success" });
  };

  const addInitialCondition = (condition: Condition) =>
    setConditions([...conditions, { type: ConditionType.Or, conditions: [condition] }]);

  const addOrCondition = (condition: Condition, index: number) => {
    setConditions(immutableUpdate(conditions, { [index]: { conditions: { $push: [condition] } } }));
  };

  const addCondition = (condition: Condition, index?: number) => {
    if (typeof index === "undefined") {
      addInitialCondition(condition);
    } else {
      addOrCondition(condition, index);
    }
  };

  const updateCondition = (index: number) => (subIndex: number) => (updates: any) => {
    setConditions(immutableUpdate(conditions, { [index]: { conditions: { [subIndex]: { $merge: updates } } } }));
  };

  const removeCondition = (index: number) => (subIndex: number) => () => {
    let updatedConditions = immutableUpdate(conditions, { [index]: { conditions: { $splice: [[subIndex, 1]] } } });

    if (updatedConditions?.[index]?.conditions.length === 0) {
      updatedConditions = immutableUpdate(conditions, { $splice: [[index, 1]] });
    }

    setConditions(updatedConditions);
  };

  const addConditionOptions = (index?: number) => [
    {
      label: "Have a property",
      description: "Filter by a column in the table",
      onClick: () => addCondition(initialPropertyCondition as PropertyCondition, index),
      icon: PropertyIcon,
    },
    {
      label: "Have a relation",
      description: "Filter by a property in a related model",
      onClick: () => addCondition(initialNumberOfCondition as NumberOfCondition, index),
      icon: UsersIcon,
    },
    {
      label: "Performed an event",
      description: "Filter by whether they have an associated event",
      onClick: () => addCondition(initialEventCondition as EventCondition, index),
      icon: EventIcon,
    },
    {
      label: "Part of an Audience",
      description: "Filter by whether they are included or not in an Audience",
      onClick: () => addCondition(initialSetCondition as SegmentSetCondition, index),
      icon: SegmentIcon,
    },
  ];

  return (
    <Box sx={{ height: "100%", overflowY: "auto" }}>
      <VStack gap={8} sx={{ pb: 12, pr: 3, fontWeight: "bold", fontSize: 0 }}>
        {conditions.map((condition, index) => (
          <Row key={index} sx={{ width: "100%" }}>
            <Column sx={{ position: "relative" }}>
              <Row sx={{ alignItems: "center", pt: 4 }}>
                <Row
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "32px",
                    width: "42px",
                    flexShrink: 0,
                    bg: "indigos.1",
                    borderRadius: 1,
                  }}
                >
                  <Text sx={{ color: "white", fontSize: 0, fontWeight: "bold" }}>{conditions.length < 2 ? "ALL" : "AND"}</Text>
                </Row>
                <Box sx={{ height: "2px", bg: "indigos.1", width: "24px" }} />
              </Row>
              <Box sx={{ position: "absolute", top: "48px", height: "100%", bg: "indigos.1", width: "2px", ml: "20px" }} />
            </Column>
            <VStack gap={4}>
              {condition.conditions.map((condition, subIndex) => {
                return (
                  <>
                    {subIndex > 0 && <Text sx={{ fontSize: 0, fontWeight: "bold", color: "base.4" }}>OR</Text>}
                    <VStack
                      gap={4}
                      sx={{
                        p: 4,
                        bg: "secondaries.0",
                        borderRadius: 1,
                        border: "small",
                        borderColor: "secondaries.1",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      {audience && (isTraitCondition(condition) || condition.type === ConditionType.Event) && (
                        <Button
                          size="small"
                          sx={{ position: "absolute", top: 4, right: 4 }}
                          variant="gray"
                          onClick={() => setSelectedCondition(condition)}
                        >
                          Add trait
                        </Button>
                      )}
                      <ConditionField
                        audience={audience}
                        columns={columns}
                        condition={condition}
                        events={events}
                        parent={parent}
                        relationships={relationships}
                        traits={traits}
                        onChange={updateCondition(index)(subIndex)}
                        onRemove={removeCondition(index)(subIndex)}
                      />
                    </VStack>
                  </>
                );
              })}

              <Menu options={addConditionOptions(index)} width="480px">
                <Button propagate iconBefore={<PlusIcon size={14} />} size="small" variant="gray">
                  OR
                </Button>
              </Menu>
            </VStack>
          </Row>
        ))}
        <Row sx={{ alignItems: "center" }}>
          {conditions.length === 0 && (
            <Text sx={{ fontWeight: "semi", color: "base.5", mr: 4, fontSize: 2 }}>All rows that...</Text>
          )}
          <Menu options={addConditionOptions()} width="480px">
            <Button propagate iconBefore={<PlusIcon size={14} />} size="small" sx={{ position: "relative" }} variant="indigo">
              Add condition
            </Button>
          </Menu>
        </Row>
      </VStack>
      {selectedCondition &&
        (isTraitCondition(selectedCondition) ? (
          <AudienceTraitForm
            alias={""}
            conditions={selectedCondition.property.column.conditions}
            parent={parent}
            title="Add trait"
            trait={traits?.find((t) => t.id === selectedCondition.property.column.traitDefinitionId)}
            onClose={() => {
              setSelectedCondition(null);
            }}
            onSubmit={addAdditionalColumn}
          />
        ) : (
          <AudienceEventTraitForm
            alias={""}
            condition={selectedCondition}
            parent={parent}
            title="Add event trait"
            onClose={() => {
              setSelectedCondition(null);
            }}
            onSubmit={addAdditionalColumn}
          />
        ))}
    </Box>
  );
};

export const VStack: FC<{ sx?: ThemeUIStyleObject; gap: number }> = ({ children, gap, sx = {} }) => (
  <Column sx={{ width: "100%", "& > *:not(:last-child)": { mb: gap }, alignItems: "flex-start", ...sx }}>{children}</Column>
);
