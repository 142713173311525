import { FC } from "react";

import { useToasts } from "react-toast-notifications";
import { Box, Text, IconButton } from "theme-ui";

import { CopyIcon } from "src/ui/icons";
import { Tooltip } from "src/ui/tooltip";

type DisplaySlugProps = {
  currentSlug: string | undefined | null;
};

export const DisplaySlug: FC<Readonly<DisplaySlugProps>> = ({ currentSlug }) => {
  const { addToast } = useToasts();

  const copyToClipboard = () => {
    if (currentSlug) {
      navigator.clipboard.writeText(currentSlug);
      addToast("Slug copied to clipboard.", {
        appearance: "success",
      });
    }
  };
  return (
    <Box sx={{ width: "124px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Text
        sx={{
          textDecoration: "underline",
          textDecorationStyle: "dotted",
          fontWeight: "semi",
          color: "base.5",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {currentSlug}
      </Text>

      <Tooltip text="Copy to clipboard">
        <IconButton
          sx={{
            border: "none",
            fill: "base.5",
            py: 0,
            ":hover": {
              backgroundColor: "transparent !important",
              fill: "base.4",
            },
          }}
          onClick={copyToClipboard}
        >
          <CopyIcon color="inherit" size={16} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
