import { FC } from "react";

import { ThemeProvider as Provider } from "theme-ui";

import theme from ".";
import { GlobalStyles } from "./global-styles";

export const ThemeProvider: FC = ({ children }) => {
  return (
    <Provider theme={theme}>
      <GlobalStyles />
      {children}
    </Provider>
  );
};
