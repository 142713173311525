import { ReactNode, VFC } from "react";

import { Text } from "theme-ui";

import { Row } from "src/ui/box/row";

import { colors } from "../../../../design";

type Props = {
  key: string;
  selected: boolean;
  onClick: () => void;
  icon: ReactNode;
  children: ReactNode;
};

export const SelectorRow: VFC<Readonly<Props>> = ({ key, selected, onClick, icon, children }) => {
  return (
    <Row
      key={key}
      sx={{
        borderLeft: `2px solid ${selected ? colors.primary : "transparent"}`,
        alignItems: "center",
        borderBottom: "small",
        py: 2,
        pl: 2,
        bg: selected ? "primaries.0" : undefined,
        cursor: "pointer",
        fontWeight: selected ? "bold" : undefined,
        svg: {
          fill: selected ? "primary" : undefined,
        },
        ":hover": {
          bg: "primaries.0",
        },
      }}
      onClick={onClick}
    >
      {icon}
      <Text sx={{ ml: 2 }}>{children}</Text>
    </Row>
  );
};
