import { FC } from "react";

import { Text, Grid } from "theme-ui";

import { Field } from "src/ui/field";
import { Link } from "src/ui/link";

type Props = {
  model: any;
};

export const DbtQuery: FC<Readonly<Props>> = ({ model }) => {
  return (
    <Grid gap={3}>
      <Field inline label="Name">
        <Text>{model?.data?.alias || model?.name}</Text>
      </Field>

      <Field inline label="dbt Unique ID">
        <Text>{model?.dbt_unique_id}</Text>
      </Field>

      {model?.data?.description && (
        <Field inline label="Description">
          <Text>{model?.data?.description}</Text>
        </Field>
      )}

      <Field inline label="Repository">
        <Link newTab to={model?.dbt_sync_config?.git_credentials?.repository}>
          {model?.dbt_sync_config?.repository}
        </Link>
      </Field>
    </Grid>
  );
};
