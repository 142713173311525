import { VFC, ReactNode } from "react";

import pluralize from "pluralize";
import { useToasts } from "react-toast-notifications";
import { Text } from "theme-ui";

import { Button } from "src/ui/button";
import { Modal } from "src/ui/modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  count: number;
  label: string;
  onDelete: () => Promise<void>;
  loading: boolean;
  disabled?: boolean;
  content?: ReactNode | string;
};

export const BulkDeleteConfirmationModal: VFC<Readonly<Props>> = ({
  isOpen,
  onClose,
  count,
  label,
  onDelete,
  loading,
  disabled,
  content,
}) => {
  const { addToast } = useToasts();

  const pluralizedLabel = pluralize(label, count);

  const handleBulkDelete = async () => {
    try {
      await onDelete();
      addToast(`Deleted ${count} ${pluralizedLabel}`, { appearance: "success" });
    } catch (error) {
      addToast(error.message, { appearance: "error" });
    }

    onClose();
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={disabled} loading={loading} variant="red" onClick={handleBulkDelete}>
            Delete
          </Button>
        </>
      }
      isOpen={isOpen}
      sx={{ width: "500px" }}
      title={`Delete ${count} ${pluralizedLabel}`}
      onClose={onClose}
    >
      {content || (
        <Text>
          Are you sure you want to delete {count} {pluralizedLabel}? You won't be able to undo this.
        </Text>
      )}
    </Modal>
  );
};
