import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { MappingsField } from "src/components/destinations/mappings-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { ColumnOrConstantField } from "../column-or-constant-field";
import { DeleteField } from "../delete-field";
import { LOOKBACK_WINDOWS } from "../events";
import { IdMappingField } from "../id-mapping-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";
import { TypeField } from "../type-field";

export const validation = Yup.object().shape({
  type: Yup.string().required().default("object"),
  mode: Yup.string().when("type", {
    is: "object",
    then: Yup.string().required().default("upsert"),
    otherwise: Yup.string().notRequired(),
  }),
  object: Yup.string().when("type", {
    is: "object",
    then: Yup.string().required().default("customer"),
    otherwise: Yup.string().notRequired(),
  }),
  eventName: Yup.mixed().when("type", {
    is: "event",
    then: Yup.mixed().when("eventType", {
      is: "page",
      then: Yup.string().notRequired(),
      otherwise: COMMON_SCHEMAS.columnOrConstant,
    }),
    otherwise: Yup.string().notRequired(),
  }),
  eventType: Yup.string().notRequired(),
  externalIdMapping: Yup.object().when("type", {
    is: "event",
    then: Yup.object().notRequired(),
    otherwise: COMMON_SCHEMAS.externalIdMapping,
  }),
  timestampFrom: Yup.mixed().notRequired(),

  userIdFrom: Yup.mixed().when("eventType", {
    is: "page",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),

  pageUrl: Yup.mixed().when("eventType", {
    is: "page",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),

  mappings: COMMON_SCHEMAS.mappings,
});

const TYPES = [
  { label: "Object", value: "object" },
  { label: "Event", value: "event" },
  { label: "Segment", value: "segment" },
];

const ATTRIBUTE_MODES = [
  { label: "Upsert", value: "upsert" },
  { label: "Update", value: "update" },
];

const OBJECTS = [{ label: "Customer", value: "customer" }];

const OBJECT_EXTERNAL_ID_FIELDS = [
  { label: "Customer.io External User ID", value: "id" },
  { label: "Email", value: "email" },
];

const SEGMENT_EXTERNAL_ID_FIELDS = [{ label: "Customer.io External User ID", value: "id" }];

export const CustomerIOForm: FC = () => {
  const { config, setConfig, errors, hightouchColumns, reloadModel, loadingModel } = useDestinationForm();

  return (
    <>
      <TypeField
        options={TYPES}
        value={config?.type}
        onChange={(type) => {
          setConfig({ type });
        }}
      />

      {config?.type === "object" && (
        <ObjectField options={OBJECTS} onChange={(object) => setConfig({ type: config?.type, object })} />
      )}

      {config?.type === "object" && (
        <ModeField
          options={ATTRIBUTE_MODES}
          onChange={(mode) => setConfig({ type: config?.type, object: config?.object, mode })}
        />
      )}

      {config?.type === "event" && (
        <Section>
          <Grid gap={8}>
            <Field error={errors?.eventType} label="What is the event type?">
              <RadioGroup
                options={[
                  {
                    label: "Track Event",
                    value: undefined,
                  },
                  {
                    label: "Track Page Views",
                    value: "page",
                  },
                ]}
                value={config?.eventType}
                onChange={(eventType) => {
                  setConfig({ type: config?.type, eventType });
                }}
              />
            </Field>

            {config?.eventType !== "page" && (
              <ColumnOrConstantField
                columnLabel="Which column contains the event name?"
                constantInput={
                  <Input
                    defaultValue={config?.eventName}
                    error={errors?.eventName}
                    placeholder="Enter event name..."
                    sx={{ width: "240px" }}
                    onChange={(value) => {
                      setConfig({
                        ...config,
                        eventName: value,
                      });
                    }}
                  />
                }
                constantLabel="What is the event name?"
                fieldProps={{ size: "small" }}
                property="eventName"
              />
            )}

            <Field
              description={
                config?.eventType === "page"
                  ? "Tracking page views requires a Customer.io user ID to be sent with the request"
                  : "If this is empty, the event will be sent as an anonymous event."
              }
              error={errors?.userIdFrom}
              label="Which column contains the Customer.io user ID?"
              optional={!config?.eventType}
            >
              <Select
                isClearable={!config?.eventType}
                isError={errors?.userIdFrom}
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.userIdFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, userIdFrom: val });
                }}
              />
            </Field>

            {config?.eventType === "page" && (
              <Field error={errors?.pageUrl} label="Which column contains the page URL of the page viewed?">
                <Select
                  isError={errors?.pageUrl}
                  isLoading={loadingModel}
                  options={hightouchColumns}
                  placeholder="Select a column..."
                  reload={reloadModel}
                  value={config?.pageUrl}
                  width="240px"
                  onChange={(selected) => {
                    const val = selected?.value;
                    setConfig({ ...config, pageUrl: val });
                  }}
                />
              </Field>
            )}

            <Field
              optional
              description="If specified, Hightouch will use this column to only query your warehouse for new events."
              label="Which column contains the event timestamp?"
            >
              <Select
                isClearable
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.timestampFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, timestampFrom: val });
                }}
              />
            </Field>

            {config?.timestampFrom && (
              <Field
                optional
                description="Hightouch will expand the scope of our timestamp filter by this duration to give time to late-arriving events before they're excluded from the sync."
                label="How long should Hightouch wait for events to arrive?"
              >
                <Select
                  isClearable
                  options={LOOKBACK_WINDOWS}
                  placeholder="Choose a time window..."
                  value={LOOKBACK_WINDOWS.find((w) => w.value === config?.lookbackWindow) || null}
                  width="240px"
                  onChange={(selected) => {
                    const val = selected?.value;
                    setConfig({ ...config, lookbackWindow: val });
                  }}
                />
              </Field>
            )}
          </Grid>
        </Section>
      )}

      {(config?.type === "object" || config?.type === "segment") && (
        <Section>
          <IdMappingField options={config?.type === "segment" ? SEGMENT_EXTERNAL_ID_FIELDS : OBJECT_EXTERNAL_ID_FIELDS} />
        </Section>
      )}

      {(config?.type === "object" || config?.type === "event") && (
        <Section>
          <MappingsField />
        </Section>
      )}

      {config?.type === "object" && <DeleteField modes={["delete"]} />}
    </>
  );
};

export default {
  form: CustomerIOForm,
  validation,
};
