import { capitalize, lowerCase } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

export enum AudienceObjectType {
  ParentModels = "parent-models",
  RelatedModels = "related-models",
  Events = "events",
}

export const useObject = () => {
  const navigate = useNavigate();
  const { object } = useParams<{ object: string }>();

  const objectName = lowerCase(object);
  const objectNameCapitalized = capitalize(objectName);
  const objectNameSingular = objectName.slice(0, -1);

  if (
    object !== AudienceObjectType.ParentModels &&
    object !== AudienceObjectType.RelatedModels &&
    object !== AudienceObjectType.Events
  ) {
    navigate(`/audiences/${AudienceObjectType.ParentModels}`);
  }

  return {
    objectPath: object,
    objectName,
    objectNameCapitalized,
    objectNameSingular,
    isEvent: object === AudienceObjectType.Events,
    isParentModel: object === AudienceObjectType.ParentModels,
    isRelatedModel: object === AudienceObjectType.RelatedModels,
  };
};
