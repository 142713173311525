import { VFC } from "react";

import { Flex, Box, Grid, Progress } from "theme-ui";

import { CheckIcon } from "src/ui/icons";

import { colors } from "../../../../design/colors";

const steps = [
  {
    title: "Connect source",
  },
  {
    title: "Set up model",
  },
  {
    title: "Connect destination",
  },
  {
    title: "Configure sync",
  },
];
type Props = {
  currentStep: number;
};

export const ProgressNav: VFC<Props> = ({ currentStep }) => {
  return (
    <>
      <Grid
        columns={[4]}
        gap={6}
        sx={{
          flexGrow: 1,
          justifyContent: "space-evenly",
          alignItems: "center",
          height: "40px",
          minHeight: "40px",
          background: colors.base[0],
          color: colors.base[5],
        }}
      >
        {steps.map((step, index) => {
          return (
            <Flex key={index} sx={{ justifyContent: "space-between", alignItems: "center", height: "100%" }}>
              <Box
                sx={{
                  color: `${index === currentStep - 1 ? colors.base[7] : colors.base[5]}`,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {currentStep > index + 1 ? (
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      bg: colors.green,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 1,
                    }}
                  >
                    <CheckIcon color={colors.white} height={16} width={16} />
                  </Box>
                ) : (
                  `${index + 1}.`
                )}
                <Box sx={{ display: "inline", ml: 1 }}>{step.title}</Box>
              </Box>
            </Flex>
          );
        })}
      </Grid>
      <Progress backgroundColor={colors.base[2]} color="green" max={4} sx={{ borderRadius: 0 }} value={currentStep - 1} />
    </>
  );
};
