import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Grid } from "theme-ui";

import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { QueryTypeSelect } from "src/components/explore/query-type-select";
import { Slug } from "src/components/slug";
import { SourcesGrid } from "src/components/sources/sources-grid";
import { useUser } from "src/contexts/user-context";
import { ModelQuery, useCreateModelMutation } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Message } from "src/ui/message";
import { Select } from "src/ui/select";
import { Step } from "src/ui/wizard/wizard";
import { QueryType, useModelRun, useModelState, useQueryState } from "src/utils/models";
import { UseSourcesResult, useSources } from "src/utils/sources";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type Source = UseSourcesResult["data"][0];

type CreateModelWizardArgs = {
  initialStep?: number;
  model?: ModelQuery["segments_by_pk"];
  source?: Source;
  onSubmit?: ({ id, type }: { id: string; type: string | undefined }) => void;
};

export const useCreateModelWizard = ({
  initialStep = 0,
  model: preselectedModel,
  source: preselectedSource,
  onSubmit,
}: CreateModelWizardArgs) => {
  const location = useLocation();
  const { addToast } = useToasts();
  const { queryState, initQueryState, resetQueryState, setSQL, setTable, setDBTModel, setLookerLook, setCustomQuery } =
    useQueryState();
  const { user, workspace } = useUser();
  const { modelState, setName, setSlug, setPrimaryKey } = useModelState();
  const [type, setType] = useState<QueryType | undefined>();
  const [source, setSource] = useState<Source | undefined>();
  const { data: params } = useQueryString();

  const [step, setStep] = useWizardStepper(initialStep);

  const { data: sources, loading: sourcesLoading, error: sourcesError } = useSources();

  const { mutateAsync: createModel, isLoading: creating } = useCreateModelMutation();

  const {
    runQuery,
    getSchema,
    cancelQuery,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(type, undefined, {
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  const create = async () => {
    const data = await createModel({
      input: {
        query_type: type,
        name: modelState?.name,
        slug: modelState?.slug,
        primary_key: modelState?.primaryKey,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        query_dbt_model_id: queryState?.dbtModel?.id,
        query_looker_look_id: queryState?.lookerLook?.id,
        query_table_name: queryState?.table,
        query_raw_sql: queryState?.sql,
        custom_query: queryState?.customQuery,
        columns: { data: columns.map(({ name, type }) => ({ name, type })) },
        destination_instances: { data: [] },
        git_sync_metadata:
          type === "dbt"
            ? {
                git_sync_config_id: queryState?.dbtModel?.git_sync_config?.id,
                file_path: queryState?.dbtModel?.original_file_path,
                dbt_model_id: queryState?.dbtModel?.id,
              }
            : null,
      },
    });

    if (!data) {
      return;
    }

    const id = data.insert_segments_one?.id;

    addToast(`Model ${modelState?.name} created!`, {
      appearance: "success",
    });

    analytics.track("Model Created", {
      workspace_id: workspace?.id,
      workspace_slug: workspace?.slug,
      model_id: id,
      model_name: modelState?.name,
      source_id: source?.id,
      source_type: source?.type,
      query_type: type,
      origin_page: location.pathname,
    });

    onSubmit?.({ id, type });
  };

  const steps: Step[] = [
    {
      title: "Select",
      disabled: !source,
      render: () => (
        <>
          <Heading sx={{ mb: 8 }} variant="h3">
            Sources
          </Heading>

          {sources && <SourcesGrid error={sourcesError} selection={source?.id} sources={sources} onSelect={setSource} />}
        </>
      ),
    },
    {
      pageSize: type ? "xlarge" : "medium",
      title: "Define",
      hideContinue: true,
      render: () =>
        type ? (
          <>
            {source?.is_demo && (
              <Message sx={{ mb: 4 }}>
                Welcome to Hightouch's demo source! Try out <code>SELECT * FROM users</code> or{" "}
                <code>SELECT * FROM organizations</code> to get some demo data into the destination of your choice.
              </Message>
            )}

            <ExploreWithSave
              cancelQuery={cancelQuery}
              columns={columns}
              getSchema={getSchema}
              isResultTruncated={Boolean(isResultTruncated)}
              numRowsWithoutLimit={numRowsWithoutLimit}
              rows={rows}
              runQuery={runQuery}
              saveLabel="Continue"
              source={source}
              type={type}
              onSave={async () => {
                setStep((step) => step + 1);
              }}
              {...queryState}
              error={queryError || sourcesError?.message}
              errorAtLine={queryErrorAtLine}
              loading={queryLoading || sourcesLoading}
              rowsPerPage={15}
              onCustomQueryChange={setCustomQuery}
              onDBTModelChange={setDBTModel}
              onLookerLookChange={setLookerLook}
              onSQLChange={setSQL}
              onTableChange={setTable}
              onTypeChange={setType}
            />
          </>
        ) : (
          <QueryTypeSelect selected={type} source={source!} onChange={setType} />
        ),
    },
    {
      pageSize: "small",
      title: "Finish",
      disabled: !modelState?.primaryKey || !modelState?.name || !modelState?.slug || !modelState?.available,
      loading: creating,
      render: () => {
        const columnOptions = columns?.map(({ name }) => ({ value: name, label: name }));
        return (
          <>
            <Heading sx={{ mb: 8 }} variant="h3">
              Finalize your settings
            </Heading>
            <Grid gap={12}>
              <Field label="Name">
                <Input value={modelState?.name} onChange={(name) => setName(name)} />
              </Field>
              <Field label="Slug">
                <Slug
                  available={Boolean(modelState.available)}
                  loading={modelState.loadingSlug}
                  placeholder={"your-model-slug"}
                  value={modelState.slug}
                  onChange={setSlug}
                />
              </Field>
              <Field
                help="A primary key is column that contains unique values to identify each of the rows (e.g, email, id)."
                label="Primary key"
              >
                <Select
                  options={columnOptions}
                  placeholder="Select a column as the primary key (usually id)"
                  value={modelState?.primaryKey}
                  onChange={({ value }) => {
                    setPrimaryKey(value);
                  }}
                />
              </Field>
            </Grid>
          </>
        );
      },
    },
  ];

  // update type and source if a model is passed in
  useEffect(() => {
    if (preselectedModel) {
      setType(preselectedModel.query_type as QueryType);
      setSource(sources.find(({ id }) => id === preselectedModel.connection?.id));
      initQueryState(preselectedModel);
    }
  }, [preselectedModel]);

  // update source if source is passed in
  useEffect(() => {
    if (preselectedSource) {
      setSource(preselectedSource);
      setStep(1);
    }
  }, [preselectedSource]);

  useEffect(() => {
    if (source && !preselectedModel) {
      resetQueryState();
      analytics.track("Add Model Source Selected", {
        workspace_id: workspace?.id,
        workspace_slug: workspace?.slug,
        source_type: source?.type,
        source_name: source?.name,
      });
    }
  }, [source]);

  useEffect(() => {
    if (type) {
      analytics.track("Add Model Query Mode Selected", {
        workspace_id: workspace?.id,
        workspace_slug: workspace?.slug,
        source_type: source?.type,
        source_name: source?.name,
        query_mode: type,
      });
    }
  }, [type]);

  useEffect(() => {
    if (params?.source && sources && !source) {
      setSource(sources.find(({ id }) => String(id) === String(params?.source)));
    }
  }, [params, sources]);

  return { createModel: create, loading: sourcesLoading, setStep, step, steps };
};
