import { VFC, useEffect, useState } from "react";

import { uniqueId } from "lodash";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import { Flex, Grid, Text } from "theme-ui";

import {
  useCreateDatadogCredentialsMutation,
  useDatadogCredentialsQuery,
  useUpdateDatadogCredentialsMutation,
  useValidateDatadogCredentialsQuery,
} from "src/graphql";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";

import { Badge } from "../../ui/badge";
import { Container, Row } from "../../ui/box";
import { Circle } from "../../ui/circle";

export const DatadogCredentials: VFC = () => {
  const client = useQueryClient();
  const { data } = useDatadogCredentialsQuery();
  const credentials = data?.datadog_credentials?.[0];
  const [testLoading, setTestLoading] = useState<boolean>();
  const [testError, setTestError] = useState<string | undefined | null>();

  const [site, setSite] = useState<string | undefined>(credentials?.site);
  const [apiKey, setApiKey] = useState<string>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (credentials) {
      setSite(credentials.site);
    }
  }, [credentials]);

  const { addToast } = useToasts();

  const { mutateAsync: createDatadogCredentials } = useCreateDatadogCredentialsMutation();
  const { mutateAsync: updateDatadogCredentials } = useUpdateDatadogCredentialsMutation();

  const save = async () => {
    try {
      setLoading(true);

      if (!credentials) {
        await createDatadogCredentials({
          input: {
            api_key: apiKey,
            site,
          },
        });
      } else {
        await updateDatadogCredentials({
          id: credentials.id,
          credentials: {
            api_key: apiKey,
            site,
          },
        });
      }
      addToast("Your Datadog credentials have been saved!", {
        appearance: "success",
      });
    } catch (err) {
      throw new Error(`Failed to create/update Datadog credential: ${err.message}`, {
        cause: err,
      });
    } finally {
      setLoading(false);
    }
  };

  const validate = async () => {
    if (!site || !apiKey) {
      return;
    }

    setTestLoading(true);
    const { ValidateDatadogCredentials } = await client.fetchQuery(uniqueId(), {
      queryFn: useValidateDatadogCredentialsQuery.fetcher({
        site: site,
        apiKey: apiKey,
      }),
    });
    setTestLoading(false);
    if (ValidateDatadogCredentials?.success) {
      setTestError(null);
    } else {
      setTestError(ValidateDatadogCredentials?.message);
    }
  };

  const siteOptions = [
    { label: "US1", value: "datadoghq.com" },
    { label: "US3", value: "us3.datadoghq.com" },
    { label: "US5", value: "us5.datadoghq.com" },
    { label: "EU1", value: "datadoghq.eu" },
    { label: "US1-FED", value: "app.ddog-gov.com" },
  ];

  return (
    <Section
      divider
      footer={
        <Flex sx={{ justifyContent: "flex-end" }}>
          <Button label={"Save"} loading={loading} onClick={save} />
        </Flex>
      }
    >
      <Container center={false} size="small">
        <Field
          description="Enter your Datadog credentials to receive metrics and events on Hightouch operations"
          error={testError}
          label="Datadog Credentials"
          size="large"
        >
          <Grid gap={8}>
            <Field label="Site">
              <Select
                options={siteOptions}
                placeholder="Select a datadog site for your datadog account"
                value={siteOptions?.find((o) => o.value === site) || null}
                onChange={(selected) => {
                  const val = selected?.value;
                  setSite(val);
                }}
              />
            </Field>
            <Field label="API Key">
              <Input placeholder={credentials ? "<REDACTED>" : undefined} type="password" value={apiKey} onChange={setApiKey} />
            </Field>
            <Row sx={{ alignItems: "center" }}>
              <Button disabled={!apiKey || !site} loading={testLoading} variant="secondary" onClick={validate}>
                Validate Credential
              </Button>
              {testError !== undefined && (
                <Badge sx={{ ml: 2 }} variant="base">
                  <Circle color={testError === null ? "green" : "red"} radius="12px" />
                  <Text sx={{ ml: 2 }}>{!testError ? "Success" : "Error"}</Text>
                </Badge>
              )}
            </Row>
          </Grid>
        </Field>
      </Container>
    </Section>
  );
};
