import { VFC, useState, useMemo, useEffect, useCallback } from "react";

import { Completion } from "@codemirror/autocomplete";
import { SQLConfig, StandardSQL, schemaCompletionSource } from "@codemirror/lang-sql";
import { LanguageSupport } from "@codemirror/language";
import { Compartment } from "@codemirror/state";
import { EditorView } from "@codemirror/view";
import { format, FormatFnOptions as SqlFormatterOptions } from "sql-formatter";
import { Box, Text } from "theme-ui";

import { useSourceObjectDefinitionsQuery } from "src/graphql";
import { UnsavedValue } from "src/hooks/use-unsaved-value";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { UseSourcesResult, SourceTile } from "src/utils/sources";

import { Editor, Props as EditorProps } from "../editor";
import { highlightErroredLine } from "./highlight-errored-line";

type Source = UseSourcesResult["data"][0];

const formatterLanguageBySourceType: Record<string, SqlFormatterOptions["language"]> = {
  athena: "hive",
  bigquery: "bigquery",
  mssql: "tsql",
  mysql: "mysql",
  postgres: "postgresql",
  redshift: "redshift",
  // What is the best default for these?
  // postgresql seems to be more correct than sql
  // 1 known issue is 'sql' causes || to convert to | | which is invalid
  snowflake: "postgresql",
  trino: "postgresql",
  clickhouse: "postgresql",
  databricks: "postgresql",
  looker: "postgresql",
  metabase: "postgresql",
};

const erroredLineCompartment = new Compartment();

interface Props extends Omit<EditorProps, "language"> {
  /**
   * Line number to highlight with a red background, in case there's an error
   */
  highlightErroredLine?: number;

  /**
   * Source to show database schema autocomplete for
   */
  source: Source | undefined;

  /**
   * Result of `useUnsavedValue` hook to support storing drafts in local storage
   */
  unsavedValue?: UnsavedValue<string>;
}

export const SqlEditor: VFC<Props> = ({
  highlightErroredLine: highlightErroredLineNumber,
  source,
  value,
  unsavedValue,
  onChange,
  ...props
}) => {
  const [view, setView] = useState<EditorView | undefined>();

  const editorInitialized = useCallback(({ view }: { view: EditorView }) => {
    setView(view);
  }, []);

  const { data } = useSourceObjectDefinitionsQuery(
    { sourceId: source?.id },
    {
      enabled: Boolean(source?.id),
    },
  );

  const config = useMemo<SQLConfig | undefined>(() => {
    if (!data) {
      return undefined;
    }

    const schema: Record<string, Completion[]> = {};
    const tables: Completion[] = [];

    for (const objectDefinition of data.object_definitions) {
      const schemaName = objectDefinition.object_definition_group?.name;

      if (schemaName?.toLowerCase() === "hightouch_planner") {
        continue;
      }

      const tableName = schemaName ? `${schemaName}.${objectDefinition.name}` : objectDefinition.name;

      tables.push({
        label: tableName,
        type: "table",
      });

      schema[tableName] = [];

      for (const objectSchema of objectDefinition.object_schemas) {
        schema[tableName]?.push({
          label: objectSchema.key,
          type: "column",
        });
      }
    }

    return { schema, tables };
  }, [data]);

  const language = useMemo(() => {
    if (!config) {
      return new LanguageSupport(StandardSQL.language);
    }

    return new LanguageSupport(StandardSQL.language, [
      StandardSQL.language.data.of({
        autocomplete: schemaCompletionSource({
          ...config,
          defaultSchema: "public",
        }),
      }),
    ]);
  }, [config]);

  useEffect(() => {
    if (!view) {
      return;
    }

    view.dispatch({
      effects: erroredLineCompartment.reconfigure(highlightErroredLine(highlightErroredLineNumber)),
    });
  }, [view, highlightErroredLineNumber]);

  const changeValue = useCallback(
    (value: string) => {
      if (unsavedValue) {
        unsavedValue.set(value);
      }

      if (typeof onChange === "function") {
        onChange(value);
      }
    },
    [unsavedValue, onChange],
  );

  const restoreValue = useCallback(() => {
    if (!unsavedValue) {
      return;
    }

    if (typeof onChange === "function" && typeof unsavedValue.value === "string") {
      onChange(unsavedValue.value);
    }

    unsavedValue.restore();
  }, [unsavedValue, onChange]);

  const formatterLanguage = source ? formatterLanguageBySourceType[source.type] : undefined;

  const beautify = useCallback(() => {
    changeValue(
      format(value, {
        keywordCase: "upper",
        language: formatterLanguage,
      }),
    );
  }, [value, formatterLanguage, changeValue]);

  const extensions = useMemo(() => {
    return [erroredLineCompartment.of(highlightErroredLine(highlightErroredLineNumber))];
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          border: "small",
          borderTopRightRadius: 1,
          borderTopLeftRadius: 1,
          borderBottom: "none",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SourceTile source={source} />
        </Box>

        <Button disabled={!formatterLanguage} variant="secondary" onClick={beautify}>
          Beautify
        </Button>
      </Box>

      {unsavedValue?.value && (
        <Row
          sx={{
            bg: "yellows.0",
            p: 2,
            border: "small",
            borderBottom: "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text>You have unsaved changes from last session.</Text>

          <Row>
            <Button sx={{ bg: "white", mr: 2 }} variant="secondary" onClick={unsavedValue.clear}>
              Dismiss
            </Button>

            <Button onClick={restoreValue}>Restore</Button>
          </Row>
        </Row>
      )}

      <Box
        sx={{
          width: "100%",
          height: "400px",
          border: "small",
          borderBottomLeftRadius: 1,
          borderBottomRightRadius: 1,
          overflow: "hidden",
        }}
      >
        <Editor
          extensions={extensions}
          language={language}
          value={value}
          onChange={onChange}
          onInit={editorInitialized}
          {...props}
        />
      </Box>
    </Box>
  );
};
