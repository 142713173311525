import { FC } from "react";

import * as Yup from "yup";

import { MappingsField } from "src/components/destinations/mappings-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { useJiraIssueFieldsQuery, useJiraProjectsQuery, useJiraIssueTypesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { IdMappingField } from "../id-mapping-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape({
  mode: Yup.string().required().default("upsert"),
  object: Yup.string().required().default("issue"),
  projectId: Yup.string().required(),
  issueType: Yup.string().required(),
  externalIdMapping: COMMON_SCHEMAS.externalIdMapping,
  mappings: COMMON_SCHEMAS.mappings,
});

const MODES = [{ label: "Upsert", value: "upsert" }];

const OBJECTS = [{ label: "Issue", value: "issue" }];

export const JiraForm: FC = () => {
  const { config, setConfig, destination, errors } = useDestinationForm();

  const {
    data: jiraProjectData,
    error: jiraProjectError,
    isFetching: loadingJiraProjectData,
    refetch: getProjects,
  } = useJiraProjectsQuery({
    destinationId: String(destination?.id),
  });

  const {
    data: jiraTypeData,
    error: jiraTypeError,
    isFetching: loadingJiraTypeData,
    refetch: getIssueTypes,
  } = useJiraIssueTypesQuery(
    {
      destinationId: String(destination?.id),
      projectId: String(config?.projectId),
    },
    { enabled: Boolean(config?.projectId) },
  );

  const {
    data: jiraIssueData,
    error: jiraIssueError,
    isFetching: loadingJiraIssueData,
    refetch: getIssueFields,
  } = useJiraIssueFieldsQuery(
    {
      destinationId: String(destination?.id),
      projectId: String(config?.projectId),
      issueType: String(config?.issueType),
    },
    { enabled: Boolean(config?.projectId) && Boolean(config?.issueType) },
  );

  const projects = jiraProjectData?.jiraGetProjects?.projects;
  const projectOptions = projects?.map((p) => ({ label: p?.name, value: p?.id, type: p?.type })) || [];

  const issueTypes = jiraTypeData?.jiraGetIssueTypes?.issueTypes;
  const issueTypeOptions = issueTypes?.map((t) => ({ label: t?.name, value: t?.id }));

  const fields = jiraIssueData?.jiraGetIssueFields?.fields;
  const fieldOptions =
    fields
      ?.filter((f) => f?.id !== "project" && f?.id !== "issuetype")
      ?.map((f) => ({ label: f?.name, value: f?.id, type: f?.type })) || [];

  const externalIdOptions =
    fields
      ?.filter(
        (f) =>
          f?.type === StandardFieldType.STRING &&
          f?.customFieldType === "com.atlassian.jira.plugin.system.customfieldtypes:readonlyfield",
      )
      .map((f) => ({ label: f?.name, value: f?.id, type: f?.type })) || [];

  return (
    <>
      <ObjectField options={OBJECTS} />

      <ModeField options={MODES} onChange={(mode) => setConfig({ object: config?.object, mode })} />

      <Section>
        <Field
          description="Select an available Jira project to sync to."
          error={errors?.projectId || jiraProjectError?.message}
          label="Which project would you like to sync to?"
          size="large"
        >
          <Select
            isError={errors?.projectId}
            isLoading={loadingJiraProjectData}
            options={projectOptions}
            placeholder={"Select a project..."}
            reload={getProjects}
            value={config?.projectId}
            width="340px"
            onChange={(selected) => setConfig({ object: config?.object, mode: config?.mode, projectId: selected?.value })}
          />
        </Field>
      </Section>

      {config?.projectId && (
        <Section>
          <Field
            description="Select an issue type that is available in the selected project."
            error={errors?.issueType || jiraTypeError?.message}
            label="What issue type would you like to sync?"
            size="large"
          >
            <Select
              isError={errors?.issueType}
              isLoading={loadingJiraTypeData}
              options={issueTypeOptions}
              placeholder={"Select an issue type..."}
              reload={getIssueTypes}
              value={config?.issueType}
              width="340px"
              onChange={(selected) =>
                setConfig({
                  object: config?.object,
                  mode: config?.mode,
                  projectId: config?.projectId,
                  issueType: selected?.value,
                })
              }
            />
          </Field>
        </Section>
      )}

      {config?.projectId && config?.issueType && (
        <>
          <Section>
            <IdMappingField
              error={jiraIssueError?.message}
              loading={loadingJiraIssueData}
              options={externalIdOptions}
              reload={getIssueFields}
              tip={"Hightouch only supports read only text fields for Jira external IDs"}
            />
          </Section>

          <Section>
            <MappingsField
              error={jiraIssueError?.message}
              loading={loadingJiraIssueData}
              options={fieldOptions}
              reload={getIssueFields}
            />
          </Section>
        </>
      )}
    </>
  );
};

export default {
  form: JiraForm,
  validation,
};
