import { useUser } from "src/contexts/user-context";
import { ResourcePermission as GQLResourcePermission, Scalars, usePermissionsQuery } from "src/graphql";

export interface ResourcePermission extends Omit<GQLResourcePermission, "resource_id"> {
  resource_id?: Scalars["String"];
}

const useHasPermission = (permissions: ResourcePermission[] | undefined): { isLoading: boolean; hasPermission: boolean } => {
  const { hasPermissions } = useUser();
  const hasResourceIds = permissions?.some(({ resource_id }) => {
    return resource_id;
  });

  const { data, isLoading } = usePermissionsQuery(
    { filters: permissions?.map(({ resource, resource_id }) => `${resource}:${resource_id}`) },
    { enabled: hasResourceIds },
  );
  if (!permissions) {
    return { isLoading: false, hasPermission: true };
  }
  const userPermissions = data?.getCurrentPermissions;
  if (!hasResourceIds) {
    return { isLoading: false, hasPermission: hasPermissions(permissions) };
  }
  if (isLoading) {
    return { isLoading: true, hasPermission: false };
  }
  const hasIdPermission = permissions.every(({ resource, resource_id, grants }) => {
    return grants?.every((grant) => {
      return Boolean(
        userPermissions?.find((permission) => {
          return (
            permission.resource === resource && permission?.resource_id == resource_id && permission?.grants?.includes(grant)
          );
        }),
      );
    });
  });
  return { isLoading: false, hasPermission: hasIdPermission };
};

export default useHasPermission;
