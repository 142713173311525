import { VFC } from "react";

import { Text, ThemeUIStyleObject } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { Banner } from "src/ui/banner/banner";
import { Button } from "src/ui/button";

export { Banner } from "src/ui/banner";

const buttonStyle: ThemeUIStyleObject = {
  fontWeight: "bold",
  color: "white",
  fontSize: 1,
  mx: 2,
  ":hover": {
    bg: "rgba(255, 255, 255, 0.2) !important",
  },
};

export const WorkspaceValidation: VFC = () => {
  const { workspaceError } = useUser();

  if (!workspaceError) return null;

  function reload() {
    window.location.reload();
  }

  return (
    <Banner>
      <Text sx={{ fontWeight: "bold", color: "white", fontSize: 1, mx: 4 }}>
        {workspaceError.message} Reload to continue working in this workspace. You will lose any unsaved changes.
      </Text>
      <Button sx={buttonStyle} variant="secondary" onClick={reload}>
        Reload workspace
      </Button>
    </Banner>
  );
};
