import { FC } from "react";

import { useRelatedAudiencesQuery } from "src/graphql";
import { SegmentSetCondition, segmentSetOperatorOptions } from "src/types/visual";
import { NewSelect } from "src/ui/new-select";

import { AttributeSelect } from "./attribute-select";
import { OperatorLabel, HStack, RemoveButton, FilterProps } from "./condition";
import { Filter } from "./filter";

export const SegmentSetFilter: FC<Readonly<FilterProps<SegmentSetCondition>>> = ({
  audience,
  parent,
  condition,
  onChange,
  onRemove,
}) => {
  const { data: relatedAudiencesData, isLoading: loading } = useRelatedAudiencesQuery(
    {
      parentModelId: parent?.id,
      modelId: audience?.id,
    },
    { enabled: Boolean(parent) },
  );

  const relatedAudiencesOptions = relatedAudiencesData?.segments?.map(({ id, name }) => ({ value: id, label: name }));

  const operatorLabel = segmentSetOperatorOptions.find((option) => option.value === condition.includes)?.label;

  return (
    <HStack gap={4}>
      <Filter
        content={
          <NewSelect
            options={segmentSetOperatorOptions}
            value={condition.includes}
            onChange={(value) => onChange({ includes: Boolean(value) })}
          />
        }
      >
        <OperatorLabel>{`${operatorLabel} in`}</OperatorLabel>
      </Filter>
      <AttributeSelect
        loading={loading}
        options={relatedAudiencesOptions}
        placeholder="Select an audience"
        value={condition.modelId}
        onChange={(modelId) => onChange({ modelId })}
      />
      <RemoveButton onRemove={onRemove} />
    </HStack>
  );
};
