import { VFC } from "react";

import { Text } from "theme-ui";

import { useObject } from "src/components/audiences/use-object";
import { Page } from "src/components/layout";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import { ResourcePermissionGrant, useEventsQuery, useParentModelsQuery, useRelatedModelsQuery } from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { ChevronDownIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading";
import { Menu } from "src/ui/menu";
import { Table } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useNavigate } from "src/utils/navigate";
import { SourceBadges, SourceIcon, useSources } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

export const AudienceObjects: VFC = () => {
  const navigate = useNavigate();
  const { objectPath, objectName, objectNameCapitalized, objectNameSingular, isParentModel, isEvent, isRelatedModel } =
    useObject();

  const {
    data: parentModelsData,
    error: parentModelsError,
    isLoading: parentModelsLoading,
  } = useParentModelsQuery(undefined, { enabled: isParentModel });
  const { data: eventsData, error: eventsError, isLoading: eventsLoading } = useEventsQuery(undefined, { enabled: isEvent });
  const {
    data: relatedModelsData,
    error: relatedModelsError,
    isLoading: relatedModelsLoading,
  } = useRelatedModelsQuery(undefined, { enabled: isRelatedModel });

  const { data: sources, error: sourcesError, loading: sourcesLoading } = useSources();

  const loading = sourcesLoading || parentModelsLoading || relatedModelsLoading || eventsLoading;

  const objects = (isParentModel ? parentModelsData : isEvent ? eventsData : relatedModelsData)?.segments;
  const error = isParentModel ? parentModelsError : isEvent ? eventsError : relatedModelsError || sourcesError;

  const columns = [
    {
      name: "Name",
      cell: ({ name, connection }) => {
        const source = sources.find((source) => source.id === connection.id);

        return (
          <Row sx={{ alignItems: "center" }}>
            <SourceIcon source={source} sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2 }} />
            <Text sx={{ fontWeight: "semi" }}>{name}</Text>
            <SourceBadges source={source} />
          </Row>
        );
      },
    },
    LastUpdatedColumn,
  ];

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <PermissionProvider permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}>
      <Page crumbs={[{ label: "Audiences", link: "/audiences" }, { label: objectNameCapitalized }]} size="full">
        <Row
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            mb: 5,
            width: "100%",
          }}
        >
          <Menu
            options={[
              {
                label: "Parent models",
                description: "Models that audiences are built from",
                onClick: () => navigate("/audiences/setup/parent-models"),
              },
              {
                label: "Related models",
                description: "Models that can be joined with parent models",
                onClick: () => navigate("/audiences/setup/related-models"),
              },
              {
                label: "Events",
                description: "Models that filter audiences by timestamped events",
                onClick: () => navigate("/audiences/setup/events"),
              },
              {
                label: "Sync templates",
                description: "Configure syncs for audiences",
                onClick: () => navigate("/audiences/setup/sync-templates"),
              },
            ]}
          >
            <Row sx={{ alignItems: "center" }}>
              <Heading sx={{ mr: 2 }}>{objectNameCapitalized}</Heading>
              <ChevronDownIcon />
            </Row>
          </Menu>
          <Permission>
            <Button
              onClick={() => {
                navigate(`/audiences/setup/${objectPath}/new`);
              }}
            >
              Add {objectNameSingular}
            </Button>
          </Permission>
        </Row>
        <Table
          columns={columns}
          data={objects}
          error={Boolean(error)}
          placeholder={{
            title: `No ${objectName}`,
            body: `Add a ${objectNameSingular} to get started`,
            error: `${objectNameCapitalized} failed to load, please try again.`,
          }}
          onRowClick={({ id }, event) => openUrl(`/audiences/setup/${objectPath}/${id}`, navigate, event)}
        />
      </Page>
    </PermissionProvider>
  );
};
