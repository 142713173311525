import { isNumber, isObject } from "lodash";
import formatXml from "xml-formatter";

import { SyncFailedWithRejectedRowsError } from "src/types/sync-errors";
import { Badge } from "src/ui/badge";
import { Row } from "src/ui/box";

import { SyncAttemptFragment } from "../graphql/types";

export enum SyncStatus {
  DISABLED = "disabled",
  PENDING = "pending",
  SUCCESS = "success",
  QUERYING = "querying",
  REPORTING = "reporting",
  PREPARING = "preparing-plan",
  PROCESSING = "processing",
  ACTIVE = "active",
  INCOMPLETE = "incomplete",
  IN_PROGRESS = "inprogress",
  ABORTED = "aborted",
  CANCELLED = "cancelled",
  FAILED = "failed",
  WARNING = "warning",
  INTERRUPTED = "interrupted",
  ENQUEUED = "queued",

  /**
   * @deprecated Use SyncStatus.FAILED with a syncRequestErrorCode of PREVIOUS_SYNC_RUN_OBJECT_MISSING or WAREHOUSE_TABLE_MISSING.
   */
  UNPROCESSABLE = "unprocessable",
}

/**
 * Returns true if the specified SyncStatus is terminal, i.e. it will never
 * transition to another status.
 */
export const syncStatusIsTerminal = (status: SyncStatus): boolean => {
  switch (status) {
    case SyncStatus.DISABLED:
    case SyncStatus.SUCCESS:
    case SyncStatus.ABORTED:
    case SyncStatus.CANCELLED:
    case SyncStatus.FAILED:
      return true;
    default:
      return false;
  }
};

export const getSyncStatusColor = (status: SyncStatus) => {
  switch (status) {
    case SyncStatus.SUCCESS:
      return "green";
    case SyncStatus.WARNING:
      return "yellow";
    case SyncStatus.FAILED:
      return "red";
    case SyncStatus.DISABLED:
    case SyncStatus.CANCELLED:
    case SyncStatus.PENDING:
      return "base.3";
    default:
      return "blue";
  }
};

export const SyncStatusBadge = ({ status: statusProp, request }: { status?: any; request: any }) => {
  const status = statusProp || request?.status_computed;

  if (!status) {
    return null;
  }

  switch (status) {
    case SyncStatus.DISABLED:
      return (
        <Badge tooltip="This sync was disabled by the user." variant="base">
          Disabled
        </Badge>
      );
    case SyncStatus.WARNING:
      return (
        <Badge tooltip="We processed all rows, but some had errors when syncing to the destination." variant="yellow">
          Warning
        </Badge>
      );
    case SyncStatus.FAILED:
      return (
        <Badge tooltip="We were unable to process all rows due to a fatal error." variant="red">
          Failed
        </Badge>
      );
    case SyncStatus.PROCESSING:
      return <ProcessingBadge request={request} />;
    case SyncStatus.CANCELLED:
      return (
        <Badge tooltip="This sync was canceled by a user." variant="base">
          Canceled
        </Badge>
      );
    case SyncStatus.SUCCESS:
      return (
        <Badge tooltip="All rows were synced to the destination." variant="green">
          Healthy
        </Badge>
      );
    case SyncStatus.PENDING:
      return (
        <Badge tooltip="You haven't started a run yet." variant="base">
          Pending
        </Badge>
      );
    case SyncStatus.QUERYING:
      return (
        <Badge tooltip="We are running your model query." variant="indigo">
          Querying
        </Badge>
      );
    case SyncStatus.INTERRUPTED:
      return (
        <Badge tooltip="This sync was momentarily interrupted and will be automatically restarted." variant="base">
          Interrupted
        </Badge>
      );
    case SyncStatus.ENQUEUED:
      return (
        <Badge tooltip="This sync will start soon." variant="base">
          Queued
        </Badge>
      );
    case SyncStatus.REPORTING:
      if (request.phase_to_status?.report?.error?.message) {
        return (
          <Badge
            tooltip={`Hightouch will automatically retry writing Sync Logs. The full error was: ${request.phase_to_status.report.error.message}`}
            variant="yellow"
          >
            Reporting
          </Badge>
        );
      }
      return (
        <Badge tooltip="We are reporting post-run summary" variant="indigo">
          Reporting
        </Badge>
      );

    default:
      throw new Error("Invalid sync status: " + status);
  }
};

/** Don't include a percentage for syncs that used AllPlanner */
function getProcessingString(request: { plannerType?: string; completion_ratio: number }): string {
  if (request.plannerType === "all") {
    return "";
  }
  return `${request.completion_ratio ? Math.round(request.completion_ratio * 100) : "0"}%`;
}

interface SyncAttemptDiff {
  synced: {
    add: number;
    remove: number;
    change: number;
  };
  rejected: {
    add: number | null;
    remove: number | null;
    change: number | null;
  };
}

export const getSyncAttemptDiff = (
  attempt:
    | Pick<
        SyncAttemptFragment,
        "add_checkpoint" | "remove_checkpoint" | "change_checkpoint" | "add_rejected" | "remove_rejected" | "change_rejected"
      >
    | undefined,
): SyncAttemptDiff | undefined => {
  if (attempt) {
    const { add_checkpoint, remove_checkpoint, change_checkpoint, add_rejected, remove_rejected, change_rejected } = attempt;

    return {
      synced: {
        add: add_checkpoint - (add_rejected ?? 0),
        remove: remove_checkpoint - (remove_rejected ?? 0),
        change: change_checkpoint - (change_rejected ?? 0),
      },
      rejected: {
        add: add_rejected,
        remove: remove_rejected,
        change: change_rejected,
      },
    };
  }

  return undefined;
};

/**
 * getObjectName returns a human friendly name for a synced object.
 **/
export function getObjectName(objectVal: string | undefined): string | undefined {
  if (!objectVal) {
    return objectVal;
  }

  const salesforceMultiOptions = [
    { label: "Contact or Lead", value: "___hightouch-reserved-contact-or-lead" },
    { label: "Account or Lead", value: "___hightouch-reserved-account-or-lead" },
  ];

  // We use a special identifier for Salesforce multitypes (e.g. Contact or Lead).
  const sfMultiType = salesforceMultiOptions.find((mt) => mt.value === objectVal);
  if (sfMultiType != null) {
    return sfMultiType.label;
  }

  return objectVal;
}

export const DEPRECATED_ERROR = SyncFailedWithRejectedRowsError.MESSAGE;

// DLQ not supported before this
export const DLQ_RELEASE_TIMESTAMP = 1615260600; //Unix

export interface RequestInfo {
  requestType: string;
  data: unknown;
  status: string;
  method: string;
  meta: any;
  destination: string;
  requestBody: string;
  requestIsJson: boolean;
  requestIsXml: boolean;
  requestHeaders: {
    [name: string]: string | number;
  };
  responseBody: string;
  responseIsJson: boolean;
  responseIsXml: boolean;
  errored: boolean;
}

const BATCH_REQUEST_INFO_DEFAULT_STATUS = "Success";
const BATCH_REQUEST_INFO_DEFAULT_DESTINATION = "Unknown destination";
const BATCH_REQUEST_INFO_DEFAULT_METHOD = "Save";

export const isXml = (value: unknown): boolean => {
  if (typeof value !== "string") {
    return false;
  }
  try {
    formatXml(value);
    return true;
  } catch (err) {
    return false;
  }
};

export const processRequestInfo = (requestInfo, definition) => {
  let data;
  let request;
  let requestBody;
  let requestIsJson = false;
  let requestIsXml = false;
  let response;
  let meta;
  let responseBody;
  let responseIsJson = false;
  let responseIsXml = false;
  let errored = false;
  let status = BATCH_REQUEST_INFO_DEFAULT_STATUS;
  let method = BATCH_REQUEST_INFO_DEFAULT_METHOD;
  let destination = BATCH_REQUEST_INFO_DEFAULT_DESTINATION;
  let requestHeaders;

  try {
    data = requestInfo.data;

    request = requestInfo.requestType === "method-call" ? data.parameters : data.request?.body;
    requestIsJson = isObject(request);
    requestIsXml = isXml(request);
    requestBody = requestIsJson ? JSON.stringify(request, null, 2) : requestIsXml ? formatXml(request) : request;

    response = requestInfo.requestType === "method-call" ? data.result : data.response?.body;
    responseIsJson = isObject(response);
    responseIsXml = isXml(response);
    responseBody = responseIsJson
      ? JSON.stringify(response, null, 2)
      : responseIsXml
      ? formatXml(response)
      : response || JSON.stringify(data.error, null, 2);

    if (data.method) {
      method = data.method;
    }
    if (data.error) {
      errored = true;
    }

    if (data.response?.status) {
      status = data.response?.status;
    }
    if (data.request?.method) {
      method = data.request?.method;
    }
    if (data.request?.url) {
      destination = data.request?.url;
    }
    if (data.request?.headers) {
      requestHeaders = data.request?.headers;
    }

    if (data.meta) {
      meta = data.meta;
    }
  } catch (error) {
    data = requestInfo.data;
  }

  if (isNumber(status)) {
    errored = status >= 400;
    status = errored ? `${status} ERR` : `${status} OK`;
  }

  if (destination === BATCH_REQUEST_INFO_DEFAULT_DESTINATION && definition?.name) {
    destination = definition.name;
  }

  if (status === BATCH_REQUEST_INFO_DEFAULT_STATUS && errored) {
    status = "Error";
  }

  return {
    ...requestInfo,
    requestBody,
    requestIsJson,
    requestIsXml,
    requestHeaders,
    responseBody,
    responseIsJson,
    responseIsXml,
    meta,
    data,
    errored,
    status,
    method,
    destination,
  };
};

const ProcessingBadge = ({ request }) => {
  const percentString = getProcessingString(request);

  const percent = Math.round(request.completion_ratio * 100);

  return (
    <Badge
      sx={{ bg: "white", position: "relative" }}
      tooltip={`We are syncing rows to the destination${percentString ? ` (${percent}%)` : ""}`}
      variant="indigo"
    >
      Processing
      <Row
        sx={{
          height: "100%",
          width: "100%",
          transition: "transform 250ms",
          transform: `translateX(${percent - 100}%)`,
          position: "absolute",
          top: 0,
          left: 0,
          bg: "blues.0",
        }}
      />
    </Badge>
  );
};
