import ConfigurationForm from "./configuration";

export default {
  ConfigurationForm,
  isConfigurationComplete: (configuration: Record<string, unknown>, tunnel?: any): boolean => {
    if (tunnel) {
      return Boolean(configuration?.user);
    }

    for (const field of ["host", "port", "user"]) {
      if (!configuration?.[field]) {
        return false;
      }
    }

    return true;
  },
};
