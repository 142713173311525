import { VFC, useState } from "react";

import { useToasts } from "react-toast-notifications";
import { Grid } from "theme-ui";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { ObjectGrid } from "src/components/audiences/object-grid";
import { useUser } from "src/contexts/user-context";
import { useCreateAudienceMutation, ParentsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { QueryType, useModelRun, useModelState, useQueryState } from "src/utils/models";
import { useNavigate } from "src/utils/navigate";
import { useSource } from "src/utils/sources";

type Segment = ParentsQuery["segments"][0];

export const CreateAudience: VFC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [object, setObject] = useState<Segment | undefined>();
  const [step, setStep] = useState<number>(0);

  const { data: source, loading: sourceLoading, error: sourceError } = useSource(object?.connection?.id);

  const { queryState, setVisualQueryFilter } = useQueryState();
  const { modelState, setName } = useModelState();

  const { isLoading: creating, mutateAsync: createAudience } = useCreateAudienceMutation();

  const {
    runQuery,
    getSchema,
    cancelQuery,
    rows,
    numRowsWithoutLimit,
    columns,
    loading: queryLoading,
    error: queryError,
    transformedSql,
  } = useModelRun(QueryType.Visual, object?.columns, {
    variables: { sourceId: source?.id, parentModelId: object?.id, ...queryState },
  });

  const create = async () => {
    await createAudience({
      input: {
        query_type: QueryType.Visual,
        visual_query_parent_id: object?.id,
        visual_query_filter: queryState?.visualQueryFilter,
        name: modelState?.name,
        primary_key: object?.primary_key,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        destination_instances: { data: [] },
      },
    });

    addToast(`${modelState?.name} created!`, {
      appearance: "success",
    });

    navigate("/audiences");
  };

  const steps: Step[] = [
    {
      title: "Select parent",
      disabled: !object,
      render: () => (
        <>
          <Heading sx={{ mb: 8 }} variant="h3">
            Objects
          </Heading>
          <ObjectGrid selection={object} onSelect={setObject} />
        </>
      ),
    },
    {
      pageSize: "xlarge",
      title: "Define",
      hideContinue: true,
      render: () => (
        <AudienceExplore
          cancelQuery={cancelQuery}
          columns={columns}
          error={queryError || sourceError?.message}
          getSchema={getSchema}
          loading={queryLoading || sourceLoading}
          numRowsWithoutLimit={numRowsWithoutLimit}
          parentModel={object}
          rows={rows}
          runQuery={runQuery}
          saveLabel="Continue"
          source={source}
          transformedSql={transformedSql}
          onSave={async () => {
            setStep(step + 1);
          }}
          onVisualQueryFilterChange={setVisualQueryFilter}
          {...queryState}
        />
      ),
    },
    {
      pageSize: "small",
      title: "Finish",
      disabled: !modelState?.name,
      loading: creating,
      render: () => {
        return (
          <>
            <Heading sx={{ mb: 8 }} variant="h3">
              Finalize your settings
            </Heading>
            <Grid gap={12}>
              <Field label="Name">
                <Input value={modelState?.name} onChange={(name) => setName(name)} />
              </Field>
            </Grid>
          </>
        );
      },
    },
  ];

  return (
    <Wizard
      setStep={setStep}
      step={step}
      steps={steps}
      title="Add a new audience"
      onCancel={() => {
        navigate("/audiences");
      }}
      onSubmit={create}
    />
  );
};
