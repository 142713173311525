import { VFC } from "react";

import { Box, Image } from "theme-ui";

import CheckIcon from "src/components/personalization/check.svg";

type StepDotProps = {
  complete: boolean;
  active: boolean;
  disabled: boolean;
  label?: string;
};

export const StepDot: VFC<Readonly<StepDotProps>> = ({ complete = false, active = false, disabled = false, label }) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Box
      sx={{
        width: complete ? "24px" : "12px",
        height: complete ? "24px" : "12px",
        borderRadius: "50%",
        bg: active || complete ? "secondary" : "transparent",
        mx: 2,
        border: "small",
        borderColor: "secondary",
        cursor: disabled ? "default" : "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {complete && <Image src={CheckIcon} />}
    </Box>
    {label && <span>{label}</span>}
  </Box>
);
