import { FC, ReactNode } from "react";

import { Props as HeaderProps, PageHeader } from "./header/page-header";
import { Props as ContainerProps, PageContainer } from "./page-container";

export type Props = { header?: ReactNode } & HeaderProps & ContainerProps;

export const Page: FC<Readonly<Props>> = ({ children, crumbs, header, ...containerProps }) => {
  return (
    <>
      <PageHeader crumbs={crumbs}>{header}</PageHeader>
      <PageContainer {...containerProps}>{children}</PageContainer>
    </>
  );
};
