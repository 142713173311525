import { VFC } from "react";

import { IconProps, Icon } from "./icon";

export const ChevronRightIcon: VFC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M8.58984 16.59L13.1698 12L8.58984 7.41L9.99984 6L15.9998 12L9.99984 18L8.58984 16.59Z" />
    </Icon>
  );
};
