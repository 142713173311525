import CronParser from "cron-parser";

import { ScheduleType } from "src/components/schedule/types";

export const validCronExpression = (exp: string | undefined): boolean => {
  if (!exp) {
    return false;
  }

  try {
    CronParser.parseExpression(exp);
    return true;
  } catch (error) {
    return false;
  }
};

export const isScheduleComplete = (schedule: any): boolean => {
  switch (schedule?.type) {
    case ScheduleType.MANUAL:
      return true;
    case ScheduleType.INTERVAL:
      return (
        schedule?.schedule?.interval?.quantity >= 1 &&
        Number.isInteger(schedule?.schedule?.interval?.quantity) &&
        schedule?.schedule?.interval?.unit
      );
    case ScheduleType.CRON:
      return validCronExpression(schedule?.schedule?.expression);
    case ScheduleType.CUSTOM:
      return Array.isArray(schedule?.schedule?.expressions);
    case ScheduleType.DBT_CLOUD:
      return schedule?.schedule?.account?.id && schedule?.schedule?.job?.id;
    default:
      return false;
  }
};

export const getScheduleLabel = (type) => {
  switch (type) {
    case ScheduleType.INTERVAL:
      return "Interval";
    case ScheduleType.CUSTOM:
      return "Custom";
    case ScheduleType.CRON:
      return "Cron";
    case ScheduleType.DBT_CLOUD:
      return "dbt Cloud";
    default:
      return "Manual";
  }
};
