import { VFC, useState } from "react";

import { Image, Text } from "theme-ui";

import { QueryColumn, SuccessfulQueryResponse } from "src/graphql";
import { Badge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { XIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { Link } from "src/ui/link";
import { Modal } from "src/ui/modal";
import ErrorSrc from "src/ui/table/error.svg";
import { getColumnName } from "src/utils/models";
import { abbreviateNumber } from "src/utils/numbers";
import { getInitials } from "src/utils/user";

import { ErrorModal } from "../modals/error-modal";

export interface Props {
  columns: QueryColumn[] | undefined;
  rows: SuccessfulQueryResponse["rows"] | undefined;
  parentModel: any;
  numRowsWithoutLimit: number;
  error: string | undefined;
}

export const AudienceResults: VFC<Readonly<Props>> = ({ parentModel, rows, numRowsWithoutLimit, error }) => {
  const [search, setSearch] = useState<string>("");
  const [selection, setSelection] = useState<number | undefined>();

  const primaryLabelKey = parentModel.visual_query_primary_label
    ? getColumnName(parentModel.visual_query_primary_label, parentModel.columns)
    : parentModel.primary_key;
  const secondaryLabelKey = getColumnName(parentModel.visual_query_secondary_label, parentModel.columns);

  const filteredRows = search
    ? rows?.filter((row) => {
        const primary = row[primaryLabelKey]?.toLowerCase() || "";

        return primary.includes(search.toLowerCase());
      })
    : rows;

  const selectedRow = typeof selection !== "undefined" ? rows?.[selection] : undefined;

  if (error) {
    return (
      <Column sx={{ alignItems: "center", pt: 24, px: 4, flex: 1 }}>
        <Image src={ErrorSrc} sx={{ mb: 4, maxWidth: "200px" }} />
        <Text sx={{ fontWeight: "bold", fontSize: 2, textAlign: "center", mb: 2 }}>Error</Text>
        <ErrorModal error={error} />
      </Column>
    );
  }

  if (!rows || !rows?.length) {
    return (
      <Column sx={{ alignItems: "center", pt: 24, px: 4, flex: 1 }}>
        <Image src={ErrorSrc} sx={{ mb: 4 }} />
        <Text sx={{ fontWeight: "bold", fontSize: 2, textAlign: "center" }}>No results</Text>
        <Text sx={{ mt: 2, maxWidth: "500px", textAlign: "center" }}>
          Modify the query to return at least one row, or{" "}
          <Link
            onClick={() => {
              if (typeof (window as any)?.Intercom !== "undefined") {
                (window as any)?.Intercom("showNewMessage", `Could you help me create a model for a query with no results?`);
              }
            }}
          >
            message us on Intercom
          </Link>{" "}
          if your query cannot return results.
        </Text>
      </Column>
    );
  }

  return (
    <>
      <Column sx={{ flex: 1, overflow: "hidden" }}>
        <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Row sx={{ alignItems: "center", mr: 2, flexShrink: 0 }}>
            <Text sx={{ fontSize: 3, fontWeight: "semi", mr: 2 }}>Results</Text>
            <Badge tooltip={`${numRowsWithoutLimit} rows`} variant="base">
              {abbreviateNumber(numRowsWithoutLimit)}
            </Badge>
          </Row>
          <SearchInput
            placeholder={`Search ${rows?.length} rows...`}
            sx={{ width: "100%" }}
            value={search}
            onChange={setSearch}
          />
        </Row>

        {Array.isArray(filteredRows) && filteredRows.length > 0 ? (
          <Column sx={{ overflow: "auto", mt: 2 }}>
            {filteredRows.map((row, i) => {
              return (
                <Row
                  key={row[parentModel.primary_key]}
                  sx={{ p: 4, borderBottom: "small", alignItems: "center", cursor: "pointer", ":hover": { bg: "base.0" } }}
                  onClick={() => setSelection(i)}
                >
                  <Header primaryLabelKey={primaryLabelKey} row={row} secondaryLabelKey={secondaryLabelKey} />
                </Row>
              );
            })}
          </Column>
        ) : (
          <Column sx={{ alignItems: "center", pt: 24, px: 4 }}>
            <Text sx={{ fontSize: 3, fontWeight: "bold", mb: 1 }}>No rows found</Text>
            <Text sx={{ color: "base.5" }}>There are no rows with a primary label matching your search.</Text>
          </Column>
        )}
      </Column>
      <Modal
        footer={
          typeof selection !== "undefined" && (
            <>
              <Button disabled={selection === 0} variant="secondary" onClick={() => setSelection(selection - 1)}>
                Previous
              </Button>
              <Text sx={{ fontSize: 0, color: "base.5", mx: 2 }}>
                {selection + 1} / {rows?.length}
              </Text>
              <Button disabled={selection === rows?.length - 1} variant="secondary" onClick={() => setSelection(selection + 1)}>
                Next
              </Button>
            </>
          )
        }
        header={
          <>
            <Header primaryLabelKey={primaryLabelKey} row={selectedRow} secondaryLabelKey={secondaryLabelKey} />
            <Button variant="plain" onClick={() => setSelection(undefined)}>
              <XIcon color="base.4" />
            </Button>
          </>
        }
        isOpen={Boolean(selectedRow)}
        sx={{ width: "100%", maxWidth: "600px" }}
        onClose={() => setSelection(undefined)}
      >
        <Details row={selectedRow} />
      </Modal>
    </>
  );
};

const Details = ({ row }) => {
  const columns = Object.keys(row);
  const maxColumnChar = Math.min(
    columns.reduce((max, key) => Math.max(max, key.length), 0),
    32,
  );
  const maxColumnWidth = `${maxColumnChar * 12}px`;

  return (
    <>
      {columns.map((key) => {
        const value = row[key];
        const sanitized = typeof value === "object" ? JSON.stringify(value) : value?.toString();
        return (
          <Row key={key} sx={{ p: 2, borderBottom: "small", overflow: "hidden" }}>
            <Text
              sx={{
                width: maxColumnWidth,
                flexShrink: 0,
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "semi",
                color: "base.6",
              }}
            >
              {key}
            </Text>
            <Text>{sanitized}</Text>
          </Row>
        );
      })}
    </>
  );
};

const Header = ({ primaryLabelKey, secondaryLabelKey, row }) => {
  const primary = row[primaryLabelKey];
  const secondary = row[secondaryLabelKey];
  const initials = getInitials(primary);

  return (
    <Row sx={{ alignItems: "center" }}>
      <Circle color="primaries.2" radius="32px">
        <Text sx={{ fontSize: 0, fontWeight: "bold", color: "primary" }}>{initials}</Text>
      </Circle>
      <Column sx={{ ml: 3 }}>
        {primary && <Text sx={{ fontSize: 2, fontWeight: "semi" }}>{primary}</Text>}
        {secondary && <Text sx={{ color: "base.5", fontSize: 0 }}>{secondary}</Text>}
      </Column>
    </Row>
  );
};
