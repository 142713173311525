import { VFC } from "react";

import { Image, Box, Text } from "theme-ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import { Arrow } from "src/ui/arrow";
import { ObjectBadge } from "src/ui/badge";
import { Row } from "src/ui/box";
import { Link } from "src/ui/link";
import { QueryType } from "src/utils/models";
import { UseSourceResult, SourceBadges, SourceIcon } from "src/utils/sources";
import { getObjectName } from "src/utils/syncs";

type Source = NonNullable<UseSourceResult["data"]>;

type Props = {
  model: any;
  source: Source | null;
  destination: any;
  sync: any;
  destinationDefinition: DestinationDefinition | undefined | null;
};

export const SyncName: VFC<Readonly<Props>> = ({ model, source, destination, destinationDefinition, sync }) => {
  return (
    <Row sx={{ alignItems: "center", flex: 1 }}>
      <Link
        sx={{
          color: "unset",
          p: 3,
          border: "small",
          borderColor: "white",
          ":hover": {
            bg: "base.0",
            borderColor: "primaries.3",
            borderRadius: 1,
          },
        }}
        to={model?.query_type === QueryType.Visual ? `/audiences/${model?.id}` : `/models/${model?.id}`}
      >
        <Row sx={{ alignItems: "center", justifyContent: "flex-start", textAlign: "left" }}>
          <SourceIcon source={source} sx={{ width: "32px" }} />
          <Box sx={{ ml: 4 }}>
            <Text sx={{ fontSize: 0, color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>
              {model?.query_type === QueryType.Visual ? "Audience" : "Model"}
            </Text>
            <IntegrationRow>
              <Text sx={{ fontWeight: "bold" }}>{model?.name}</Text>
              <SourceBadges source={source} sx={{ ml: 0 }} />
            </IntegrationRow>
          </Box>
        </Row>
      </Link>

      <Arrow size={20} />

      <Row sx={{ alignItems: "center" }}>
        <Link
          sx={{
            color: "unset",
            p: 3,
            border: "small",
            borderColor: "white",
            ":hover": {
              bg: "base.0",
              borderColor: "primaries.3",
              borderRadius: 1,
            },
          }}
          to={`/destinations/${destination?.id}`}
        >
          <Row sx={{ alignItems: "center", justifyContent: "flex-start", textAlign: "left" }}>
            <Row sx={{ alignItems: "center" }}>
              <Image alt={destinationDefinition?.name} src={destinationDefinition?.icon} width={32} />

              <Box sx={{ ml: 4 }}>
                <Text sx={{ fontSize: 0, color: "base.4", fontWeight: "bold", textTransform: "uppercase" }}>Destination</Text>
                <IntegrationRow>
                  <Text sx={{ fontWeight: "bold" }}>{destination?.name || destinationDefinition?.name}</Text>
                  {sync?.config?.object && <ObjectBadge>{getObjectName(sync.config.object)}</ObjectBadge>}
                </IntegrationRow>
              </Box>
            </Row>
          </Row>
        </Link>
      </Row>
    </Row>
  );
};

const IntegrationRow = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: ["column", "column", "column", "row", "row"],
      gap: 2,
      alignItems: ["flex-start", "flex-start", "flex-start", "center", "center"],
    }}
  >
    {children}
  </Box>
);
