import { FC, memo, MouseEvent, ReactElement, useCallback, useMemo, VFC } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { ThemeUIStyleObject } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";
import { OrderBy } from "src/graphql";
import { Box, Column } from "src/ui/box";

import { Indices } from "../../../../design";
import { Checkbox } from "../checkbox";
import { Spinner } from "../loading";
import { HeaderCell, TableCell } from "./cells";
import { Placeholder, PlaceholderContent } from "./placeholder";
import { Row } from "./row";

export type TableColumn = {
  name?: string;
  header?: () => ReactElement;
  cell?: (value: any) => ReactElement | string | null;
  key?: string;
  sortKey?: string;
  defaultValue?: string;
  min?: string;
  max?: string;
  divider?: boolean;
  disabled?: (row: any) => boolean;
  sortDirection?: OrderBy | null;
  onClick?(): void;
};

export type RowClickHandler<Data> = (row: Data, event: MouseEvent) => void;

export type TableProps<Data> = {
  columns: TableColumn[];
  data: Data[] | undefined;
  onRowClick?: RowClickHandler<Data>;
  onSelect?: (key: string | number | Array<string | number>) => void;
  selectedRows?: Array<any>;
  width?: string;
  defaultMin?: string;
  defaultMax?: string;
  sx?: ThemeUIStyleObject;
  error?: boolean;
  placeholder?: PlaceholderContent;
  placeholderSx?: ThemeUIStyleObject;
  primaryKey?: string;
  disabled?: (row: Data) => boolean;
  rowHeight?: number;
  highlight?: number | string;
  loading?: boolean;
  tableSx?: ThemeUIStyleObject;
  showHeaders?: boolean;
};

export function Table<Data>({
  primaryKey = "id",
  columns,
  data = [],
  onRowClick,
  onSelect,
  selectedRows,
  sx,
  defaultMin = "min-content",
  defaultMax = "1fr",
  placeholder,
  placeholderSx,
  error,
  rowHeight = 48,
  disabled,
  highlight,
  loading,
  tableSx,
  showHeaders = true,
}: Readonly<TableProps<Data>>) {
  const isEmpty = data.length === 0;
  const permission = usePermission();
  const { appEnableTableSorting } = useFlags();

  const supportsSelection = Boolean(selectedRows && onSelect) && !permission?.unauthorized;

  const rows = useMemo(
    () =>
      data.map((row, index: number) => (
        <MemoizedTableRow
          key={row[primaryKey]}
          columns={columns}
          disabled={disabled}
          height={rowHeight}
          index={index}
          primaryKey={primaryKey}
          row={row}
          selected={
            selectedRows?.includes(row[primaryKey]) || (typeof highlight !== "undefined" && highlight === row[primaryKey])
          }
          onClick={onRowClick}
          onSelect={supportsSelection ? onSelect : undefined}
        />
      )),
    [data, columns, highlight, onRowClick, onSelect, disabled, selectedRows, primaryKey, rowHeight, supportsSelection],
  );

  const containerSx: ThemeUIStyleObject = useMemo(
    () => ({
      width: "100%",
      overflow: "hidden",
      ...sx,
    }),
    [sx],
  );

  tableSx = useMemo(
    () => ({
      bg: "white",
      borderRadius: 1,
      display: isEmpty || error ? "flex" : "grid",
      gridTemplateColumns: getGridTemplateColumns(
        supportsSelection ? [{ max: "max-content" }, ...columns] : columns,
        defaultMin,
        defaultMax,
      ),
      gridTemplateRows: `40px repeat(auto-fill, ${rowHeight}px)`,
      width: "100%",
      overflowX: "auto",
      ...tableSx,
    }),
    [sx, tableSx, columns.length, defaultMin, defaultMax, rowHeight, isEmpty, error, supportsSelection],
  );

  placeholderSx = useMemo(() => ({ ...placeholderSx }), [placeholderSx]);

  const headerCells = useMemo(() => {
    return columns.map(({ header, name, sortDirection, onClick }: TableColumn) => {
      const sortingProps = appEnableTableSorting
        ? {
            sortDirection,
            onClick,
          }
        : {};

      return (
        <HeaderCell key={name} {...sortingProps}>
          {header ? header() : name}
        </HeaderCell>
      );
    });
  }, [columns]);

  const onSelectAll = useCallback(
    (value) => {
      if (typeof onSelect !== "function") {
        return;
      }

      if (value) {
        onSelect(data.map((row) => row[primaryKey]));
      } else {
        onSelect([]);
      }
    },
    [data, onSelect, primaryKey],
  );

  if (!loading && (isEmpty || error)) {
    return <Placeholder content={placeholder} error={Boolean(error)} sx={placeholderSx} />;
  }

  return (
    <Column
      sx={{
        width: "100%",
        overflow: "hidden",
        position: "relative",
        minHeight: loading && !data?.length ? "200px" : undefined,
        ...containerSx,
      }}
    >
      <Box as="table" sx={tableSx}>
        {showHeaders && (
          <TableHead>
            <TableHeadRow>
              {supportsSelection && (
                <HeaderCell>
                  <Checkbox value={data?.length > 0 && selectedRows?.length === data?.length} onChange={onSelectAll} />
                </HeaderCell>
              )}
              {headerCells}
            </TableHeadRow>
          </TableHead>
        )}

        <TableBody>{rows}</TableBody>
      </Box>
      {loading && (
        <Column
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "calc(100% - 40px)",
            alignItems: "center",
            justifyContent: "center",
            bg: "rgba(250, 251, 252, .75)",
            zIndex: Indices.Content,
          }}
        >
          <Spinner size={64} />
        </Column>
      )}
    </Column>
  );
}

const getGridTemplateColumns = (columns, defaultMin, defaultMax) =>
  columns.map(({ min, max }) => `minmax(${min || defaultMin}, ${max || defaultMax})`).join(" ");

type TableRowProps = {
  row: any;
  primaryKey: string;
  selected: boolean;
  disabled?: (row: any) => boolean;
  height?: number;
  onClick?: RowClickHandler<any>;
  onSelect?: (value: number | string) => void;
  columns: TableColumn[];
  index: number;
};

const TableRow: VFC<Readonly<TableRowProps>> = ({
  row,
  disabled,
  selected,
  height,
  columns,
  onClick,
  onSelect,
  primaryKey,
}) => {
  return (
    <Row
      clickable={Boolean(onClick)}
      disabled={disabled ? disabled(row) : false}
      primaryKey={primaryKey}
      row={row}
      selected={selected}
      onClick={onClick}
      onSelect={onSelect}
    >
      {columns.map((column: TableColumn) => (
        <TableCell key={column.name} column={column} height={height} row={row} />
      ))}
    </Row>
  );
};

const MemoizedTableRow = memo(TableRow);

const tableHeadSx = {
  display: "contents",
};

const TableHead: FC = ({ children }) => (
  <Box as="thead" sx={tableHeadSx}>
    {children}
  </Box>
);

const TableHeadRow: FC = ({ children }) => (
  <Box as="tr" sx={tableHeadSx}>
    {children}
  </Box>
);

const tableBodySx: ThemeUIStyleObject = {
  position: "relative",
  display: "contents",
};

const TableBody: FC = ({ children }) => (
  <Box as="tbody" sx={tableBodySx}>
    {children}
  </Box>
);
