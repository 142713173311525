import { FC, useMemo, useState } from "react";

import { Text } from "theme-ui";

import { useGitSyncAttemptsByIdQuery, useGitSyncAttemptsQuery } from "src/graphql";
import { Badge } from "src/ui/badge";
import { Button } from "src/ui/button";
import { Modal } from "src/ui/modal";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { formatDatetime } from "src/utils/time";

type GitActivityProps = {
  id?: string;
  error?: boolean;
};

export const GitActivity: FC<Readonly<GitActivityProps>> = ({ id, error }) => {
  const { offset, limit, page, setPage } = useTableConfig({ limit: 10 });

  const { data: gitActivityByIdData, isFetching: gitActivityByIdLoading } = useGitSyncAttemptsByIdQuery(
    {
      syncId: Number(id),
      offset,
      limit,
    },
    { enabled: Boolean(id), notifyOnChangeProps: "tracked", keepPreviousData: true },
  );
  const { data: gitActivityData, isFetching: gitActivityLoading } = useGitSyncAttemptsQuery(
    { offset, limit },
    { enabled: !id, notifyOnChangeProps: "tracked", keepPreviousData: true },
  );

  const data = id ? gitActivityByIdData : gitActivityData;

  const gitActivity = data?.git_sync_attempts;
  const count = data?.git_sync_attempts_aggregate?.aggregate?.count ?? 0;

  const [attemptError, setError] = useState<string>("");

  const columns = useMemo(
    () => [
      {
        name: "Started",
        key: "created_at",
        cell: (createdAt) => formatDatetime(createdAt),
      },
      {
        name: "Type",
        key: "type",
        cell: (type) =>
          type === "inbound" ? <Badge variant="green">Inbound</Badge> : <Badge variant="indigo">Outbound</Badge>,
      },
      {
        name: "State",
        key: "state",
        cell: (state) => (state === "success" ? <Badge variant="green">Success</Badge> : <Badge variant="red">Error</Badge>),
      },
      {
        name: "Error",
        key: "error",
        cell: (error) =>
          error ? (
            <Button
              size="small"
              variant="secondary"
              onClick={() => {
                setError(error.message);
              }}
            >
              View
            </Button>
          ) : null,
      },
    ],
    [],
  );

  return (
    <>
      <Table
        columns={columns}
        data={gitActivity}
        error={error}
        loading={gitActivityLoading || gitActivityByIdLoading}
        placeholder={{
          title: "No recent git activity.",
          body: "To view git activity, update this sync or change the definition in your git repository.",
          error: "Git activity failed to load, please try again.",
        }}
      />
      <Pagination count={count} label="git syncs" page={page} rowsPerPage={limit} setPage={setPage} />
      <Modal info isOpen={Boolean(attemptError)} title="Error" onClose={() => setError("")}>
        <Text>{attemptError}</Text>
      </Modal>
    </>
  );
};
