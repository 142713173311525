import { VFC } from "react";

import { set, format, getHours, getMinutes } from "date-fns";
import { Text } from "theme-ui";

import { Row } from "src/ui/box";

import { NewSelect } from "../new-select";
import { AM_PM_OPTIONS, HOUR_OPTIONS, MINUTE_OPTIONS } from "./constants";

export type Props = { value: Date; onChange: (value: Date) => void };

export const TimeSelect: VFC<Readonly<Props>> = ({ value, onChange }) => {
  const militaryHours = getHours(value);
  const minutes = getMinutes(value);

  const isPM = militaryHours >= 12;
  const hours = militaryHours === 0 ? 12 : militaryHours > 12 ? militaryHours - 12 : militaryHours;

  return (
    <Row sx={{ height: "32px", alignItems: "center" }}>
      <NewSelect
        options={HOUR_OPTIONS}
        sx={{ mr: 1, flex: "0 1 auto" }}
        value={hours}
        width={64}
        onChange={(hours) =>
          onChange(
            set(value, {
              hours: hours === 12 && !isPM ? 0 : isPM && hours < 12 ? hours + 12 : hours,
            }),
          )
        }
      />
      <Text sx={{ fontWeight: "bold", mr: 1 }}>:</Text>
      <NewSelect
        options={MINUTE_OPTIONS}
        sx={{ mr: 1, flex: "0 1 auto" }}
        value={minutes}
        width={64}
        onChange={(minutes) => onChange(set(value, { minutes }))}
      />
      <NewSelect
        options={AM_PM_OPTIONS}
        sx={{ width: "64px", flex: "0 1 auto", mr: 1 }}
        value={isPM ? "PM" : "AM"}
        width={64}
        onChange={(val) => onChange(set(value, { hours: val === "PM" ? militaryHours + 12 : militaryHours - 12 }))}
      />
      <Text>({format(value, "z")})</Text>
    </Row>
  );
};
