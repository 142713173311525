import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { Message } from "src/ui/message";
import { SensitiveField } from "src/ui/sensitive-field";

const ConfigurationForm = ({ config, setConfig, error, hideSecret = false }) => {
  return (
    <>
      <Field label="Hostname or url">
        <Input
          placeholder="my-clickhouse.com"
          value={config?.host || ""}
          onChange={(value) => setConfig({ ...config, host: value })}
        />
      </Field>

      <Field optional label="Port">
        <Input placeholder="8123" value={config?.port || ""} onChange={(value) => setConfig({ ...config, port: value })} />
      </Field>

      <Field optional label="Username">
        <Input
          placeholder="default"
          value={config?.username || ""}
          onChange={(value) => setConfig({ ...config, username: value })}
        />
      </Field>

      <SensitiveField
        optional
        hideSecret={hideSecret}
        label="Password"
        value={config?.password || ""}
        onChange={(password) => setConfig({ ...config, password })}
      />

      <Field optional label="Database">
        <Input
          placeholder="default"
          value={config?.database || ""}
          onChange={(value) => setConfig({ ...config, database: value })}
        />
      </Field>

      <Field label="Otherwise, you can specify the connection string for JDBC with extra options">
        <Input
          placeholder="jdbc:ch[:<protocol>]://<host>:[<port>][/<database>[?param1=value1&param2=value2]]"
          value={config?.connectionString || ""}
          onChange={(value) => setConfig({ ...config, connectionString: value })}
        />
      </Field>

      <FieldError error={error} />

      <Message>Hightouch only supports Clickhouse server version 20 or newer.</Message>
    </>
  );
};

export default {
  ConfigurationForm,
  isConfigurationComplete: (configuration: Record<string, unknown>, tunnel?: any): boolean => {
    if (!configuration) {
      return false;
    }

    if (configuration["connectionString"]) {
      return true;
    }

    return Boolean(configuration["host"] || tunnel);
  },
};
