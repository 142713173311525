import { VFC } from "react";

import { Text, ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";

type Props = {
  label: { key: string; value: string | number };
  sx?: ThemeUIStyleObject;
};

export const labelStyle: ThemeUIStyleObject = {
  alignItems: "center",
  bg: "base.2",
  borderRadius: 2,
  px: 2,
  height: "28px",
  width: "max-content",
  flexShrink: 0,
  fontSize: 0,
};

export const Label: VFC<Readonly<Props>> = ({ label, sx = {} }) => {
  return (
    <Row
      sx={{
        ...labelStyle,
        ...sx,
      }}
    >
      <Text sx={{ color: "primaries.8", fontWeight: "bold" }}>{label.key}</Text>
      <Text sx={{ ml: 2 }}>{String(label.value)}</Text>
    </Row>
  );
};
