import { forwardRef, FocusEvent } from "react";

import { Input as ThemedInput, ThemeUIStyleObject } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";

export type InputProps = {
  error?: boolean;
  // `onChange` callback receives different argument based on whether `name` prop exists.
  // That's why it's not currently possible to properly define a type for such
  // behavior, because it requires adding a generic, but `forwardRef` doesn't allow it.
  // So for now, unfortunately, `onChange` argument remains as `any`.
  onChange?: (payload: any) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: any) => void;
  size?: "small" | "large";
  value?: string | null;
  sx?: ThemeUIStyleObject;
  type?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  defaultValue?: string;
  min?: string;
  max?: string;
  maxLength?: number;
  name?: string;
  permissioned?: boolean;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ error, onChange, sx = {}, size, disabled, permissioned = true, ...props }, ref) => {
    const value =
      props.name || props.defaultValue
        ? undefined
        : typeof props.value === "undefined" || props.value === null
        ? ""
        : props.value;

    const permission = permissioned ? usePermission() : undefined;

    return (
      <ThemedInput
        ref={ref}
        disabled={permission?.unauthorized || disabled}
        sx={{
          width: "100%",
          borderColor: error ? "red" : undefined,
          ...sx,
        }}
        variant={size === "small" ? "inputSmall" : size === "large" ? "inputLarge" : "input"}
        onChange={onChange ? (event) => (props.name ? onChange(event) : onChange(event?.target?.value)) : undefined}
        {...props}
        value={value}
      />
    );
  },
);

Input.displayName = "Input";
