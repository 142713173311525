import { FC, ReactNode } from "react";

import { useUser } from "src/contexts/user-context";

type Props = {
  fallback?: ReactNode;
};

export const Organization: FC<Readonly<Props>> = ({ children, fallback = null }) => {
  const { organization } = useUser();

  // This component determines whether to show something to a user that is scoped to an organization.
  // Note that this is not whether the user is part of a workspace that is part of an organization,
  // but rather whether the user logged in through an SSO method that is scoped to an organization vs.
  // the unscoped social methods like Google / Facebook

  return <>{organization ? fallback : children}</>;
};
