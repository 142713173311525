import { FC } from "react";

import { Grid } from "theme-ui";
import { isPresent } from "ts-extras";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { usePostgresColumnsQuery, usePostgresTablesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";

const validation = Yup.object().shape({
  table: Yup.string().required(),
  mappings: COMMON_SCHEMAS.mappings,
  sleepIntervalMs: Yup.number().integer().notRequired(),
  batchSize: Yup.number().integer().notRequired(),
});

const MODES = [{ label: "Upsert", value: "upsert" }];

const PostgresForm: FC = () => {
  const { errors, config, setConfig, destination } = useDestinationForm();

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = usePostgresColumnsQuery(
    {
      input: {
        destinationId: String(destination?.id),
        table: config?.table,
      },
    },
    { enabled: Boolean(config?.table) },
  );

  const columns = columnsData?.listPostgresColumns;

  const {
    data: tablesData,
    isFetching: loadingTables,
    refetch: getTables,
  } = usePostgresTablesQuery({
    input: {
      destinationId: String(destination?.id),
    },
  });

  const tables = tablesData?.listPostgresTables;

  const pgTableOpts = (tables || []).filter(isPresent).map((table) => ({
    label: table.name,
    value: table.name,
  }));

  const columnOpts = (columns || []).filter(isPresent).map((c) => ({ label: c.name, value: c.name }));

  return (
    <>
      <ModeField options={MODES} value={"upsert"} />

      <Section>
        <Field error={errors?.table} label="Which PostgreSQL table would you like to sync to?" size="large">
          <Select
            isError={errors?.table}
            isLoading={loadingTables}
            options={pgTableOpts}
            placeholder="Select a Postgres table"
            reload={getTables}
            value={pgTableOpts?.find((o) => o.value === config?.table)}
            width="340px"
            onChange={(selected) => {
              const val = selected.value;
              setConfig({ ...config, table: val });
            }}
          />
        </Field>
      </Section>

      <Section>
        <Grid gap={8}>
          <Field optional description="Defaults to 100ms" error={errors?.sleepIntervalMs} label="Custom Sleep Interval (ms)">
            <Input
              error={errors?.sleepIntervalMs}
              min="0"
              sx={{ width: "180px" }}
              type="number"
              value={config?.sleepIntervalMs}
              onChange={(sleepIntervalMs) => {
                setConfig({
                  ...config,
                  sleepIntervalMs: sleepIntervalMs || sleepIntervalMs === 0 ? Number(sleepIntervalMs) : undefined,
                });
              }}
            />
          </Field>

          <Field optional description="Defaults to 100 rows per batch" error={errors?.batchSize} label="Custom Batch Size">
            <Input
              error={errors?.batchSize}
              min="1"
              sx={{ width: "180px" }}
              type="number"
              value={config?.batchSize}
              onChange={(batchSize) => {
                setConfig({
                  ...config,
                  batchSize: batchSize || batchSize === 0 ? Number(batchSize) : undefined,
                });
              }}
            />
          </Field>
        </Grid>
      </Section>

      {config?.table && (
        <Section>
          <MappingsField error={columnsError?.message} loading={loadingColumns} options={columnOpts} reload={getColumns} />
        </Section>
      )}

      {config?.table && <DeleteField modes={["delete"]} />}
    </>
  );
};

export default {
  form: PostgresForm,
  validation,
};
