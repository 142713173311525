import { useState, VFC } from "react";

import moment from "moment";
import { Text, Flex, Grid } from "theme-ui";

import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { XIcon } from "src/ui/icons";
import { TimeSelect } from "src/ui/time-select";
import { WEEK_DAYS } from "src/utils/constants";

import { Permission } from "../permission";
import { Schedule, ScheduleExpression } from "./types";

export const LocalDayToUTCDay = (localTime: string, dayIndex: number) => {
  return Number(moment(`${dayIndex} ${localTime}`, "e HH:mm").utc().format("e"));
};

export const UTCDayToLocalDay = (utcTime: string, dayIndex: number) => {
  return Number(moment.utc(`${dayIndex} ${utcTime}`, `e HH:mm`).local().format("e"));
};

interface Props {
  index: number;
  schedule: Schedule;
  setSchedule: (schedule: Schedule) => void;
}

export const VisualCronExpression: VFC<Props> = ({ index = 0, setSchedule, schedule }) => {
  const [cronTime, setCronTime] = useState(
    moment.utc(schedule.schedule?.expressions?.[index]?.time, "HH:mm").local().toDate() || new Date(),
  );

  return (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        borderTop: index > 0 ? "small" : undefined,
        pt: index > 0 ? 4 : 0,
      }}
    >
      <Grid gap={8}>
        <Field label="Repeat at">
          <TimeSelect
            value={cronTime}
            onChange={(value) => {
              setSchedule({
                ...schedule,
                schedule: {
                  ...schedule?.schedule,
                  expressions: schedule.schedule?.expressions?.map((exp, idx) => {
                    if (idx === index) {
                      const newTime = moment(value).utc().format("HH:mm");
                      const localTime = moment(value).format("HH:mm");

                      const days = Object.entries(exp.days ?? {})
                        .filter(([_, value]) => Boolean(value))
                        .map(([key]) =>
                          WEEK_DAYS[
                            LocalDayToUTCDay(localTime, UTCDayToLocalDay(exp.time, Number(moment(key, "dddd").format("e"))))
                          ]!.toLowerCase(),
                        )
                        .reduce((state, index) => {
                          state[index] = true;
                          return state;
                        }, {});

                      return {
                        days,
                        time: newTime,
                      };
                    }
                    return exp;
                  }),
                },
              });
              setCronTime(value as Date);
            }}
          />
        </Field>

        <Field label="Repeat on">
          <Grid gap={2} sx={{ gridAutoFlow: "column", p: 2, bg: "white", borderRadius: 2, border: "small" }}>
            {WEEK_DAYS.map((localDay, dayIndex) => {
              const day = WEEK_DAYS[LocalDayToUTCDay(moment(cronTime).format("HH:mm"), dayIndex)]!;
              const selected = schedule?.schedule?.expressions?.[index]?.days
                ? Boolean(schedule.schedule?.expressions?.[index]?.days?.[day.toLowerCase()])
                : new Date().getDay() === dayIndex;

              return (
                <Flex
                  key={localDay}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setSchedule({
                      ...schedule,
                      schedule: {
                        ...schedule?.schedule,
                        expressions: schedule.schedule?.expressions?.map((exp, idx) => {
                          if (idx === index) {
                            const newDays = {
                              ...schedule?.schedule?.expressions?.[index]?.days,
                              [day.toLowerCase()]: selected ? undefined : true,
                            };

                            return {
                              ...exp,
                              days: newDays,
                            };
                          }
                          return exp;
                        }),
                      },
                    })
                  }
                >
                  <Circle
                    radius="20px"
                    sx={{
                      bg: selected ? "primary" : "blacks.4",
                      ":hover": {
                        bg: "primary",
                      },
                    }}
                  >
                    <Text sx={{ fontSize: "12px", color: "white" }}>{localDay.charAt(0)}</Text>
                  </Circle>
                </Flex>
              );
            })}
          </Grid>
        </Field>
      </Grid>
      {index > 0 && (
        <Permission>
          <Button
            sx={{ ml: 2 }}
            variant="plain"
            onClick={() =>
              setSchedule({
                ...schedule,
                schedule: {
                  ...schedule?.schedule,
                  expressions: schedule.schedule?.expressions?.reduce<ScheduleExpression[]>((arr, expression, idx) => {
                    if (idx === index) {
                      return arr;
                    }

                    return [...arr, expression];
                  }, []),
                },
              })
            }
          >
            <XIcon color="base.3" size={16} strokeWidth="1px" />
          </Button>
        </Permission>
      )}
    </Flex>
  );
};
