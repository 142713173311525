import { useEffect, useState, VFC } from "react";

import Papa from "papaparse";
import Dropzone, { FileRejection } from "react-dropzone";
import { useToasts } from "react-toast-notifications";

import {
  BooleanOperator,
  ColumnType,
  MultiValueColumnTypes,
  MultiValueOperators,
  OperatorsWithoutValue,
} from "src/types/visual";
import { Box } from "src/ui/box";
import { NewSelect } from "src/ui/new-select";
import { CreatableMultiSelect, CreatableSelect } from "src/ui/select";

import { AmountInput } from "./amount-input";
import { PropertyFilterProps } from "./property-filter";
import { TimestampInput } from "./timestamp-input";

export type PropertyInputProps = PropertyFilterProps & { suggestions: Array<string | number> | undefined | null };

export const PropertyInput: VFC<Readonly<PropertyInputProps>> = ({ condition, onChange, suggestions }) => {
  const { addToast } = useToasts();

  const suggestionOptions = suggestions
    ?.filter((label) => label !== null)
    ?.map((label) => ({ label: String(label), value: label }));
  const [multiSelectOptions, setMultiSelectOptions] = useState(suggestionOptions || []);

  useEffect(() => {
    setMultiSelectOptions(suggestionOptions || []);
  }, [condition.property]);

  if (OperatorsWithoutValue.includes(condition.operator)) {
    return null;
  }

  if (
    MultiValueOperators.includes(condition.operator) &&
    condition.propertyType &&
    MultiValueColumnTypes.includes(condition.propertyType)
  ) {
    const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        addToast("Each file must be in '.csv' or '.txt' format and uploaded one at a time.", {
          appearance: "error",
        });
      }

      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];

        if (file) {
          Papa.parse(file, {
            complete: (results) => {
              const values = results.data.flat();
              addToast(`Values from '${file.name}' successfully added.`, { appearance: "success" });

              setMultiSelectOptions([...multiSelectOptions, ...values.map((value) => ({ label: value, value }))]);
              onChange({ value: [...(condition.value || []), ...values] });
            },
          });
        }
      }
    };

    return (
      <Dropzone
        accept={[".csv", ".txt"]}
        maxFiles={1}
        multiple={false}
        noClick={true}
        noKeyboard={true}
        onDrop={handleFileDrop}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <Box {...getRootProps()}>
              <CreatableMultiSelect
                {...getInputProps()}
                options={multiSelectOptions}
                placeholder={isDragActive ? "Upload your file here..." : "Add values or drag/drop a text file..."}
                value={condition.value || []}
                width="300px"
                onChange={(options) => {
                  const values = options.map((option) => option.value);
                  onChange({ value: values });
                }}
                onCreateOption={(v) => {
                  setMultiSelectOptions([...multiSelectOptions, { label: v, value: v }]);
                  onChange({ value: [...(condition.value || []), v] });
                }}
              />
            </Box>
          );
        }}
      </Dropzone>
    );
  }

  if (suggestions) {
    return (
      <CreatableSelect
        isClearable
        formatCreateLabel={(string) => string}
        options={suggestionOptions ?? []}
        placeholder="search..."
        tip={`${suggestionOptions?.length ?? 0} suggested values. Type to use a custom value.`}
        value={condition.value}
        width="200px"
        onChange={(option) => onChange({ value: option?.value })}
        onCreateOption={(value) => onChange({ value })}
      />
    );
  }

  if (condition.propertyType === ColumnType.Boolean) {
    return (
      <NewSelect
        options={[
          {
            value: true,
            label: "True",
          },
          {
            value: false,
            label: "False",
          },
        ]}
        placeholder="True / False"
        value={condition.value}
        width={100}
        onChange={(value) => {
          onChange({
            operator: BooleanOperator.Equals,
            value,
          });
        }}
      />
    );
  }

  if (condition.propertyType && [ColumnType.Timestamp, ColumnType.Date].includes(condition.propertyType)) {
    return <TimestampInput condition={condition} onChange={onChange} />;
  }

  return (
    <AmountInput
      type={
        condition.propertyType && [ColumnType.Number, ColumnType.JsonArrayNumbers].includes(condition.propertyType)
          ? "number"
          : "string"
      }
      value={condition.value}
      onChange={(value) => {
        onChange({ value });
      }}
    />
  );
};
