import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Select } from "src/ui/select";

import { FacebookAccountSelector } from "./facebook-account-selector";
import { PipedriveDomainSelector } from "./pipedrive-domain-selector";
import { SnapchatAccountSelector } from "./snapchat-account-selector";

export const PostSetupForm = ({ destination, config, setConfig }) => {
  if (destination?.type === "facebook")
    return (
      <Field label="Ad Account">
        <FacebookAccountSelector config={config} destination={destination} setConfig={setConfig} />
      </Field>
    );

  if (destination?.type === "pipedrive")
    return <PipedriveDomainSelector config={config} destination={destination} setConfig={setConfig} />;
  if (destination?.type === "snapchat")
    return <SnapchatAccountSelector config={config} destination={destination} setConfig={setConfig} />;

  if (destination?.type === "googleSheetsSA") {
    const regex = /serviceAccounts\/([\w-@.]*)/;

    return (
      <Field
        description={"Share your Google Sheets with this email as an editor in order for Hightouch to access it."}
        label={"Google Service Account Email"}
      >
        <Input readOnly value={destination?.config?.name?.match(regex)?.[1]} />
      </Field>
    );
  }

  if (destination?.type === "pardot" || destination?.type === "pardotSandbox") {
    const versionOptions = [
      { label: "V3", value: "3" },
      { label: "V4", value: "4" },
    ];
    return (
      <>
        <Field label={"Pardot Business Unit ID"}>
          <Input
            value={config?.businessUnitId}
            onChange={(businessUnitId) => {
              setConfig({ ...config, businessUnitId });
            }}
          />
        </Field>
        <Field label={"API Version"}>
          <Select
            options={versionOptions}
            placeholder="Select an API Version..."
            value={versionOptions.find((v) => v.value === config?.apiVersion)}
            onChange={({ value: apiVersion }) => {
              setConfig({ ...config, apiVersion });
            }}
          />
        </Field>
        <Field optional label={"Pardot Custom Domain"}>
          <Input
            value={config?.endpoint}
            onChange={(endpoint) => {
              setConfig({ ...config, endpoint });
            }}
          />
        </Field>
      </>
    );
  }
  return null;
};

export const hasPostSetupForm = (type: string | undefined) =>
  type === "facebook" ||
  type === "snapchat" ||
  type === "googleSheetsSA" ||
  type === "pipedrive" ||
  type === "pardot" ||
  type === "pardotSandbox";
