/* eslint "no-restricted-imports": ["error", {
    "patterns": [
      {
        "group": ["*"],
        "message": "This file is used on the frontend, so try not to import extra code."
      }
    ]
  }
] */

export enum SyncRequestErrorCode {
  /**
   * Returned if the specified primary key isn't actually a primary key because it's not unique.
   * Not all planners require unique primary keys (e.g. `all` or `events` don't need it), but some
   * like in-warehouse do.
   */
  NON_UNIQUE_PRIMARY_KEY = "NON_UNIQUE_PRIMARY_KEY",

  /**
   * Returned if the specified primary key type is not suppported bv warehouse planning
   */
  UNSUPPORTED_PRIMARY_KEY_TYPE = "UNSUPPORTED_PRIMARY_KEY_TYPE",

  /**
   * Returned if the objects from the most recent run of a sync can't be found.
   * This is most common when S3 plan objects expire (as of this writing, after 30 days).
   * When that happens, a full resync is needed.
   */
  PREVIOUS_SYNC_RUN_OBJECT_MISSING = "PREVIOUS_SYNC_RUN_OBJECT_MISSING",

  /**
   * Returned if the previous sync was a migration sync from the local differ to the
   * in-warehouse differ and there were some unsupported column types that
   * couldn't be backfilled.
   */
  REMOVE_PLAN_INCOMPLETE = "REMOVE_PLAN_INCOMPLETE",

  /**
   * Returned if the previous sync had rejected removes to retry, but the model
   * has changed the types of one or more columns.
   */
  REMOVE_RETRY_CHANGED_COLUMN_TYPES = "REMOVE_RETRY_CHANGED_COLUMN_TYPES",

  /**
   * Returned if we ran out of disk space when sorting rows.
   */
  SORT_RAN_OUT_OF_DISK_SPACE = "SORT_RAN_OUT_OF_DISK_SPACE",

  /**
   * Default error code for when there isn't a more specific one.
   */
  UNSPECIFIED = "UNSPECIFIED",

  /**
   * Returned if a table necessary for in-warehouse planning is not present.
   */
  WAREHOUSE_TABLE_MISSING = "WAREHOUSE_TABLE_MISSING",
}

/**
 * SyncRequestErrorInfo is the data stored in `sync_request.error`.
 * All fields of SyncRequestErrorInfo are stored in the DB and exposed to the client,
 * so do NOT add anything sensitive to it.
 * It doesn't extend Error because it's a serialized JSON object, not an instance of Error.
 */
export interface SyncRequestErrorInfo {
  // We call this `syncRequestErrorCode` instead of the terser `code` so that it doesn't conflict
  // with any other error codes that may be on an error cause chain.
  syncRequestErrorCode?: SyncRequestErrorCode;

  /**
   * Message to show to the end user.
   */
  userFacingMessage?: string;

  /**
   * Default message to use when we don't have a more specific one.
   * Often populated from `Error.prototype.message`.
   */
  message?: string;
}

export class NonUniquePrimaryKeyErrorInfo implements SyncRequestErrorInfo {
  syncRequestErrorCode = SyncRequestErrorCode.NON_UNIQUE_PRIMARY_KEY;
  nonUniquePrimaryKeyInfo: {
    sqlToIdentifyDuplicateRows: string;
  };
  constructor(info: { sqlToIdentifyDuplicateRows: string }) {
    this.nonUniquePrimaryKeyInfo = info;
  }
}

export class UnsupportedPrimaryKeyTypeErrorInfo
  implements SyncRequestErrorInfo
{
  syncRequestErrorCode = SyncRequestErrorCode.UNSUPPORTED_PRIMARY_KEY_TYPE;
  supportedPrimaryKeyType: {
    strings: string;
    ints: string;
    floats: string;
  };
  type: string;
  constructor(info: {
    type: string;
    supportedPrimaryKeyType: {
      strings: string;
      ints: string;
      floats: string;
    };
  }) {
    this.supportedPrimaryKeyType = info.supportedPrimaryKeyType;
    this.type = info.type;
  }
}

export class PreviousSyncRunObjectMissing implements SyncRequestErrorInfo {
  syncRequestErrorCode?: SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING;
}

export class RemovePlanIncompleteErrorInfo implements SyncRequestErrorInfo {
  syncRequestErrorCode = SyncRequestErrorCode.REMOVE_PLAN_INCOMPLETE;
  userFacingMessage?: string;
  constructor(info: { userFacingMessage: string }) {
    this.userFacingMessage = info.userFacingMessage;
  }
}

export class RemoveRetryChangedColumnTypes implements SyncRequestErrorInfo {
  syncRequestErrorCode = SyncRequestErrorCode.REMOVE_RETRY_CHANGED_COLUMN_TYPES;
  changedColumns: string[];
  constructor(info: { changedColumns: string[] }) {
    this.changedColumns = info.changedColumns;
  }
}

export class SortRanOutOfDiskSpace implements SyncRequestErrorInfo {
  syncRequestErrorCode = SyncRequestErrorCode.SORT_RAN_OUT_OF_DISK_SPACE;
}

export class WarehouseTableMissing implements SyncRequestErrorInfo {
  syncRequestErrorCode = SyncRequestErrorCode.WAREHOUSE_TABLE_MISSING;
  missingTables?: string[];
  constructor(info: { missingTables: string[] }) {
    this.missingTables = info.missingTables;
  }
}

/**
 * RejectedRowError is a custom type used to signal that a sync failed due to some rows being rejected by the destination.
 */
export class SyncFailedWithRejectedRowsError extends Error {
  static MESSAGE = "Some updates failed. See the syncs page for more details.";

  constructor() {
    super(SyncFailedWithRejectedRowsError.MESSAGE);

    // Need to do this to get `instanceof` to work until we upgrade to ES2015 compile target.
    // TypeScript doesn't set the prototype when extending Error.
    Object.setPrototypeOf(this, SyncFailedWithRejectedRowsError.prototype);
  }
}

/**
 * RetryWithBackoffError is an error type that needs to be retried with a backoff manner by worker
 * Right now we just don't expire the worker reservation so that worker will have 5 minutes delay in retrying
 * In the future we can implement proper retrying logic with backoff
 */
export class RetryWithDelayError extends Error {
  constructor(error: Error) {
    super(`Encountered error: ${error.message}. Retrying later`);
  }
}
