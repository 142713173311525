import { VFC, useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";
import { Flex, Grid, Text } from "theme-ui";

import { useCreateGitCredentialsMutation, useGitCredentialsQuery, useUpdateGitCredentialsMutation } from "src/graphql";
import { Container } from "src/ui/box";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { Input, TextArea } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";

import { TunnelSelect } from "../tunnels/tunnel-select";

export const GitCredentials: VFC = () => {
  const { data } = useGitCredentialsQuery();
  const credentials = data?.git_credentials?.[0];

  const [tunnel_id, setTunnelId] = useState<any>(credentials?.tunnel_id);
  const [username, setUsername] = useState<string | null>(credentials?.username || "");
  const [password, setPassword] = useState<string>("");
  const [ssh_privatekey, setSshPrivateKey] = useState<string>("");
  const [type, setType] = useState<string>(credentials?.type || "");
  const [protocol, setProtocol] = useState<string>(!credentials || credentials.username ? "http" : "ssh");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (credentials) {
      setUsername(credentials.username);
      setType(credentials.type);
    }
  }, [credentials]);

  const { addToast } = useToasts();

  const { mutateAsync: createGitConfigSync } = useCreateGitCredentialsMutation();
  const { mutateAsync: updateGitConfigSync } = useUpdateGitCredentialsMutation();

  const save = async () => {
    try {
      setLoading(true);

      if (!credentials) {
        await createGitConfigSync({
          credentials: {
            username,
            password,
            type,
            tunnel_id,
            ssh_privatekey,
          },
        });
      } else {
        await updateGitConfigSync({
          id: credentials.id,
          credentials: {
            username,
            password,
            type,
            tunnel_id,
            ssh_privatekey,
          },
        });
      }
      addToast("Your Git credentials have been saved!", {
        appearance: "success",
      });
    } catch {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const dirty = username !== credentials?.username || password || ssh_privatekey || type !== credentials?.type;

  const complete =
    type !== "github_app" && ((username && password !== undefined) || credentials || ssh_privatekey !== undefined) && type;

  const serviceOptions = [
    { label: "Github", value: "github" },
    { label: "Github OAuth", value: "github_app" },
    { label: "Gitlab", value: "gitlab" },
    { label: "Bitbucket", value: "bitbucket" },
    { label: "Other", value: "other" },
  ];

  const protocolOption = [
    { label: "HTTP", value: "http" },
    { label: "SSH", value: "ssh" },
  ];

  return (
    <Section
      divider
      footer={
        <Flex sx={{ justifyContent: "flex-end" }}>
          <Button disabled={!dirty || !complete} label={"Save"} loading={loading} onClick={save} />
        </Flex>
      }
    >
      <Container center={false} size="small">
        <Field
          description="Hightouch can use your Git repo as a source of dbt models. Enter your Git username and token in order to give Hightouch access to your repositories."
          label="Git Credentials"
          size="large"
        >
          <Grid gap={8}>
            <Field label="Service">
              <Select
                options={serviceOptions}
                placeholder="Select a service..."
                value={serviceOptions?.find((o) => o.value === type) || null}
                onChange={(selected) => {
                  const val = selected?.value;
                  setType(val);
                }}
              />
            </Field>
            {type === "github_app" ? (
              credentials?.credentials ? (
                <Field label="Install the Hightouch Github App">
                  <Flex sx={{ mb: 2, alignItems: "center" }}>
                    <Circle color={"green"} radius="12px" />
                    <Text sx={{ ml: 2 }}>Installed with ID: {credentials?.credentials?.installationId}</Text>
                  </Flex>
                  <Button
                    label={`Configure App Permissions`}
                    variant="secondary"
                    onClick={() => {
                      window.location.href = `${import.meta.env.VITE_API_BASE_URL}/github/oauth/integration`;
                    }}
                  />
                </Field>
              ) : (
                <Field label="Install the Hightouch Github App">
                  <Button
                    label={`Install App`}
                    variant="secondary"
                    onClick={() => {
                      window.location.href = `${import.meta.env.VITE_API_BASE_URL}/github/oauth/integration`;
                    }}
                  />
                </Field>
              )
            ) : (
              <>
                <TunnelSelect
                  optional
                  value={{ id: tunnel_id }}
                  onChange={(value) => {
                    if (!value) {
                      setTunnelId(null);
                    } else {
                      setTunnelId(value.id);
                    }
                  }}
                />
                <Field label="Protocol">
                  <Select
                    options={protocolOption}
                    placeholder="Select Protocol"
                    value={protocol ? protocol : "http"}
                    onChange={(selected) => {
                      setProtocol(selected?.value);
                    }}
                  />
                </Field>
                {protocol === "http" ? (
                  <>
                    <Field label="Username">
                      <Input
                        value={username}
                        onChange={(username) => {
                          setUsername(username);
                          setSshPrivateKey("");
                        }}
                      />
                    </Field>
                    <Field label="Token">
                      <Input
                        placeholder={credentials ? "<REDACTED>" : undefined}
                        type="password"
                        value={password}
                        onChange={(password) => {
                          setPassword(password);
                          setSshPrivateKey("");
                        }}
                      />
                    </Field>
                  </>
                ) : (
                  <Field
                    description="Access git server with ssh protocol. You don't need to provide if you are using http."
                    label="SSH Private key"
                  >
                    <TextArea
                      placeholder={credentials ? "<REDACTED>" : undefined}
                      rows={10}
                      value={ssh_privatekey}
                      onChange={(event) => {
                        setSshPrivateKey(event.target.value);
                        setUsername("");
                        setPassword("");
                      }}
                    />
                  </Field>
                )}
              </>
            )}
          </Grid>
        </Field>
      </Container>
    </Section>
  );
};
