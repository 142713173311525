import { Global, css } from "@emotion/react";

export const GlobalStyles = () => (
  <>
    <Global styles={base} />
    <Global styles={reset} />
    <Global styles={ace} />
  </>
);

const base = () => css`
  body {
    height: 100%;
    font-family: inherit;
    color: inherit;
    background-color: inherit;
    line-height: inherit;
    overflow-x: hidden !important;
  }

  @media (max-width: 800px) {
    body {
      overflow-y: hidden !important;
    }
  }

  #react {
    height: 100%;
    display: flex;
  }

  a {
    color: var(--theme-ui-colors-blue);
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    color: #271f4d;
  }

  a:active,
  a:focus {
    outline: none;
  }

  strong {
    font-weight: 500;
  }
`;

const reset = () => css`
  /**
 * Manually forked from SUIT CSS Base: https://github.com/suitcss/base
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */

  /**
 * Removes the default spacing and border for appropriate elements.
 */

  button {
    background-color: transparent;
    background-image: none;
  }

  /**
  * Work around a Firefox/IE bug where the transparent button background
  * results in a loss of the default button focus styles.
  */

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    margin: 0;
    padding-left: 14px;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  textarea {
    resize: vertical;
  }

  input::placeholder,
  textarea::placeholder {
    opacity: 1;
  }

  button,
  [role="button"] {
    cursor: pointer;
  }

  table {
    border-collapse: collapse;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }

  /**
  * Make replaced elements display: block by default as that's
  * the behavior you want almost all of the time. Inspired by
  * CSS Remedy, with svg added as well.
  *
  * https://github.com/mozdevs/cssremedy/issues/14
  */

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  /**
  * Constrain images and videos to the parent width and preserve
  * their instrinsic aspect ratio.
  *
  * https://github.com/mozdevs/cssremedy/issues/14
  */

  img,
  video {
    max-width: 100%;
    height: auto;
  }
`;

const ace = css`
  .ace_editor {
    border-radius: 3px;
    border: 1px solid var(--theme-ui-colors-base-2) !important;
  }

  .ace_gutter {
    background-color: var(--theme-ui-colors-base-1) !important;
  }

  .ace_gutter-active-line {
    background-color: var(--theme-ui-colors-primary) !important;
    color: white !important;
  }
`;
