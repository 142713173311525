import { FC, RefAttributes, PropsWithoutRef, ForwardRefExoticComponent } from "react";

import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { Link as ThemedLink, LinkProps as ThemedUILinkProps } from "theme-ui";

import { useUser } from "src/contexts/user-context";

type ForwardRef<T, P> = ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<T>>;

export interface LinkProps {
  newTab?: boolean;
  variant?: string;
  to?: string;
  slug?: boolean;
}

type CombinedProps = ThemedUILinkProps & Omit<RouterLinkProps, "to"> & LinkProps;

const FinalLink: ForwardRef<HTMLAnchorElement, CombinedProps> = ThemedLink;

export const Link: FC<Readonly<Omit<CombinedProps, "href">>> = ({
  to,
  newTab,
  onClick,
  children,
  variant,
  slug = true,
  sx = {},
}) => {
  const { slug: workspaceSlug } = useUser();

  if (!to || to?.startsWith("http")) {
    return (
      <ThemedLink
        href={to}
        rel={newTab ? "noreferrer noopener" : undefined}
        sx={{
          cursor: "pointer",
          outline: "none",
          color: variant === "subtle" ? "dark.2" : "blue",
          ...sx,
        }}
        target={newTab ? "_blank" : undefined}
        onClick={onClick}
      >
        {children}
      </ThemedLink>
    );
  }

  const path = to.startsWith("/") && slug ? `/${workspaceSlug}${to}` : to;

  return (
    <FinalLink
      as={RouterLink}
      sx={{
        cursor: "pointer",
        outline: "none",
        color: variant === "subtle" ? "dark.2" : "primary",
        ...sx,
      }}
      target={newTab ? "_blank" : undefined}
      to={path}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {children}
    </FinalLink>
  );
};
