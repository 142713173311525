import { Checkbox } from "src/ui/checkbox";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { SensitiveField } from "src/ui/sensitive-field";

export const MssqlForm = ({ config, setConfig, error, tunnel, hideSecret = false }) => {
  return (
    <>
      <Field label="Server">
        <Input
          disabled={tunnel}
          value={tunnel ? `Using ${tunnel?.name} tunnel` : config?.server || ""}
          onChange={(value) => setConfig({ ...config, server: value })}
        />
      </Field>
      <Field label="Port">
        <Input
          disabled={tunnel}
          value={tunnel ? `Using ${tunnel?.name} tunnel` : config?.port || ""}
          onChange={(value) => {
            setConfig({ ...config, port: Number(value) });
          }}
        />
      </Field>
      <Field label="Database">
        <Input value={config?.database || ""} onChange={(value) => setConfig({ ...config, database: value })} />
      </Field>
      <Field label="Username">
        <Input value={config?.user || ""} onChange={(value) => setConfig({ ...config, user: value })} />
      </Field>

      <SensitiveField
        hideSecret={hideSecret}
        label="Password"
        value={config?.password || ""}
        onChange={(password) => setConfig({ ...config, password })}
      />

      <Checkbox
        label="Always trust the server certificate (for self-signed certificates)"
        value={config?.options?.trustServerCertificate}
        onChange={(trustServerCertificate) => {
          if (trustServerCertificate) {
            setConfig({ ...config, options: { trustServerCertificate: true } });
          } else {
            const newConfig = { ...config };
            delete newConfig.options;
            setConfig(newConfig);
          }
        }}
      />

      <Field label="Otherwise, you can specify the connection string with extra options">
        <Input
          placeholder="Server=localhost,1433;Database=database;User Id=username;Password=password;Encrypt=true"
          value={config?.connectionString || ""}
          onChange={(value) => setConfig({ ...config, connectionString: value })}
        />
      </Field>

      <FieldError error={error} />
    </>
  );
};
