export enum ScheduleType {
  MANUAL = "manual",
  INTERVAL = "interval",
  CRON = "cron",
  CUSTOM = "visual_cron",
  DBT_CLOUD = "dbt",
}

export interface Schedule {
  type: ScheduleType;
  schedule?: {
    state?: {
      time?: string;
      interval?: ScheduleIntervalUnit;
      days?: Record<string, boolean>;
      every?: number;
      advancedCronEditor?: unknown;
    };
    interval?: ScheduleInterval;
    expression?: string;
    expressions?: ScheduleExpression[];
    advancedCronEditor?: unknown;
    dbtCredentialId?: number;
    account?: {
      id: string;
      name: string;
    };
    job?: {
      id: string;
      name: string;
    };
  } | null;
}

export interface ScheduleInterval {
  quantity?: number | null;
  unit?: ScheduleIntervalUnit;
}

export type ScheduleIntervalUnit = "minute" | "hour" | "day" | "week";

export interface ScheduleExpression {
  time: string;
  days?: Record<string, boolean | undefined>;
}
