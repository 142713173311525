import { ReactElement } from "react";

import { Checkbox } from "src/ui/checkbox";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { SensitiveField } from "src/ui/sensitive-field";

import { FormProps } from "../form";

function ConfigurationForm({ config, setConfig, error, hideSecret = false }: Readonly<FormProps>): ReactElement<any, any> {
  return (
    <>
      <Field label="Username">
        <Input value={config?.username || ""} onChange={(value) => setConfig({ ...config, username: value })} />
      </Field>

      <SensitiveField
        hideSecret={hideSecret}
        label="Password"
        value={config?.password || ""}
        onChange={(password) => setConfig({ ...config, password })}
      />

      <Field label="Database name">
        <Input value={config?.database || ""} onChange={(value) => setConfig({ ...config, database: value })} />
      </Field>

      <Field label="Engine name">
        <Input value={config?.engine || ""} onChange={(value) => setConfig({ ...config, engine: value })} />
      </Field>

      <Field label="Start the engine automatically">
        <Checkbox
          sx={{ mt: 2 }}
          value={config?.startEngine}
          onChange={(startEngine) => setConfig({ ...config, startEngine })}
        />
      </Field>

      <FieldError error={error} />
    </>
  );
}

export default {
  ConfigurationForm,
};
