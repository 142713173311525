import { useState } from "react";

import { Text } from "theme-ui";

import { useDbtModelsQuery, useGitSyncConfigQuery, DbtModelsQuery } from "src/graphql";
import { Row, Column } from "src/ui/box";
import { ChevronDownIcon, ChevronRightIcon, DBTIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";

type DbtModel = DbtModelsQuery["dbt_models"][0];

export const DBTSelector = ({ dbtModelId, onChange, objectDefinitions, objectDefinitionsLoading }) => {
  const { data, isLoading: dbtModelQueryLoading } = useDbtModelsQuery();
  const { data: gitSyncConfigData } = useGitSyncConfigQuery();

  const loading = dbtModelQueryLoading || objectDefinitionsLoading;

  const noDBTModels = !dbtModelQueryLoading && !data?.dbt_models?.length;

  const [showingOmitted, setShowingOmitted] = useState(false);
  const available: DbtModel[] = [];
  const omitted: DbtModel[] = [];

  for (const dbtModel of data?.dbt_models ?? []) {
    if (objectDefinitions?.length > 0) {
      const inSchema = objectDefinitions.find(
        (object) => object.name === dbtModel.name && object.object_definition_group.name === dbtModel.git_sync_config.schema,
      );

      if (inSchema) {
        available.push(dbtModel);
      } else {
        omitted.push(dbtModel);
      }

      continue;
    }

    available.push(dbtModel);
  }

  if (loading) {
    return <Spinner size={64} />;
  }

  const DBTRow = ({ text, selected, onSelect, level = 0 }) => (
    <Row
      sx={{ cursor: "pointer", alignContent: "center", py: 2, backgroundColor: selected ? "primary" : null }}
      onClick={onSelect}
    >
      <DBTIcon size={12} sx={{ ml: 2 + level * 2, mr: 2 }} />
      <Text sx={{ pr: 2, textAlign: "center", fontSize: 1, color: selected ? "white" : "blacks.3" }}>{`${text}`}</Text>
    </Row>
  );

  return (
    <Column sx={{ minWidth: noDBTModels ? "100%" : "200px", minHeight: 0, overflow: "auto" }}>
      {noDBTModels && (
        <Column sx={{ pt: 10, justifyContent: "center", alignItems: "center", width: "100%" }}>
          {gitSyncConfigData?.git_sync_config?.length ? (
            <>
              <Text sx={{ color: "red" }}>We could not find any dbt Models in your connected repository.</Text>
              <Text sx={{ color: "red" }}>
                Please check that your repository is configured correctly in the <Link to="/settings/git">settings</Link>.
              </Text>
            </>
          ) : (
            <>
              <Text sx={{ color: "red" }}>There is no git repository available to source dbt Models.</Text>
              <Text sx={{ color: "red" }}>
                Please connect a repository in the <Link to="/settings/git">settings</Link>.
              </Text>
            </>
          )}
        </Column>
      )}

      {available.map((model) => (
        <DBTRow
          key={model?.id}
          selected={model.id === dbtModelId}
          text={`${model.git_sync_config.schema}.${model?.alias || model?.name}`}
          onSelect={() => {
            onChange(model);
          }}
        />
      ))}

      {Boolean(omitted?.length) && (
        <Column sx={{ borderTop: "2px solid #D9DFFF" }}>
          <Row
            sx={{ mt: 2, cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              setShowingOmitted(!showingOmitted);
            }}
          >
            <Row sx={{ mx: 2 }}>{showingOmitted ? <ChevronDownIcon size={18} /> : <ChevronRightIcon size={18} />}</Row>
            <Text sx={{ color: "base.4", fontSize: 2, fontWeight: "semi" }}>Unavailable Models</Text>
          </Row>

          {showingOmitted &&
            omitted.map((model) => (
              <DBTRow
                key={model?.id}
                level={1}
                selected={model.id === dbtModelId}
                text={`${model.git_sync_config.schema}.${model?.alias || model?.name}`}
                onSelect={() => {
                  onChange(model);
                }}
              />
            ))}
        </Column>
      )}
    </Column>
  );
};
