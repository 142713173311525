import { VFC } from "react";

import { format } from "date-fns";
import { Text } from "theme-ui";

import { Row } from "src/ui/box";
import { Calendar, Props as CalendarProps } from "src/ui/calendar";
import { Popout } from "src/ui/popout";
import { TimeSelect } from "src/ui/time-select";

export type Props = { hideTime?: boolean } & CalendarProps;

export const DateTimeSelect: VFC<Readonly<Props>> = ({ hideTime, value, onChange }) => {
  return (
    <Row sx={{ alignItems: "center", flexShrink: 0 }}>
      <Popout
        content={({ close }) => (
          <Calendar
            value={value}
            onChange={(value) => {
              onChange(value);
              close();
            }}
          />
        )}
        contentSx={{
          bg: "white",
          borderRadius: 2,
          p: 2,
          border: "small",
        }}
        sx={{
          flexShrink: 0,
        }}
      >
        <Row
          sx={{
            alignItems: "center",
            fontSize: 1,
            height: "32px",
            borderRadius: 2,
            border: "small",
            bg: "white",
            p: 3,
            cursor: "pointer",
            whiteSpace: "nowrap",
          }}
        >
          {format(value, "PPP")}
        </Row>
      </Popout>
      {!hideTime && (
        <>
          <Text sx={{ mx: 2 }}>at</Text>
          <TimeSelect value={value} onChange={onChange} />
        </>
      )}
    </Row>
  );
};
