import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { SensitiveField } from "src/ui/sensitive-field";

const ConfigurationForm = ({ config, setConfig, error, hideSecret = false }) => {
  return (
    <>
      <Field label="Server Hostname">
        <Input
          placeholder="*.palantir.com"
          value={config?.host || ""}
          onChange={(value) => setConfig({ ...config, host: value })}
        />
      </Field>

      <SensitiveField
        hideSecret={hideSecret}
        label="Access Token"
        value={config?.token || ""}
        onChange={(token) => setConfig({ ...config, token })}
      />

      <Field label="Default Branch">
        <Input
          placeholder="default"
          value={config?.defaultBranch || ""}
          onChange={(value) => setConfig({ ...config, defaultBranch: value })}
        />
      </Field>

      <Field label="Otherwise, you can specify the connection string for JDBC with extra options">
        <Input
          placeholder="jdbc:foundrysql://<YOUR_URL>?password=<YOUR_TOKEN>&optionalParamOne=ABC&optionalParamOne=XYZ"
          value={config?.connectionString || ""}
          onChange={(value) => setConfig({ ...config, connectionString: value })}
        />
      </Field>

      <FieldError error={error} />
    </>
  );
};

export default {
  ConfigurationForm,
  isConfigurationComplete: (configuration: Record<string, unknown>): boolean => {
    return Boolean(configuration?.connectionString || (configuration?.host && configuration?.token));
  },
};
