import { VFC, useState } from "react";

import { Controller, NestedValue, useForm } from "react-hook-form";
import { Grid } from "theme-ui";

import { RelatedColumn, EventTraitColumn, AudienceParent, AdditionalColumn, EventCondition, TraitType } from "src/types/visual";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";

import { VStack } from "../explore/query-builder";
import { ConditionField } from "../explore/visual/condition";
import { TraitAggregationFields } from "./parent-traits";

export const AudienceEventTraitForm: VFC<
  Readonly<{
    title?: string;
    alias?: string;
    condition: EventCondition;
    parent: AudienceParent | undefined | null;
    type?: TraitType;
    config?: any;
    onClose: () => void;
    onSubmit: (value: AdditionalColumn) => Promise<void>;
  }>
> = (props) => {
  const { title, onSubmit, parent, onClose } = props;
  const relationships = parent?.relationships;
  const events = relationships?.filter(({ to_model: { event } }) => Boolean(event));

  const [submitting, setSubmitting] = useState(false);

  const { handleSubmit, control, register, watch } = useForm<{
    alias: string | undefined;
    condition: NestedValue<EventCondition>;
    type: TraitType | undefined;
    config: any;
  }>({
    defaultValues: {
      alias: props.alias,
      condition: props.condition,
      type: props.type,
      config: props.config,
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const type = watch("type");

  const condition = watch("condition");

  const propertyOptions = events
    ?.find(({ id }) => id === condition.relationshipId)
    ?.to_model?.filterable_audience_columns?.map(({ column_reference, name, alias }) => ({
      value: column_reference,
      label: alias || name,
    }));

  const submit = async ({ alias, type, config, condition }) => {
    setSubmitting(true);

    const eventTraitColumn: EventTraitColumn = {
      type: "event_trait",
      filteredEvent: condition,
      traitType: type,
      traitConfig: config,
    };

    const relatedColumn: RelatedColumn = {
      type: "related",
      path: [String(condition.relationshipId)],
      column: eventTraitColumn,
    };

    await onSubmit({ alias, column: relatedColumn });

    setSubmitting(false);

    onClose();
  };

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={submitting} onClick={handleSubmit(submit)}>
            {title ? "Save" : "Add"}
          </Button>
        </>
      }
      sx={{ maxWidth: "568px", width: "100%" }}
      title={title}
      onClose={onClose}
    >
      <Grid gap={8}>
        <Field label="Name">
          <Input {...register("alias")} />
        </Field>
        <Field label="Event">
          <VStack gap={2} sx={{ fontWeight: "bold", fontSize: 0 }}>
            <Controller
              control={control}
              name="condition"
              render={({ field }) => (
                <ConditionField
                  isEventTrait
                  audience={undefined}
                  columns={[]}
                  condition={field.value}
                  events={events}
                  parent={parent}
                  relationships={undefined}
                  traits={undefined}
                  onChange={(value) => {
                    field.onChange({ ...condition, ...value });
                  }}
                  onRemove={() => {}}
                />
              )}
            />
          </VStack>
        </Field>
        <TraitAggregationFields
          control={control}
          disabled={!condition.relationshipId}
          propertyOptions={propertyOptions}
          type={type as TraitType}
        />
      </Grid>
    </Modal>
  );
};
