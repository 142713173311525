import { FC } from "react";

import { Image, ThemeUIStyleObject } from "theme-ui";

import BrandSrc from "./logo-white.svg";

export interface BrandProps {
  size?: string;
  sx?: ThemeUIStyleObject;
}

export const Brand: FC<Readonly<BrandProps>> = ({ size = "52px", sx = {} }) => (
  <Image src={BrandSrc} sx={{ width: size, ...sx }} />
);
