import { VFC, useState } from "react";

import { Navigate, useParams } from "react-router-dom";
import { Text, Image, Grid } from "theme-ui";

import {
  useAvailableWorkspacesQuery,
  useJoinWorkspaceWithAutoJoinMutation,
  usePartnerConnectGetConnectionQuery,
  useWorkspacesQuery,
} from "src/graphql";
import { Fade } from "src/ui/animations";
import { Row } from "src/ui/box";
import { Brand } from "src/ui/brand";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Checkbox } from "src/ui/checkbox";
import { CheckCircleIcon, PlusIcon } from "src/ui/icons";
import { useNavigate } from "src/utils/navigate";
import { switchWorkspace } from "src/utils/workspaces";

const PartnerConnect: VFC = () => {
  const navigate = useNavigate();
  const params = useParams<{ uuid: string }>();
  const uuid = params.uuid ?? "";

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [hasAcceptedSharing, setHasAcceptedSharing] = useState(false);

  const { data: partnerConnection, error: connectionError } = usePartnerConnectGetConnectionQuery(
    { uuid },
    {
      select: (data) => data.partnerConnectGetConnection,
      enabled: Boolean(uuid),
      cacheTime: 0,
      suspense: true,
      useErrorBoundary: false,
    },
  );

  const { data: workspaces } = useWorkspacesQuery(undefined, {
    select: (data) => data.workspaces,
    cacheTime: 0,
    suspense: true,
  });
  const { data: joinableWorkspaces } = useAvailableWorkspacesQuery(undefined, {
    select: (data) => data.getAvailableWorkspaces.joinable,
    suspense: true,
    cacheTime: 0,
  });
  const { mutateAsync: joinWithAutojoin, isLoading: joiningWorkspace } = useJoinWorkspaceWithAutoJoinMutation();

  const linkedWorkspace = workspaces?.find((w) => w?.id === partnerConnection?.workspaceId);
  const joinableLinkedWorkspace = joinableWorkspaces?.find(({ id }) => id === partnerConnection?.workspaceId);

  if (connectionError) {
    return <Navigate state={{ error: connectionError?.message }} to="/unauthorized" />;
  }

  if (partnerConnection?.workspaceId) {
    if (!linkedWorkspace && !joinableLinkedWorkspace) {
      return (
        <Navigate
          state={{
            partnerConnection,
            error: `You are not authorized to access the workspace ${partnerConnection.partnerName} is connected to.`,
          }}
          to="/unauthorized"
        />
      );
    }

    return (
      <Card sx={{ maxWidth: "550px", width: "100%", mx: "auto" }}>
        <Fade sx={{ width: "100%" }}>
          <Text sx={{ fontSize: 7, fontWeight: "bold", textAlign: "center" }}>{partnerConnection.partnerName} connected!</Text>

          <Text sx={{ color: "base.7", my: 10, textAlign: "center" }}>
            <strong>"{(linkedWorkspace || joinableLinkedWorkspace)?.name}"</strong> is connected to{" "}
            {partnerConnection.partnerName}
          </Text>

          <Row sx={{ alignItems: "center", justifyContent: "center", mb: 16 }}>
            <Brand size="56px" />
            <Row sx={{ height: "3px", width: "30px", bg: "base.3" }} />
            <CheckCircleIcon color="green" size={32} />
            <Row sx={{ height: "3px", width: "30px", bg: "base.3" }} />
            <Row
              sx={{
                alignItems: "center",
                justifyContent: "center",
                bg: "white",
                border: "small",
                borderRadius: "8px",
                p: 3,
                height: "56px",
              }}
            >
              <Image alt={partnerConnection.partnerName} src={partnerConnection.partnerLogo} sx={{ height: "100%" }} />
            </Row>
          </Row>

          <Button
            label="Go to workspace"
            loading={joiningWorkspace}
            size="large"
            sx={{ width: "100%" }}
            onClick={async () => {
              if (joinableLinkedWorkspace) {
                await joinWithAutojoin({ workspaceId: String(joinableLinkedWorkspace.id) });
              }

              if (partnerConnection.workspaceId) {
                switchWorkspace(partnerConnection.workspaceId, `/${(linkedWorkspace || joinableLinkedWorkspace)?.slug}`);
              }
            }}
          />
        </Fade>
      </Card>
    );
  }

  if (!partnerConnection) {
    return null;
  }

  return (
    <Card sx={{ maxWidth: "550px", width: "100%", mx: "auto" }}>
      <Fade sx={{ width: "100%" }}>
        <PartnerConnectLogo logo={partnerConnection.partnerLogo} name={partnerConnection.partnerName} />
        <Text sx={{ fontSize: 7, fontWeight: "bold", textAlign: "center", mb: 10 }}>
          Connect {partnerConnection.partnerName}
        </Text>

        <Grid gap={2}>
          <Checkbox
            description={
              <>
                I agree to Hightouch's{" "}
                <a href={`${import.meta.env.VITE_WEBSITE_URL}/terms-of-service/`} rel="noreferrer" target="_blank">
                  terms of service
                </a>{" "}
                related to its usage in conjunction with {partnerConnection.partnerName}.
              </>
            }
            label="Terms of service"
            size="large"
            value={hasAcceptedTerms}
            onChange={setHasAcceptedTerms}
          />
          <Checkbox
            description={`I acknowledge that Hightouch will share data on its usage with ${partnerConnection.partnerName}`}
            label="Usage sharing"
            size="large"
            value={hasAcceptedSharing}
            onChange={setHasAcceptedSharing}
          />
        </Grid>
        <Button
          disabled={!(hasAcceptedSharing && hasAcceptedTerms)}
          label="Continue"
          size="large"
          sx={{ mt: 8, width: "100%" }}
          onClick={() => {
            const workspaceCount = workspaces?.length ?? 0;
            navigate(workspaceCount > 0 ? "/workspaces" : "/workspaces/new", { slug: false, state: { partnerConnection } });
          }}
        />
      </Fade>
    </Card>
  );
};

export default PartnerConnect;

export const PartnerConnectLogo: VFC<Readonly<{ name: string; logo: string }>> = ({ name, logo }) => (
  <Row sx={{ alignItems: "center", justifyContent: "center", mb: 6 }}>
    <Brand size="56px" />
    <PlusIcon color="base.4" size={32} sx={{ mx: 4 }} />
    <Row
      sx={{
        alignItems: "center",
        justifyContent: "center",
        bg: "white",
        border: "small",
        borderRadius: "8px",
        p: 3,
        height: "56px",
      }}
    >
      <Image alt={name} src={logo} sx={{ height: "100%" }} />
    </Row>
  </Row>
);
