import { VFC } from "react";

import moment from "moment";
import pluralize from "pluralize";
import { Flex, Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { Box } from "src/ui/box";
import { ExternalLinkIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";
import { Table } from "src/ui/table";

const placeholder = {
  title: "No Workspaces Data",
  error: "Usage failed to load, please try again.",
};

export interface WorkspaceMetrics {
  id: string;
  name: string;
  created_at: string;
  destinations?: number | null;
  connections?: number | null;
  models?: number | null;
  audiences?: number | null;
  syncs?: number | null;
  roles?: {
    name: string;
    count?: number | null;
    workspace_id: string;
  }[];
}

interface Props {
  metrics: WorkspaceMetrics[];
  isLoading: boolean;
}

export const WorkspaceMetricsTable: VFC<Props> = ({ metrics, isLoading }) => {
  const { workspace } = useUser();
  const usageColumns = [
    {
      name: "Name",
      cell: ({ name, slug }) => {
        return workspace?.slug === slug ? (
          <>
            {name} <Text sx={{ display: "inline", color: "dark.2" }}>(this workspace)</Text>
          </>
        ) : (
          <Flex sx={{ alignItems: "center", ":hover a div": { visibility: "visible" } }}>
            <Text>{name}</Text>
            <Link newTab slug={false} to={`/${slug || name}`}>
              <Box sx={{ pl: 2, visibility: "hidden" }}>
                <ExternalLinkIcon size={14} />
              </Box>
            </Link>
          </Flex>
        );
      },
    },
    {
      name: "Created",
      cell: ({ created_at }) => {
        return moment(parseInt(created_at)).fromNow();
      },
    },
    {
      name: "Members",
      cell: ({ roles }) => {
        return roles.map((r) => `${r.count} ${pluralize(r.name, r.count)}`).join(", ");
      },
    },
    {
      name: "Destinations",
      cell: ({ destinations }) => {
        return destinations || 0;
      },
    },
    {
      name: "Syncs",
      cell: ({ syncs }) => {
        return syncs || 0;
      },
    },
  ];

  return (
    <Box>
      {isLoading ? (
        <Flex sx={{ flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "space-between", p: 6 }}>
          <Spinner size={64} />
        </Flex>
      ) : (
        <Table columns={usageColumns} data={metrics} placeholder={placeholder} rowHeight={55} />
      )}
    </Box>
  );
};
