import { VFC } from "react";

import { Row } from "src/ui/box";
import { LabelIcon } from "src/ui/icons";
import { Popout } from "src/ui/popout";

import { Label, labelStyle } from "./label";
import { Labels } from "./labels";

type Props = {
  labels: Record<string, string>;
};

export const LabelsCell: VFC<Readonly<Props>> = ({ labels = {} }) => {
  const entries = Object.entries(labels).sort(([a], [b]) => a.localeCompare(b));

  if (!entries.length) {
    return null;
  }

  const visible = entries.slice(0, 3);
  const collapsed = entries.slice(3, entries.length);

  return (
    <>
      <Row sx={{ alignItems: "center", display: ["none", "none", "none", "flex"] }}>
        {visible.map(([key, value]) => (
          <Label key={key} label={{ key, value }} sx={{ ml: 2 }} />
        ))}
        {collapsed.length > 0 && (
          <Popout
            content={
              <Labels
                labels={collapsed.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})}
                sx={{ maxWidth: "200px" }}
              />
            }
            contentSx={{ p: 2 }}
            offset={4}
            sx={{ ml: 2 }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Row sx={{ ...labelStyle, fontWeight: "bold" }}>+ {collapsed.length}</Row>
          </Popout>
        )}
      </Row>
      <Popout
        content={<Labels labels={labels} sx={{ maxWidth: "200px" }} />}
        contentSx={{ p: 2 }}
        offset={4}
        sx={{ display: ["flex", "flex", "flex", "none"], ml: 2, p: 2 }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Row sx={{ ":hover": { svg: { fill: "primary" } } }}>
          <LabelIcon color={null} size={16} />
        </Row>
      </Popout>
    </>
  );
};
