import { VFC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Row } from "src/ui/box";

import { Label } from "./label";

type Props = {
  labels: Record<string, string>;
  sx?: ThemeUIStyleObject;
};

export const Labels: VFC<Readonly<Props>> = ({ labels = {}, sx = {} }) => {
  const entries = Object.entries(labels).sort(([a], [b]) => a.localeCompare(b));

  return (
    <Row sx={{ flexWrap: "wrap", m: -1, ...sx }}>
      {entries.map(([key, value]) => (
        <Label key={key} label={{ key, value }} sx={{ m: 1 }} />
      ))}
    </Row>
  );
};
