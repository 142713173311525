import { VFC, useState, useMemo, useEffect } from "react";

import { orderBy } from "lodash";
import { useParams } from "react-router-dom";
import { Text, Grid, ThemeUIStyleObject } from "theme-ui";
import { isPresent } from "ts-extras";
import { useClipboard } from "use-clipboard-copy";

import { Page } from "src/components/layout";
import {
  useAttemptedRowsByBatchIdQuery,
  useAttemptedRowsByPrimaryKeyQuery,
  useBatchRequestInfoQuery,
  useSyncAttemptQuery,
  useRequestInfoSetQuery,
} from "src/graphql";
import { Badge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Editor } from "src/ui/editor";
import { CheckIcon, CopyIcon, MaximizeIcon, MinimizeIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading/loading";
import { Modal } from "src/ui/modal";
import { SimplePagination } from "src/ui/table";
import { Placeholder } from "src/ui/table/placeholder";
import { Table, TableColumn } from "src/ui/table/table";
import { useDestination } from "src/utils/destinations";
import { processRequestInfo, DEPRECATED_ERROR } from "src/utils/syncs";

import { getOpTypeBadge } from "./run";

export const RunDebug: VFC = () => {
  const { run: runId, sync: syncId, row: rowId } = useParams<{ run: string; sync: string; row: string }>();

  const [selectedRequestIndex, setSelectedRequestIndex] = useState<number>(0);
  const [runError, setRunError] = useState<string | undefined | null>();
  const [page, setPage] = useState<number>(0);
  const [pageKeys, setPageKeys] = useState<string[]>([]);
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [previousLoading, setPreviousLoading] = useState<boolean>(false);

  const { data: attemptData, isLoading: attemptLoading } = useSyncAttemptQuery({
    syncRequestId: runId ?? "",
  });

  const attempt = attemptData?.sync_attempts?.[0];
  const run = attempt?.sync_request;
  const primaryKey = run?.sync?.segment?.primary_key;

  const {
    data: { definition },
  } = useDestination(run?.sync?.destination?.id, {
    pause: !run?.sync?.destination,
  });

  const { data: selectedRowData, isLoading: selectedRowLoading } = useAttemptedRowsByPrimaryKeyQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      id: rowId ?? "",
      alreadyHashed: true,
    },
    { enabled: Boolean(rowId) },
  );

  const selectedRow = selectedRowData?.getAttemptedRowsByPrimaryKey?.rows?.[0];

  const { data: batchData, isFetching: loading } = useAttemptedRowsByBatchIdQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      id: selectedRow?.batchId ?? "",
      pageKey: pageKeys.slice(-1)[0],
    },
    { enabled: Boolean(selectedRow), keepPreviousData: true },
  );

  const batch = batchData?.getAttemptedRowsByBatchId?.rows;

  const { data: batchRequestInfoData, isLoading: batchRequestInfoLoading } = useBatchRequestInfoQuery(
    {
      batchRequestInfoKey: selectedRow?.batchRequestInfoKey ?? "",
    },
    { enabled: Boolean(selectedRow && selectedRow?.batchRequestInfoKey) },
  );

  const requestInfoKeys = useMemo<string[] | undefined>(() => {
    return selectedRow?.requestInfoKeys?.filter(isPresent);
  }, [selectedRow]);

  const { data: requestInfoSetData, isLoading: requestInfoSetLoading } = useRequestInfoSetQuery(
    {
      requestInfoKeys: requestInfoKeys ?? [],
    },
    { enabled: Boolean(requestInfoKeys?.length) },
  );

  const requestsData = requestInfoSetData?.getRequestInfoSet ?? batchRequestInfoData?.getBatchRequestInfo?.requests;
  const requests = useMemo(
    () =>
      requestsData
        ?.map((request) => processRequestInfo(request, definition))
        ?.map((request, index) => ({ ...request, id: index }))
        ?.filter((request) => request.method !== "Contact.bulkload"),
    [requestsData],
  );

  const rows = useMemo(() => {
    if (selectedRow && batch) {
      return [selectedRow, ...batch.filter(isPresent).filter(({ id }) => id !== rowId)].map(
        ({ id, opType, rejectionReason, fields, batchId, batchRequestInfoKey, isBatchError }) => ({
          hightouchRowId: id,
          opType,
          rejectionReason,
          batchId,
          batchRequestInfoKey,
          isBatchError,
          fields,
          ...JSON.parse(fields),
        }),
      );
    }

    return undefined;
  }, [selectedRow, batch]);

  const selectedRequest = requests?.[selectedRequestIndex];

  const relatedRowColumns: TableColumn[] = useMemo(() => {
    let columns: TableColumn[] = [];

    try {
      columns = orderBy(
        Object.keys(JSON.parse(selectedRow?.fields ?? "{}")).map((key, i) => ({
          key,
          name: key,
          cell: (value) => <>{String(value)}</>,
          divider: i === 0,
        })),
        (column) => column.key !== primaryKey,
      );
    } catch (error) {
      // Fields not valid JSON?
    }

    return columns;
  }, [selectedRow]);

  useEffect(() => {
    if (nextLoading) {
      setPage((page) => page + 1);
      setNextLoading(false);
    }
    if (previousLoading) {
      setPage((page) => page - 1);
      setPreviousLoading(false);
    }
  }, [batch, setPage, setPreviousLoading, setNextLoading]);

  if (batchRequestInfoLoading || requestInfoSetLoading || attemptLoading || selectedRowLoading) {
    return <PageSpinner />;
  }

  if (!requests || requests?.length === 0) {
    return (
      <Page
        crumbs={[
          { label: "Syncs", link: "/syncs" },
          { label: "Sync", link: `/syncs/${syncId}` },
          {
            label: "Run",
            link: `/syncs/${syncId}/runs/${runId}`,
          },
          {
            label: "Run Debugger",
          },
        ]}
        size="small"
      >
        <Placeholder content={{ title: "No requests were found" }} error={false} />
      </Page>
    );
  }

  return (
    <>
      <Page
        crumbs={[
          { label: "Syncs", link: "/syncs" },
          { label: "Sync", link: `/syncs/${syncId}` },
          {
            label: "Run",
            link: `/syncs/${syncId}/runs/${runId}`,
          },
          {
            label: "Run Debugger",
          },
        ]}
        size="xlarge"
      >
        {attempt?.error && ![DEPRECATED_ERROR, "Error: " + DEPRECATED_ERROR].includes(attempt?.error) && (
          <Button sx={{ mb: 4 }} variant="secondary" onClick={() => setRunError(attempt?.error)}>
            View error
          </Button>
        )}
        <Grid columns={2} gap={8} sx={{ gridAutoRows: "1fr", width: "100%" }}>
          <Requests requests={requests} selection={selectedRequestIndex} onSelect={setSelectedRequestIndex} />

          <Data
            body={selectedRequest?.requestBody}
            destinationName={definition?.name}
            isJSON={selectedRequest?.requestIsJson}
            isXML={selectedRequest?.requestIsXml}
            sx={{ gridColumn: 2 }}
            timestamp={selectedRequest?.meta?.invokedTimestamp}
            title="Request"
          />
          <Data
            body={selectedRequest?.responseBody}
            destinationName={definition?.name}
            isJSON={selectedRequest?.responseIsJson}
            isXML={selectedRequest?.responseIsXml}
            sx={{ gridColumn: 2 }}
            timestamp={selectedRequest?.meta?.finishedTimestamp}
            title="Response"
          />
        </Grid>

        <Column sx={{ width: "100%", mt: 4 }}>
          <Row sx={{ mb: 4 }}>
            <Text sx={{ fontSize: 2, fontWeight: "semi", mr: 4 }}>Batch rows</Text>
            {selectedRow?.opType && getOpTypeBadge(selectedRow?.opType)}
          </Row>
          <Table
            columns={relatedRowColumns}
            data={rows}
            highlight={rowId}
            loading={loading}
            placeholder={{
              title: `No related rows`,
            }}
            primaryKey="hightouchRowId"
          />
          <SimplePagination
            nextLoading={nextLoading}
            page={page}
            previousLoading={previousLoading}
            onNext={() => {
              if (batchData && batchData?.getAttemptedRowsByBatchId?.nextPageKey == null) {
                // We've made a request and the server said there are no more rows.
                return;
              }
              setNextLoading(true);
              setPageKeys((pageKeys) => [...pageKeys, batchData?.getAttemptedRowsByBatchId?.nextPageKey].filter(isPresent));
            }}
            onPrevious={() => {
              setPreviousLoading(true);
              setPageKeys((pageKeys) => {
                if (page === 1) {
                  return [];
                } else {
                  return pageKeys.slice(0, -1);
                }
              });
            }}
          />
        </Column>
      </Page>

      <Modal info isOpen={Boolean(runError)} title="Error" onClose={() => setRunError("")}>
        <Text sx={{ maxWidth: "800px" }}>{runError}</Text>
      </Modal>
    </>
  );
};

const requestsColumns = [
  {
    key: "status",
    name: "Status",
    cell: (status) => <Badge variant={status.match(/E[Rr][Rr]/) ? "red" : "green"}>{status}</Badge>,
  },
  {
    name: "Method",
    key: "method",
    cell: (method) => <Text sx={{ fontFamily: "monospace" }}>{method}</Text>,
  },
  {
    name: "Destination",
    key: "destination",
    cell: (destination) => <Text sx={{ fontFamily: "monospace" }}>{destination}</Text>,
  },
];

const Requests = ({ requests, selection, onSelect }) => {
  return (
    <Column sx={{ gridRow: "1 / span 2", height: "60vh" }}>
      <Text sx={{ fontSize: 3, fontWeight: "semi", width: "100%", pb: 4 }}>Requests</Text>
      <Table columns={requestsColumns} data={requests} highlight={selection} onRowClick={(row: any) => onSelect(row.id)} />
    </Column>
  );
};

const Data: VFC<
  Readonly<{
    title: string;
    body?: string;
    timestamp?: string;
    destinationName?: string;
    isJSON?: boolean;
    isXML?: boolean;
    sx?: ThemeUIStyleObject;
  }>
> = ({ title, body, timestamp, destinationName, isJSON, isXML, sx }) => {
  const clipboard = useClipboard({
    copiedTimeout: 600,
  });
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  const copyBody = () => {
    clipboard.copy(body);
  };

  return (
    <>
      <Column sx={sx}>
        <Row
          sx={{
            pb: 2,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Row sx={{ alignItems: "center", mr: 2 }}>
            <Text
              sx={{
                fontWeight: "semi",
                fontSize: 2,
              }}
            >
              {title}
            </Text>
            {timestamp && <Text sx={{ display: "inline", color: "base.5", fontSize: 0, ml: 2 }}>({timestamp})</Text>}
          </Row>

          {body && (
            <Row gap={2}>
              <Button variant="icon" onClick={copyBody}>
                {clipboard.copied ? <CheckIcon color="green" size={12} /> : <CopyIcon size={12} />}
              </Button>
              {fullscreen ? (
                <Button variant="icon" onClick={() => setFullscreen(false)}>
                  <MinimizeIcon size={12} />
                </Button>
              ) : (
                <Button variant="icon" onClick={() => setFullscreen(true)}>
                  <MaximizeIcon size={12} />
                </Button>
              )}
            </Row>
          )}
        </Row>
        <Column sx={{ border: body ? undefined : "small", flex: 1 }}>
          {body ? (
            <Editor
              code={body}
              language={isJSON ? "json" : isXML ? "xml" : "text"}
              minLines={10}
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          ) : (
            <Text sx={{ p: 4 }}>
              No {title.toLowerCase()}
              {destinationName ? ` from ${destinationName}` : ""}
            </Text>
          )}
        </Column>
      </Column>
      <Modal
        bodySx={{ p: 0 }}
        footer={
          <>
            <Button variant="secondary" onClick={copyBody}>
              {clipboard?.copied ? "Copied" : "Copy"}
            </Button>
            <Button variant="secondary" onClick={() => setFullscreen(false)}>
              Close
            </Button>
          </>
        }
        isOpen={fullscreen}
        sx={{ width: "100%", height: "100%" }}
        title={title}
        onClose={() => setFullscreen(false)}
      >
        <Column sx={{ width: "100%", height: "100%" }}>
          <Editor
            code={body ?? ""}
            language={isJSON ? "json" : isXML ? "xml" : "text"}
            sx={{
              flex: 1,
              height: "100%",
              width: "100%",
            }}
          />
        </Column>
      </Modal>
    </>
  );
};
