import { VFC, ReactNode, MouseEventHandler, useMemo } from "react";

import { ThemeUIStyleObject, Text } from "theme-ui";

import { Row, Column, Box } from "src/ui/box";
import { Link } from "src/ui/link";
import { Popout } from "src/ui/popout";

import { DotsIcon } from "../icons";
import { IconProps } from "../icons/icon";
import { Placement } from "../popout/popout";

export type MenuOption = {
  label?: ReactNode;
  icon?: VFC<IconProps>;
  onClick?: () => void;
  sx?: ThemeUIStyleObject;
  divider?: boolean;
  disabled?: boolean;
  variant?: string;
  title?: string;
  description?: string;
  link?: string;
  newTab?: boolean;
  render?: VFC;
};

export interface MenuProps {
  disabled?: boolean;
  options: MenuOption[];
  children?: ReactNode;
  sx?: ThemeUIStyleObject;
  width?: string;
  footer?: ReactNode;
  header?: ReactNode;
  placement?: Placement;
  offset?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  portal?: boolean;
  strategy?: "absolute" | "fixed";
}

export const Menu: VFC<Readonly<MenuProps>> = ({
  children,
  disabled = false,
  options,
  footer,
  header,
  width,
  placement = "bottom-start",
  offset,
  sx = {},
  onClick,
  portal,
  strategy = "absolute",
}) => {
  const style = useMemo(
    () => ({
      minWidth: "112px",
      backgroundColor: "white",
      p: 1,
      width: width,
    }),
    [width],
  );

  return (
    <Popout
      content={({ close }) => (
        <>
          {header && <Row sx={{ p: 2 }}>{header}</Row>}
          {options.map((option, i) => (
            <Option
              key={i}
              {...option}
              onClick={() => {
                close();
                if (option.onClick) {
                  option.onClick();
                }
              }}
            />
          ))}
          {footer && <Row sx={{ p: 2 }}>{footer}</Row>}
        </>
      )}
      contentSx={style}
      disabled={disabled}
      offset={offset}
      placement={placement}
      portal={portal}
      strategy={strategy}
      sx={sx}
      onClick={onClick}
    >
      {({ isOpen }) => {
        return children || <DotsIcon size={16} sx={{ color: isOpen ? "primary" : "base.4", p: 1 }} />;
      }}
    </Popout>
  );
};

const Option: VFC<Readonly<MenuOption>> = ({
  variant,
  sx = {},
  title,
  divider,
  label,
  disabled,
  icon: Icon,
  onClick,
  description,
  newTab,
  link,
  render,
}) => {
  const element = (
    <Row
      sx={{
        alignItems: "center",
        p: 2,
        borderRadius: 1,
        color: variant === "danger" ? "red" : "black",
        cursor: disabled ? "not-allowed" : "pointer",
        transition: "100ms color background-color",
        svg: {
          transition: "100ms fill",
        },
        ":hover": {
          bg: disabled ? undefined : "primaries.0",
          color: "black",
          svg: {
            fill: "primaries.10",
          },
        },
        ...sx,
      }}
      onClick={() => {
        if (link && !newTab) {
          window.location.href = link;
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      {render ? (
        render({})
      ) : (
        <>
          {divider ? <Box sx={{ borderTop: "small" }} /> : null}
          {Icon && <Icon color="primaries.7" size={description ? 30 : 20} sx={{ mr: description ? 3 : "6px" }} />}
          <Column>
            <Text
              sx={{
                fontWeight: description ? "bold" : "semi",
                color: disabled ? "base.3" : variant === "danger" ? "red" : description ? "black" : "inherit",
              }}
            >
              {label}
            </Text>
            {description && <Text sx={{ color: "base.7" }}>{description}</Text>}
          </Column>
        </>
      )}
    </Row>
  );

  return (
    <>
      {divider ? <Box sx={{ borderTop: "small", my: 2 }} /> : null}
      {title ? (
        <Text sx={{ px: 2, py: "6px", fontSize: 0, fontWeight: "bold", textTransform: "uppercase", color: "dark.0" }}>
          {title}
        </Text>
      ) : null}
      {newTab ? (
        <Link newTab to={link}>
          {element}
        </Link>
      ) : (
        element
      )}
    </>
  );
};
