import { VFC, useState, useEffect, useMemo } from "react";

import { Flex, Text, Grid } from "theme-ui";

import { Page } from "src/components/layout";
import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";
import { Heading } from "src/ui/heading";
import { SyncIcon, ModelIcon, DestinationIcon, SourceIcon, CheckIcon, ChevronRightIcon } from "src/ui/icons";
import { useNavigate } from "src/utils/navigate";

export const OnboardingPage: VFC = () => {
  const { user, onboarding } = useUser();
  const navigate = useNavigate();
  const usingDemoSource = useMemo(() => window.localStorage.getItem("useDemoSource"), []);

  useEffect(() => {
    analytics.track("Onboarding Started", {
      source: Boolean(onboarding?.source),
      destination: Boolean(onboarding?.destination),
      model: Boolean(onboarding?.model),
      sync: Boolean(onboarding?.sync),
    });
  }, [Boolean(onboarding?.source), Boolean(onboarding?.destination), Boolean(onboarding?.model), Boolean(onboarding?.sync)]);

  if (!onboarding) {
    navigate("/", { replace: true });
    return null;
  }

  return (
    <>
      <Page crumbs={[{ label: "Onboarding" }]} sx={{ alignItems: "center" }}>
        <Heading sx={{ mt: 8 }} variant="h1">
          Welcome to Hightouch, {user?.name}!
        </Heading>
        <Text sx={{ mt: 4, textAlign: "center", color: "base.4" }}>
          You’re only a few steps away from arming your business teams with customer data.
        </Text>

        <Grid gap={8} sx={{ mt: 8, width: "100%", justifyItems: "center", maxWidth: "800px" }}>
          <Step
            complete={Boolean(onboarding.source) || usingDemoSource}
            description="Start by connecting Hightouch to a source like Snowflake, Postgres, Google Sheets, and many more."
            icon={SourceIcon}
            title="Create Source"
            onClick={() => navigate("/sources/new")}
          />
          <Step
            complete={Boolean(onboarding.destination)}
            description="Connect Hightouch to your favorite tools like Salesforce, HubSpot, Facebook Ads, and many more."
            icon={DestinationIcon}
            title="Create Destination"
            onClick={() => navigate("/destinations/new")}
          />
          <Step
            complete={Boolean(onboarding.model)}
            description="Use a model to build a specific view of the data in your source."
            disabled={!onboarding.source && !usingDemoSource}
            icon={ModelIcon}
            title="Create Model"
            onClick={() => navigate(`/models/new?source=${onboarding.source?.id}`)}
          />
          <Step
            complete={Boolean(onboarding.sync)}
            description="Operationalize your data by syncing a model to a destination."
            disabled={!onboarding.model || !onboarding.destination || (!onboarding.source && !usingDemoSource)}
            icon={SyncIcon}
            title="Create Sync"
            onClick={() => navigate(`/syncs/new?model=${onboarding?.model?.id}&destination=${onboarding?.destination?.id}`)}
          />
        </Grid>
      </Page>
    </>
  );
};

const Step = ({ title, description, complete, icon, onClick, disabled = false }) => {
  const [hover, setHover] = useState<boolean>(false);

  const locked = disabled || complete;
  disabled || complete;
  disabled || complete;

  return (
    <Flex
      sx={{
        border: "small",
        borderRadius: 1,
        width: "100%",
        transition: "100ms border-color",
        borderColor: complete ? "greens.1" : undefined,
        ":hover": locked
          ? undefined
          : {
              borderColor: "primary",
            },
      }}
    >
      <Flex
        sx={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          p: 4,
          bg: disabled ? "white" : complete ? "greens.0" : "grays.3",
          opacity: disabled ? 0.3 : 1,
          cursor: locked ? "default" : "pointer",
        }}
        onClick={locked ? undefined : onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Flex sx={{ alignItems: "center" }}>
          <StepIcon check={complete} icon={icon} />
          <Flex sx={{ ml: 4, flexDirection: "column" }}>
            <Heading variant="h3">{title}</Heading>
            <Text sx={{ mt: 1 }} variant="subtle">
              {description}
            </Text>
          </Flex>
        </Flex>
        {!locked && (
          <ChevronRightIcon
            color={hover ? (complete ? "green" : "primary") : "base.3"}
            size={24}
            sx={{ transition: "100ms all", flexShrink: 0 }}
          />
        )}
      </Flex>
    </Flex>
  );
};

const StepIcon = ({ check, icon: Icon }) =>
  check ? <CheckIcon color="green" size={20} /> : <Icon color="primary" size={24} />;
