import { VFC } from "react";

import { IconProps, Icon } from "./icon";

export const ChevronDownIcon: VFC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M7.41 8.59L12 13.17L16.59 8.59L18 10L12 16L6 10L7.41 8.59Z" />
    </Icon>
  );
};
