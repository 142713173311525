import { VFC, useEffect, useMemo, useState } from "react";

import { orderBy } from "lodash";
import { useToasts } from "react-toast-notifications";
import { Grid, Text, Flex } from "theme-ui";

import { RepositorySelector } from "src/components/git/repository-selector";
import { Settings } from "src/components/settings";
import { useUser } from "src/contexts/user-context";
import {
  useCreateGitSyncConfigsMutation,
  useGitSyncConfigsQuery,
  useUpdateGitSyncConfigsMutation,
  GitSyncConfigsQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Badge } from "src/ui/badge";
import { Column, Container, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Label } from "src/ui/label";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { Modal } from "src/ui/modal";
import { Section } from "src/ui/section";
import { Table } from "src/ui/table";
import { Toggle } from "src/ui/toggle";
import { formatDatetime } from "src/utils/time";

type GitSyncConfigProps = {
  data: GitSyncConfigsQuery | undefined;
  loading: boolean;
};

export const GitSyncConfig: VFC<Readonly<GitSyncConfigProps>> = ({ data, loading }) => {
  const { addToast } = useToasts();
  const { workspace } = useUser();

  const [gitSyncEnabled, setGitSyncEnabled] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [gitCredentialId, setGitCredentialId] = useState<string | undefined>();
  const [path, setPath] = useState<string | undefined | null>();
  const [branch, setBranch] = useState<string | undefined>();
  const [repository, setRepository] = useState<string | undefined>();

  const { mutateAsync: updateGitSyncConfig } = useUpdateGitSyncConfigsMutation();
  const { mutateAsync: createGitSyncConfig } = useCreateGitSyncConfigsMutation();

  const gitSyncConfig = data?.git_sync_configs?.[0];

  useEffect(() => {
    let enabled = false;

    if (gitSyncConfig) {
      enabled = gitSyncConfig.enabled || false;
      setGitCredentialId(gitSyncConfig?.git_credential_id);
      setPath(gitSyncConfig?.path);
      setBranch(gitSyncConfig?.branch);
      setRepository(gitSyncConfig?.repository);
    }
    setGitSyncEnabled(enabled);
  }, [gitSyncConfig]);

  // TODO: add visibility for outbound / inbound runs

  const save = async () => {
    setSaveLoading(true);

    const configObject = {
      repository,
      branch,
      path,
      git_credential_id: gitCredentialId,
      enabled: gitSyncEnabled,
    };

    try {
      if (gitSyncConfig) {
        await updateGitSyncConfig({
          id: gitSyncConfig.id,
          object: configObject,
        });
      } else {
        await createGitSyncConfig({
          object: configObject,
        });
      }

      if (!gitSyncConfig?.enabled && gitSyncEnabled) {
        analytics.track("Git Sync Enabled", {
          workspace_id: workspace?.id,
        });
      }

      if (gitSyncConfig?.enabled && !gitSyncEnabled) {
        analytics.track("Git Sync Disabled", {
          workspace_id: workspace?.id,
        });
      }

      addToast("Your git sync settings for this source has been saved.", {
        appearance: "success",
      });
    } catch (err) {
      analytics.track("Git Sync Setup Error", {
        workspace_id: workspace?.id,
      });
      throw err;
    } finally {
      setSaveLoading(false);
    }
  };

  const dirty =
    gitCredentialId !== gitSyncConfig?.git_credential_id ||
    path !== gitSyncConfig?.path ||
    branch !== gitSyncConfig?.branch ||
    repository !== gitSyncConfig?.repository ||
    gitSyncEnabled !== gitSyncConfig?.enabled;

  const complete = gitCredentialId && branch && repository;

  return (
    <Section
      divider
      footer={
        <Flex sx={{ justifyContent: "flex-end" }}>
          <Button disabled={!dirty || !complete} label="Save" loading={saveLoading} onClick={save} />
        </Flex>
      }
    >
      <Container center={false} size="small">
        <Field label="Git Sync Configuration" size="large">
          {loading ? (
            <Spinner />
          ) : (
            <Grid gap={8}>
              <Field label="Enabled">
                <Row>
                  <Toggle sx={{ mr: 4 }} value={gitSyncEnabled} onChange={setGitSyncEnabled} />
                </Row>
              </Field>

              <>
                <RepositorySelector
                  branch={branch}
                  gitCredentialId={gitCredentialId}
                  repository={repository}
                  setBranch={setBranch}
                  setGitCredentialId={setGitCredentialId}
                  setRepository={setRepository}
                />

                <Field optional description="Specify a custom path to look for the sync and model folders." label="Path">
                  <Input placeholder="./hightouch" value={path} onChange={setPath} />
                </Field>
              </>
            </Grid>
          )}
        </Field>
      </Container>
    </Section>
  );
};

const StatusBadge = ({ setup, lastAttemptedAt, error }: { setup: boolean; lastAttemptedAt: string; error: any }) => {
  return (
    <Badge sx={{ alignItems: "center" }} variant="base">
      {!setup || lastAttemptedAt ? (
        <Circle color={lastAttemptedAt ? (error ? (error?.temp ? "yellow" : "red") : "green") : "gray"} radius="12px" />
      ) : (
        <Spinner size={18} />
      )}
      <Text sx={{ ml: 2 }}>
        {lastAttemptedAt ? formatDatetime(lastAttemptedAt) : setup ? "Waiting to be synced" : "Not synced"}
      </Text>
    </Badge>
  );
};

const ErrorBlock = ({ error }: { error: any }) => {
  if (!error) return null;
  return (
    <>
      {error?.fatal && (
        <Message sx={{ width: "100%", maxWidth: "100%", mb: 2 }} variant="error">
          Hightouch has detected a fatal error and temporarily disabled git sync. Examples of fatal errors are: your credentials
          may be invalid, you may not have access to this repository, etc.
        </Message>
      )}
      {error?.temp && (
        <Message sx={{ width: "100%", maxWidth: "100%", mb: 2 }} variant="warning">
          Hightouch has detected a possible race condition and will attempt to automatically resolve. No action is required.
        </Message>
      )}
      <Text as="pre" sx={{ bg: "base.1", p: 4, wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
        {JSON.stringify(error, null, 2)}
      </Text>
    </>
  );
};

export const GitSyncStatus = ({ data, loading }) => {
  const { workspace } = useUser();
  const { addToast } = useToasts();

  const gitSyncConfig = data?.git_sync_configs?.[0];
  const [inboundChanges, setInboundChanges] = useState<unknown | null>();
  const [outboundChanges, setOutboundChanges] = useState<unknown | null>();

  const featureFlags = workspace?.feature_flags;
  const unidirectionalEnabled = featureFlags?.unidirection_git_sync as boolean;

  const { mutateAsync: updateGitSyncConfig, isLoading } = useUpdateGitSyncConfigsMutation();

  const fullResync = async () => {
    await updateGitSyncConfig({
      id: gitSyncConfig.id,
      object: {
        full_resync: true,
      },
    });

    addToast("Resync will begin shortly.", {
      appearance: "success",
    });
  };

  const outboundRuns =
    gitSyncConfig?.git_outbound_runs?.map((o) => ({
      createdAt: o.created_at,
      type: "outbound",
      state: o.changelog_id,
      numChanges: o.commits?.length || 0,
      changes: o.commits,
    })) || [];
  const inboundRuns =
    gitSyncConfig?.git_inbound_runs?.map((i) => ({
      createdAt: i.created_at,
      type: "inbound",
      state: i.commit,
      numChanges: i.affected_resources.syncs.length + i.affected_resources.models.length || 0,
      changes: i.affected_resources,
    })) || [];

  const rows = orderBy([...outboundRuns, ...inboundRuns], ["createdAt"], ["desc"]);

  const getCommitUrl = (commit) => {
    const repo = gitSyncConfig?.repository.toString();
    const url = repo.endsWith(".git") ? repo.slice(0, -4) : repo;
    return `${url}/commit/${commit}`;
  };

  const columns = useMemo(
    () => [
      {
        name: "Type",
        key: "type",
        cell: (type) =>
          type === "inbound" ? <Badge variant="green">Inbound</Badge> : <Badge variant="indigo">Outbound</Badge>,
      },
      {
        name: "Completed",
        key: "createdAt",
        cell: (createdAt) => formatDatetime(createdAt),
      },
      {
        name: "State",
        max: "200px",
        min: "150px",
        cell: ({ type, state }) =>
          type === "inbound" ? <Link newTab to={getCommitUrl(state)}>{`Commit: ${state}`}</Link> : `Changelog ID: ${state}`,
      },
      {
        name: "Changes",
        cell: ({ type, numChanges, changes }) => {
          if (numChanges) {
            return (
              <Link
                onClick={() => {
                  if (type === "inbound") {
                    setInboundChanges(changes);
                  } else {
                    setOutboundChanges(changes);
                  }
                }}
              >{`${numChanges} resource changed`}</Link>
            );
          } else return "None";
        },
      },
    ],
    [],
  );

  return (
    <Section>
      <Grid gap={8}>
        <Container center={false} size="small">
          <Column>
            <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <Label size={"large"}>Git Sync Status</Label>
              <Button loading={isLoading || gitSyncConfig?.full_resync} sx={{ mb: 6 }} variant="secondary" onClick={fullResync}>
                Full Resync
              </Button>
            </Row>
            {loading ? (
              <Spinner />
            ) : (
              <Grid gap={2}>
                {!unidirectionalEnabled && (
                  <Field
                    label="Hightouch to Git"
                    labelComponent={(children) => (
                      <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <Label inline>{children}</Label>
                        <StatusBadge
                          error={gitSyncConfig?.outbound_error}
                          lastAttemptedAt={gitSyncConfig?.last_attempted_at}
                          setup={gitSyncConfig}
                        />
                      </Row>
                    )}
                    labelSx={{ width: "300px" }}
                    sx={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <ErrorBlock error={gitSyncConfig?.outbound_error} />
                  </Field>
                )}

                <Field
                  label="Git to Hightouch"
                  labelComponent={(children) => (
                    <Row sx={{ alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                      <Label inline help={"Hightouch will only sync resources from Git after the outbound sync has succeeded."}>
                        {children}
                      </Label>
                      <StatusBadge
                        error={gitSyncConfig?.inbound_error}
                        lastAttemptedAt={!gitSyncConfig?.outbound_error ? gitSyncConfig?.last_attempted_at : null}
                        setup={gitSyncConfig}
                      />
                    </Row>
                  )}
                  labelSx={{ width: "300px" }}
                  sx={{ justifyContent: "space-between", width: "100%" }}
                >
                  <ErrorBlock error={gitSyncConfig?.inbound_error} />
                </Field>
              </Grid>
            )}
          </Column>
        </Container>

        <Field label="Successful Runs" size="large">
          <Table
            columns={columns}
            data={rows}
            loading={loading}
            placeholder={{
              title: "No runs",
              body: "Enable syncing to your repository",
              error: "Runs failed to load, please try again.",
            }}
          />
        </Field>
      </Grid>

      <Modal
        info
        isOpen={Boolean(outboundChanges)}
        sx={{ maxWidth: "500px", width: "100%" }}
        title="Git Commits"
        onClose={() => {
          setOutboundChanges(null);
        }}
      >
        {(outboundChanges as any)?.map((c, i) => (
          <Link key={i} newTab sx={{ display: "block" }} to={getCommitUrl(c)}>
            {getCommitUrl(c)}
          </Link>
        ))}
      </Modal>
      <Modal
        info
        isOpen={Boolean(inboundChanges)}
        sx={{ maxWidth: "500px", width: "100%" }}
        title="Changed Resources"
        onClose={() => {
          setInboundChanges(null);
        }}
      >
        <Grid gap={6}>
          <Field label={"Syncs"}>
            {(inboundChanges as any)?.syncs?.map((s) => (
              <Link key={s} newTab sx={{ display: "block" }} to={`/syncs/${s}`}>
                Sync {s}
              </Link>
            ))}
          </Field>
          <Field label={"Models"}>
            {(inboundChanges as any)?.models?.map((m) => (
              <Link key={m} newTab sx={{ display: "block" }} to={`/models/${m}`}>
                Model {m}
              </Link>
            ))}
          </Field>
        </Grid>
      </Modal>
    </Section>
  );
};

export const GitSync = () => {
  const { data: gitSyncConfigsData, isLoading: gitSyncLoading } = useGitSyncConfigsQuery({}, { refetchInterval: 3000 });
  return (
    <Settings route="git-sync">
      <Grid gap={12}>
        <GitSyncConfig data={gitSyncConfigsData} loading={gitSyncLoading} />
        {gitSyncConfigsData?.git_sync_configs && <GitSyncStatus data={gitSyncConfigsData} loading={gitSyncLoading} />}
      </Grid>
    </Settings>
  );
};
