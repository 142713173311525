import { VFC, useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";
import { Flex, Grid } from "theme-ui";

import {
  useAlertingCredentialsQuery,
  useCreatePagerDutyCredentialsMutation,
  useUpdatePagerDutyCredentialsMutation,
} from "src/graphql";
import { Container } from "src/ui/box";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Spinner } from "src/ui/loading";
import { Section } from "src/ui/section";

export const Notifications: VFC = () => {
  const { addToast } = useToasts();
  const [_error, setError] = useState("");
  const [pagerDutyIntegrationKey, setPagerDutyIntegrationKey] = useState<string | undefined | null>();
  const [saveLoading, setSaveLoading] = useState(false);

  const { mutateAsync: updatePagerDutyCredential } = useUpdatePagerDutyCredentialsMutation();
  const { mutateAsync: createPagerDutyCredential } = useCreatePagerDutyCredentialsMutation();

  const { data: credentialsData, isLoading: credentialsLoading } = useAlertingCredentialsQuery();

  const slackCredentials = credentialsData?.slack_credentials?.[0];
  const pagerDutyCredentials = credentialsData?.pagerduty_credentials?.[0];

  useEffect(() => {
    setPagerDutyIntegrationKey(pagerDutyCredentials?.api_key);
  }, [pagerDutyCredentials]);

  const save = async () => {
    setSaveLoading(true);

    try {
      if (pagerDutyCredentials) {
        await updatePagerDutyCredential({
          id: pagerDutyCredentials.id,
          object: {
            api_key: pagerDutyIntegrationKey,
          },
        });
      } else {
        await createPagerDutyCredential({
          object: {
            config: {},
            api_key: pagerDutyIntegrationKey,
            state: "enabled",
          },
        });
      }

      addToast("Your notification settings have been saved.", {
        appearance: "success",
      });
    } catch (e) {
      setError("Invalid notification settings.");
    } finally {
      setSaveLoading(false);
    }
  };

  const dirty = pagerDutyIntegrationKey !== pagerDutyCredentials?.api_key;

  return (
    <Section footer={<Button disabled={!dirty} label={"Save"} loading={saveLoading} onClick={save} />}>
      <Container center={false} size="small">
        <Field
          description={`Hightouch can notify you when syncs in your workspace have errors. We are working on giving you more control over alerts, but currently alert upon any error in a sync.`}
          label="Alert Credentials"
          size="large"
        >
          {credentialsLoading ? (
            <Spinner />
          ) : (
            <Grid gap={8}>
              <Field label="Slack">
                <Flex sx={{ alignItems: "center" }}>
                  <Button
                    label={!slackCredentials ? `Authorize your Slack Account` : `Reauthorize your Slack Account`}
                    variant="secondary"
                    onClick={() => {
                      window.location.href = `${import.meta.env.VITE_API_BASE_URL}/slackv2/oauth/alert`;
                    }}
                  />
                  {slackCredentials && <Circle color={"green"} radius="12px" sx={{ ml: 2 }} />}
                </Flex>
              </Field>
              <Field label="PagerDuty API Key">
                <Input
                  placeholder="Enter an API key..."
                  value={pagerDutyIntegrationKey}
                  onChange={setPagerDutyIntegrationKey}
                />
              </Field>
            </Grid>
          )}
        </Field>
      </Container>
    </Section>
  );
};
