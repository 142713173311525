import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import {
  useMarketoActivitiesQuery,
  useMarketoAttributesQuery,
  useMarketoColumnsQuery,
  useMarketoObjectsQuery,
} from "src/graphql";
import { Field } from "src/ui/field";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

export const validation = Yup.object().shape({
  type: Yup.string().required().default("objects"),
  mode: Yup.string().when("type", {
    is: "activities",
    then: Yup.string().required().default("insert"),
    otherwise: Yup.string().required().default("upsert"),
  }),
  object: Yup.string().required(),

  activityTypeId: Yup.string().when("type", {
    is: "activities",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  timestampFrom: Yup.mixed().when("type", {
    is: "activities",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),
  leadIdFrom: Yup.mixed().when("type", {
    is: "activities",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),

  externalIdMapping: COMMON_SCHEMAS.externalIdMapping,
  mappings: COMMON_SCHEMAS.mappings,
  deleteMode: Yup.string().notRequired(),
});

const TYPES = [
  { label: "Database Objects", value: "objects" },
  { label: "Custom Activities (Beta)", value: "activities" },
];

interface ObjectOption {
  label: string;
  value: string;
  activityTypeId: string | null;
}

export const MarketoForm: FC = () => {
  const { hightouchColumns, errors, config, setConfig, destination, reloadModel, loadingModel } = useDestinationForm();

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = useMarketoColumnsQuery(
    {
      destinationId: String(destination?.id),
      object: config?.object,
    },
    { enabled: Boolean(config?.object) },
  );

  const columns = columnsData?.marketoDescribeObject?.fields;

  const {
    data: attributesData,
    error: attributesError,
    isFetching: loadingAttributes,
    refetch: getAttributes,
  } = useMarketoAttributesQuery(
    {
      destinationId: String(destination?.id),
      activity: config?.object,
    },
    { enabled: Boolean(config?.object) },
  );

  const attributes = attributesData?.marketoDescribeActivity?.attributes;

  const {
    data: objectsData,
    error: objectsError,
    isFetching: loadingObjects,
    refetch: getObjects,
  } = useMarketoObjectsQuery({
    destinationId: String(destination?.id),
  });

  const objects = objectsData?.marketoListObjects?.objects;

  const {
    data: activitiesData,
    error: activitiesError,
    isFetching: loadingActivities,
    refetch: getActivities,
  } = useMarketoActivitiesQuery({
    destinationId: String(destination?.id),
  });

  const activities = activitiesData?.marketoListActivities?.activities;

  const modeOptions =
    config?.type === "activities"
      ? [{ label: "Insert", value: "insert" }]
      : [
          { label: "Upsert", value: "upsert" },
          { label: "Update", value: "update" },
        ];

  const columnOptions =
    columns?.filter((field) => !field.isID && field.isUpdateable)?.map((a) => ({ label: a?.name, value: a?.id })) || [];

  const attributeOptions = attributes?.filter((f) => !f?.isPrimary)?.map((a) => ({ label: a?.name, value: a?.id })) || [];

  const objectOptions: ObjectOption[] | undefined = objects?.map((a) => ({ label: a.name, value: a.id, activityTypeId: null }));

  const activityOptions: ObjectOption[] | undefined = activities?.map((a) => ({
    label: a.name,
    value: a.id,
    activityTypeId: a.activityTypeId,
  }));

  const objOptions = config?.type === "activities" ? activityOptions : objectOptions;

  const primaryColumnOptions = attributes?.filter((f) => f?.isPrimary)?.map((f) => ({ label: f.name, value: f.id })) || [];

  const externalIdColumnOptions =
    columns
      ?.filter((f) => (config?.mode === "update" ? f.isLookup || f.isID : f.isLookup && !f.isID))
      ?.map((f) => ({ label: f.name, value: f.id })) || [];

  return (
    <>
      <Section>
        <Field required error={errors?.type} label="Are you looking to sync database objects or activities?" size="large">
          <Select
            isError={errors?.type}
            options={TYPES}
            placeholder="Select a sync type..."
            value={config?.type ? TYPES?.find((s) => config?.type === s.value) : null}
            width="340px"
            onChange={(selected) => setConfig({ type: selected?.value })}
          />
        </Field>
      </Section>

      {config?.type && (
        <ObjectField
          error={config?.type === "activities" ? activitiesError?.message : objectsError?.message}
          loading={config?.type === "activities" ? loadingActivities : loadingObjects}
          options={objOptions}
          path={[config?.type]}
          reload={config?.type === "activities" ? getActivities : getObjects}
          onChange={(object) => {
            const option = objOptions?.find((o) => o.value === object);
            if (config?.type === "activities") {
              setConfig({ type: config?.type, object, activityTypeId: option?.activityTypeId });
            } else {
              setConfig({ type: config?.type, object });
            }
          }}
        />
      )}

      {config?.type && (
        <ModeField
          options={modeOptions}
          path={[config.type]}
          onChange={(mode) => {
            setConfig({
              mode,
              type: config?.type,
              object: config?.object,
              activityTypeId: config?.activityTypeId,
            });
          }}
        />
      )}

      {config?.object && (
        <Section>
          <IdMappingField
            error={config?.type === "activities" ? attributesError?.message : columnsError?.message}
            loading={config?.type === "activities" ? loadingAttributes : loadingColumns}
            options={config?.type === "activities" ? primaryColumnOptions : externalIdColumnOptions}
            path={[config.type]}
            reload={config?.type === "activities" ? getAttributes : getColumns}
          />
        </Section>
      )}

      {config?.type === "activities" && config?.object && (
        <Section>
          <Grid gap={8}>
            <Field error={errors?.leadIdFrom} label="Which column contains the Marketo Lead GUID?">
              <Select
                isError={errors?.leadIdFrom}
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.leadIdFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected.value;
                  setConfig({ ...config, leadIdFrom: val });
                }}
              />
            </Field>
            <Field error={errors?.timestampFrom} label="Which column contains the activity timestamp?">
              <Select
                isError={errors?.timestampFrom}
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.timestampFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected.value;
                  setConfig({ ...config, timestampFrom: val });
                }}
              />
            </Field>
          </Grid>
        </Section>
      )}

      {config?.object && (
        <Section>
          <MappingsField
            error={config?.type === "activities" ? attributesError?.message : columnsError?.message}
            loading={config?.type === "activities" ? loadingAttributes : loadingColumns}
            options={config?.type === "activities" ? attributeOptions : columnOptions}
            reload={config?.type === "activities" ? getAttributes : getColumns}
          />
        </Section>
      )}

      {config?.object && config?.mode && <DeleteField modes={config?.mode === "update" ? ["clear"] : ["delete"]} />}
    </>
  );
};

export default {
  form: MarketoForm,
  validation,
};
