import { useState, VFC, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/browser";
import { uniqueId } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Outlet, Routes, Route, useOutletContext, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Grid } from "theme-ui";
import * as yup from "yup";

import monitoringImage from "src/components/extensions/assets/monitoring.png";
import { Overview } from "src/components/extensions/overview";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import {
  DatadogCredentialsQuery,
  useCreateDatadogCredentialsMutation,
  useDatadogCredentialsQuery,
  useUpdateDatadogCredentialsMutation,
  useValidateDatadogCredentialsQuery,
} from "src/graphql";
import { Container, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field, FieldError } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { MonitoringIcon, DatadogIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading";
import { Select } from "src/ui/select";
import { SensitiveField } from "src/ui/sensitive-field";
import { Tabs } from "src/ui/tabs";
import { useNavigate } from "src/utils/navigate";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const TABS = [Tab.Overview, Tab.Configuration];

export const Monitoring: VFC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="Hightouch integrates with best-in-class 3rd party tools so you can monitor sync health all in one place and have
          greater observability on your data pipelines. This is done for both individual rows and overall syncs. We send both
          real-time events and aggregated metrics. Setting up monitoring is done through the Configuration tab, where you can
          enter in credentials for your monitoring provider. We currently support Datadog."
              icon={MonitoringIcon}
              image={monitoringImage}
              integrations={[{ name: "Datadog", icon: DatadogIcon }]}
              subtitle="Monitor sync health with dashboards"
              title="Monitoring"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
      </Route>
    </Routes>
  );
};

const Layout: VFC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const { data: credentials, isLoading: loading } = useDatadogCredentialsQuery(undefined, {
    select: (data) => data?.datadog_credentials?.[0],
  });

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "Monitoring" }]} size="medium">
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Overview) {
            navigate("/extensions/monitoring");
          } else {
            navigate("configuration");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet context={{ credentials, loading }} />
    </Page>
  );
};

const siteOptions = [
  { label: "US1", value: "datadoghq.com" },
  { label: "US3", value: "us3.datadoghq.com" },
  { label: "US5", value: "us5.datadoghq.com" },
  { label: "EU1", value: "datadoghq.eu" },
  { label: "US1-FED", value: "app.ddog-gov.com" },
];

type DatadogCredentials = DatadogCredentialsQuery["datadog_credentials"][0];

interface OutletContext {
  credentials: DatadogCredentials;
  loading: boolean;
}

const validation = yup
  .object({
    api_key: yup.string(),
    site: yup.string().required(),
  })
  .required();

const Configuration: VFC = () => {
  const client = useQueryClient();
  const { credentials, loading } = useOutletContext<OutletContext>();
  const { addToast } = useToasts();

  const [error, setError] = useState<string | undefined>();
  const { mutateAsync: create } = useCreateDatadogCredentialsMutation();
  const { mutateAsync: update } = useUpdateDatadogCredentialsMutation();

  const {
    reset,
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(validation),
  });

  const site = watch("site");
  const apiKey = watch("api_key");

  const validate = async () => {
    const { ValidateDatadogCredentials } = await client.fetchQuery(uniqueId(), {
      queryFn: useValidateDatadogCredentialsQuery.fetcher({
        site: site,
        apiKey: apiKey,
      }),
    });

    if (ValidateDatadogCredentials?.success) {
      setError(undefined);
      return true;
    }

    setError("Datadog configuration is invalid");

    addToast("There was an error saving your configuration.", {
      appearance: "error",
    });
    return false;
  };

  const submit = async (data) => {
    try {
      const valid = await validate();

      if (!valid) {
        return;
      }

      if (!credentials) {
        await create({
          input: data,
        });
      } else {
        await update({
          id: credentials.id,
          credentials: data,
        });
      }
      addToast("Configuration saved!", {
        appearance: "success",
      });
    } catch (e) {
      addToast("There was an error saving your configuration.", {
        appearance: "error",
      });
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    reset({
      site: credentials?.site ?? "",
      api_key: "",
    });
  }, [credentials]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Row sx={{ justifyContent: "space-between" }}>
      <Container center={false} size="small">
        <Grid gap={8}>
          <Heading>Datadog Configuration</Heading>

          <Field error={errors.site?.type === "required" && "Site is required"} label="Site">
            <Controller
              control={control}
              name="site"
              render={({ field }) => (
                <Select
                  options={siteOptions}
                  placeholder="Select a datadog site for your datadog account"
                  value={field.value}
                  onChange={(selected) => {
                    field.onChange(selected?.value);
                  }}
                />
              )}
            />
          </Field>

          <Field error={errors.api_key?.type === "required" && "API Key is required"} label="API Key">
            <Controller
              control={control}
              name="api_key"
              render={({ field }) => (
                <SensitiveField hideSecret={Boolean(credentials?.id)} value={field.value} onChange={field.onChange} />
              )}
            />
          </Field>

          <FieldError error={error} />
        </Grid>
      </Container>

      <SidebarForm
        buttons={
          <Button loading={isSubmitting} sx={{ width: "100%" }} onClick={handleSubmit(submit)}>
            Save
          </Button>
        }
        docsUrl="/syncs/datadog"
        name="Datadog"
      />
    </Row>
  );
};
