import { VFC, ReactNode, useState, useEffect, useCallback, MouseEventHandler, useMemo } from "react";

import {
  useFloating,
  Placement,
  shift as shiftMiddleware,
  flip as flipMiddleware,
  offset as offsetMiddleware,
  size as sizeMiddleware,
  hide as hideMiddleware,
} from "@floating-ui/react-dom";
import { Portal } from "react-portal";
import { ThemeUIStyleObject } from "theme-ui";

import { Column, Row } from "src/ui/box";

import { Indices } from "../../../../design";

export type { Placement };

type StateArgs = {
  isOpen: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
};

type PopoutProps = {
  content?: ReactNode | ((args: StateArgs) => ReactNode);
  children?: ReactNode | ((args: StateArgs) => ReactNode);
  disabled?: boolean;
  sx?: ThemeUIStyleObject;
  contentSx?: ThemeUIStyleObject;
  width?: string;
  placement?: Placement;
  onClose?: () => void;
  onOpen?: () => void;
  isOpen?: boolean;
  offset?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
  ignoreClose?: boolean;
  portal?: boolean;
  minHeight?: number;
  maxHeight?: number;
  strategy?: "absolute" | "fixed";
  autoHide?: boolean;
};

export const Popout: VFC<Readonly<PopoutProps>> = ({
  autoHide = true,
  portal,
  children,
  content,
  contentSx = {},
  sx = {},
  offset = 8,
  placement = "bottom-start",
  disabled,
  onClose,
  onOpen,
  onClick,
  ignoreClose,
  minHeight = 100,
  maxHeight,
  strategy = "absolute",
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(props.isOpen));
  const [preOpen, setPreOpen] = useState<boolean>(Boolean(props.isOpen));

  const {
    x,
    y,
    reference,
    floating,
    refs,
    update,
    middlewareData: { hide },
  } = useFloating({
    placement,
    strategy,
    middleware: [
      offsetMiddleware(offset),
      flipMiddleware(),
      sizeMiddleware({
        apply({ width, height }) {
          setContentSize({ width, height });
        },
        padding: 8,
      }),
      shiftMiddleware({ padding: 8 }),
      hideMiddleware(),
    ],
  });

  const setContentSize = useCallback(
    ({ width, height }) => {
      const contentElement = refs?.floating?.current;
      if (contentElement) {
        if (height < minHeight) {
          //close();
        } else {
          Object.assign(contentElement.style, {
            maxWidth: `${width}px`,
            maxHeight: maxHeight ? `${Math.min(maxHeight, height)}px` : `${height}px`,
          });
        }
      }
    },
    [refs],
  );

  const open = useCallback(() => {
    // force position update before opening
    setPreOpen(true);
    if (onOpen) {
      onOpen();
    }
  }, [setPreOpen, onOpen]);

  const close = useCallback(() => {
    if (!ignoreClose) {
      setPreOpen(false);
      setIsOpen(false);
      if (onClose) {
        onClose();
      }
    }
  }, [setIsOpen, setPreOpen, onClose, ignoreClose]);

  const toggle = useCallback(() => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  }, [isOpen, open, close]);

  const handleClick = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
      toggle();
    },
    [onClick, toggle],
  );

  useEffect(() => {
    if (ignoreClose) {
      setIsOpen(Boolean(props.isOpen));
      setPreOpen(Boolean(props.isOpen));
    }
  }, [props.isOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Esc" || event.key === "Escape") {
        close();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, close]);

  useEffect(() => {
    // use mousedown since we stop click events sometimes
    const handleMouseDown = (e: MouseEvent) => {
      if (refs.floating?.current?.contains(e?.target as Node) || refs?.reference?.current?.contains(e?.target as Node)) {
        return;
      }
      if (onClose) {
        onClose();
      }
      close();
    };

    if (isOpen) {
      window.addEventListener("mousedown", handleMouseDown);
    } else {
      window.removeEventListener("mousedown", handleMouseDown);
    }

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [refs, isOpen, onClose, close]);

  useEffect(() => {
    if (autoHide && hide?.referenceHidden) {
      close();
    }
  }, [autoHide, hide]);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("scroll", update);
      window.addEventListener("resize", update);
    } else {
      window.removeEventListener("scroll", update);
      window.removeEventListener("resize", update);
    }

    return () => {
      window.removeEventListener("scroll", update);
      window.removeEventListener("resize", update);
    };
  }, [isOpen, update]);

  useEffect(() => {
    if (preOpen) {
      update();
      setIsOpen(true);
    }
  }, [preOpen, update, setIsOpen]);

  const contentStyle = useMemo(() => {
    return {
      visibility: isOpen ? "visible" : "hidden",
      pointerEvents: isOpen ? "auto" : "none",
      position: strategy,
      zIndex: Indices.Popup,
      top: 0,
      left: 0,
      transform: translate(x, y),
      border: "small",
      boxShadow: "large",
      borderRadius: 1,
      backgroundColor: "white",
      height: "max-content",
      maxHeight,
      ...contentSx,
    } as ThemeUIStyleObject;
  }, [contentSx, x, y, strategy, isOpen]);

  const node = useMemo(() => {
    if (!preOpen) {
      return null;
    }

    const node = (
      <Column ref={floating} sx={contentStyle}>
        {typeof content === "function" ? content({ isOpen, toggle, open, close }) : content}
      </Column>
    );

    if (portal) {
      return <Portal>{node}</Portal>;
    }

    return <>{node}</>;
  }, [content, contentStyle, floating, isOpen, toggle, open, close, preOpen]);

  return (
    <Column sx={sx}>
      <Row
        ref={reference}
        sx={{
          cursor: disabled ? "default" : "pointer",
          userSelect: "none",
          pointerEvents: disabled ? "none" : undefined,
        }}
        onClick={handleClick}
      >
        {typeof children === "function" ? children({ isOpen, toggle, open, close }) : children}
      </Row>

      {node}
    </Column>
  );
};

const translate = (x: number | null, y: number | null) => `translate3d(${Math.round(x || 0)}px, ${Math.round(y || 0)}px, 0)`;
