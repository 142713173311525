import { VFC, useEffect } from "react";

import * as Sentry from "@sentry/browser";
import { useForm } from "react-hook-form";
import { Outlet, Route, Routes, useOutletContext, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Grid } from "theme-ui";

import dbtCloudImage from "src/components/extensions/assets/dbt-cloud.png";
import { Overview } from "src/components/extensions/overview";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import {
  useDbtCredentialsQuery,
  DbtCredentialsQuery,
  useCreateDbtCredentialMutation,
  useUpdateDbtCredentialMutation,
} from "src/graphql";
import { Container, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { DBTIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Tabs } from "src/ui/tabs";
import { useNavigate } from "src/utils/navigate";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const TABS = [Tab.Overview, Tab.Configuration];

export const DbtCloud: VFC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="Hightouch lets you use dbt Cloud to trigger syncs. Whenever a job runs in dbt Cloud, Hightouch can then trigger the
          relevant sync in our platform. You just need to select “dbt Cloud” and then the relevant dbt job in the scheduling
          step when you’re configuring a sync. Setting up dbt Cloud triggers requires you to provide your dbt API key under the
          Configuration tab."
              icon={DBTIcon}
              image={dbtCloudImage}
              subtitle="Schedule syncs triggered by dbt Cloud"
              title="dbt Cloud"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
      </Route>
    </Routes>
  );
};

const Layout: VFC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const { data: credentials, isLoading: loading } = useDbtCredentialsQuery(undefined, {
    select: (data) => data.dbt_credentials?.[0],
  });

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "dbt Cloud" }]} size="medium">
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Overview) {
            navigate("/extensions/dbt-cloud");
          } else {
            navigate("configuration");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet context={{ credentials, loading }} />
    </Page>
  );
};

interface OutletContext {
  credentials: DbtCredentialsQuery["dbt_credentials"][0];
  loading: boolean;
}

const Configuration: VFC = () => {
  const { credentials, loading } = useOutletContext<OutletContext>();
  const { addToast } = useToasts();
  const {
    setError,
    reset,
    handleSubmit,
    register,
    formState: { isDirty, isSubmitting, errors },
  } = useForm();

  const { mutateAsync: create } = useCreateDbtCredentialMutation();
  const { mutateAsync: update } = useUpdateDbtCredentialMutation();

  const submit = async (data) => {
    try {
      if (credentials?.id) {
        await update({
          id: credentials.id,
          ...data,
        });
      } else {
        await create(data);
      }
    } catch (e) {
      addToast("There was an error saving your configuration.", {
        appearance: "error",
      });
      setError("api_key", { type: "custom", message: "Invalid API Key" });
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    reset({
      api_key: credentials?.api_key ?? "",
      subdomain: credentials?.subdomain,
    });
  }, [credentials]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Row sx={{ justifyContent: "space-between" }}>
      <Container center={false} size="small">
        <Grid gap={8}>
          <Heading>dbt Cloud Configuration</Heading>

          <Field error={errors?.api_key?.message} label="dbt Cloud API Key">
            <Input type="password" {...register("api_key")} />
          </Field>

          <Field
            optional
            description='If left empty, Hightouch will default to "cloud" as the subdomain.'
            label="Custom Subdomain"
          >
            <Input {...register("subdomain")} />
          </Field>
        </Grid>
      </Container>
      <SidebarForm
        buttons={
          <Button disabled={!isDirty} loading={isSubmitting} sx={{ width: "100%" }} onClick={handleSubmit(submit)}>
            Save
          </Button>
        }
        docsUrl="syncs/dbt-cloud"
        name="dbt Cloud"
      />
    </Row>
  );
};
