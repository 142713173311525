import { FC } from "react";

import { Box, ThemeUIStyleObject } from "theme-ui";

export type IconProps = {
  color?: string | null;
  strokeWidth?: string;
  sx?: ThemeUIStyleObject;
  size?: number;
  viewBox?: string;
  width?: number;
  height?: number;
};

export const Icon: FC<Readonly<IconProps>> = ({
  color = "secondary",
  viewBox = "0 0 24 24",
  strokeWidth,
  children,
  size = 24,
  width,
  height,
  sx = {},
}) => (
  <Box sx={{ svg: { fill: color, strokeWidth, stroke: strokeWidth ? color : undefined }, flexShrink: 0, ...sx }}>
    <svg height={height ?? size} viewBox={viewBox} width={width ?? size} xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  </Box>
);
