import { VFC } from "react";

import { PageSpinner } from "src/ui/loading";
import { Wizard } from "src/ui/wizard";
import { useNavigate } from "src/utils/navigate";

import { useCreateModelWizard } from "./use-create-model-wizard";

export const CreateModel: VFC = () => {
  const navigate = useNavigate();

  const { createModel, loading, setStep, step, steps } = useCreateModelWizard({
    onSubmit: ({ id }) => navigate(`/models/${id}`),
  });

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Wizard
      setStep={setStep}
      step={step}
      steps={steps}
      title="Add a new model"
      onCancel={() => {
        navigate("/models");
      }}
      onSubmit={createModel}
    />
  );
};
