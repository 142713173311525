import { VFC, useState, useEffect } from "react";

import { useToasts } from "react-toast-notifications";
import { Text, Grid, Image } from "theme-ui";

import { DestinationForm } from "src/components/destinations/destination-form";
import { DestinationsGrid } from "src/components/destinations/destinations-grid";
import { ScheduleManager } from "src/components/schedule";
import { Schedule } from "src/components/schedule/types";
import { FormkitDestination, FormkitModel } from "src/formkit/components/formkit-context";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  useCreateSyncTemplateMutation,
  useIsResourceSlugAvailableQuery,
  useParentModelsQuery,
  CreateSyncTemplateMutationVariables,
} from "src/graphql";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { PageSpinner } from "src/ui/loading";
import { Selectable } from "src/ui/selectable";
import { Wizard } from "src/ui/wizard";
import { useDestinations } from "src/utils/destinations";
import { useNavigate } from "src/utils/navigate";
import { generateSlug } from "src/utils/slug";
import { SourceBadges, SourceIcon, useSources } from "src/utils/sources";
import { formatDatetime } from "src/utils/time";

import { Slug } from "../../../components/slug";

type Config = CreateSyncTemplateMutationVariables["object"]["config"];

export const CreateSyncTemplate: VFC = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [destination, setDestination] = useState<FormkitDestination | undefined>();
  const [destinationDefinition, setDestinationDefinition] = useState<DestinationDefinition | undefined>();
  const [parentModel, setParentModel] = useState<FormkitModel | undefined>();
  const [step, setStep] = useState<number>(0);
  const [config, setConfig] = useState<Config | undefined>();
  const [schedule, setSchedule] = useState<Schedule | undefined>();
  const [name, setName] = useState<string>("");

  const [slug, _setSlug] = useState<string>("");
  const [dirtySlug, setDirtySlug] = useState<boolean>(false);
  const { data, isLoading: loadingSlug } = useIsResourceSlugAvailableQuery(
    {
      resourceType: "sync_templates",
      slug,
    },
    { enabled: Boolean(slug) },
  );
  const available = data?.isResourceSlugAvailable;
  const setSlug = (value) => {
    _setSlug(value);
    setDirtySlug(true);
  };

  useEffect(() => {
    if (!dirtySlug && parentModel && destination) {
      const name = `${parentModel.name} to ${destination.name}`;
      _setSlug(generateSlug(name));
    }
  }, [parentModel, destination]);

  const { data: parentModelsData, isLoading: parentModelsLoading } = useParentModelsQuery();

  const {
    data: { destinations, definitions: destinationDefinitions },
    loading: destinationsLoading,
  } = useDestinations();

  const parentModels = parentModelsData?.segments;

  const { data: sources, loading: sourcesLoading } = useSources();

  const { mutateAsync: createSyncTemplate, isLoading: creating } = useCreateSyncTemplateMutation();

  const create = async () => {
    if (!destination || !parentModel) {
      return;
    }

    createSyncTemplate({
      object: {
        config: { ...config, configVersion: destinationDefinition?.configVersion },
        destination_id: destination.id,
        segment_id: parentModel.id,
        schedule: schedule?.type === "manual" ? null : schedule,
        name,
        slug,
      },
    });

    addToast(`Sync template created!`, {
      appearance: "success",
    });

    navigate(`/audiences/setup/sync-templates`);
  };

  const steps = [
    {
      title: "Select parent model",
      disabled: !parentModel,
      render: () => (
        <>
          <Column sx={{ mb: 8, pb: 8, borderBottom: "small" }}>
            <Heading sx={{ mb: 2 }} variant="h2">
              Select a parent model
            </Heading>
            <Text sx={{ color: "base.7" }}>
              Only audiences that are built on the selected parent model can be synced with this template.
            </Text>
          </Column>
          {Array.isArray(parentModels) && parentModels.length > 0 ? (
            <Grid gap={4}>
              {parentModels.map((model) => {
                const { name, id, connection, updated_at, created_at } = model;
                const source = sources.find((s) => s.id === connection?.id);
                return (
                  <Selectable
                    key={id}
                    selected={id === parentModel?.id}
                    sx={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                    onSelect={() => setParentModel(model)}
                  >
                    <Row sx={{ alignItems: "center" }}>
                      <SourceIcon source={source} sx={{ width: "24px", maxHeight: "100%", objectFit: "contain" }} />
                      <Text sx={{ fontWeight: "bold", ml: 4, textOverflow: "ellipsis", overflow: "hidden" }}>{name}</Text>
                      <SourceBadges source={source} />
                    </Row>

                    <Text sx={{ ml: 8, flexShrink: 0, color: "base.5" }}>
                      Updated on {formatDatetime(updated_at || created_at)}
                    </Text>
                  </Selectable>
                );
              })}
            </Grid>
          ) : (
            <Column>
              <Text sx={{ mb: 2, fontSize: 2, fontWeight: "semi" }}>Create a parent model to start using sync templates</Text>
              <Link to="/audiences/setup/parent-models/new">
                <Button>Create parent model</Button>
              </Link>
            </Column>
          )}
        </>
      ),
    },
    {
      title: "Select destination",
      disabled: !destination,
      render: () => (
        <>
          <Column sx={{ mb: 8, pb: 8, borderBottom: "small" }}>
            <Heading sx={{ mb: 2 }} variant="h2">
              Select a destination
            </Heading>
            <Text sx={{ color: "base.7" }}>
              Below are your configured destinations. Go to <Link to="/destinations">Destinations</Link> to setup more.
            </Text>
          </Column>
          <DestinationsGrid
            definitions={destinationDefinitions ?? []}
            destinations={destinations}
            selectedDestinationId={destination?.id}
            onSelect={({ destination, definition }) => {
              setDestination(destination);
              setDestinationDefinition(definition);
            }}
          />
        </>
      ),
    },
    {
      pageSize: "medium",
      title: "Configure sync",
      continueProps: { form: "destination-form", type: "submit" },
      onContinue: () => {
        // empty function needed to set step after submit is triggered
      },
      render: () => (
        <>
          {destination && destinationDefinition && parentModel && (
            <>
              <Row sx={{ alignItems: "center", mb: 8, pb: 8, borderBottom: "small" }}>
                <Image alt={destinationDefinition.name} src={destinationDefinition.icon} width="24px" />
                <Heading sx={{ ml: 2 }} variant="h2">
                  Configure {destination.name || destinationDefinition.name}
                </Heading>
              </Row>

              <DestinationForm
                hideSave
                destination={destination}
                destinationDefinition={destinationDefinition}
                model={parentModel}
                slug={destination?.type}
                sourceDefinition={sources?.find((s) => s.id === parentModel?.connection?.id)?.definition}
                onSubmit={(config) => {
                  setConfig(config);
                  setStep(step + 1);
                  return Promise.resolve();
                }}
              />
            </>
          )}
        </>
      ),
    },
    {
      pageSize: "small",
      title: "Finalize",
      disabled: !name || !schedule,
      loading: creating,
      render: () => {
        return (
          <>
            <Heading sx={{ pb: 8, mb: 8, borderBottom: "small" }} variant="h2">
              Finalize settings
            </Heading>

            <Row></Row>

            <Grid gap={6}>
              <Row>
                <Field label="Template name" sx={{ width: "468px" }}>
                  <Input placeholder={destination?.name ?? undefined} value={name} onChange={setName} />
                </Field>
              </Row>

              <Row sx={{ pb: 4, borderBottom: "small" }}>
                <Field label="Slug" sx={{ width: "468px" }}>
                  <Slug
                    available={Boolean(available)}
                    loading={loadingSlug}
                    placeholder={"your-template-slug"}
                    value={slug}
                    onChange={setSlug}
                  />
                </Field>
              </Row>

              <ScheduleManager schedule={schedule} setSchedule={setSchedule} />
            </Grid>
          </>
        );
      },
    },
  ];

  if (sourcesLoading || parentModelsLoading || destinationsLoading) {
    return <PageSpinner />;
  }

  return (
    <Wizard
      setStep={setStep}
      step={step}
      steps={steps}
      title={`Add a sync template`}
      onCancel={() => {
        navigate(`/audiences/setup/sync-templates`);
      }}
      onSubmit={create}
    />
  );
};
