import { ReactElement } from "react";

import { ResourcePermissionGrant } from "src/graphql";
import { ResourcePermission } from "src/hooks/use-has-permission";
import { Audience } from "src/pages/audiences/audience";
import { Audiences } from "src/pages/audiences/audiences";
import { CloneAudience } from "src/pages/audiences/clone-audience";
import { CreateAudience } from "src/pages/audiences/create-audience";
import { CreateAudienceObject } from "src/pages/audiences/setup/create-object";
import { CreateSyncTemplate } from "src/pages/audiences/setup/create-sync-template";
import { AudienceObject } from "src/pages/audiences/setup/object";
import { AudienceObjectQuery } from "src/pages/audiences/setup/object-query";
import { AudienceObjects } from "src/pages/audiences/setup/objects";
import { SyncTemplate } from "src/pages/audiences/setup/sync-template";
import { SyncTemplates } from "src/pages/audiences/setup/sync-templates";
import { CreateDestination } from "src/pages/destinations/create-destination";
import { Destination } from "src/pages/destinations/destination";
import { Destinations } from "src/pages/destinations/destinations";
import { Alerting } from "src/pages/extensions/alerting";
import { DbtCloud } from "src/pages/extensions/dbt-cloud";
import { DbtModels } from "src/pages/extensions/dbt-models";
import { Extensions } from "src/pages/extensions/extensions";
import { GitSync as GitSyncExtension } from "src/pages/extensions/git-sync";
import { Looker } from "src/pages/extensions/looker";
import { Monitoring } from "src/pages/extensions/monitoring";
import { CloneModel } from "src/pages/models/clone-model";
import { CreateModel } from "src/pages/models/create-model";
import { Model } from "src/pages/models/model";
import { ModelQuery } from "src/pages/models/model-query";
import { Models } from "src/pages/models/models";
import { Onboarding } from "src/pages/onboarding/onboarding";
import { Pricing } from "src/pages/pricing";
import { Alerts } from "src/pages/settings/alerts";
import { APIKeys } from "src/pages/settings/api-keys";
import { Billing } from "src/pages/settings/billing";
import { GitSync } from "src/pages/settings/git-sync";
import { Integrations } from "src/pages/settings/integrations";
import { Members } from "src/pages/settings/members";
import { Storage } from "src/pages/settings/storage";
import { Tunnels } from "src/pages/settings/tunnels";
import { Usage } from "src/pages/settings/usage";
import { VersionControl } from "src/pages/settings/version-control";
import { Workspace } from "src/pages/settings/workspace";
import { CreateSource } from "src/pages/sources/create-source";
import { Source } from "src/pages/sources/source";
import { Sources } from "src/pages/sources/sources";
import { CreateSync } from "src/pages/syncs/create/create-sync";
import { RunDebug } from "src/pages/syncs/debug";
import { Run } from "src/pages/syncs/run";
import { Sync } from "src/pages/syncs/sync";
import { Syncs } from "src/pages/syncs/syncs";

export type RouteObject = {
  path: string;
  component: ReactElement;
  permissions?: ResourcePermission[];
  unauthorizedRedirect?: string;
};

export const config: RouteObject[] = [
  { path: "onboarding", component: <Onboarding /> },
  { path: "pricing", component: <Pricing /> },

  {
    path: "models/:id/clone",
    component: <CloneModel />,
    permissions: [{ resource: "model", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/models",
  },
  { path: "models/:id/query", component: <ModelQuery /> },
  {
    path: "models/new",
    component: <CreateModel />,
    permissions: [{ resource: "model", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/models",
  },
  { path: "models/:id", component: <Model /> },
  {
    path: "models",
    component: <Models />,
    permissions: [{ resource: "model", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/syncs",
  },
  { path: "syncs/:sync/runs/:run/debug/:row", component: <RunDebug /> },
  { path: "syncs/:sync/runs/:run", component: <Run /> },
  {
    path: "syncs/new",
    component: <CreateSync />,
    permissions: [{ resource: "sync", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/syncs",
  },
  { path: "syncs/:id", component: <Sync /> },
  { path: "syncs", component: <Syncs /> },

  {
    path: "sources/new",
    component: <CreateSource />,
    permissions: [{ resource: "source", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/destinations",
  },
  { path: "sources/:id", component: <Source /> },
  { path: "sources", component: <Sources /> },

  {
    path: "destinations/new",
    component: <CreateDestination />,
    permissions: [{ resource: "destination", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/destinations",
  },
  { path: "destinations/:id", component: <Destination /> },
  { path: "destinations", component: <Destinations /> },

  {
    path: "extensions/monitoring/*",
    component: <Monitoring />,
  },
  {
    path: "extensions/dbt-cloud/*",
    component: <DbtCloud />,
  },
  {
    path: "extensions/dbt-models/*",
    component: <DbtModels />,
  },
  {
    path: "extensions/looker/*",
    component: <Looker />,
  },
  {
    path: "extensions/alerting/*",
    component: <Alerting />,
  },
  {
    path: "extensions/git-sync/*",
    component: <GitSyncExtension />,
  },
  {
    path: "extensions",
    component: <Extensions />,
  },

  {
    path: "settings/storage",
    component: <Storage />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    path: "settings/tunnels",
    component: <Tunnels />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    path: "settings/tunnels/:modal",
    component: <Tunnels />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    path: "settings/version-control",
    component: <VersionControl />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    path: "settings/api-keys",
    component: <APIKeys />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    path: "settings/alerts",
    component: <Alerts />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    path: "settings/alerts/:id",
    component: <Alerts />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    path: "settings/integrations",
    component: <Integrations />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    path: "settings/usage",
    component: <Usage />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    path: "settings/billing",
    component: <Billing />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  { path: "settings/members", component: <Members /> },
  {
    path: "settings/git-sync",
    component: <GitSync />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    path: "settings/*",
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
    component: <Workspace />,
  },
  {
    path: "audiences/setup/sync-templates/new",
    component: <CreateSyncTemplate />,
  },
  {
    path: "audiences/setup/sync-templates/:id",
    component: <SyncTemplate />,
  },
  {
    path: "audiences/setup/sync-templates/",
    component: <SyncTemplates />,
  },
  { path: "audiences/setup/:object/:id/query", component: <AudienceObjectQuery /> },
  {
    path: "audiences/setup/:object/new",
    component: <CreateAudienceObject />,
    permissions: [{ resource: "source", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/audiences/setup",
  },
  { path: "audiences/setup/:object/:id", component: <AudienceObject /> },
  { path: "audiences/setup/:object", component: <AudienceObjects /> },
  {
    path: "audiences/:id/clone",
    component: <CloneAudience />,
    permissions: [{ resource: "source", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/audiences/setup",
  },
  {
    path: "audiences/new",
    component: <CreateAudience />,
    permissions: [{ resource: "source", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/audiences",
  },
  { path: "audiences/:id", component: <Audience /> },
  { path: "audiences", component: <Audiences /> },
];
