import { useState, VFC } from "react";

import moment from "moment";
import { Text, Box } from "theme-ui";

import { useDeleteCloudCredentialsMutation, useCloudCredentialsQuery } from "src/graphql";
import { Fade } from "src/ui/animations/fade";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { AWSIcon } from "src/ui/icons/logo-aws";
import { GCPIcon } from "src/ui/icons/logo-gcp";
import { Label } from "src/ui/label";
import { Section } from "src/ui/section";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";

import { CreateCredential } from "../credentials";
import { BulkDeleteConfirmationModal } from "../modals/bulk-delete-confirmation-modal";

const columns = [
  {
    name: "Name",
    cell: ({ name, provider }) => {
      let icon;
      switch (provider) {
        case "aws":
          icon = <AWSIcon />;
          break;
        case "gcp":
          icon = <GCPIcon />;
          break;
      }

      return (
        <Row sx={{ alignItems: "center", mt: 1 }}>
          <Box sx={{ marginRight: "15px" }}>{icon}</Box>
          <Text sx={{ fontWeight: "semi" }}>{name}</Text>
        </Row>
      );
    },
  },
  {
    name: "Created At",
    cell: ({ created_at }) => moment(created_at).calendar(),
    max: "50px",
  },
];

export const CloudProviders: VFC = () => {
  const [addingCredential, setAddingCredential] = useState<boolean>(false);
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();

  const { data } = useCloudCredentialsQuery();
  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteCloudCredentialsMutation();

  return (
    <Section divider>
      <Row sx={{ alignItems: "flex-start", justifyContent: "space-between" }}>
        <Label description={"Connect Hightouch to your cloud providers."} size={"large"} sx={{ marginBottom: 0 }}>
          Cloud Providers
        </Label>
        <Row sx={{ alignItems: "center" }}>
          <Fade hidden={!selectedRows.length} sx={{ mr: 2 }}>
            <Button variant="soft" onClick={() => setConfirmingDelete(true)}>
              Delete
            </Button>
          </Fade>

          <Button label="Add Cloud Provider" onClick={() => setAddingCredential(true)} />
        </Row>
      </Row>

      <Table
        columns={columns}
        data={data?.cloud_credentials}
        placeholder={placeholder}
        selectedRows={selectedRows}
        onSelect={onRowSelect}
      />

      {addingCredential && <CreateCredential onClose={() => setAddingCredential(false)} />}

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="cloud provider"
        loading={loadingBulkDelete}
        onClose={() => setConfirmingDelete(false)}
        onDelete={async () => {
          await bulkDelete({ ids: selectedRows.map(String) });
          onRowSelect([]);
        }}
      />
    </Section>
  );
};

const placeholder = {
  title: "No cloud credentials.",
  error: "Credentials failed to load, please try again.",
};
