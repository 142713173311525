import { VFC, useState, useMemo } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Text } from "theme-ui";

import { Filters, getHasuaExpFromFilters, sourceFilterDefinitions } from "src/components/filter";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteSourcesModal } from "src/components/modals/bulk-delete-sources-modal";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import { ConnectionsBoolExp, ConnectionsOrderBy, ResourcePermissionGrant, useDeleteSourcesMutation } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations";
import { Box, Row } from "src/ui/box";
import { Button, DropdownButton } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { SearchInput } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { useNavigate } from "src/utils/navigate";
import { SourceBadges, SourceIcon, useSources } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

import { useUser } from "../../contexts/user-context";

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

export const Sources: VFC = () => {
  const { appEnableResourceTags, appEnablePrimaryResourceFiltering } = useFlags();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [isConfirmingDeletion, setConfirmingDeletion] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [warningOpen, setWarningOpen] = useState(false);
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);

  const { workspace } = useUser();

  const { mutateAsync: bulkDelete, isLoading: isBulkDeleting } = useDeleteSourcesMutation();

  const { onSort, orderBy } = useTableConfig<ConnectionsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "sources" });

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: ConnectionsBoolExp = {
      _and: [getHasuaExpFromFilters(sourceFilterDefinitions, appEnablePrimaryResourceFiltering ? filters : [])],
    };

    if (search && Array.isArray(hasuraFilters._and)) {
      hasuraFilters._and.push({
        name: {
          _ilike: `%${search}%`,
        },
      });
    }

    return hasuraFilters;
  }, [appEnablePrimaryResourceFiltering, filters, search]);

  const { data: allSourcesForFilters } = useSources({ limit: 1000 });

  const { data: allSources, error, loading, isRefetching } = useSources({ filters: hasuraFilters, orderBy });

  const usingDemoSource = useMemo(() => window.localStorage.getItem("useDemoSource"), []);

  const deleteSources = async () => {
    await bulkDelete({ ids: selectedRows.map(String) });
  };

  if (loading && !isRefetching) {
    return <PageSpinner />;
  }

  // We only allow to select demo sources if there are no other sources available.
  const nonDemoSources = allSources.filter((source) => !source.is_demo);
  const sources = nonDemoSources.length > 0 || !usingDemoSource ? nonDemoSources : allSources;

  const columns: TableColumn[] = [
    {
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort(SortKeys.Name),
      cell: (source) => {
        return (
          <Row sx={{ alignItems: "center" }}>
            <Text sx={{ fontWeight: "semi" }}>{source.name}</Text>
            {appEnableResourceTags && <LabelsCell labels={source.tags} />}
            <SourceBadges source={source} />
          </Row>
        );
      },
    },
    {
      name: "Type",
      sortDirection: orderBy?.type,
      onClick: () => onSort(SortKeys.Type),
      cell: (source) => {
        return (
          <Row sx={{ alignItems: "center" }}>
            <SourceIcon source={source} sx={{ width: "18px", mr: 2, flexShrink: 0 }} />
            <Text sx={{ fontWeight: "semi" }}>{source.definition.name}</Text>
          </Row>
        );
      },
    },
    {
      ...LastUpdatedColumn,
      sortDirection: orderBy?.updated_at,
      onClick: () => onSort(SortKeys.UpdatedAt),
    },
  ];

  return (
    <>
      <PermissionProvider permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}>
        <Page crumbs={[{ label: "Sources" }]} size="full">
          <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8, width: "100%" }}>
            <Row sx={{ alignItems: "center" }}>
              <Heading sx={{ mr: 2 }}>Sources</Heading>
              {appEnablePrimaryResourceFiltering && (
                <>
                  <Views value={selectedView} views={views} onChange={selectView} onDelete={deleteView} />
                  {viewNotSaved &&
                    (selectedView === "Default view" ? (
                      <Button
                        sx={{ ml: 2 }}
                        variant="purple"
                        onClick={() => {
                          setCreateViewModalOpen(true);
                        }}
                      >
                        Save as
                      </Button>
                    ) : (
                      <DropdownButton
                        loading={updatingView}
                        options={[
                          {
                            label: "Save as",
                            onClick: () => {
                              setCreateViewModalOpen(true);
                            },
                          },
                          {
                            label: "Reset changes",
                            onClick: () => {
                              resetViewFilters();
                            },
                          },
                        ]}
                        sx={{ ml: 2 }}
                        onClick={updateCurrentView}
                      >
                        Save
                      </DropdownButton>
                    ))}
                </>
              )}
            </Row>
            <Permission>
              <Button
                onClick={() => {
                  analytics.track("Add Source Clicked");
                  navigate(`/sources/new`);
                }}
              >
                Add source
              </Button>
            </Permission>
          </Row>
          <Row sx={{ alignItems: "center", mb: 3, width: "100%", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
              <SearchInput placeholder="Search sources by name..." value={search ?? ""} onChange={setSearch} />

              {appEnablePrimaryResourceFiltering && (
                <Filters
                  data={allSourcesForFilters}
                  filterDefinitions={sourceFilterDefinitions}
                  filters={filters}
                  resourceType="connection"
                  sx={{ ml: 2 }}
                  onChange={updateFilters}
                />
              )}
            </Box>

            <Fade hidden={!selectedRows.length} sx={{ display: "flex", alignItems: "center" }}>
              <Button sx={{ mr: 4 }} variant="secondary" onClick={() => onRowSelect([])}>
                Cancel
              </Button>
              <Button variant="soft" onClick={() => setConfirmingDeletion(true)}>
                Delete
              </Button>
            </Fade>
          </Row>

          <Table
            columns={columns}
            data={sources}
            error={Boolean(error)}
            loading={isRefetching}
            placeholder={{
              title: "No sources",
              body: search ? "" : "Add a source to get started",
              error: "Sources failed to load, please try again.",
            }}
            selectedRows={selectedRows}
            onRowClick={({ is_demo, id }, event) =>
              is_demo ? setWarningOpen(true) : openUrl(`/sources/${id}`, navigate, event)
            }
            onSelect={onRowSelect}
          />
        </Page>

        <BulkDeleteSourcesModal
          isOpen={isConfirmingDeletion}
          loading={isBulkDeleting}
          sources={selectedRows as string[]}
          workspaceName={workspace?.name ?? ""}
          onCancel={() => {
            setConfirmingDeletion(false);
          }}
          onDelete={deleteSources}
        />
      </PermissionProvider>

      <Modal
        footer={
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setWarningOpen(false);
              }}
            >
              Close
            </Button>
            <Button
              permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}
              onClick={() => {
                navigate("/sources/new");
              }}
            >
              Add source
            </Button>
          </>
        }
        isOpen={warningOpen}
        title="Demo Source"
        onClose={() => {
          setWarningOpen(false);
        }}
      >
        <Text>This is Hightouch's demo source. Create your own source in less than 5 minutes!</Text>
      </Modal>

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />
    </>
  );
};
