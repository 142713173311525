import { forwardRef, Ref, useEffect, useMemo, useRef, Fragment, FC, createElement } from "react";

import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  useMatches,
  KBarResults,
  useKBar,
  VisualState,
  ActionImpl,
} from "kbar";
import { Grid, Text } from "theme-ui";

import { Indices } from "../../../../design";
import { Box, Column, Row } from "../../ui/box";
import { IconProps } from "../../ui/icons/icon";
import { Input } from "../../ui/input";
import { Spinner } from "../../ui/loading";
import { CommandBarProvider, useCommandBar } from "./context";

const animatorStyle = {
  maxWidth: "600px",
  width: "100%",
  borderRadius: "8px",
  overflow: "hidden",
};

const groupNameStyle = {
  padding: "8px 16px",
  fontSize: "10px",
  textTransform: "uppercase" as const,
  opacity: 0.5,
};

/**
 * TODO:
 *  - Add loading indicator to workspace switcher
 */

const KBAR_LISTBOX = "kbar-listbox";
const getListboxItemId = (id: number) => `kbar-listbox-item-${id}`;

const Search = ({ defaultPlaceholder = undefined }) => {
  const { isFetching } = useCommandBar();
  const { query, search, actions, currentRootActionId, activeIndex, showing, options } = useKBar((state) => ({
    search: state.searchQuery,
    currentRootActionId: state.currentRootActionId,
    actions: state.actions,
    activeIndex: state.activeIndex,
    showing: state.visualState === VisualState.showing,
  }));

  const ownRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    query.setSearch("");
    ownRef.current!.focus();
    return () => query.setSearch("");
  }, [currentRootActionId, query]);

  const placeholder = useMemo((): string => {
    const defaultText = defaultPlaceholder ?? "Type a command or search…";
    return currentRootActionId ? actions[currentRootActionId]?.name ?? defaultText : defaultText;
  }, [actions, currentRootActionId, defaultPlaceholder]);

  return (
    <Row sx={{ alignItems: "center", p: 4 }}>
      <Input
        ref={ownRef}
        autoFocus
        aria-activedescendant={getListboxItemId(activeIndex)}
        aria-controls={KBAR_LISTBOX}
        aria-expanded={showing}
        placeholder={isFetching ? "Loading..." : placeholder}
        sx={{ border: "none", p: 0, height: "22px", minHeight: "unset", fontSize: 2, flex: 1 }}
        value={search}
        onChange={(value) => {
          query.setSearch(value);
          options?.callbacks?.onQueryChange?.(value);
        }}
        onKeyDown={(event) => {
          if (currentRootActionId && !search && event.key === "Backspace") {
            const parent = actions[currentRootActionId]?.parent;
            query.setCurrentRootAction(parent);
          }
        }}
      />
      {isFetching && <Spinner sx={{ marginLeft: "auto" }} />}
    </Row>
  );
};

const ResultItem = forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: string;
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    // TODO: Fix render

    const ancestors = useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex((ancestor) => ancestor.id === currentRootActionId);
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    const icon = action.icon && (action.icon as FC<Readonly<IconProps>>);

    return (
      <Row
        ref={ref}
        sx={{
          cursor: "pointer",
          userSelect: "none",
          p: 4,
          transition: "100ms all",
          borderLeft: "2px solid",
          borderColor: active ? "primary" : "white",
          bg: active ? "base.1" : "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <>
          <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center" }}>
            {icon && createElement(icon, { size: 18, sx: { mr: 2 }, color: active ? "primary" : "black" })}
            {ancestors.length > 0 &&
              ancestors.map((ancestor) => (
                <Fragment key={ancestor.id}>
                  <span
                    style={{
                      opacity: 0.5,
                      marginRight: 8,
                    }}
                  >
                    {ancestor.name}
                  </span>
                  <span
                    style={{
                      marginRight: 8,
                    }}
                  >
                    &rsaquo;
                  </span>
                </Fragment>
              ))}

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Text>{action.name}</Text>
              {action.subtitle && <Text sx={{ color: "base.4", fontSize: 0 }}>{action.subtitle}</Text>}
            </Box>
          </Box>

          {action.shortcut?.length ? (
            <Grid sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              {action.shortcut.map((sc) => (
                <kbd
                  key={sc}
                  style={{
                    padding: "2px 6px",
                    background: "rgba(0 0 0 / .1)",
                    borderRadius: "4px",
                    fontSize: 14,
                  }}
                >
                  {sc}
                </kbd>
              ))}
            </Grid>
          ) : null}
        </>
      </Row>
    );
  },
);

ResultItem.displayName = "ResultItem";

function Results() {
  const { results, rootActionId } = useMatches();
  if (results?.length === 0) {
    return <Text sx={{ color: "base.5", p: 4 }}>No items match this search</Text>;
  }

  const filteredResults = results.filter((result) => {
    if (typeof result === "string") {
      return true;
    }

    // Exclude workspace switch options from root.
    if (rootActionId !== "workspace.switch" && result.id.startsWith("workspace.switch.")) {
      return false;
    }

    return true;
  });

  return (
    <KBarResults
      items={filteredResults}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div style={groupNameStyle}>{item}</div>
        ) : (
          <ResultItem action={item} active={active} currentRootActionId={rootActionId ?? ""} />
        )
      }
    />
  );
}

export const CommandBar = ({ children }) => {
  return (
    <CommandBarProvider>
      <KBarProvider actions={[]}>
        <KBarPortal>
          <Row
            sx={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              bg: "rgba(30,30,46,.6)",
              backdropFilter: "blur(12px)",
              justifyContent: "center",
              alignItems: "center",
              zIndex: Indices.Modal,
            }}
          >
            <KBarPositioner>
              <KBarAnimator style={animatorStyle}>
                <Row sx={{ justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
                  <Column sx={{ borderRadius: 1, bg: "white", width: "500px", overflow: "hidden", maxHeight: "100%" }}>
                    <Search />
                    <Results />
                  </Column>
                </Row>
              </KBarAnimator>
            </KBarPositioner>
          </Row>
        </KBarPortal>
        {children}
      </KBarProvider>
    </CommandBarProvider>
  );
};
