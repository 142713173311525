import { FC } from "react";

import { isPresent } from "ts-extras";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { useClientSuccessColumnsQuery } from "src/graphql";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";

const OBJECTS = [
  { label: "Clients", value: "clients" },
  { label: "Contacts", value: "contacts" },
  { label: "Subscriptions", value: "subscriptions" },
];

const ID_FIELDS = {
  clients: [
    { label: "External Id", value: "externalId" },
    { label: "Internal Id", value: "id" },
    { label: "UUID", value: "uuid" },
  ],
  contacts: [{ label: "UUID", value: "uuid" }],
  subscriptions: [{ label: "Internal Id", value: "id" }],
};

const MODES = {
  clients: [
    { label: "Upsert", value: "upsert" },
    { label: "Update", value: "update" },
    { label: "Insert", value: "insert" },
  ],
  contacts: [
    { label: "Upsert", value: "upsert" },
    { label: "Update", value: "update" },
    { label: "Insert", value: "insert" },
  ],
  subscriptions: [
    { label: "Update", value: "update" },
    { label: "Insert", value: "insert" },
  ],
};

export const validation = Yup.object().shape({
  mode: Yup.string().required().default("upsert").oneOf(["upsert", "update", "insert"]),
  object: Yup.string().required(),
  externalIdMapping: COMMON_SCHEMAS.externalIdMapping,
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
});

export const ClientSuccessForm: FC = () => {
  const { config, destination, setConfig } = useDestinationForm();

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = useClientSuccessColumnsQuery(
    {
      destinationId: String(destination?.id),
      object: config.object,
    },
    { enabled: Boolean(config?.object) },
  );

  const columns = columnsData?.clientSuccessDescribeObject;

  const columnOptions =
    columns?.fields?.map((field) => ({
      label: field.name,
      value: field.id,
      type: field.type,
    })) || [];

  const customFieldsOptions =
    columns?.customFields?.filter(isPresent).map((customField) => ({
      label: customField.name,
      value: customField.id,
      type: customField.type,
    })) || [];

  return (
    <>
      <ObjectField options={OBJECTS} />

      {config?.object && (
        <ModeField
          options={MODES[config.object]}
          onChange={(mode) => {
            setConfig({ ...config, mode });
          }}
        />
      )}

      {config?.object && config?.mode && (
        <>
          <Section>
            <IdMappingField options={ID_FIELDS[config.object]} />
          </Section>

          <Section>
            <MappingsField
              error={columnsError?.message}
              loading={loadingColumns}
              options={columnOptions.filter((c) => c.value !== "id")}
              reload={getColumns}
            />
          </Section>

          {(customFieldsOptions || []).length > 0 && (
            <Section>
              <MappingsField
                isCustom
                error={columnsError?.message}
                loading={loadingColumns}
                options={customFieldsOptions}
                reload={getColumns}
              />
            </Section>
          )}
        </>
      )}

      {config?.object && config?.mode && <DeleteField modes={["delete"]} />}
    </>
  );
};

export default {
  form: ClientSuccessForm,
  validation,
};
