import { FC } from "react";

import { times } from "lodash";
import { Grid, Text } from "theme-ui";

import { useParentsQuery } from "src/graphql";
import { AudienceParent } from "src/types/visual";
import { Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Link } from "src/ui/link";
import { Skeleton } from "src/ui/loading";
import { Selectable } from "src/ui/selectable";
import { SourceBadges, SourceIcon, useSources } from "src/utils/sources";

type Props = {
  onSelect: (selection: AudienceParent) => void;
  selection: AudienceParent | undefined;
};

export const ObjectGrid: FC<Readonly<Props>> = ({ onSelect, selection }) => {
  const { data: objectsData, isLoading: loadingObjects } = useParentsQuery();

  const objects = objectsData?.segments;

  const { data: sources, loading: sourcesLoading } = useSources();

  if (loadingObjects || sourcesLoading) {
    return (
      <Grid columns="repeat(3, 260px)" gap={4}>
        {times(3).map((i) => (
          <Skeleton key={i} height="78px" />
        ))}
      </Grid>
    );
  }

  if (objects?.length === 0) {
    return (
      <Column
        sx={{
          width: "100%",
          mt: 4,
          justifyContent: "center",
          alignItems: "center",
          border: "small",
          bg: "white",
          borderRadius: 2,
          p: 8,
        }}
      >
        <Text sx={{ fontSize: 3, fontWeight: "bold" }}>No parent models</Text>
        <Text sx={{ color: "dark.1" }}>Create a parent model to start using audiences</Text>
        <Link sx={{ mt: 4 }} to="/audiences/setup/parent-models/new">
          <Button>Create parent model</Button>
        </Link>
      </Column>
    );
  }

  return (
    <Grid
      gap={4}
      sx={{
        gridTemplateColumns: "repeat(3, 260px)",
        gridAutoRows: "1fr",
      }}
    >
      {objects?.map((object) => {
        const source = sources.find((s) => s.id === object.connection?.id);

        return (
          <Selectable
            key={object.id}
            selected={object.id === selection?.id}
            sx={{ flexDirection: "row", alignItems: "center" }}
            onSelect={() => onSelect(object)}
          >
            <SourceIcon source={source} sx={{ width: "36px", mr: 4, flexShrink: 0 }} />
            <Column>
              <Text sx={{ fontWeight: "bold", textOverflow: "ellipsis", overflow: "hidden" }}>{object.name}</Text>
              <Text
                sx={{ fontSize: 0, color: "dark.0", textOverflow: "ellipsis", overflow: "hidden" }}
              >{`${object.connection?.name}`}</Text>
              <SourceBadges source={source} />
            </Column>
          </Selectable>
        );
      })}
    </Grid>
  );
};
