import { VFC, useState, useMemo } from "react";

import { Controller, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { Grid, Text } from "theme-ui";

import {
  ObjectQuery,
  RelationshipFragment,
  TraitDefinitionFragment,
  useCreateTraitMutation,
  useDeleteTraitMutation,
  useUpdateTraitMutation,
} from "src/graphql";
import { TraitType, TraitTypeOptions, ColumnType } from "src/types/visual";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Input, TextArea } from "src/ui/input";
import { Menu } from "src/ui/menu";
import { Modal } from "src/ui/modal";
import { NewSelect } from "src/ui/new-select";
import { Table } from "src/ui/table";

import { DeleteConfirmationModal } from "../modals/delete-confirmation-modal";

type Model = NonNullable<ObjectQuery["segments_by_pk"]>;

type Props = {
  model: Model;
};

export const ParentTraits: VFC<Readonly<Props>> = ({ model }) => {
  const { traits } = model;

  const { addToast } = useToasts();

  const { mutateAsync: deleteTrait } = useDeleteTraitMutation();

  const [trait, setTrait] = useState<TraitDefinitionFragment | null>(null);
  const [deletingTrait, setDeletingTrait] = useState(false);
  const [creatingTrait, setCreatingTrait] = useState(false);

  const tableColumns = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "relationship.to_model.name",
      name: "Related model",
    },
    {
      key: "type",
      name: "Type",
      cell: (type) => TraitTypeOptions.find((option) => option.value === type)?.label ?? null,
    },
    {
      max: "24px",
      cell: (trait) => {
        return (
          <Menu
            options={[
              {
                label: "Edit",
                onClick: () => setTrait(trait),
              },
              {
                label: "Delete",
                onClick: () => {
                  setTrait(trait);
                  setDeletingTrait(true);
                },
                variant: "danger",
              },
            ]}
            strategy="fixed"
          />
        );
      },
    },
  ];

  return (
    <>
      {traits?.length ? (
        <>
          <Row sx={{ justifyContent: "flex-end", width: "100%", mb: 4 }}>
            <Button onClick={() => setCreatingTrait(true)}>+ Add trait</Button>
          </Row>
          <Table columns={tableColumns} data={traits} />
        </>
      ) : (
        <Column sx={{ p: 8, borderRadius: 1, border: "small", alignItems: "center" }}>
          <Text sx={{ fontWeight: "bold", fontSize: 3, mb: 4, color: "base.5" }}>You haven’t added any traits</Text>
          <Text sx={{ mb: 6, color: "base.5", textAlign: "center", maxWidth: "60ch" }}>
            Traits allow you to define and sync specific data from this model
          </Text>
          <Button onClick={() => setCreatingTrait(true)}>+ Add trait</Button>
        </Column>
      )}
      {(creatingTrait || (trait && !deletingTrait)) && (
        <TraitForm
          modelId={model.id}
          relationships={model.relationships}
          trait={trait}
          onClose={() => {
            setTrait(null);
            setCreatingTrait(false);
          }}
        />
      )}
      <DeleteConfirmationModal
        isOpen={deletingTrait}
        label="trait"
        onClose={() => {
          setTrait(null);
          setDeletingTrait(false);
        }}
        onDelete={async () => {
          if (trait) {
            await deleteTrait({ id: trait.id });
            addToast("Trait deleted", { appearance: "success" });
          }
        }}
      />
    </>
  );
};

export const TraitForm: VFC<
  Readonly<{
    modelId: string;
    trait?: TraitDefinitionFragment | null;
    relationships: RelationshipFragment[];
    onClose: () => void;
  }>
> = ({ trait, onClose, relationships, modelId }) => {
  const { addToast } = useToasts();
  const { register, control, handleSubmit, watch } = useForm({
    defaultValues: {
      name: trait?.name || "",
      type: trait?.type || "",
      relationship_id: trait?.relationship?.id || "",
      config: trait?.config || {},
    },
  });

  const { mutateAsync: updateTrait, isLoading: updating } = useUpdateTraitMutation();
  const { mutateAsync: createTrait, isLoading: creating } = useCreateTraitMutation();

  const type = watch("type");
  const relationshipId = watch("relationship_id");

  const relationshipOptions = useMemo(
    () => relationships.map(({ id, to_model: { name } }) => ({ label: name, value: id })),
    [relationships],
  );
  const propertyOptions = useMemo(() => {
    const columns = relationships.find(({ id }) => id === relationshipId)?.to_model?.filterable_audience_columns;
    if (columns) {
      const validColumns = columns?.filter((column) => (type === TraitType.Sum ? column.type === ColumnType.Number : true));
      return validColumns.map(({ alias, name, column_reference }) => ({
        label: alias || name,
        value: column_reference,
      }));
    }
    return [];
  }, [relationshipId, relationships, type]);

  const submit = async (data) => {
    if (trait) {
      await updateTrait({ id: trait.id, input: data });
      addToast("Trait updated", { appearance: "success" });
    } else {
      await createTrait({ input: { ...data, parent_model_id: modelId } });
      addToast("Trait created", { appearance: "success" });
    }
    onClose();
  };

  const submitting = updating || creating;

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={submitting} onClick={handleSubmit(submit)}>
            {trait ? "Save" : "Add trait"}
          </Button>
        </>
      }
      sx={{ maxWidth: "568px", width: "100%" }}
      title={trait ? "Edit trait" : "Add trait"}
      onClose={onClose}
    >
      <Grid gap={8}>
        <Field label="Name">
          <Input {...register("name")} />
        </Field>
        <Field label="Related model">
          <Controller
            control={control}
            name="relationship_id"
            render={({ field }) => <NewSelect {...field} options={relationshipOptions} />}
          />
        </Field>
        <TraitAggregationFields
          control={control}
          disabled={!relationshipId}
          propertyOptions={propertyOptions}
          type={type as TraitType}
        />
      </Grid>
    </Modal>
  );
};

export const TraitAggregationFields: VFC<
  Readonly<{ type: TraitType; control: any; disabled: boolean; propertyOptions: any }>
> = ({ type, control, propertyOptions, disabled }) => (
  <>
    <Field description="How should the rows be aggregated?" label="Aggregation">
      <Controller control={control} name="type" render={({ field }) => <NewSelect {...field} options={TraitTypeOptions} />} />
    </Field>
    {type === TraitType.RawSql && (
      <>
        <Field description="Rows will be counted according to a custom SQL aggregation." label="SQL">
          <Controller
            control={control}
            name="config.aggregation"
            render={({ field }) => (
              <TextArea {...field} placeholder={"CASE WHEN SUM({{column \"price\"}}) > 100 THEN 'high' ELSE 'low' END`"} />
            )}
          />
        </Field>
        <Field description="Defines the value when the above SQL returns no rows." label="Default value">
          <Controller control={control} name="config.defaultValue" render={({ field }) => <Input {...field} />} />
        </Field>
        <Field description="Defines the type of the resulting value" label="Property type">
          <Controller
            control={control}
            name="config.resultingType"
            render={({ field }) => (
              <NewSelect
                {...field}
                options={[
                  {
                    value: ColumnType.Boolean,
                    label: "Boolean",
                  },
                  {
                    value: ColumnType.Number,
                    label: "Number",
                  },
                  {
                    value: ColumnType.String,
                    label: "String",
                  },
                  {
                    value: ColumnType.Timestamp,
                    label: "Timestamp",
                  },
                ]}
              />
            )}
          />
        </Field>
      </>
    )}
    {type === TraitType.Count && (
      <Field
        description="Rows will be counted according to the distinct values of this column. If not specified, all rows will be counted."
        label="Count by"
      >
        <Controller
          control={control}
          name="config.column"
          render={({ field }) => <NewSelect {...field} disabled={disabled} options={propertyOptions} />}
        />
      </Field>
    )}
    {type === TraitType.Sum && (
      <Field description="The column that will be summed for all rows" label="Sum by">
        <Controller
          control={control}
          name="config.column"
          render={({ field }) => <NewSelect {...field} disabled={disabled} options={propertyOptions} />}
        />
      </Field>
    )}
    {(type === TraitType.MostFrequent || type === TraitType.LeastFrequent) && (
      <>
        <Field description="Frequency will be based on this column" label="Frequency column">
          <Controller
            control={control}
            name="config.toSelect"
            render={({ field }) => <NewSelect {...field} disabled={disabled} options={propertyOptions} />}
          />
        </Field>
      </>
    )}
    {(type === TraitType.First || type === TraitType.Last) && (
      <>
        <Field description="The column that represents the value" label="Trait value">
          <Controller
            control={control}
            name="config.toSelect"
            render={({ field }) => <NewSelect {...field} disabled={disabled} options={propertyOptions} />}
          />
        </Field>
        <Field description="Rows will be ordered according to this column" label="Order by">
          <Controller
            control={control}
            name="config.orderBy"
            render={({ field }) => <NewSelect {...field} disabled={disabled} options={propertyOptions} />}
          />
        </Field>
      </>
    )}
  </>
);
