import { VFC, useState, useRef, useEffect } from "react";

import { Flex } from "theme-ui";

import { WarningModal, ConfettiModal, DiagramDeclaration, DiagramOnboarding } from "src/components/onboarding";
import * as analytics from "src/lib/analytics";
import { Spinner } from "src/ui/loading";
import { useNavigate } from "src/utils/navigate";
import { useQueryString } from "src/utils/use-query-string";

import { OnboardingModal } from "./onboarding-modal";
import { OnboardingActions, OnboardingData, OnboardingLoading } from "./onboarding-reducer";

type Props = {
  currentStep: number;
  onboardingData: OnboardingData;
  setOnboardingData: (action: OnboardingActions) => void;
  loading: OnboardingLoading;
};

const urlIncludeOauthParameters = () =>
  location.search.includes("onboardingDestinationId") || location.search.includes("onboardingSourceId");

export const Diagram: VFC<Props> = ({ currentStep, onboardingData, loading, setOnboardingData }) => {
  const navigate = useNavigate();
  const ignoreQueryString = useRef(false);
  const hideModalTimeout = useRef<number | null>(0);
  const [openModal, setOpenModal] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [currentWarning, setCurrentWarning] = useState<
    "changeSource" | "changeDestination" | "changeModel" | "changeSourceWithModel"
  >();
  const [onboardingModal, setOnboardingModal] = useState<
    | "declareSource"
    | "declareDestination"
    | "connectSource"
    | "connectDestination"
    | "changeSource"
    | "changeDestination"
    | "setupModel"
    | "changeModel"
    | "createSync"
    | undefined
  >();

  const {
    data: { onboardingDestinationId, onboardingSourceId },
  } = useQueryString();
  if (onboardingDestinationId && onboardingModal !== "connectDestination" && !ignoreQueryString.current) {
    setOnboardingModal("connectDestination");
    setOpenModal(true);
  }
  if (onboardingSourceId && onboardingModal !== "connectSource" && !ignoreQueryString.current) {
    setOnboardingModal("connectSource");
    setOpenModal(true);
  }

  const handleChangeClick = (type: "changeSource" | "changeDestination" | "changeModel") => {
    if (type === "changeSource" && onboardingData.onboardingModel) {
      setCurrentWarning("changeSourceWithModel");
      setWarningOpen(true);
    } else {
      handleOnboarding(type);
    }
  };

  const handleOnboarding = (type: typeof onboardingModal) => {
    if (type === "declareSource") {
      analytics.track("Source Declaration Started", {});
    }
    if (type === "declareDestination") {
      analytics.track("Destination Declaration Started", {});
    }
    if (type === "connectSource") {
      analytics.track("Source Config Started", {
        source_type: onboardingData.declareSource?.name,
      });
    }
    if (type === "connectDestination") {
      analytics.track("Destination Config Started", {
        destination_type: onboardingData.declareDestination?.name,
      });
    }
    if (type === "changeSource") {
      analytics.track("Change Source Started", {
        connected_source_id: onboardingData.onboardingSource?.id,
      });
    }
    if (type === "changeDestination") {
      analytics.track("Change Destination Started", {
        connected_destination_id: onboardingData.onboardingDestination?.id,
      });
    }
    if (type === "setupModel") {
      analytics.track("Model Config Started", {
        source_id: onboardingData.onboardingSource?.id,
      });
    }
    if (type === "changeModel") {
      analytics.track("Change Model Started", {
        model_id: onboardingData.onboardingModel?.id,
      });
    }
    if (type === "createSync") {
      analytics.track("Sync Config Started", {
        source_id: onboardingData.onboardingSource?.id,
        model_id: onboardingData.onboardingModel?.id,
        destination_id: onboardingData.onboardingDestination?.id,
      });
    }
    setOnboardingModal(type);
    setOpenModal(true);
  };

  const closeModal = (clearOAuth?: boolean) => {
    // location.search here checks if there are any oauth variables present in the url
    // that would cause an endless loop of close the modal and it automatically being re-opened
    if (clearOAuth && urlIncludeOauthParameters()) {
      ignoreQueryString.current = true;
    }
    setOpenModal(false);
    hideModalTimeout.current = window.setTimeout(() => {
      setOnboardingModal(undefined);
      if (clearOAuth && urlIncludeOauthParameters()) {
        // clear out oauth
        navigate(`/onboarding`);
      }
    }, 400);
  };
  useEffect(() => {
    analytics.track("Onboarding Started", {});

    return () => {
      if (hideModalTimeout.current) {
        clearTimeout(hideModalTimeout.current);
        hideModalTimeout.current = null;
      }
    };
  }, []);

  return (
    <>
      {loading.initial || loading.rest ? (
        <Flex sx={{ width: "100%", height: "70vh", justifyContent: "center", alignItems: "center" }}>
          <Spinner size={64} />
        </Flex>
      ) : (
        <>
          {currentStep === 0 && (
            <DiagramDeclaration
              handleOnboarding={handleOnboarding}
              onboardingData={onboardingData}
              onSetOnboardingData={setOnboardingData}
            />
          )}
          {currentStep > 0 && (
            <DiagramOnboarding
              handleChangeClick={handleChangeClick}
              handleOnboarding={handleOnboarding}
              onboardingData={onboardingData}
              onboardingLoading={loading}
            />
          )}
          {currentStep === 5 && onboardingData.onboardingSync && (
            <ConfettiModal
              destinationIcon={onboardingData.declareDestination?.icon}
              destinationTitle={onboardingData.declareDestination?.name}
              isOpen={true}
              sourceIcon={onboardingData.declareSource?.icon}
              sourceTitle={onboardingData.declareSource?.name}
              sync={onboardingData.onboardingSync}
            />
          )}
        </>
      )}
      <OnboardingModal
        closeModal={closeModal}
        onboardingData={onboardingData}
        onboardingModal={onboardingModal}
        openModal={openModal}
        onSetOnboardingData={setOnboardingData}
      />
      {currentWarning && (
        <WarningModal
          currentWarning={currentWarning}
          isOpen={warningOpen}
          onCancel={() => setWarningOpen(false)}
          onConfirm={() => {
            if (currentWarning === "changeSource" || currentWarning == "changeSourceWithModel") {
              handleOnboarding("changeSource");
            } else if (currentWarning === "changeDestination") {
              handleOnboarding("changeDestination");
            } else if (currentWarning === "changeModel") {
              handleOnboarding("changeModel");
            }
            setWarningOpen(false);
          }}
        />
      )}
    </>
  );
};
