import { VFC, useState } from "react";

import { Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Checkbox } from "src/ui/checkbox";
import { ChevronDownIcon, TableIcon, ObjectIcon, VariableIcon, TraitIcon } from "src/ui/icons";
import { Popout } from "src/ui/popout";
import { RadioGroup } from "src/ui/radio";

import { StandardInput } from "./standard-input";
import { StaticInput } from "./static-input";
import { TemplateInput } from "./template-input";
import { MappingType, FormProps, Props, STATIC_ICONS } from "./types";
import { VariableInput } from "./variable-input";

const INPUTS: Record<MappingType, VFC<FormProps>> = {
  [MappingType.STANDARD]: StandardInput,
  [MappingType.STATIC]: StaticInput,
  [MappingType.VARIABLE]: VariableInput,
  [MappingType.TEMPLATE]: TemplateInput,
};

export const Mapper: VFC<Readonly<Props>> = ({ isError, onChange, placeholder, templates, ...props }) => {
  const [value, setValue] = useState<any>(props.value);

  const MappingInput = value.type ? INPUTS[value.type] : StandardInput;

  return (
    <Popout
      content={({ close }) => (
        <>
          <Row sx={{ flex: 1, overflow: "hidden" }}>
            <Column sx={{ p: 3, borderRight: "small", minWidth: "180px" }}>
              <RadioGroup
                options={[
                  { label: "Column value", value: MappingType.STANDARD },
                  { label: "Static value", value: MappingType.STATIC },
                  { label: "Variable value", value: MappingType.VARIABLE },
                  { label: "Template", value: MappingType.TEMPLATE },
                ]}
                size="small"
                value={value.type}
                onChange={(type) => setValue({ type })}
              />
            </Column>

            <MappingInput templates={templates} value={value} onChange={setValue} />
          </Row>
          <Row sx={{ justifyContent: "space-between", alignItems: "center", p: 2, borderTop: "small" }}>
            <Row>
              {value.type === MappingType.STANDARD && (
                <Checkbox
                  label="Don't sync null values"
                  value={value.ignoreNull}
                  onChange={(ignoreNull) => {
                    setValue({ ...value, ignoreNull: ignoreNull || undefined });
                  }}
                />
              )}
            </Row>
            <Row sx={{ alignItems: "center" }}>
              <Button sx={{ mr: 2 }} variant="secondary" onClick={close}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onChange(value);
                  close();
                }}
              >
                Apply
              </Button>
            </Row>
          </Row>
        </>
      )}
      contentSx={{
        height: "400px",
        width: "700px",
        overflow: "hidden",
      }}
      strategy="fixed"
    >
      <Row
        sx={{
          alignItems: "center",
          flex: 1,
          border: "small",
          borderColor: isError ? "red" : "base.2",
          borderRadius: 1,
          px: 2,
          height: "32px",
          justifyContent: "space-between",
          cursor: "pointer",
          overflow: "hidden",
          transition: "100ms border-color",
          ":hover": {
            borderColor: isError ? "red" : "base.3",
          },
        }}
      >
        <Value isError={Boolean(isError)} placeholder={placeholder} value={props.value} />
        <ChevronDownIcon color="base.3" />
      </Row>
    </Popout>
  );
};

const Value: VFC<Readonly<{ isError: boolean; placeholder: string | undefined; value: any }>> = ({
  isError,
  placeholder,
  value,
}) => {
  const Icon =
    value.type === MappingType.STATIC
      ? STATIC_ICONS[value.valueType]
      : value.type === MappingType.VARIABLE
      ? VariableIcon
      : value.type === MappingType.TEMPLATE
      ? ObjectIcon
      : TableIcon;

  const backgroundColor =
    value.type === MappingType.STATIC
      ? "primaries.1"
      : value.type === MappingType.VARIABLE
      ? "yellows.0"
      : value.type === MappingType.TEMPLATE
      ? "blues.0"
      : "base.1";

  if (
    (value.type === MappingType.STANDARD && typeof value.from === "undefined") ||
    (value.type === MappingType.VARIABLE && typeof value.variable === "undefined") ||
    (value.type === MappingType.STATIC && typeof value.value === "undefined") ||
    (value.type === MappingType.TEMPLATE && typeof value.template === "undefined")
  ) {
    return <Text sx={{ color: "base.3" }}>{placeholder}</Text>;
  }

  return (
    <Row
      sx={{
        alignItems: "center",
        bg: backgroundColor,
        borderColor: isError ? "red" : "base.2",
        borderRadius: 1,
        fontSize: 0,
        fontWeight: "bold",
        px: 1,
        py: "2px",
      }}
    >
      <Icon color="base.5" size={14} sx={{ mr: 2 }} />
      <Text sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{formatValue(value)}</Text>
    </Row>
  );
};

const formatValue = (value: any) => {
  if (typeof value === "object") {
    if (value.variable) {
      return value.variable;
    }
    if (value.from) {
      if (typeof value.from === "object") {
        if (value.from.type === "additionalColumnReference") {
          return (
            <Row sx={{ alignItems: "center" }}>
              <TraitIcon color="base.5" size={16} sx={{ mr: 2 }} />
              <Text sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{value.from.columnAlias}</Text>
            </Row>
          );
        }
        if (value.from.type === "related") {
          return value.from.column.name;
        }
        if (value.from.type === "raw") {
          return value.from.name;
        }
      } else {
        return value.from;
      }
    }
    if (typeof value.value === "undefined") {
      return value.template;
    } else {
      return value.value;
    }
  }
  return value;
};
