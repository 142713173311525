import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SourceIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M19.612 5.717c0 2.073 0 3.82-.039 5.29 0 8.511 1.295 10.811-7.807 10.811-9.101 0-7.846-1.84-7.846-11.5-.23-1.03 0-2.184 0-4.6 0-1.652 3.513-2.99 7.846-2.99s7.846 1.338 7.846 2.99z"
        opacity={0.4}
      />
      <path
        d="M16.818 7.06s-.2 1.667-4.8 1.667-5.2-1.666-5.2-1.666M16.818 12.06s-.2 1.667-4.8 1.667-5.2-1.666-5.2-1.666M16.818 17.06s-.2 1.667-4.8 1.667-5.2-1.666-5.2-1.666"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
    </Icon>
  );
};
