import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { useMixpanelLookupTablesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { ColumnOrConstantField } from "../column-or-constant-field";
import { FromIdField } from "../from-id-field";
import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";
import { TypeField } from "../type-field";

export const validation = Yup.object().shape({
  type: Yup.string().required().default("event"),
  mode: Yup.string().when("type", {
    is: "object",
    then: Yup.string().required().default("upsert"),
    otherwise: Yup.string().notRequired(),
  }),
  object: Yup.string().when("type", {
    is: "object",
    then: Yup.string().required().default("user"),
    otherwise: Yup.string().notRequired(),
  }),
  eventName: Yup.mixed().when("type", {
    is: "event",
    then: COMMON_SCHEMAS.columnOrConstant,
    otherwise: Yup.string().notRequired(),
  }),
  timestampFrom: Yup.mixed().notRequired(),
  userIdFrom: Yup.mixed().when("type", {
    is: "event",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),
  groupKey: Yup.string().when("object", {
    is: "group",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  fromId: Yup.mixed().when("type", {
    is: "object",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),
  lookupTableId: Yup.string().when("type", {
    is: "enrichment",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  externalIdMapping: Yup.mixed().when("type", {
    is: "enrichment",
    then: COMMON_SCHEMAS.externalIdMapping,
    otherwise: Yup.mixed().notRequired(),
  }),
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
});

const FIELDS = [
  { label: "Name", value: "$name" },
  { label: "First Name", value: "$first_name" },
  { label: "Last Name", value: "$last_name" },
  { label: "Email", value: "$email" },
  { label: "Phone", value: "$phone" },
  { label: "Latitude", value: "$latitude" },
  { label: "Longitude", value: "$longitude" },
  { label: "User IP", value: "$ip" },
  { label: "Created", value: "$created" },
];

const OBJECTS = [
  { label: "User", value: "user" },
  { label: "Group", value: "group" },
];

const TYPES = [
  { label: "Event", value: "event" },
  { label: "Object", value: "object" },
  { label: "Enrichment", value: "enrichment" },
];

const MODES = [{ label: "Upsert", value: "upsert" }];

export const MixpanelForm: FC = () => {
  const { hightouchColumns, config, setConfig, errors, reloadModel, loadingModel, destination } = useDestinationForm();

  const {
    data: mixpanelData,
    error: mixpanelError,
    isFetching: loadingMixpanelData,
    refetch: getLookupTables,
  } = useMixpanelLookupTablesQuery({
    destinationId: String(destination?.id),
  });

  const fields = mixpanelData?.mixpanelGetLookupTables;

  const lookupTableOptions =
    fields?.map((field) => ({
      label: field?.name,
      value: field?.id,
    })) || [];

  return (
    <>
      <TypeField options={TYPES} />

      {config?.type && config?.type === "object" && (
        <ObjectField options={OBJECTS} onChange={(object) => setConfig({ type: config?.type, object })} />
      )}

      {config?.type && config?.type === "object" && (
        <ModeField options={MODES} onChange={(mode) => setConfig({ type: config?.type, object: config?.object, mode })} />
      )}

      {config?.type && config?.type === "object" && (
        <Section>
          <Grid gap={8}>
            <FromIdField fieldName={`Mixpanel ${config?.object === "group" ? "Group" : "User"} ID`} />
            {config?.object === "group" && (
              <Field error={errors?.groupKey} label="What is the Mixpanel group key?">
                <Input
                  defaultValue={config?.groupKey}
                  error={errors?.groupKey}
                  placeholder="Enter group key..."
                  sx={{ width: "340px" }}
                  onChange={(value) => {
                    setConfig({
                      ...config,
                      groupKey: value,
                    });
                  }}
                />
              </Field>
            )}
          </Grid>
        </Section>
      )}

      {config?.type && config?.type === "event" && (
        <Section>
          <Grid gap={8}>
            <ColumnOrConstantField
              columnLabel="Which column contains the Mixpanel event name?"
              constantInput={
                <Input
                  defaultValue={config?.eventName}
                  error={errors?.eventName}
                  placeholder="Enter event name..."
                  sx={{ width: "240px" }}
                  onChange={(value) => {
                    setConfig({
                      ...config,
                      eventName: value,
                    });
                  }}
                />
              }
              constantLabel="What is the Mixpanel event name?"
              property="eventName"
            />
            <Field label="Which column contains the Mixpanel distinct user ID?">
              <Select
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.userIdFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, userIdFrom: val });
                }}
              />
            </Field>
            <Field
              optional
              description="If this is empty, Mixpanel will use the time the event arrives at the server."
              label="Which column contains the event timestamp?"
            >
              <Select
                isClearable
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.timestampFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, timestampFrom: val });
                }}
              />
            </Field>
          </Grid>
        </Section>
      )}

      {config?.type !== "enrichment" && (
        <>
          <Section>
            <MappingsField options={config?.object === "user" ? FIELDS : undefined} />
          </Section>

          {config?.object === "user" && (
            <Section>
              <MappingsField isCustom />
            </Section>
          )}
        </>
      )}

      {config?.type === "enrichment" && (
        <>
          <Section>
            <Grid gap={8}>
              <Field error={mixpanelError?.message} label="Which lookup table would you like to replace?">
                <Select
                  isError={errors?.lookupTableId}
                  isLoading={loadingMixpanelData}
                  options={lookupTableOptions || []}
                  placeholder="Select a table..."
                  reload={getLookupTables}
                  value={config?.lookupTableId}
                  width="240px"
                  onChange={(selected) => {
                    const val = selected?.value;
                    setConfig({ ...config, lookupTableId: val });
                  }}
                />
              </Field>
            </Grid>
          </Section>

          <Section>
            <IdMappingField isCreatable />
          </Section>

          <Section>
            <MappingsField isCreatable />
          </Section>
        </>
      )}
    </>
  );
};

export default {
  form: MixpanelForm,
  validation,
};
