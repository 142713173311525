import { useState, VFC } from "react";

import { Text, Flex, Spinner } from "theme-ui";

import { useTunnelsQuery, useTestTunnelQuery } from "src/graphql";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { Link } from "src/ui/link";
import { Select } from "src/ui/select";

import { Permission } from "../permission";
import { CreateReverseTunnelForm, CreateNormalTunnelForm } from "./create-tunnel";

export interface TunnelSelectProps {
  value:
    | {
        id: string | undefined;
        tunnel_id?: string;
      }
    | undefined;
  optional?: boolean;
  onChange: (
    tunnel:
      | {
          id: string | undefined;
          name: string | undefined | null;
        }
      | undefined,
  ) => void;
}

export const TunnelSelect: VFC<TunnelSelectProps> = ({ onChange, value, optional = false }) => {
  const [creatingTunnel, setCreatingTunnel] = useState<boolean>(false);
  const [creatingReverseTunnel, setCreatingReverseTunnel] = useState<boolean>(false);

  const { data } = useTunnelsQuery();
  const tunnelOptions = data?.getTunnels?.map(({ tunnel: { name, id } }) => ({ label: name ?? "", value: id })) ?? [];

  const { data: tunnelTest, status: tunnelTestStatus } = useTestTunnelQuery(
    {
      id: value?.id ?? "",
    },
    {
      enabled: typeof value?.id === "string",
    },
  );

  return (
    <>
      <Permission>
        <Field
          description="Tunnels allow easy access to services running within a private network."
          label="Tunnel"
          optional={optional}
        >
          {tunnelOptions.length || value ? (
            <Select
              isClearable
              options={tunnelOptions}
              placeholder="Select a tunnel..."
              value={value?.id || value?.tunnel_id}
              onChange={(option) => {
                if (option) {
                  onChange({ id: option?.value, name: option?.label });
                } else {
                  onChange(undefined);
                }
              }}
            />
          ) : (
            <Flex sx={{ alignItems: "center" }}>
              <Button variant="secondary" onClick={() => setCreatingTunnel(true)}>
                Create a tunnel
              </Button>
              <Text sx={{ mx: 4, fontSize: 0, fontWeight: "bold" }}>OR</Text>
              <Button variant="secondary" onClick={() => setCreatingReverseTunnel(true)}>
                Create a reverse tunnel
              </Button>
            </Flex>
          )}

          {tunnelTestStatus === "loading" && (
            <Flex sx={{ mt: 2, alignItems: "center" }}>
              <Spinner size={16} />
              <Text sx={{ ml: 2, fontSize: 12 }}>Testing...</Text>
            </Flex>
          )}

          {tunnelTest?.checkTunnel.success && (
            <Flex sx={{ mt: 2, alignItems: "center" }}>
              <Circle color="green" radius="8px" />
              <Text sx={{ ml: 2, fontSize: 12 }}>Active</Text>
            </Flex>
          )}

          {tunnelTest?.checkTunnel.success === false && (
            <Flex sx={{ mt: 2, alignItems: "center" }}>
              <Circle color="red" radius="8px" />
              <Text sx={{ ml: 2, fontSize: "11px" }}>
                Disconnected. Go to{" "}
                <Link newTab to={`/settings/tunnels`}>
                  tunnel settings
                </Link>{" "}
                to re-connect.
              </Text>
            </Flex>
          )}
        </Field>
      </Permission>

      {creatingTunnel && <CreateNormalTunnelForm onClose={() => setCreatingTunnel(false)} />}
      {creatingReverseTunnel && <CreateReverseTunnelForm onClose={() => setCreatingReverseTunnel(false)} />}
    </>
  );
};
