import { Buffer } from "buffer";

import { ReactElement, useEffect } from "react";

import { useLocation } from "react-router-dom";

import { Permission } from "src/components/permission";
import { useGoogleSheetsSourceSpreadSheetsQuery } from "src/graphql";
import { Button } from "src/ui/button";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { Select } from "src/ui/select";

import { FormProps } from "../form";

export default function ConfigurationForm({ config, setConfig, error, name }: Readonly<FormProps>): ReactElement<any, any> {
  function searchUrl() {
    return new URLSearchParams(useLocation().search);
  }

  function noQueryString() {
    return window.location.href.indexOf("?") == -1;
  }

  useEffect(() => {
    const source = new URL(location.href)?.searchParams?.get("source") || null;

    if (source) {
      const decodeSource = JSON.parse(Buffer.from(source, "base64").toString());
      setConfig({
        ...config,
        accessToken: decodeSource.payload.accessToken,
        refreshToken: decodeSource.payload.refreshToken,
      });
    }
  }, []);

  const { data: listSpreadsheetsData, isLoading: listSpreadsheetsLoading } = useGoogleSheetsSourceSpreadSheetsQuery(
    {
      accessToken: config?.accessToken,
    },
    { enabled: Boolean(config?.accessToken) },
  );

  const spreadSheets =
    listSpreadsheetsData?.googlesheetsListSpreadSheets?.map((sheet) => ({ value: sheet, label: sheet.name })) || [];

  const unconnected = !config?.spreadsheetId && (!searchUrl().get("source") || noQueryString());

  const spreadsheet = searchUrl().get("source") || config?.spreadsheetId;

  return (
    <>
      {unconnected && (
        <Permission>
          <Button
            label={`Authorize a new Google account`}
            variant="secondary"
            onClick={() => {
              if (new URL(location.href).pathname.endsWith("/onboarding")) {
                localStorage.setItem("onboardingOauth", "googlesheet");
              } else {
                localStorage.removeItem("onboardingOauth");
              }
              const openUrl = name
                ? `${import.meta.env.VITE_API_BASE_URL}/google-sheets-conn/oauth/?name=${name}`
                : `${import.meta.env.VITE_API_BASE_URL}/google-sheets-conn/oauth/`;
              location.href = openUrl;
            }}
          />
        </Permission>
      )}
      {spreadsheet && (
        <Field label="Spreadsheet">
          {config?.accessToken ? (
            <Select
              isLoading={listSpreadsheetsLoading}
              options={spreadSheets}
              value={spreadSheets.find(({ value }) => value.id === config.spreadsheetId)}
              onChange={({ value }) => {
                setConfig({
                  ...config,
                  name: value.name,
                  spreadsheetId: value.id,
                  url: value.webViewLink,
                });
              }}
            />
          ) : (
            <Input readOnly value={config?.name} />
          )}
        </Field>
      )}
      <FieldError error={error} />
    </>
  );
}
