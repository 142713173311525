import { useMemo } from "react";

import { Image, Text, ThemeUIStyleObject } from "theme-ui";

import {
  ConnectionsBoolExp,
  ConnectionsOrderBy,
  SourceDefinition,
  SourceQuery,
  SourcesQuery,
  useDiscoverSourceMutation,
  useSampleDataSourceDefinitionQuery,
  useSampleDataSourceDefinitionsQuery,
  useSourceDefinitionQuery,
  useSourceDefinitionsQuery,
  useSourceObjectDefinitionsQuery,
  useSourceQuery,
  useSourcesQuery,
  useSourceStrippedConfigurationQuery,
} from "src/graphql";
import { Badge } from "src/ui/badge";

export type SourceConfig = Record<string, unknown>;

export type SourceTunnel = NonNullable<NonNullable<SourceQuery["connections_by_pk"]>["tunnel"]>;

export const useObjectDefinitions = (sourceId: string, enable = true) => {
  const {
    data: objectDefinitionsData,
    isFetching: objectDefinitionsLoading,
    error: objectDefinitionsError,
    refetch: refetchObjectDefinitions,
  } = useSourceObjectDefinitionsQuery({ sourceId }, { enabled: Boolean(sourceId) && enable });

  const { isLoading: discoverLoading, error: discoverError, mutateAsync: discover } = useDiscoverSourceMutation();

  const objectDefinitions = objectDefinitionsData?.object_definitions;

  const objectDefinitionOptions =
    objectDefinitions?.map(({ name, object_definition_group }) => ({
      label: `${object_definition_group?.name}.${name}`,
      value: `${object_definition_group?.name}.${name}`,
    })) || [];

  const refetch = async () => {
    try {
      await discover({ sourceId });
      refetchObjectDefinitions();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    refetch,
    objectDefinitions,
    objectDefinitionOptions,
    loading: discoverLoading || objectDefinitionsLoading,
    error: (discoverError || objectDefinitionsError)?.message,
  };
};

export interface UseSourceResult {
  data:
    | (NonNullable<SourceQuery["connections_by_pk"]> & {
        definition: SourceDefinition;
        config: SourceConfig;
      })
    | null;
  loading: boolean;
  error: Error | null;
  refetch: () => void;
}

export function useSource(id: string | undefined, { pause }: { pause?: boolean } = {}): UseSourceResult {
  const {
    data: sourceData,
    isLoading: sourceLoading,
    error: sourceError,
    refetch: refetchSource,
  } = useSourceQuery(
    {
      id: String(id),
    },
    {
      enabled: !pause && Boolean(id),
    },
  );

  const source = sourceData?.connections_by_pk;

  const {
    data: configData,
    isLoading: configLoading,
    error: configError,
    refetch: refetchConfig,
  } = useSourceStrippedConfigurationQuery(
    {
      connectionId: String(id),
    },
    {
      enabled: !pause && Boolean(id),
    },
  );

  const config = configData?.getSourceStrippedConfiguration;

  const {
    data: definitionData,
    isLoading: definitionLoading,
    error: definitionError,
  } = useSourceDefinitionQuery(
    {
      type: source?.type ?? "",
    },
    {
      enabled: Boolean(source) && !source?.sample_data_source_id,
    },
  );

  const definition = definitionData?.getSourceDefinition;

  const {
    data: sampleDataSourceData,
    isLoading: sampleDataSourceLoading,
    error: sampleDataSourceError,
  } = useSampleDataSourceDefinitionQuery(
    {
      sampleDataSourceId: source?.sample_data_source_id,
    },
    {
      enabled: Boolean(source?.sample_data_source_id),
    },
  );

  const sampleDataSource = sampleDataSourceData?.getSampleDataSourceDefinition;

  const sourceWithConfig = useMemo(() => {
    const sourceDefinition = definition || sampleDataSource;

    if (source && sourceDefinition && config) {
      return { ...source, config, definition: sourceDefinition };
    } else {
      return null;
    }
  }, [source, config, definition, sampleDataSource]);

  return {
    data: sourceWithConfig,
    loading: sourceLoading || configLoading || definitionLoading || sampleDataSourceLoading,
    error: sourceError || configError || definitionError || sampleDataSourceError,
    refetch: () => {
      refetchSource();
      refetchConfig();
    },
  };
}

export interface UseSourcesResult {
  data: Array<
    SourcesQuery["connections"][0] & {
      definition: SourceDefinition | undefined;
      config: Record<string, unknown>;
    }
  >;
  loading: boolean;
  error: Error | null;
  isRefetching: boolean;
  refetch: () => void;
}

export function useSources(
  {
    filters,
    limit,
    orderBy,
    pause,
  }: { filters?: ConnectionsBoolExp; limit?: number; orderBy?: ConnectionsOrderBy; pause?: boolean } = {
    orderBy: { created_at: "desc" },
  },
): UseSourcesResult {
  const {
    data: sourcesData,
    isLoading: sourcesLoading,
    error: sourcesError,
    isRefetching: isRefetchingSources,
    refetch: refetchSources,
  } = useSourcesQuery(
    { filters, limit, orderBy },
    {
      enabled: !pause,
      keepPreviousData: true,
    },
  );

  const sources = sourcesData?.connections;

  const {
    data: definitionsData,
    isLoading: definitionsLoading,
    error: definitionsError,
    isRefetching: isRefetchingSourceDefinitions,
    refetch: refetchSourceDefinitions,
  } = useSourceDefinitionsQuery(undefined, {
    enabled: !pause,
    keepPreviousData: true,
  });

  const definitions = definitionsData?.getSourceDefinitions;

  const {
    data: sampleDataSourcesData,
    isLoading: sampleDataSourcesLoading,
    error: sampleDataSourcesError,
    isRefetching: isRefetchingSampleDataSources,
    refetch: refetchSampleDataSourceDefinitions,
  } = useSampleDataSourceDefinitionsQuery(undefined, {
    enabled: !pause,
    keepPreviousData: true,
  });

  const sampleDataSources = sampleDataSourcesData?.getSampleDataSourceDefinitions;

  const data = useMemo(() => {
    if (sourcesLoading || definitionsLoading || sampleDataSourcesLoading || !sources) {
      return [];
    }

    return sources.map((source) => {
      const definition = source.sample_data_source_id
        ? sampleDataSources?.find((sampleDataSource) => sampleDataSource.type === source.sample_data_source_id)
        : definitions?.find((sourceDefinition) => sourceDefinition.type === source.type);
      return { ...source, config: {}, definition };
    });
  }, [sources, definitions, sampleDataSources, sourcesLoading, definitionsLoading, sampleDataSourcesLoading]);

  const refetch = () => {
    refetchSources();
    refetchSourceDefinitions();
    refetchSampleDataSourceDefinitions();
  };

  return {
    data,
    loading: sourcesLoading || definitionsLoading || sampleDataSourcesLoading,
    error: sourcesError || definitionsError || sampleDataSourcesError,
    isRefetching: isRefetchingSources || isRefetchingSourceDefinitions || isRefetchingSampleDataSources,
    refetch,
  };
}

type SourceTileProps = {
  source:
    | {
        name: string;
        is_demo: boolean | null;
        definition?: {
          name: string;
          icon: string;
          isSampleDataSource: boolean;
        };
      }
    | undefined;
  iconSx?: Record<string, unknown>;
};

export function SourceTile({ source, iconSx = {} }: Readonly<SourceTileProps>): JSX.Element | null {
  return (
    <>
      <SourceIcon source={source} sx={{ width: "24px", ...iconSx }} />
      <Text sx={{ fontWeight: "semi", fontSize: 2, ml: 2 }}>{source?.name}</Text>
      <SourceBadges source={source} />
    </>
  );
}

export function SourceIcon({
  source,
  sx,
}: Readonly<{
  source:
    | {
        definition?: {
          name: string;
          icon: string;
        };
      }
    | undefined
    | null;
  sx: ThemeUIStyleObject;
}>): JSX.Element | null {
  return <Image alt={source?.definition?.name} src={source?.definition?.icon} sx={sx} />;
}

export function SourceBadges({
  source,
  sx = {},
}: Readonly<{
  source:
    | {
        is_demo: boolean | null;
        definition?: {
          isSampleDataSource: boolean;
        };
      }
    | undefined
    | null;
  sx?: ThemeUIStyleObject;
}>): JSX.Element | null {
  return (
    <>
      {/* Sample data sources */}
      {source?.definition?.isSampleDataSource && (
        <Badge sx={{ ml: 2, ...sx }} variant="orange">
          Sample
        </Badge>
      )}
      {/* Demo data sources (Deprecated) */}
      {source?.is_demo && (
        <Badge sx={{ ml: 2, ...sx }} variant="yellow">
          Demo
        </Badge>
      )}
    </>
  );
}
