import { ReactElement } from "react";

import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { SensitiveField } from "src/ui/sensitive-field";

import { FormProps } from "../form";

export default function ConfigurationForm({
  config,
  setConfig,
  error,
  tunnel,
  hideSecret = false,
}: Readonly<FormProps>): ReactElement<any, any> {
  return (
    <>
      <Field label="Url">
        <Input
          disabled={tunnel}
          placeholder="https://my-instance.metabaseapp.com or https://my-ip:3000/"
          value={tunnel ? `Using ${tunnel?.name} tunnel` : config?.url || ""}
          onChange={(value) => setConfig({ ...config, url: value })}
        />
      </Field>

      <Field label="Username">
        <Input value={config?.username || ""} onChange={(value) => setConfig({ ...config, username: value })} />
      </Field>

      <SensitiveField
        hideSecret={hideSecret}
        label="Password"
        value={config?.password || ""}
        onChange={(password) => setConfig({ ...config, password })}
      />

      <Field optional description="Database name for Native SQL queries" label="Database">
        <Input value={config?.database || ""} onChange={(value) => setConfig({ ...config, database: value })} />
      </Field>

      <FieldError error={error} />
    </>
  );
}
