import { useFacebookAdAccountsQuery } from "src/graphql";
import { Select } from "src/ui/select";

export const FacebookAccountSelector = ({ destination, config, setConfig }) => {
  const accountId = config?.adAccountId;
  const setAccountId = (id) => {
    setConfig({ ...config, adAccountId: id });
  };

  const { data: accountsData, isLoading: accountsLoading } = useFacebookAdAccountsQuery({ destId: String(destination?.id) });

  const accounts = accountsData?.facebookListAdAccounts?.accounts;
  const accountOptions = accounts?.map((a) => ({ label: a.name, value: a.id })) || [];

  return (
    <Select
      isLoading={accountsLoading}
      options={accountOptions}
      placeholder="Select an ad account..."
      value={accountId ? accountOptions.find((s) => accountId === s.value) : null}
      width="300px"
      onChange={(selected) => setAccountId(selected.value)}
    />
  );
};
