import { ReactElement } from "react";

import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { Select } from "src/ui/select";
import { SensitiveField } from "src/ui/sensitive-field";

import { FormProps } from "../form";

export default function ConfigurationForm({
  config,
  setConfig,
  tunnel,
  error,
  hideSecret = false,
}: Readonly<FormProps>): ReactElement {
  const protocolOptions = [
    {
      label: "http",
      value: "http",
    },
    {
      label: "https",
      value: "https",
    },
  ];

  return (
    <>
      <Field label="Protocol">
        <Select
          options={protocolOptions}
          placeholder="Select a protocol..."
          value={config?.protocol ? protocolOptions?.find((s) => config?.protocol === s.value) : null}
          onChange={(selected) => {
            setConfig({
              ...config,
              protocol: selected?.value,
            });
          }}
        />
      </Field>
      <Field label="Host">
        <Input
          disabled={tunnel}
          value={tunnel ? `Using ${tunnel?.name} tunnel` : config?.host || ""}
          onChange={(value) => setConfig({ ...config, host: value })}
        />
      </Field>
      <Field label="Port">
        <Input
          disabled={tunnel}
          placeholder="9200"
          value={tunnel ? `Using ${tunnel?.name} tunnel` : config?.port || ""}
          onChange={(value) => setConfig({ ...config, port: value })}
        />
      </Field>

      {/* See https://www.elastic.co/guide/en/elasticsearch/client/javascript-api/current/client-connecting.html for all possible auth.
          For now, we only support username/password. */}
      <Field label="Username">
        <Input
          placeholder="elastic"
          value={config?.username || ""}
          onChange={(value) => setConfig({ ...config, username: value })}
        />
      </Field>

      <SensitiveField
        hideSecret={hideSecret}
        label="Password"
        value={config?.password || ""}
        onChange={(password) => setConfig({ ...config, password })}
      />

      <FieldError error={error} />
    </>
  );
}
