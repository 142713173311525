import { useState } from "react";

import { Grid, Flex, Text } from "theme-ui";

import { useCreateNormalTunnelMutation, useCreateReverseTunnelMutation, useTestTunnelQuery } from "src/graphql";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { CheckCircleIcon, XCircleIcon } from "src/ui/icons";
import { CopyInput, Input, TextArea } from "src/ui/input";
import { Spinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { downloadText } from "src/utils/download";

import { useUser } from "../../contexts/user-context";
import { tunnelAddresses } from "../../utils/network-details";

export const CreateNormalTunnelForm = ({ onClose }) => {
  const [sshHost, setSSHHost] = useState("");
  const [sshPort, setSSHPort] = useState("");
  const [serviceHost, setServiceHost] = useState("");
  const [servicePort, setServicePort] = useState("");
  const [name, setName] = useState("");

  const { isLoading: creating, data, mutateAsync: createNormalTunnel } = useCreateNormalTunnelMutation();

  const tunnel = data?.createNormalTunnel;

  return (
    <Modal
      footer={
        tunnel ? (
          <Button onClick={onClose}>Done</Button>
        ) : (
          <>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={Boolean(!sshHost || !sshPort || !servicePort || !serviceHost)}
              loading={creating}
              onClick={() =>
                createNormalTunnel({
                  input: {
                    name,
                    sshHost,
                    sshPort: Number(sshPort),
                    serviceHost,
                    servicePort: Number(servicePort),
                  },
                })
              }
            >
              Create
            </Button>
          </>
        )
      }
      sx={{ maxWidth: "500px", width: "100%" }}
      title="Create a Tunnel"
      onClose={onClose}
    >
      <Grid gap={8}>
        {tunnel ? (
          <>
            <Field label="Public key">
              <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Input readOnly sx={{ mr: 2 }} value={tunnel.publicKey} />

                <Button
                  variant="secondary"
                  onClick={() => {
                    if (tunnel.publicKey) {
                      downloadText(tunnel.publicKey, "key.pem");
                    }
                  }}
                >
                  Download public key
                </Button>
              </Flex>
            </Field>
            <TestTunnel id={tunnel.tunnel?.id} />
          </>
        ) : (
          <>
            <Field label="Name">
              <Input placeholder="postgres-prod" value={name} onChange={setName} />
            </Field>
            <Field label="SSH Host">
              <Input placeholder="10.24.24.16" value={sshHost} onChange={setSSHHost} />
            </Field>
            <Field label="SSH Port">
              <Input placeholder="22" value={sshPort} onChange={setSSHPort} />
            </Field>
            <Field label="Service Host">
              <Input placeholder="10.20.20.18" value={serviceHost} onChange={setServiceHost} />
            </Field>
            <Field label="Service Port">
              <Input placeholder="5432" value={servicePort} onChange={setServicePort} />
            </Field>
          </>
        )}
      </Grid>
    </Modal>
  );
};

export const CreateReverseTunnelForm = ({ onClose }) => {
  const { region } = useUser();
  const [name, setName] = useState<string>();
  const [downloaded, setDownloaded] = useState<boolean>(false);

  const { isLoading: creating, data, mutateAsync: createReverseTunnel } = useCreateReverseTunnelMutation();

  const tunnel = data?.createReverseTunnel;
  const privateKey = tunnel?.privateKeyBase64 ? atob(tunnel?.privateKeyBase64) : undefined;

  return (
    <Modal
      footer={
        tunnel ? (
          <Button disabled={!downloaded} onClick={onClose}>
            Done
          </Button>
        ) : (
          <>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={!name}
              loading={creating}
              onClick={() => {
                if (name) {
                  createReverseTunnel({ input: { name } });
                }
              }}
            >
              Create
            </Button>
          </>
        )
      }
      sx={{ maxWidth: "500px", width: "100%" }}
      title="Create a Reverse Tunnel"
      onClose={onClose}
    >
      <Grid gap={8}>
        {tunnel ? (
          <>
            <Field description="You will not be able to access your private key again." label="Private key">
              <TextArea readOnly rows={5} value={privateKey} />
            </Field>
            <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <Text sx={{ mr: 4, fontSize: 0, color: "dark.2" }}>We require that you download your private key</Text>
              <Button
                size="small"
                variant="secondary"
                onClick={() => {
                  if (privateKey) {
                    downloadText(privateKey, "key.pem");
                    setDownloaded(true);
                  }
                }}
              >
                Download private key
              </Button>
            </Flex>
            <Field description="Run the following ssh command to start the tunnel." label="Start ssh">
              <CopyInput value={generateTunnelSSHCommand(tunnel, region)} />
            </Field>
            <TestTunnel id={tunnel.tunnel?.id} />
          </>
        ) : (
          <Field label="Name">
            <Input placeholder="postgres-prod" value={name} onChange={setName} />
          </Field>
        )}
      </Grid>
    </Modal>
  );
};

function generateTunnelSSHCommand(tunnel: any, region: string): string {
  return `ssh -i path/to/key.pem -R 0.0.0.0:${tunnel?.tunnel?.tunnelPort}:$SERVICE_HOST:$SERVICE_PORT ${tunnelAddresses[region]} -p ${tunnel?.tunnel?.sshdPort} -o ExitOnForwardFailure=yes`;
}

const TestTunnel = ({ id }) => {
  const { data, isLoading } = useTestTunnelQuery({ id }, { refetchInterval: 5000 });

  return (
    <Field label="Tunnel status">
      <Flex sx={{ alignItems: "center" }}>
        {isLoading ? (
          <Spinner />
        ) : data?.checkTunnel?.success ? (
          <>
            <CheckCircleIcon color="green" />
            <Text sx={{ ml: 2, fontWeight: "semi" }}>Connection successful</Text>
          </>
        ) : (
          <>
            <XCircleIcon color="red" />
            <Text sx={{ ml: 2, fontWeight: "semi" }}>No connection</Text>
          </>
        )}
      </Flex>
    </Field>
  );
};
