import { isPresent } from "ts-extras";

import { usePipedriveDomainsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Select } from "src/ui/select";

export const PipedriveDomainSelector = ({ destination, config, setConfig }) => {
  const setDomain = (domain) => {
    setConfig({ ...config, domain: domain });
  };

  const {
    data: domainData,
    isLoading: domainLoading,
    error: domainError,
  } = usePipedriveDomainsQuery({
    destId: String(destination?.id),
    apiKey: String(config?.apiKey),
  });

  const domains = domainData?.pipedriveListDomains?.domains?.filter(isPresent);

  return (
    <Field error={domainError?.message} label="Pipedrive Domain">
      <Select
        isLoading={domainLoading}
        options={domains}
        placeholder="Select a domain..."
        value={config?.domain}
        width="300px"
        onChange={(selected) => setDomain(selected.value)}
      />
    </Field>
  );
};
