import { Text } from "theme-ui";

import { Avatar } from "src/ui/avatar";
import { Row } from "src/ui/box";
import { formatDate } from "src/utils/time";

export const LastUpdatedColumn = {
  name: "Updated",
  max: "max-content",
  cell: ({ updated_at: timestamp, updated_by_user, created_by_user }) => {
    const name = updated_by_user?.name || created_by_user?.name;

    if (!name && !timestamp) {
      return <Text sx={{ fontWeight: "semi" }}>-</Text>;
    }

    if (!name) {
      return <Text sx={{ fontWeight: "semi" }}>{formatDate(timestamp)}</Text>;
    }

    return (
      <Row sx={{ alignItems: "center" }}>
        <Text sx={{ fontWeight: "semi" }}>{formatDate(timestamp)}</Text>
        <Text sx={{ color: "base.6", mr: 1 }}>&nbsp;by</Text>
        <Avatar name={name} />
      </Row>
    );
  },
};
