import { ReactElement } from "react";

import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { SensitiveField } from "src/ui/sensitive-field";

import { FormProps } from "../form";

export default function ConfigurationForm({
  config,
  setConfig,
  error,
  hideSecret = false,
}: Readonly<FormProps>): ReactElement<any, any> {
  return (
    <>
      <Field label="Service Account Username">
        <Input value={config?.username || ""} onChange={(value) => setConfig({ ...config, username: value })} />
      </Field>

      <SensitiveField
        hideSecret={hideSecret}
        label="Service Account Password"
        value={config?.password || ""}
        onChange={(password) => setConfig({ ...config, password })}
      />

      <Field optional label="Domain">
        <Input
          placeholder={"mixpanel.com"}
          value={config?.domain}
          onChange={(value) => setConfig({ ...config, domain: value })}
        />
      </Field>
      <Field optional label="Region">
        <Input value={config?.region || ""} onChange={(value) => setConfig({ ...config, region: value })} />
      </Field>
      <FieldError error={error} />
    </>
  );
}
