import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Checkbox } from "src/ui/checkbox";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { MappingsField } from "../mappings-field";

export const validation = Yup.object().shape(
  {
    eventType: Yup.string().required(),
    anonymousIdFrom: Yup.mixed().when("userIdFrom", {
      is: (v) => v,
      then: Yup.mixed().notRequired(),
      otherwise: Yup.mixed().required("Either anonymous ID or user ID column must be selected"),
    }),
    userIdFrom: Yup.mixed().when("anonymousIdFrom", {
      is: (v) => v,
      then: Yup.mixed().notRequired(),
      otherwise: Yup.mixed().required("Either anonymous ID or user ID column must be selected"),
    }),

    timestampFrom: Yup.mixed().notRequired(),
    messageIdFrom: Yup.mixed().notRequired(),
    onAdd: Yup.object().when("eventType", {
      is: "genericTrack",
      then: Yup.object().shape({ eventName: Yup.string().required() }),
      otherwise: Yup.object().notRequired(),
    }),
    onChange: Yup.object().notRequired(),
    onRemove: Yup.object().notRequired(),
    sendInitialEvents: Yup.boolean().notRequired(),
    mappings: COMMON_SCHEMAS.mappings,
  },
  [["anonymousIdFrom", "userIdFrom"]],
);

const EVENTS = [
  { label: "Track (Generic)", value: "genericTrack" },
  { label: "Track (Segment Entered / Exited)", value: "track" },
  { label: "Identify", value: "identify" },
];

export const SegmentForm: FC = () => {
  const { hightouchColumns, errors, config, setConfig, reloadModel, loadingModel } = useDestinationForm();

  return (
    <>
      <Section>
        <Field error={errors?.eventType} label="Which event type would you like to send?" size="large">
          <Select
            isError={errors?.eventType}
            options={EVENTS}
            placeholder="Select event type..."
            value={EVENTS?.find((o) => o.value === config?.eventType)}
            width="340px"
            onChange={(selected) => {
              const val = selected.value;
              setConfig({ eventType: val });
            }}
          />
        </Field>
      </Section>

      {config?.eventType && (
        <Section>
          <Grid gap={8}>
            <Field
              description="Either anonymous ID or user ID column must be selected."
              error={errors?.userIdFrom}
              label="Which column contains the event user ID?"
              size="large"
            >
              <Select
                isClearable
                isError={errors?.userIdFrom}
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.userIdFrom}
                width="340px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, userIdFrom: val });
                }}
              />
            </Field>

            <Field
              description="Either anonymous ID or user ID column must be selected."
              error={errors?.anonymousIdFrom}
              label="Which column contains the event anonymous ID?"
              size="large"
            >
              <Select
                isClearable
                isError={errors?.anonymousIdFrom}
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.anonymousIdFrom}
                width="340px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, anonymousIdFrom: val });
                }}
              />
            </Field>
            {(config.eventType === "track" || config.eventType === "genericTrack") && (
              <Checkbox
                label="Send events for initial result set?"
                value={config.sendInitialEvents}
                onChange={(sendInitialEvents) => {
                  setConfig({ ...config, sendInitialEvents });
                }}
              />
            )}
          </Grid>
        </Section>
      )}

      {config.eventType === "genericTrack" && (
        <Section>
          <Grid gap={8}>
            <Field optional label="Which column contains the event timestamp?">
              <Select
                isClearable
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.timestampFrom}
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, timestampFrom: val });
                }}
              />
            </Field>

            <Field optional label="Which column contains the event message ID?">
              <Select
                isClearable
                isLoading={loadingModel}
                options={hightouchColumns}
                placeholder="Select a column..."
                reload={reloadModel}
                value={config?.messageIdFrom}
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, messageIdFrom: val });
                }}
              />
            </Field>
          </Grid>
        </Section>
      )}

      {config?.eventType && (
        <Section>
          <MappingsField />
        </Section>
      )}

      {config.eventType === "genericTrack" && (
        <Section>
          <Grid gap={8}>
            <Field error={errors?.["onAdd.eventName"]} label="Event Name on Row Add">
              <Input
                error={errors?.["onAdd.eventName"]}
                sx={{ width: "180px" }}
                value={config?.onAdd?.eventName || ""}
                onChange={(eventName) => {
                  if (eventName) {
                    setConfig({
                      ...config,
                      onAdd: { eventName },
                    });
                  } else {
                    setConfig({
                      ...config,
                      onAdd: undefined,
                    });
                  }
                }}
              />
            </Field>
            <Field optional label="Event Name on Row Update">
              <Input
                sx={{ width: "180px" }}
                value={config?.onUpdate?.eventName || ""}
                onChange={(eventName) => {
                  if (eventName) {
                    setConfig({
                      ...config,
                      onUpdate: { eventName },
                    });
                  } else {
                    setConfig({
                      ...config,
                      onUpdate: undefined,
                    });
                  }
                }}
              />
            </Field>
            <Field optional label="Event Name on Row Remove">
              <Input
                sx={{ width: "180px" }}
                value={config?.onRemove?.eventName || ""}
                onChange={(eventName) => {
                  if (eventName) {
                    setConfig({
                      ...config,
                      onRemove: { eventName },
                    });
                  } else {
                    setConfig({
                      ...config,
                      onRemove: undefined,
                    });
                  }
                }}
              />
            </Field>
          </Grid>
        </Section>
      )}
    </>
  );
};

export default {
  form: SegmentForm,
  validation,
};
