import { VFC } from "react";

import { Box, Flex } from "theme-ui";

import { Row } from "src/ui/box";
import { SyncCircleIcon, ChevronUpIcon } from "src/ui/icons";

import { colors } from "../../../../design/colors";

export const DiagramIllustrations: VFC = () => {
  return (
    <Row>
      <Flex sx={{ width: "100%" }}>
        <Flex sx={{ flexGrow: 1, alignItems: "center", flexDirection: "column", mx: "20%" }}>
          <Box
            sx={{
              width: "100%",
              border: `2px dotted ${colors.base[3]}`,
              borderTop: "none",
              height: "60px",
              borderRadius: "0 0 14px 14px",
              display: "flex",
            }}
          >
            <Box
              sx={{
                mt: "-2px",
                ml: "-4px",
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                border: `2px solid ${colors.base[3]} `,
              }}
            ></Box>
            <Flex sx={{ flexGrow: 1, justifyContent: "right", mr: "-13px", mt: -2 }}>
              <ChevronUpIcon color={colors.base[3]} />
            </Flex>
          </Box>
          <Box sx={{ mt: -7, background: "white", border: `2px solid ${colors.base[3]}`, p: 2, borderRadius: "50%" }}>
            <SyncCircleIcon color={colors.base[3]} height={32} width={32} />
          </Box>
        </Flex>
      </Flex>
    </Row>
  );
};
