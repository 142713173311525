import { VFC } from "react";

import { Outlet, useLocation } from "react-router-dom";
import { Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { AnimatedBox } from "src/ui/animations";
import { Column } from "src/ui/box";
import { Logo } from "src/ui/brand";
import { LogoutIcon } from "src/ui/icons";
import { Link } from "src/ui/link";

import css from "./auth.module.css";

export const AuthLayout: VFC = () => {
  const location = useLocation();
  const { user } = useUser();

  const isWorkspaces = location.pathname === "/workspaces";

  return (
    <Column sx={{ width: "100vw", height: "100vh" }}>
      {user && (
        <Link
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.4",
            position: "absolute",
            top: 8,
            right: 8,
            cursor: "pointer",
            zIndex: 2,
          }}
          to={`${import.meta.env.VITE_API_BASE_URL}/auth/logout`}
        >
          <LogoutIcon color="base.4" />
          <Text sx={{ ml: 2, fontWeight: "semi" }}>Logout</Text>
        </Link>
      )}

      <Column sx={{ zIndex: 1, width: "100%", alignItems: "center", mt: isWorkspaces ? 10 : 16, pb: 24 }}>
        {!isWorkspaces && <Logo color="base.4" size="100px" sx={{ mb: 12 }} />}

        <Outlet />
      </Column>

      {!isWorkspaces && <Background />}
    </Column>
  );
};

const Background = () => (
  <AnimatedBox
    animate={{ opacity: 1 }}
    initial={{ opacity: 0 }}
    sx={{ position: "absolute", zIndex: 0, top: "300px", width: "100vw" }}
    transition={{ delay: 0.2 }}
  >
    <svg fill="none" viewBox="0 0 1444 248" xmlns="http://www.w3.org/2000/svg">
      <path
        className={css.stroke}
        d="M5.82989 35.4439C853.133 -18.2782 1118.76 356.201 1439.58 122.066"
        stroke="rgba(156,140,236,.75)"
        strokeDasharray="2"
      />
      <path
        className={css.stroke}
        d="M0.760254 43.9474C846.954 -186.23 1135.94 600.158 1443.39 43.9474"
        stroke="rgba(39, 31, 77, 0.24)"
        strokeDasharray="2"
      />
    </svg>
  </AnimatedBox>
);
