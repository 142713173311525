import { FC } from "react";

import { SelectComponentProps } from "../types";
import { Container } from "./container";

export const Value: FC<SelectComponentProps> = (props) => {
  return (
    <Container {...props}>
      {props.selectedOption?.render ? props.selectedOption.render({}) : props.selectedOption?.label}
    </Container>
  );
};
