import { ThemeUICSSObject } from "theme-ui";

const input: ThemeUICSSObject = {
  fontFamily: "inter",
  fontSize: 0,
  minHeight: "32px",
  borderRadius: 1,
  border: "small",
  bg: "white",
  flex: 1,
  width: "unset",
  transition: "border-color 150ms",
  outline: "none !important",
  ":focus:not(:read-only)": {
    borderColor: "primary",
  },
  ":active:not(:read-only)": {
    borderColor: "primary",
  },
  ":disabled": {
    bg: "base.1",
  },
  ":hover:not(:disabled):not(:active):not(:focus):not(:read-only)": {
    borderColor: "base.3",
  },
  "::placeholder": {
    color: "base.4",
    fontSize: 0,
  },
  px: 3,
  py: 0,
};

const inputSmall: ThemeUICSSObject = {
  ...input,
  height: "32px",
};

const inputLarge: ThemeUICSSObject = {
  ...input,
  height: "48px",
  minHeight: "48px",
  fontSize: 2,
  "::placeholder": {
    color: "base.4",
    fontSize: 2,
  },
};

const textarea: ThemeUICSSObject = {
  ...input,
  py: 3,
  height: "auto",
  width: "100%",
};

const label: ThemeUICSSObject = {
  width: "unset",
  fontWeight: "semi",
  fontSize: 1,
  color: "secondary",
};

const labelLarge: ThemeUICSSObject = {
  ...label,
  fontWeight: "semi",
  fontSize: 3,
};

const inlineLabel: ThemeUICSSObject = {
  width: "unset",
  color: "base.6",
};

const checkbox: ThemeUICSSObject = {
  fontSize: 1,
  color: "inherit !important",
  borderWidth: "1px",
  margin: 0,
};

const radio: ThemeUICSSObject = {
  fontSize: 1,
};

export const forms = {
  label,
  labelLarge,
  inlineLabel,
  input,
  inputSmall,
  inputLarge,
  textarea,
  checkbox,
  radio,
};
