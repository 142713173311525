import { useState, VFC } from "react";

import { Text } from "theme-ui";

import { Button } from "src/ui/button";
import { Modal } from "src/ui/modal";

type Props = {
  error: string;
};

export const ErrorModal: VFC<Readonly<Props>> = ({ error }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Button variant="redOutline" onClick={() => setIsOpen(true)}>
        View error
      </Button>
      <Modal
        footer={
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        }
        isOpen={isOpen}
        sx={{ minWidth: "300px" }}
        title="Error"
        onClose={() => setIsOpen(false)}
      >
        <Text sx={{ whiteSpace: "pre-line" }}>{error}</Text>
      </Modal>
    </>
  );
};
