import { VFC } from "react";

import { useToasts } from "react-toast-notifications";
import { Text } from "theme-ui";

import { useDeleteTunnelMutation, useTunnelSourceQuery } from "src/graphql";
import { Button } from "src/ui/button";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";

type Props = {
  onClose: () => void;
  name: string;
  id: string;
  refetch: () => void;
};

export const DeleteTunnel: VFC<Readonly<Props>> = ({ name, id, onClose, refetch }) => {
  const { addToast } = useToasts();

  const { data: tunnelSourceData, isLoading: dependencyLoading } = useTunnelSourceQuery({ tunnelID: id });
  const { isLoading: deleting, mutateAsync: deleteTunnel } = useDeleteTunnelMutation();

  const dependency = tunnelSourceData?.connections?.[0];

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            {dependency ? "Close" : "Cancel"}
          </Button>
          {!dependency && (
            <Button
              loading={deleting}
              variant="red"
              onClick={async () => {
                await deleteTunnel({ id });

                refetch();

                onClose();
                addToast(`${name} successfully deleted.`, {
                  appearance: "success",
                });
              }}
            >
              Delete
            </Button>
          )}
        </>
      }
      title="Delete tunnel"
      onClose={onClose}
    >
      {dependencyLoading ? (
        <Spinner />
      ) : dependency ? (
        <>
          <Text>
            You cannot delete <strong>{name}</strong> because the{" "}
            <Link sx={{ fontWeight: "bold" }} to={`/sources/${dependency.id}`}>
              {dependency.name}
            </Link>{" "}
            source depends on it.
          </Text>
        </>
      ) : (
        <Text>
          Are you sure you want to delete the <strong>{name}</strong> tunnel?
        </Text>
      )}
    </Modal>
  );
};
