import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { SensitiveField } from "src/ui/sensitive-field";

export const LookerForm = ({ config, setConfig, error, hideSecret = false }) => {
  return (
    <>
      <Field label="Url">
        <Input value={config?.url || ""} onChange={(value) => setConfig({ ...config, url: value })} />
      </Field>
      <Field label="Client ID">
        <Input value={config?.clientId || ""} onChange={(value) => setConfig({ ...config, clientId: value })} />
      </Field>

      <SensitiveField
        hideSecret={hideSecret}
        label="Client Secret"
        value={config?.clientSecret || ""}
        onChange={(clientSecret) => setConfig({ ...config, clientSecret })}
      />

      <Field label="Connection Name">
        <Input value={config?.connectionName || ""} onChange={(value) => setConfig({ ...config, connectionName: value })} />
      </Field>

      <FieldError error={error} />
    </>
  );
};
