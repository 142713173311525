import { ReactElement } from "react";

import * as Yup from "yup";

import { Field, FieldError } from "src/ui/field";
import { Input, TextArea } from "src/ui/input";
import { SensitiveField } from "src/ui/sensitive-field";

import { CustomQuery, CustomQueryFormProps, CustomQueryViewProps } from "./custom-query";

const ConfigurationForm = ({ config, setConfig, error }) => {
  return (
    <>
      <Field label="Host">
        <Input value={config?.host || ""} onChange={(host) => setConfig({ ...config, host })} />
      </Field>

      <Field label="Port">
        <Input value={config?.port || "22"} onChange={(port) => setConfig({ ...config, port })} />
      </Field>

      <Field label="Username">
        <Input value={config?.username || ""} onChange={(username) => setConfig({ ...config, username })} />
      </Field>

      <SensitiveField
        hideSecret={true}
        label="Password"
        value={config?.password || ""}
        onChange={(password) => setConfig({ ...config, password })}
      />

      <Field label="Private Key">
        <TextArea
          placeholder="<REDACTED>"
          value={config?.privateKey || ""}
          onValue={(privateKey) => setConfig({ ...config, privateKey })}
        />
      </Field>

      <SensitiveField
        hideSecret={true}
        label="Private Key Passphrase"
        value={config?.privateKeyPassphrase || ""}
        onChange={(privateKeyPassphrase) => setConfig({ ...config, privateKeyPassphrase })}
      />

      <FieldError error={error} />
    </>
  );
};

const PATH_LABEL = "Path to source CSV file";
const PATH_DESCRIPTION = "Header row is required";

function QueryForm({ query, onChange }: Readonly<CustomQueryFormProps>): ReactElement<any, any> {
  return (
    <Field description={PATH_DESCRIPTION} label={PATH_LABEL} size="large">
      <Input
        placeholder="/path/to/file.csv"
        value={query?.path}
        onChange={(path) =>
          onChange({
            path,
            type: "string",
          })
        }
      />
    </Field>
  );
}

function QueryView(props: Readonly<CustomQueryViewProps>): ReactElement<any, any> {
  return (
    <Field description={PATH_DESCRIPTION} label={PATH_LABEL} size="large">
      <code>{props.query.path}</code>
    </Field>
  );
}

const querySchema = Yup.lazy<CustomQuery | undefined>((_) => {
  return Yup.object().shape({ type: Yup.string().required() });
});

function isConfigurationComplete(config: Record<string, unknown>): boolean {
  // Host and username are required, along with at least one authentication method.
  // Private key passphrase is never required.
  return Boolean(config?.host && config?.username && (config?.password || config?.privateKey));
}

export default { ConfigurationForm, QueryForm, QueryView, querySchema, isConfigurationComplete };
