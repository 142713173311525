import { VFC, useMemo } from "react";

import moment from "moment";
import { Text, Grid } from "theme-ui";

import { Circle } from "src/ui/circle";
import { Tooltip } from "src/ui/tooltip";
import { WEEK_DAYS } from "src/utils/constants";

import { Schedule as ScheduleObject, ScheduleType, ScheduleExpression, ScheduleInterval } from "./types";
import { UTCDayToLocalDay } from "./visual-cron-expression";

type ScheduleProps = {
  schedule: ScheduleObject | undefined;
};

export const Schedule: VFC<Readonly<ScheduleProps>> = ({ schedule }) => {
  switch (schedule?.type) {
    case ScheduleType.DBT_CLOUD:
      return <DBT account={schedule.schedule?.account?.name} job={schedule.schedule?.job?.name} />;
    case ScheduleType.INTERVAL:
      return <Interval interval={schedule.schedule?.interval} />;
    case ScheduleType.CRON:
      return <Cron expression={schedule.schedule?.expression} />;
    case ScheduleType.CUSTOM:
      return <VisualCron expressions={schedule.schedule?.expressions ?? []} />;
    default:
      return <Manual />;
  }
};

interface VisualCronProps {
  expressions: ScheduleExpression[];
}

export const VisualCron: VFC<VisualCronProps> = ({ expressions }) => (
  <Grid gap={2} sx={{}}>
    {expressions.map((expression, index) => {
      if (Object.keys(expression?.days || {}).length === 7) {
        return <Text>Every day at {moment.utc(expression?.time, "HH:mm").local().format("h:mm A")}</Text>;
      }

      return <VisualCronExpression key={index} expression={expression} />;
    })}
  </Grid>
);

interface VisualCronExpressionProps {
  expression: ScheduleExpression;
}

const VisualCronExpression: VFC<VisualCronExpressionProps> = ({ expression }) => {
  const selectedDays = useMemo(() => {
    return Object.keys(expression?.days ?? {}).map((day) => {
      return UTCDayToLocalDay(expression.time, Number(moment(day, "dddd").format("e")));
    });
  }, [expression]);

  return (
    <Grid gap={2} sx={{ display: "flex", alignItems: "center" }}>
      {WEEK_DAYS.map((day, index) => {
        const isSelected = selectedDays.includes(index);

        return (
          <Tooltip key={index} text={day}>
            <Circle
              radius="20px"
              sx={{
                bg: isSelected ? "primaries.0" : undefined,
                border: "small",
                borderColor: isSelected ? "primary" : "base.2",
              }}
            >
              <Text sx={{ fontSize: "10px", color: isSelected ? "primary" : "base.5" }}>{day[0]}</Text>
            </Circle>
          </Tooltip>
        );
      })}

      <Text sx={{ fontWeight: "semi" }}>at {moment.utc(expression?.time, "HH:mm").local().format("h:mm A")}</Text>
    </Grid>
  );
};

interface CronProps {
  expression: string | undefined;
}

export const Cron: VFC<CronProps> = ({ expression }) => <Text sx={{ fontWeight: "semi" }}>{expression}</Text>;

interface IntervalProps {
  interval: ScheduleInterval | undefined;
}

export const Interval: VFC<IntervalProps> = ({ interval }) => (
  <Text sx={{ fontWeight: "semi" }}>
    Every {interval?.quantity} {interval?.unit}s
  </Text>
);

export const Manual: VFC = () => <Text sx={{ fontWeight: "semi" }}>Manual</Text>;

interface DbtProps {
  job: string | undefined;
  account: string | undefined;
}

export const DBT: VFC<DbtProps> = ({ job, account }) => (
  <Text sx={{}}>
    <strong>dbt Cloud</strong> ({account} - {job})
  </Text>
);
