import { FC } from "react";

import { Box, ThemeUIStyleObject, Text } from "theme-ui";

import { InfoIcon } from "../icons";
import { Popout } from "../popout";
import { Placement } from "../popout/popout";

export type TooltipProps = {
  text: string;
  sx?: ThemeUIStyleObject;
  size?: number;
  placement?: Placement;
  disabled?: boolean;
  portal?: boolean;
  theme?: "light" | "dark";
  hasCaret?: boolean;
};

export const Tooltip: FC<Readonly<TooltipProps>> = ({
  text,
  size = 16,
  sx = {},
  children,
  placement = "top",
  disabled,
  portal,
  theme = "light",
  hasCaret = false,
}) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <Popout
      content={<Text sx={{ fontSize: 0, fontWeight: "inherit", whiteSpace: "pre-line" }}>{text}</Text>}
      contentSx={{
        borderRadius: 2,
        maxWidth: "480px",
        p: 2,
        backgroundColor: theme === "dark" ? "base.9" : "white",
        borderColor: theme === "dark" ? "base.9" : "white",
        color: theme === "dark" ? "white" : "black",
        // caret coming from the bottom of the toolip.
        "::before": {
          content: hasCaret ? "''" : "none",
          position: "absolute",
          bottom: "-5px",
          left: "50%",
          width: "10px",
          height: "10px",
          backgroundColor: theme === "dark" ? "base.9" : "white",
          transform: "translateX(-50%) rotate(45deg)",
          zIndex: "-1",
        },
      }}
      placement={placement}
      portal={portal}
    >
      {({ isOpen, open, close }) => (
        <Box
          sx={{ cursor: "pointer", width: "max-content", ...sx }}
          onMouseEnter={() => {
            open();
          }}
          onMouseLeave={() => {
            close();
          }}
        >
          {children || <InfoIcon color={isOpen ? "primary" : "base.4"} size={size} />}
        </Box>
      )}
    </Popout>
  );
};
