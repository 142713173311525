import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";
import { TypeField } from "../type-field";

export const validation = Yup.object().shape({
  type: Yup.string().required().default("object"),
  object: Yup.string().when("type", {
    is: "object",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  mode: Yup.string().when("type", {
    is: "object",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  timestampFrom: Yup.mixed().notRequired(),
  customTableId: Yup.string().when("type", {
    is: "enrichment",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  accountId: Yup.string().when("type", {
    is: "enrichment",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  eventName: Yup.string().when("type", {
    is: "event",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),

  externalIdMapping: Yup.object().when("type", {
    is: "event",
    then: Yup.object().notRequired(),
    otherwise: COMMON_SCHEMAS.externalIdMapping,
  }),
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
});

const TYPES = [
  { label: "Event", value: "event" },
  { label: "Enrichment", value: "enrichment" }, // batch import custom table
  { label: "Object", value: "object" },
];
const OBJECTS = [
  { label: "Contact", value: "contact" },
  { label: "Account", value: "account" },
];

const MODES = [{ label: "Upsert", value: "upsert" }];

const ID_MAPPING = {
  contact: [
    {
      label: "Contact External Id",
      value: "contactExternalId",
    },
  ],
  account: [{ label: "Account External Id", value: "accountExternalId" }],
};

export const ChurnzeroForm: FC = () => {
  const { config, setConfig, errors, hightouchColumns } = useDestinationForm();

  const OPTIONS = {
    enrichment: [],
    event: [
      { label: "Account External Id", value: "accountExternalId", type: StandardFieldType.STRING, required: true },
      { label: "Contact External Id", value: "contactExternalId", type: StandardFieldType.STRING, required: true },
      { label: "Description", value: "description", type: StandardFieldType.STRING },
      { label: "Quantity", value: "quantity", type: StandardFieldType.NUMBER },
    ],

    object:
      config?.object === "contact"
        ? [
            { label: "Account External Id", value: "accountExternalId", type: StandardFieldType.STRING, required: true },
            { label: "FirstName", value: "attr_FirstName" },
            { label: "LastName", value: "attr_LastName" },
            { label: "Email", value: "attr_Email" },
          ]
        : [
            { label: "Name", value: "attr_Name" },
            { label: "NextRenewalDate", value: "attr_NextRenewalDate" },
            { label: "TotalContractAmount", value: "attr_TotalContractAmount" },
            { label: "IsActive", value: "attr_IsActive" },
            { label: "BillingAddressLine1", value: "attr_BillingAddressLine1" },
            { label: "BillingAddressLine2", value: "attr_BillingAddressLine2" },
            { label: "BillingAddressCity", value: "attr_BillingAddressCity" },
            { label: "BillingAddressState", value: "attr_BillingAddressState" },
            { label: "BillingAddressZip", value: "attr_BillingAddressZip" },
            { label: "StartDate", value: "attr_StartDate" },
            { label: "EndDate", value: "attr_EndDate" },
            { label: "LicenseCount", value: "attr_LicenseCount" },
            { label: "OwnerUserAccount", value: "attr_OwnerUserAccount" },
            { label: "ParentAccountExternalId", value: "attr_ParentAccountExternalId" },
          ],
  };
  return (
    <>
      <TypeField options={TYPES} />
      {config?.type === "object" && (
        <ObjectField
          options={OBJECTS}
          onChange={(object) => {
            setConfig({ type: config?.type, object });
          }}
        />
      )}
      {config?.type === "object" && (
        <ModeField
          options={MODES}
          onChange={(mode) => {
            setConfig({ type: config?.type, object: config?.object, mode });
          }}
        />
      )}
      {config?.type === "enrichment" && (
        <Section>
          <Grid gap={8}>
            <Field
              description="Input the custom table ID you will be submitting to. Each record in your custom table should have an identifier associated with it."
              label="Custom Table ID"
              size="large"
            >
              <Input
                placeholder="Enter custom table ID..."
                sx={{ width: "240px" }}
                value={config?.customTableId || ""}
                onChange={(value) => {
                  setConfig({ ...config, customTableId: value });
                }}
              />
            </Field>
            <Field
              description="Enter the Account ID to ChurnZero. This will allow the Custom Table to show on the correct account in ChurnZero."
              error={errors?.accountId}
              label="What is Account ID?"
              size="large"
            >
              <Input
                error={errors?.accountId}
                placeholder="Enter account id..."
                sx={{ width: "240px" }}
                value={config?.accountId}
                onChange={(value) => {
                  setConfig({
                    ...config,
                    accountId: value,
                  });
                }}
              />
            </Field>
          </Grid>
        </Section>
      )}
      {config?.type === "object" && <IdMappingField options={ID_MAPPING?.[config?.object]} />}
      {config?.type === "enrichment" && <IdMappingField />}
      {config?.type === "event" && (
        <Section>
          <Grid gap={8}>
            <Field
              description="Enter the type of events you are syncing to ChurnZero. If the Event Name is not found in ChurnZero, it will be created."
              error={errors?.eventName}
              label="What is the event name?"
              size="large"
            >
              <Input
                error={errors?.eventName}
                placeholder="Enter event name..."
                sx={{ width: "240px" }}
                value={config?.eventName}
                onChange={(value) => {
                  setConfig({
                    ...config,
                    eventName: value,
                  });
                }}
              />
            </Field>
            <Field
              optional
              description="If this is empty, Hightouch will use the time the event arrives at the server. Mapping a timestamp in the field mappings will overwrite this field."
              error={errors?.timestampFrom}
              label="Which column contains the event timestamp?"
              size="large"
            >
              <Select
                isClearable
                isError={errors?.timestampFrom}
                options={hightouchColumns}
                placeholder="Select a column..."
                value={config?.timestampFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, timestampFrom: val });
                }}
              />
            </Field>
          </Grid>
        </Section>
      )}
      {config?.type && (
        <>
          {config?.type !== "enrichment" && (
            <>
              <Section>
                <MappingsField options={OPTIONS?.[config?.type]} />
              </Section>
              <Section>
                <MappingsField isCustom />
              </Section>
            </>
          )}

          {config?.type === "enrichment" && (
            <Section>
              <MappingsField />
            </Section>
          )}
        </>
      )}
    </>
  );
};

export default { form: ChurnzeroForm, validation };
