import { VFC } from "react";

import { KeyValueMapping } from "src/components/destinations/key-value-mapping";
import { Button } from "src/ui/button";
import { Code } from "src/ui/code";
import { Field } from "src/ui/field";
import { Input, TextArea } from "src/ui/input";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";
import { Select, Option } from "src/ui/select";
import { Toggle } from "src/ui/toggle";

import { useCloudCredentialQuery } from "../../graphql";
import { SelectCredential } from "../credentials";
import { TunnelSelect, TunnelSelectProps } from "../tunnels/tunnel-select";

type Config = Record<string, unknown>;

interface Props {
  config: Config | undefined | null;
  credentialId: string | undefined;
  definition: {
    type: string;
    configurationForm?: Array<ConfigurationFormField> | null;
  };
  destination?: { id: string } | null;
  setConfig: (config: Config) => void;
  setCredentialId: (credentialId: string) => void;
}

interface ConfigurationFormField {
  type: string;
  label: string;
  excludeIfFieldPresent?: string;
  excludeIfFieldEmpty?: string;
  secret?: string;
  placeholder?: string;
  value: string;
  options?: Option[];
}

export const SetupForm: VFC<Props> = ({ config, setConfig, credentialId, setCredentialId, definition, destination = null }) => {
  const slug = definition.type;
  const configurationForm = definition.configurationForm;

  const { data: credentialData } = useCloudCredentialQuery(
    { id: credentialId ?? "" },
    {
      enabled: Boolean(credentialId),
    },
  );

  const credentialConfig = credentialData?.cloud_credentials_by_pk?.stripped_config;

  const configToField = (field: ConfigurationFormField) => {
    switch (field.type) {
      case "message":
        return <Message>{field.label || field.value}</Message>;

      case "textarea":
        return (
          <TextArea
            placeholder={field.secret && destination ? "<REDACTED>" : field.placeholder || field.label}
            value={(config?.[field.value] as string | undefined) || ""}
            onValue={(value) => setConfig({ ...config, [field.value]: value })}
          />
        );

      case "tunnel":
        return (
          <TunnelSelect
            optional
            value={config?.[field.value] as TunnelSelectProps["value"]}
            onChange={(value) => {
              if (!value) {
                setConfig({ ...config, tunnel: null });
              } else {
                setConfig({ ...config, tunnel: value });
              }
            }}
          />
        );

      case "boolean":
        return (
          <Toggle
            value={(config?.[field.value] as boolean | undefined) || false}
            onChange={(value) => setConfig({ ...config, [field.value]: !!value })}
          />
        );

      case "select":
        return (
          <Select
            options={field.options}
            placeholder={field.placeholder || field.label}
            value={field.options?.find((option) => option.value === config?.[field.value])}
            onChange={({ value }) => setConfig({ ...config, [field.value]: value })}
          />
        );

      default:
        return (
          <Input
            placeholder={field.secret && destination ? "<REDACTED>" : field.placeholder || field.label}
            type={field.secret ? "password" : undefined}
            value={(config?.[field.value] as string | undefined) || ""}
            onChange={(value) => {
              const newConfig = { ...config };
              newConfig[field.value] = value;
              setConfig(newConfig);
            }}
          />
        );
    }
  };

  return (
    <>
      {configurationForm?.map((field) => {
        // Some config fields should be excluded if another field is set.
        if (field.excludeIfFieldPresent && config?.[field.excludeIfFieldPresent]) {
          return null;
        }

        // Unless field's valueis already set, exclude the field from the form.
        // Enables backward compatibility for some destinations (e.g. S3).
        if (field.excludeIfFieldEmpty && config?.[field.excludeIfFieldEmpty] == null) {
          return null;
        }

        return (
          <Field key={field.label} label={field.label}>
            {configToField(field)}
          </Field>
        );
      })}

      {(slug === "webhook" || slug === "custom") && (
        <Field label={"Headers"}>
          <KeyValueMapping
            mapping={(config as any)?.headers}
            setMapping={(map) => {
              setConfig({ ...config, headers: map });
            }}
          />
        </Field>
      )}

      {slug === "s3" && config?.awsAccessKeyId == null && (
        <>
          <Field label={"AWS Credentials"}>
            <SelectCredential provider={"aws"} value={credentialId} onChange={(value) => setCredentialId(value)} />
          </Field>
        </>
      )}

      {(slug === "gcs" || slug === "gam360") && (
        <>
          <Field label={"GCP Credentials"}>
            <SelectCredential provider={"gcp"} value={credentialId} onChange={(value) => setCredentialId(value)} />
          </Field>

          {config?.projectId && credentialId && credentialConfig && (
            <Field
              description={"Run this commands in your Google Cloud Shell to grant the service account access to Cloud Storage."}
              label="Grant permissions"
            >
              <Code title="Add user role">
                <div>gcloud projects add-iam-policy-binding {config?.projectId} \ </div>
                <div>--member serviceAccount:{credentialConfig.client_email} \ </div>
                <div>--role roles/storage.objectAdmin</div>
              </Code>
              <br />
            </Field>
          )}
        </>
      )}

      {slug === "googleSheetsSA" &&
        (destination ? (
          <Field label="Generate a new Google Service Account">
            <Link to={`${import.meta.env.VITE_API_BASE_URL}/google-sheets/service/${slug}/${destination.id}`}>
              <Button>{`Generate new service account`}</Button>
            </Link>
          </Field>
        ) : (
          <Field label="Generate a Google Service Account">
            <Link to={`${import.meta.env.VITE_API_BASE_URL}/google-sheets/service/${slug}`}>
              <Button>{`Generate service account`}</Button>
            </Link>
          </Field>
        ))}
    </>
  );
};
