import { VFC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { ArrowRightIcon } from "../icons";

type ArrowProps = {
  bg?: string;
  color?: string;
  sx?: ThemeUIStyleObject;
  size?: number;
};

export const Arrow: VFC<Readonly<ArrowProps>> = ({ color = "base.3", size = 16, sx = {} }) => (
  <ArrowRightIcon color={color} size={size} sx={{ mx: 4, ...sx }} />
);
