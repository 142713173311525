import { useEffect, useState, VFC } from "react";

import { Image, Text } from "theme-ui";

import { UseSourcesResult } from "src/utils/sources";

import { LookerLook, useGetLookerLookQuery, useSearchLookerLooksQuery } from "../../graphql";
import { Column, Row } from "../../ui/box";
import { Button } from "../../ui/button";
import { LookerIcon } from "../../ui/icons";
import { SearchInput } from "../../ui/input";
import { Link } from "../../ui/link";
import { Spinner } from "../../ui/loading";
import { SimplePagination } from "../../ui/table";
import { LookerQuery } from "./preview/looker-query";
import { SelectorRow } from "./selector-row";

type Source = UseSourcesResult["data"][0];

type Props = {
  lookerLookId?: string | null;
  onChange: (lookerModel: any) => void;
  source: Source;
};

export const LookerSelector: VFC<Readonly<Props>> = ({ lookerLookId, onChange, source }) => {
  const [page, setPage] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [nextLoading, setNextLoading] = useState<boolean>(false);
  const [previousLoading, setPreviousLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");
  const [autoFillSearch, setAutoFillSearch] = useState<boolean>(true);
  const [exactSearch, setExactSearch] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 350);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  // Looker instances with a lot of Looks can choke on larger page sizes
  const pageSize = 5;

  const { data: lookerLookQuery } = useGetLookerLookQuery(
    { id: lookerLookId ?? "", withSql: true },
    { enabled: lookerLookId != null && lookerLookId !== "" },
  );
  const look = lookerLookQuery?.getLookerLook?.look;

  // Exact search when we first load page with existing Look set for a model.
  // Otherwise make every search a substring search.
  // NOTE: This condition works because we when we have a currently selected Look,
  // we set `search` to the Look's title upon loading. Else `search` is an empty string
  // to begin with. Additionally, `exactSearch` is set to true when we first load the page but is
  // reset to false when we change anything in the search input.
  let searchTitle: string;
  if (exactSearch && search) {
    searchTitle = look?.title ?? "";
  } else {
    searchTitle = "%" + (debouncedSearch ? debouncedSearch + "%" : "");
  }

  const {
    data: searchQuery,
    error: searchError,
    isLoading: searchLoading,
    refetch: searchAgain,
  } = useSearchLookerLooksQuery({
    title: searchTitle,
    limit: pageSize,
    offset,
  });

  const looks = searchQuery?.searchLookerLooks?.looks ?? [];
  const noLooks = !searchLoading && !looks?.length;
  const activeLookOnPage = !!looks?.find(({ id }) => id === lookerLookId);

  useEffect(() => {
    if (nextLoading) {
      setPage((page) => page + 1);
      setNextLoading(false);
    }
    if (previousLoading) {
      setPage((page) => page - 1);
      setPreviousLoading(false);
    }
  }, [looks, setPage, setPreviousLoading, setNextLoading]);

  // Auto-fills the search field with the currently selected Looker Look title.
  function getSearchValue(look: LookerLook | undefined) {
    if (autoFillSearch && look) {
      setAutoFillSearch(false);
      setSearch(look.title);
    }

    return search;
  }

  // TODO structured error codes
  const narrowYouSearchError = searchError?.message?.includes("narrow your search");

  if (searchError && !narrowYouSearchError) {
    return (
      <Column sx={{ pt: 10, justifyContent: "center", alignItems: "center", width: "100%" }}>
        <Text sx={{ color: "red" }}>We were not able to connect to your Looker instance.</Text>
        <Text sx={{ color: "red" }}>
          Please check that your Looker credentials are configured correctly in{" "}
          <Link to="/settings/integrations">settings</Link>.
        </Text>
      </Column>
    );
  }

  return (
    <Column sx={{ flex: 1, overflow: "hidden" }}>
      <Row sx={{ width: "100%", alignItems: "center", justifyContent: "space-between", pb: 2, borderBottom: "small" }}>
        <Row sx={{ alignItems: "center" }}>
          <Image alt={source?.definition?.name} src={source?.definition?.icon} width="24px" />
          <Text sx={{ fontWeight: "semi", fontSize: 3, ml: 2 }}>{source.name}</Text>
        </Row>

        <Row sx={{ alignItems: "center", ml: 3 }}>
          <SearchInput
            placeholder="Search for Looks by title..."
            value={getSearchValue(look)}
            onChange={(search) => {
              // Ensures that if we do have a currently selected Look, we only
              // perform an exact search for it on the first render.
              if (exactSearch) {
                setExactSearch(false);
              }
              setSearch(search);
              setPage(0);
              setOffset(0);
            }}
          />

          <Button loading={searchLoading} sx={{ ml: 3 }} variant="secondary" onClick={() => searchAgain()}>
            Refresh
          </Button>
        </Row>
      </Row>
      {searchLoading ? (
        <Column sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
          <Spinner size={64} />
        </Column>
      ) : noLooks && page === 0 ? (
        <Column sx={{ pt: 10, justifyContent: "center", alignItems: "center", width: "100%" }}>
          {narrowYouSearchError ? (
            <Text sx={{ color: "red" }}>{searchError?.message}</Text>
          ) : search ? (
            <Text sx={{ color: "red" }}>We could not find any Looks matching your search.</Text>
          ) : (
            <Text>Enter a title in the search bar to find Looks.</Text>
          )}
        </Column>
      ) : (
        <>
          <Row sx={{ overflow: "hidden", flex: 1 }}>
            <Column sx={{ overflow: "auto", flex: 1 }}>
              {page !== 0 && !looks?.length ? (
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ margin: "auto", py: 8 }}>No more Looks! Why don&apos;t you go back a page?</Text>
                </Row>
              ) : (
                looks?.map((look) => {
                  const activeLook = look?.id === lookerLookId;
                  return (
                    <SelectorRow
                      key={look?.id}
                      icon={<LookerIcon size={16} />}
                      selected={activeLook}
                      onClick={() => {
                        // Prevents auto-fill when clicking on different Looks.
                        if (autoFillSearch) {
                          setAutoFillSearch(false);
                        }

                        onChange(look);
                      }}
                    >
                      {look.title}
                    </SelectorRow>
                  );
                })
              )}
              {looks.length >= pageSize && (
                <SimplePagination
                  nextLoading={nextLoading}
                  page={page}
                  previousLoading={previousLoading}
                  onNext={() => {
                    if (looks.length < pageSize) {
                      // no more Looks
                      return;
                    }
                    setNextLoading(true);
                    setOffset(offset + pageSize);
                  }}
                  onPrevious={() => {
                    setPreviousLoading(true);
                    setOffset(offset - pageSize);
                  }}
                />
              )}
            </Column>
            {activeLookOnPage && look && (
              <Column sx={{ overflow: "auto", pt: 2, flex: 1, ml: 4 }}>
                <LookerQuery look={look} />
              </Column>
            )}
          </Row>
        </>
      )}
    </Column>
  );
};
