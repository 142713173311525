import { FC } from "react";

import { Column, Row } from "src/ui/box";

import { Indices } from "../../../../../design";

export const Header: FC = ({ children }) => {
  return (
    <Column
      sx={{
        position: "sticky",
        width: "100%",
        top: 0,
        left: 0,
        height: "max-content",
        zIndex: Indices.Popup,
      }}
    >
      {children}
    </Column>
  );
};

export const Topbar: FC = ({ children }) => {
  return (
    <Row
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        height: "64px",
        px: 8,
        width: "100%",
        bg: "base.0",
        flexShrink: 0,
        borderBottom: "small",
      }}
    >
      {children}
    </Row>
  );
};
