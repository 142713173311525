import { useState } from "react";

import { sha256 } from "js-sha256";
import { Text } from "theme-ui";
import { v4 as uuidv4 } from "uuid";

import { Field } from "../../ui/field";
import { Input } from "../../ui/input";
import { RadioGroup } from "../../ui/radio";

// TODO: Move to env at some point
const hightouchAccountId = "324528010823";

export const awsConfigValidator = (config): boolean => {
  return config?.roleArn || (config?.accessKeyId && config?.secretAccessKey);
};

export const AWSForm = ({ config, setConfig }) => {
  const [accessType, setAccessType] = useState<string>("crossAccountRole");

  const getAccessTypeForm = () => {
    if (accessType === "crossAccountRole") {
      return <CrossAccountRoleSetup config={config} setConfig={setConfig} />;
    } else if (accessType === "accessKey") {
      return <AccessKeySetup config={config} setConfig={setConfig} />;
    } else {
      return null;
    }
  };

  return (
    <>
      <Field label={""}>
        <RadioGroup
          options={[
            {
              label: "Cross-Account Role (recommended)",
              value: "crossAccountRole",
            },
            { label: "Access Key", value: "accessKey" },
          ]}
          value={accessType}
          onChange={(type) => {
            setAccessType(type as string);
            // Clear out the other config when we switch.
            setConfig({
              ...config,
              roleArn: undefined,
              accessKeyId: undefined,
              secretAccessKey: undefined,
              externalId: undefined,
            });
          }}
        />
      </Field>

      {getAccessTypeForm()}
    </>
  );
};

const CrossAccountRoleSetup = ({ config, setConfig }) => {
  // Autogenerate externalId and keep it in the state
  const [externalId] = useState<string>(generateExternalId());

  return (
    <>
      <Text>
        Hightouch can use AWS Cross-Account Roles to securely access your AWS resources without requiring that you hand over
        sensitive secrets.
      </Text>

      <Text>The following values will need to be provided to AWS during role setup:</Text>
      <Field label="AWS Account ID">
        <Input disabled value={hightouchAccountId} />
      </Field>

      <Field label="AWS External ID">
        <Input disabled value={externalId} />
      </Field>

      <Field label="Role ARN" required={true}>
        <Input
          placeholder="arn:aws:iam::xxxxxx:role/MyHightouchRole"
          value={config.roleArn}
          onChange={(value) =>
            setConfig({
              ...config,
              roleArn: value,
              externalId,
            })
          }
        />
      </Field>
    </>
  );
};

const AccessKeySetup = ({ config, setConfig }) => {
  return (
    <>
      <Field label="Access Key ID" required={true}>
        <Input value={config.accessKeyId} onChange={(value) => setConfig({ ...config, accessKeyId: value })} />
      </Field>

      <Field label="Secret Access Key" required={true}>
        <Input value={config.secretAccessKey} onChange={(value) => setConfig({ ...config, secretAccessKey: value })} />
      </Field>
    </>
  );
};

function generateExternalId(): string {
  return sha256.create().update(uuidv4()).hex();
}
