import { VFC } from "react";

import { IconProps, Icon } from "./icon";

export const TeammateIcon: VFC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M12.3,12.2c0.6-0.5,1.1-1.1,1.4-1.8c0.3-0.7,0.5-1.5,0.5-2.2c0-1.4-0.6-2.8-1.6-3.9c-1-1-2.4-1.6-3.9-1.6
      S5.9,3.3,4.9,4.3S3.3,6.7,3.3,8.2c0,0.8,0.2,1.5,0.5,2.2c0.3,0.7,0.8,1.3,1.4,1.8c-1.5,0.7-2.8,1.8-3.7,3.2C0.5,16.9,0,18.5,0,20.2
      c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-1.7,0.7-3.4,1.9-4.6
      c1.2-1.2,2.9-1.9,4.6-1.9s3.4,0.7,4.6,1.9c1.2,1.2,1.9,2.9,1.9,4.6c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3
      c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-1.7-0.5-3.3-1.4-4.7C15.2,14.1,13.9,12.9,12.3,12.2z M8.7,11.5
      c-0.6,0-1.3-0.2-1.8-0.6C6.4,10.5,6,10,5.7,9.4C5.5,8.8,5.4,8.2,5.5,7.5C5.7,6.9,6,6.3,6.4,5.9c0.5-0.5,1-0.8,1.7-0.9
      C8.7,4.8,9.4,4.9,10,5.1c0.6,0.2,1.1,0.7,1.5,1.2C11.8,6.9,12,7.5,12,8.2c0,0.9-0.3,1.7-1,2.3S9.6,11.5,8.7,11.5z M19.4,11.8
      C20.1,11,20.5,10,20.7,9c0.2-1,0-2.1-0.4-3.1c-0.4-1-1.1-1.8-2-2.3c-0.9-0.6-1.9-0.9-3-0.9c-0.3,0-0.6,0.1-0.8,0.3
      c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.9,0,1.7,0.3,2.3,1c0.6,0.6,1,1.4,1,2.3
      c0,0.6-0.2,1.1-0.4,1.6c-0.3,0.5-0.7,0.9-1.2,1.2c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.2,0,0.4,0.1,0.5
      c0.1,0.2,0.2,0.3,0.4,0.4l0.4,0.3l0.1,0.1c1.3,0.6,2.4,1.6,3.2,2.8c0.8,1.2,1.2,2.7,1.2,4.1c0,0.3,0.1,0.6,0.3,0.8
      c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8c0-1.7-0.4-3.3-1.2-4.8S20.8,12.7,19.4,11.8z"
      />
    </Icon>
  );
};
