import { VFC } from "react";

import { Text } from "theme-ui";

import { useUser } from "src/contexts/user-context";
import { Banner } from "src/ui/banner";
import { WarningIcon } from "src/ui/icons";

export const WorkspaceWarning: VFC = () => {
  const { user } = useUser();

  if (!user) {
    return null;
  }
  if (!user.not_member_of_current_workspace) {
    return null;
  }

  return (
    <Banner>
      <WarningIcon color="white" />
      <Text sx={{ fontWeight: "bold", color: "white", fontSize: 3, textTransform: "uppercase", mx: 4 }}>
        You do not belong to this workspace ({user.current_workspace_id}). Be careful.
      </Text>
      <WarningIcon color="white" />
    </Banner>
  );
};
