import { VFC } from "react";

import { IconProps, Icon } from "./icon";

export const LookerIcon: VFC<Readonly<IconProps>> = (props) => {
  return (
    <Icon color="#7c5ce4" viewBox="0 0 500 500" {...props}>
      <path d="M193.7 1c-13.5 3.4-22.1 14.3-22.1 28 .1 16 12.1 28.2 27.9 28.4 5.9.1 15.5-2.6 15.5-4.4 0-.4-1.6-2.2-3.6-4-3.2-2.7-4.1-3.1-7.4-2.5-8 1.3-17.2-3.2-20.5-10.1-3.5-7.4-1.8-16 4.2-21.5 4.1-3.9 6.6-4.9 11.8-4.9 6.1 0 10.3 1.9 14.3 6.4 3.8 4.4 4.6 7 4 14.1-.4 5.2-.3 5.6 3.2 9l3.7 3.6 1.8-4.3c2.3-5.8 2.2-15.4-.3-21-5.4-12.4-20-19.9-32.5-16.8zm64 43c-4.8.8-15.5 5.4-19.2 8.3-2 1.5-2 1.6 1 4.7 1.7 1.8 4.7 4.5 6.7 6.1l3.7 3 4.2-2.2c18.7-9.5 42.2 5.5 42.2 27.1 0 9-3 15.6-10 22-8.6 7.9-19 9.9-30.3 6l-5.5-1.9-6.2 6.1-6.2 6.2 3.6 2.4c16 10.9 40 8.7 55.1-5.1 11-10.2 15.7-22.1 15-38.5-.3-9.1-.7-10.6-4.3-17.8-6.8-13.8-18.3-22.8-33.4-26-7.3-1.5-9.3-1.6-16.4-.4z" />
      <path d="M225.6 67c-8 13.6-8.2 32.7-.5 47.1l3.2 6 6.4-6.3 6.3-6.3-1.9-3.9c-3.3-6.4-3.7-13.7-1.2-21.3 1.2-3.6 1.8-6.9 1.4-7.3-.4-.3-3.3-3-6.4-5.8l-5.5-5.1-1.8 2.9zm-75.8 49c-51.8 13.1-75.7 70.6-48.7 116.8 1.9 3.1 7.5 9.8 12.5 14.9 8 8 10.3 9.7 19.5 14.1 12.4 6 21.5 8.2 34.2 8.2 10.4 0 20.5-2.2 30.1-6.4l5.8-2.6-1.8-2.7c-4.4-6.9-15.5-21.3-16.3-21.3-.5 0-2.7.6-4.9 1.4-26.7 9.5-57.5-9.9-62.4-39.2-3.7-22.3 10.5-45.6 32.3-53 12.7-4.4 24.5-3.6 37.1 2.6l7.7 3.8 10.3-10.3 10.3-10.3-3-2.5c-4.6-3.7-17.2-10-25-12.5-10.2-3.2-27.3-3.7-37.7-1z" />
      <path d="m214.4 152.2-10.1 10.3 3.3 5.4c4.6 7.4 6.4 14.4 6.4 24.3 0 11.8-1.7 16.6-10.9 31.2-1.3 2.1-.9 3 7.5 14l8.8 11.8 3.4-3.8c8.1-9.4 13.6-19.4 17.4-31.4 3.3-10.6 3.3-30.8-.1-42.4-2.4-8.8-8.6-21.4-12.9-26.4l-2.7-3.3-10.1 10.3zm55.1 97.3c-4.4.8-11.5 2.6-15.8 4-8 2.6-22.3 9.1-23.3 10.6-.4.7 16.5 26.6 19.7 30.3.5.5 4.1-.6 8.6-2.7 27.5-12.5 59.7-7.4 82.5 13.2 12.6 11.4 23.6 30.1 28 47.6 3.2 12.6 3.1 33.4-.1 45-5.8 21.6-19.6 40.7-37.9 52.6-13.2 8.7-25.8 12.3-42.2 12.2-31.4-.2-56.7-16.8-71.5-46.8-11.3-22.9-13.1-46-5.4-70 3.1-9.9 9.7-22.2 15.6-29.2 2.1-2.5 4.6-5.6 5.7-7l1.9-2.4-10.8-15.9-10.8-15.9-5.6 4.9c-11.9 10.3-25.1 29.1-31 43.9-9.1 23.2-11.2 45.9-6.5 73.1 8.9 52.2 46.1 90.7 97.2 100.6 10.6 2.1 30.7 2.3 41.2.5 25.7-4.5 49.8-17.3 66.8-35.5 16.8-18.1 28.4-41.5 32.6-66.1 2.1-11.6 2.1-33.4 0-45-7.2-42-34.8-77.9-72.2-94.1-14.4-6.2-25.7-8.6-43.2-9-10.8-.2-17.9.1-23.5 1.1z" />
    </Icon>
  );
};
