import { VFC, useEffect } from "react";

import * as Yup from "yup";

import { useMetabaseListQuestionsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Spinner } from "src/ui/loading";
import { Select } from "src/ui/select";

import { CustomQueryViewProps, CustomQueryFormProps } from "../custom-query";

export const QueryForm: VFC<Readonly<CustomQueryFormProps>> = ({ source, query, onChange, setError }) => {
  const {
    data: questions,
    isLoading: questionsLoading,
    error: questionsError,
    refetch: listQuestions,
  } = useMetabaseListQuestionsQuery({ connectionId: String(source.id) });

  useEffect(() => {
    setError(questionsError);
  }, [questionsError]);

  return (
    <Field description="Select a question to retrieve the results." label="What is the question" size="large">
      <Select
        isError={Boolean(questionsError)}
        isLoading={questionsLoading}
        options={questions?.metabaseListQuestions?.map((q) => {
          return { label: q.name, value: q.id };
        })}
        placeholder="Select a question"
        reload={listQuestions}
        sx={{ maxWidth: "280px" }}
        value={query?.id || null}
        width="280px"
        onChange={(selected) => onChange({ ...query, id: selected?.value })}
      />
    </Field>
  );
};

export const QueryView: VFC<Readonly<CustomQueryViewProps>> = ({ source, query, setError }) => {
  const {
    data: questions,
    isLoading: questionsLoading,
    error: questionsError,
  } = useMetabaseListQuestionsQuery({ connectionId: String(source.id) });

  useEffect(() => {
    setError(questionsError);
  }, [questionsError]);

  const question = questions?.metabaseListQuestions?.find((q) => q.id === query.id);
  const questionName = question?.name || `Unknown question with id ${query.id}`;

  return (
    <Field inline label="Question">
      {questionsLoading ? <Spinner /> : questionName}
    </Field>
  );
};

export const querySchema = Yup.object().shape({
  type: Yup.string().required().equals(["question"]),
  id: Yup.number().required(),
});
