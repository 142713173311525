import { VFC } from "react";

import { startCase } from "lodash";
import { Box, Image, Text } from "theme-ui";

import { Row } from "src/ui/box";
import { ReloadButton } from "src/ui/button";

import { useFormkitContext } from "./formkit-context";

type Props = {
  object?: string;
  columns?: number;
  reload?: () => void;
  loading?: boolean;
};

export const MappingsHeader: VFC<Readonly<Props>> = ({ object, columns = 4, reload, loading }) => {
  const { model, loadingModel, reloadModel, sourceDefinition, destinationDefinition } = useFormkitContext();

  return (
    <>
      <Row sx={{ alignItems: "center", flex: 1 }}>
        <Image src={sourceDefinition?.icon} sx={{ width: "20px", mr: 2, flexShrink: 0 }} />
        <Text
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontWeight: "bold",
          }}
        >
          {model?.name}
        </Text>
        <ReloadButton
          loading={loadingModel}
          sx={{ ml: 2 }}
          tooltip="Click to refresh columns available in the source"
          onClick={reloadModel}
        />
      </Row>
      <Box />
      <Row sx={{ flex: 1, alignItems: "center" }}>
        <Image src={destinationDefinition?.icon} sx={{ width: "20px", mr: 2, flexShrink: 0 }} />
        <Text
          sx={{
            display: "flex",
            flexWrap: "wrap",
            fontWeight: "semi",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Text>Field from</Text>
          &nbsp;
          <Text
            sx={{
              fontWeight: "bold",
            }}
          >
            {object ? startCase(object) : destinationDefinition?.name}
          </Text>
        </Text>
        {reload && (
          <ReloadButton
            loading={loading}
            sx={{ ml: 2 }}
            tooltip="Click to refresh columns available in the destination"
            onClick={reload}
          />
        )}
      </Row>
      {columns === 4 && <Box />}
    </>
  );
};
