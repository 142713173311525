import { useEffect, VFC } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { Page } from "src/components/layout";
import { useModelQuery } from "src/graphql";
import { PageSpinner } from "src/ui/loading";
import { QueryType, useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";
import { useSource } from "src/utils/sources";

export const ModelQuery: VFC = () => {
  const { id: modelId } = useParams<{ id?: string }>();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const { queryState, initQueryState, setSQL, setDBTModel, setLookerLook, setTable, setCustomQuery } = useQueryState();

  const { data: modelData, isLoading: modelLoading } = useModelQuery(
    {
      id: modelId ?? "",
    },
    { enabled: Boolean(modelId) },
  );

  const update = useUpdateQuery();

  const model = modelData?.segments_by_pk;
  const type = model?.query_type as QueryType;
  const { data: source, loading: sourceLoading, error: sourceError } = useSource(model?.connection?.id);

  const {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    rawColumns,
  } = useModelRun(type, undefined, {
    modelId: model?.id,
    variables: { sourceId: source?.id, ...queryState },
  });

  const save = async (data) => {
    await update({ model, queryState, columns: data?.columns ?? rawColumns });

    addToast("Query updated!", {
      appearance: "success",
    });
  };

  useEffect(() => {
    initQueryState(model);
  }, [model]);

  if (modelLoading || sourceLoading) {
    return <PageSpinner />;
  }

  return (
    <Page
      crumbs={[
        { label: "Models", link: "/models" },
        {
          label: model?.name ?? "",
          link: `/models/${modelId}`,
        },
        {
          label: "Query",
        },
      ]}
      size="full"
      sx={{ height: "100vh", overflow: "hidden" }}
    >
      <ExploreWithSave
        cancelQuery={cancelQuery}
        columns={columns}
        error={queryError || sourceError?.message}
        errorAtLine={queryErrorAtLine}
        getSchema={getSchema}
        isResultTruncated={Boolean(isResultTruncated)}
        loading={queryLoading || sourceLoading}
        numRowsWithoutLimit={numRowsWithoutLimit}
        reset={resetRunState}
        rows={rows}
        runQuery={runQuery}
        saveLabel="Save"
        source={source}
        type={type}
        onBack={() => navigate(`/models/${modelId}`)}
        onSave={save}
        {...queryState}
        rowsPerPage={15}
        onCustomQueryChange={setCustomQuery}
        onDBTModelChange={setDBTModel}
        onLookerLookChange={setLookerLook}
        onSQLChange={setSQL}
        onTableChange={setTable}
      />
    </Page>
  );
};
