import { ReactElement } from "react";

import { Checkbox } from "src/ui/checkbox";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { SensitiveField } from "src/ui/sensitive-field";

import { FormProps } from "../form";

export default function ConfigurationForm({
  tunnel,
  config,
  setConfig,
  error,
  hideSecret = false,
}: Readonly<FormProps>): ReactElement<any, any> {
  return (
    <>
      <Field label="Host">
        <Input
          disabled={tunnel}
          value={tunnel ? `Using ${tunnel?.name} tunnel` : config?.host || ""}
          onChange={(value) => setConfig({ ...config, host: value })}
        />
      </Field>

      <Field label="Port">
        <Input
          disabled={tunnel}
          value={tunnel ? `Using ${tunnel?.name} tunnel` : config?.port || ""}
          onChange={(value) => setConfig({ ...config, port: value })}
        />
      </Field>

      <Field label="User">
        <Input value={config?.user || ""} onChange={(value) => setConfig({ ...config, user: value })} />
      </Field>

      <SensitiveField
        optional
        hideSecret={hideSecret}
        label="Password"
        value={config?.password || ""}
        onChange={(password) => setConfig({ ...config, password })}
      />

      <Field label="Default catalog">
        <Input value={config?.catalog || ""} onChange={(value) => setConfig({ ...config, catalog: value })} />
      </Field>

      <Field label="Default schema">
        <Input value={config?.schema || ""} onChange={(value) => setConfig({ ...config, schema: value })} />
      </Field>

      <Field label="Engine">
        <RadioGroup
          options={[
            { label: "Trino", value: "trino" }, // Default
            { label: "Presto", value: "presto" },
          ]}
          value={config?.engine || "trino"}
          onChange={(value) => setConfig({ ...config, engine: value })}
        />
      </Field>

      <Field
        description="Encryption is enabled by default. Check this if your Trino instance doesn't support encrypted connections. Not recommended"
        label="Unencrypted connection"
      >
        <Checkbox
          value={config?.unencrypted || false}
          onChange={(value) => setConfig({ ...config, unencrypted: Boolean(value) })}
        />
      </Field>

      <FieldError error={error} />
    </>
  );
}
