import { VFC } from "react";

import { Text } from "theme-ui";

import {
  NumberOfCondition,
  NumberOperator,
  numberOfOperatorOptions,
  initialPropertyCondition,
  AudienceParent,
} from "src/types/visual";
import { Row } from "src/ui/box";
import { NewSelect } from "src/ui/new-select";

import { AmountInput } from "./amount-input";
import { AttributeSelect } from "./attribute-select";
import {
  ConditionField,
  FilterProps,
  HStack,
  OperatorLabel,
  PlusButton,
  RemoveButton,
  removeSubcondition,
  updateSubconditions,
} from "./condition";
import { Filter } from "./filter";

const RelatedTo = {
  operator: NumberOperator.GreaterThan,
  value: 1,
};

const NotRelatedTo = {
  operator: NumberOperator.Equals,
  value: 0,
};

const relatedOptions = [
  { label: "have", value: "related" },
  { label: "does not have", value: "not related" },
];

export const NumberOfFilter: VFC<Readonly<FilterProps<NumberOfCondition>>> = (props) => {
  const { relationships, condition, onChange, onRemove } = props;

  // Don't show related events under the options. Users should use the
  // EventConditionFilter for creating queries based on events.
  const relationshipOptions = relationships
    ?.filter(({ to_model: { event } }) => !event)
    ?.map(({ id, to_model: { name } }) => ({ value: id, label: name }));

  const relatedModel = relationships?.find(({ id }) => id === condition.relationshipId)?.to_model;

  const relatedValue = condition.value === 0 ? "not related" : "related";
  const relatedLabel = relatedOptions?.find(({ value }) => relatedValue === value)?.label;
  const operatorLabel = numberOfOperatorOptions.find(({ value }) => value === condition.operator)?.label;

  return (
    <>
      <HStack gap={2}>
        <Filter
          content={
            <>
              <NewSelect
                options={relatedOptions}
                placeholder={"Select relation"}
                sx={{ flex: "0 0 auto" }}
                value={relatedValue}
                width={140}
                onChange={(value) => {
                  if (value === "related") {
                    onChange(RelatedTo);
                  } else {
                    onChange(NotRelatedTo);
                  }
                }}
              />
              {condition.value > 0 && (
                <>
                  <NewSelect
                    options={numberOfOperatorOptions}
                    placeholder="Filter on"
                    sx={{ flex: "0 0 auto" }}
                    value={condition.operator}
                    width={200}
                    onChange={(operator) => onChange({ operator })}
                  />
                  <AmountInput
                    sx={{ width: "100px" }}
                    type="number"
                    value={condition.value}
                    onChange={(value) => {
                      if (value > 0) {
                        onChange({ value });
                      }
                    }}
                  />
                </>
              )}
            </>
          }
        >
          <OperatorLabel>{`${relatedLabel}${condition.value > 0 ? ` ${operatorLabel} ${condition.value}` : ""}`}</OperatorLabel>
        </Filter>
        <AttributeSelect
          options={relationshipOptions}
          placeholder="Select a model"
          value={condition.relationshipId}
          onChange={(relationshipId) => {
            onChange({
              relationshipId,
            });
          }}
        />
        <RemoveButton onRemove={onRemove} />
      </HStack>
      {condition.subconditions?.map((subcondition, index) => (
        <Row key={index} sx={{ alignItems: "center", pl: 12 }}>
          <Text sx={{ color: "base.4", width: "3.5rem" }}>{index === 0 ? "WHERE" : "AND"}</Text>
          <ConditionField
            {...props}
            columns={relatedModel?.filterable_audience_columns}
            condition={subcondition}
            // this is okay since we only use property filters
            parent={relatedModel as unknown as AudienceParent}
            traits={undefined}
            onChange={updateSubconditions(onChange, condition.subconditions, index)}
            onRemove={removeSubcondition(onChange, condition.subconditions, index)}
          />
        </Row>
      ))}
      <Row sx={{ pl: 12 }}>
        <PlusButton
          disabled={!condition.relationshipId}
          onClick={() => {
            onChange({ subconditions: [...(condition.subconditions ?? []), initialPropertyCondition] });
          }}
        >
          property
        </PlusButton>
      </Row>
    </>
  );
};
