import { VFC } from "react";

import { PageSpinner } from "src/ui/loading";
import { Wizard } from "src/ui/wizard";
import { useNavigate } from "src/utils/navigate";

import { useCreateSyncWizard } from "./use-create-sync-wizard";

export const CreateSync: VFC = () => {
  const navigate = useNavigate();

  const { createSync, loading, setStep, step, steps } = useCreateSyncWizard({
    onSubmit: ({ id }) => navigate(`/syncs/${id}`),
  });

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Wizard
      setStep={setStep}
      step={step}
      steps={steps}
      title="Add a new sync"
      onCancel={() => {
        navigate("/syncs");
      }}
      onSubmit={createSync}
    />
  );
};
