import { FC } from "react";

import { Logo } from "src/ui/brand";

import { Header, Topbar } from "./header";
import { UserMenu } from "./user-menu";

export const LogoHeader: FC = () => {
  return (
    <Header>
      <Topbar>
        <Logo color="secondary" />
        <UserMenu />
      </Topbar>
    </Header>
  );
};
