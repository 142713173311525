import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Column } from "src/ui/box";

export interface SelectableProps {
  selected: boolean | undefined;
  onSelect: () => void;
  sx?: ThemeUIStyleObject;
  disabled?: boolean;
}

export const Selectable: FC<Readonly<SelectableProps>> = ({ children, selected, onSelect, disabled, sx = {} }) => {
  return (
    <Column
      sx={{
        borderRadius: 1,
        p: 5,
        bg: "white",
        border: "small",
        borderColor: selected ? "primary" : undefined,
        cursor: disabled ? "auto" : "pointer",
        position: "relative",
        transition: "all 100ms ease-in-out",
        ":hover": {
          bg: "base.0",
          borderColor: selected ? undefined : "base.2",
          boxShadow: "xs",
        },
        overflow: "hidden",
        ...sx,
      }}
      tabIndex={1}
      onClick={disabled ? undefined : onSelect}
    >
      {children}
    </Column>
  );
};
