import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const CheckCircleLightIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M0 12.0001C0 5.37271 5.37258 0.00012207 12 0.00012207C18.6274 0.00012207 24 5.37271 24 12.0001C24 18.6275 18.6274 24.0001 12 24.0001C5.37258 24.0001 0 18.6275 0 12.0001Z"
        fill="#4928ED"
        fillOpacity="0.08"
      />
      <path
        clipRule="evenodd"
        d="M17.0965 7.39016L9.9365 14.3002L8.0365 12.2702C7.6865 11.9402 7.1365 11.9202 6.7365 12.2002C6.3465 12.4902 6.2365 13.0002 6.4765 13.4102L8.7265 17.0702C8.9465 17.4102 9.3265 17.6202 9.7565 17.6202C10.1665 17.6202 10.5565 17.4102 10.7765 17.0702C11.1365 16.6002 18.0065 8.41016 18.0065 8.41016C18.9065 7.49016 17.8165 6.68016 17.0965 7.38016V7.39016Z"
        fill="#4928ED"
        fillOpacity="0.7"
        fillRule="evenodd"
      />
    </Icon>
  );
};
