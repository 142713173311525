import ConfigurationForm from "./configuration";

function isConfigurationComplete(config: Record<string, unknown>, tunnel?: any): boolean {
  return Boolean(tunnel || (config?.host && config?.port));
}

export default {
  isConfigurationComplete,
  ConfigurationForm,
};
