import { VFC } from "react";

import { Box, Image, Text } from "theme-ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Badge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Selectable } from "src/ui/selectable";

type Props = {
  definition: DestinationDefinition;
  onSelect: any;
  selected: any;
};

export const Destination: VFC<Readonly<Props>> = ({ definition, onSelect, selected }) => {
  const messageIntercom = (destination) => {
    if (typeof (window as any)?.Intercom !== "undefined") {
      (window as any)?.Intercom(
        "showNewMessage",
        `Hi, I would like to request early access to the ${destination.name} destination.`,
      );
    }
  };
  return (
    <Selectable
      selected={selected}
      sx={{ position: "relative", justifyContent: "center", height: "100px", overflow: "visible" }}
      onSelect={
        definition.status !== "released"
          ? () => {
              messageIntercom(definition);
              analytics.track("Destination Type Clicked", {
                destination_type: definition.name,
                coming_soon: true,
              });
            }
          : () => onSelect(definition)
      }
    >
      <Row sx={{ alignItems: "center" }}>
        <Image src={definition.icon} sx={{ width: "28px", maxHeight: "100%", objectFit: "contain", flexShrink: 0 }} />
        <Column sx={{ ml: 4 }}>
          <Text
            sx={{
              fontWeight: "bold",
              wordBreak: "break-word",
            }}
          >
            {definition.name}
          </Text>
          <Text sx={{ textTransform: "uppercase", color: "dark.1", fontWeight: "bold", fontSize: 0 }}>
            {definition.categories[0]?.name}
          </Text>
        </Column>
      </Row>

      {definition.status !== "released" && (
        <Box style={{ position: "absolute", right: -5, top: -5 }}>
          <Badge sx={{ bg: "base.4", border: "none", color: "white" }}>Request Early Access</Badge>
        </Box>
      )}
    </Selectable>
  );
};
