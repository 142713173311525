import { useCallback, useState } from "react";

import { useDropzone } from "react-dropzone";
import { ThemeUIStyleObject } from "theme-ui";

import { Column } from "../box";
import { Field } from "../field";

interface Props {
  /** Description to show within uploader type  */
  descripion?: string;
  /**
   * List of accepted file types.
   * If `undefined`, will accept all file types
   */
  acceptedFileTypes?: string[];

  /**
   * Function to transform file bolb into value,
   * "base64" : Will transform your file into a base64 string
   * "JSONParse": Will parse your JSON file into an object
   *  Custom Function: Will give you the file to return any value format
   */
  transformation: "base64" | "JSONParse" | ((blob: File) => any);
  label?: string;
  value: string;
  onChange?: (value: any) => void;
  sx?: ThemeUIStyleObject;
}

export const FileUploader = ({ transformation, onChange, ...props }: Props) => {
  const [error, setError] = useState<string>("");
  const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(undefined);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback(async (acceptedFiles) => {
      setError("");
      if (acceptedFiles.length === 0) {
        return setUploadedFileName(undefined);
      }

      setUploadedFileName(acceptedFiles[0].name);
      if (transformation === "JSONParse") {
        const fileContents = await acceptedFiles[0].text();
        try {
          if (typeof onChange === "function") {
            onChange(JSON.parse(fileContents));
          }
        } catch (err) {
          setError("Could not parse file.");
        }
      } else if (transformation === "base64") {
        if (typeof onChange === "function") {
          onChange(await fileToBase64(acceptedFiles[0]));
        }
      } else {
        const transformedValue = transformation(acceptedFiles[0]);

        if (typeof onChange === "function") {
          onChange(transformedValue);
        }
      }
    }, []),
    accept: Array.isArray(props.acceptedFileTypes) && props.acceptedFileTypes.length > 0 ? props.acceptedFileTypes : undefined,
  });

  return (
    <Field error={error} label={props.label ?? ""}>
      <Column
        sx={{
          alignItems: "center",
          padding: "20px",
          borderWidth: 1,
          borderRadius: 2,
          borderColor: "base.3",
          borderStyle: "dashed",
          backgroundColor: "base.2",
          color: "base.5",
          outline: "none",
          transition: "border .24s ease-in-out",
          ...props.sx,
        }}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Drag or drop your file here, or click here to select a file</p>
          {Array.isArray(props.acceptedFileTypes) && props.acceptedFileTypes.length > 0 && (
            <p>Accepted File Types: {props.acceptedFileTypes.join(", ")}</p>
          )}
          {uploadedFileName && <p>Uploaded File: {uploadedFileName}</p>}
        </div>
      </Column>
    </Field>
  );
};

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString().split(",")[1] || "");
    reader.onerror = (error) => reject(error);
  });
};
