import { VFC, useCallback } from "react";

import { Flex, Box, ThemeUICSSObject } from "theme-ui";

import { XIcon, SearchIcon } from "src/ui/icons";

import { Input } from "./input";

interface Props {
  sx?: ThemeUICSSObject;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
}

export const SearchInput: VFC<Props> = (props) => {
  const clear = useCallback(() => {
    props.onChange("");
  }, [props.onChange]);

  return (
    <Flex sx={{ alignItems: "center", position: "relative", width: props.sx?.width || "320px" }}>
      <Box sx={{ position: "absolute", left: 2 }}>
        <SearchIcon color="black" size={16} />
      </Box>

      <Box
        sx={{
          position: "absolute",
          right: 2,
          cursor: "pointer",
          visibility: props?.value?.length > 0 ? undefined : "hidden",
        }}
        onClick={clear}
      >
        <XIcon color="black" size={16} />
      </Box>

      <Input permissioned={false} {...props} sx={{ width: "100%", pl: 8, pr: 8, ...(props.sx || {}) }} />
    </Flex>
  );
};
