import { VFC } from "react";

import { Text } from "theme-ui";

import {
  ReferencePropertyDefaultOperators,
  FilterableColumn,
  isColumnReference,
  ReferencedPropertyCondition,
  ReferencePropertyOperatorOptions,
} from "src/types/visual";
import { NewSelect } from "src/ui/new-select";

import { AttributeSelect } from "./attribute-select";
import { FilterProps, HStack, OperatorLabel, RemoveButton } from "./condition";
import { Filter } from "./filter";
import { getColumnOptions } from "./property-filter";

export type ReferencePropertyFilterProps = FilterProps<ReferencedPropertyCondition> & {
  referenceColumns?: FilterableColumn[];
};

export const ReferencePropertyFilter: VFC<Readonly<ReferencePropertyFilterProps>> = (props) => {
  const { columns, referenceColumns, condition, onChange, onRemove } = props;

  const propertyOptions = getColumnOptions(columns ?? [], !isColumnReference(condition.property));
  // Use string for valueFromColumn instead of column reference
  const referenceOptions = getColumnOptions(referenceColumns ?? [], true);
  const operatorOptions = condition.propertyType ? ReferencePropertyOperatorOptions[condition.propertyType] : undefined;
  const operatorLabel = operatorOptions?.find((option) => option.value === condition.operator)?.label;

  return (
    <HStack gap={2}>
      <AttributeSelect
        options={propertyOptions}
        placeholder="Select a property"
        value={condition.property}
        onChange={(value, { type }) => {
          onChange({
            propertyType: type,
            property: value,
            operator: ReferencePropertyDefaultOperators[type],
            valueFromColumn: null,
          });
        }}
      />
      {condition.property && (
        <Filter
          content={
            <>
              <NewSelect
                options={operatorOptions}
                placeholder="Filter on"
                sx={{ flex: "0 0 auto" }}
                value={condition.operator}
                width={200}
                onChange={(operator) => {
                  onChange({ operator });
                }}
              />
              <NewSelect
                options={referenceOptions}
                placeholder="parent property"
                value={condition.valueFromColumn}
                width={180}
                onChange={(valueFromColumn) => onChange({ valueFromColumn })}
              />
            </>
          }
        >
          <OperatorLabel>{operatorLabel}</OperatorLabel>
          <Text sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
            {condition.valueFromColumn || "parent property..."}
          </Text>
        </Filter>
      )}
      <RemoveButton onRemove={onRemove} />
    </HStack>
  );
};
