import { useBillingOrganizationQuery, useOrganizationDestinationTypesQuery } from "src/graphql";

export const freeDestinations = [
  "airtable",
  "googlesheets",
  "googleSheetsSA",
  "slack",
  "teams",
  "mattermost",
  "salesforceSandbox",
];
export const premiumDestinations = ["sfmc", "netsuite"];

const getBillingType = (billing_type: string) => {
  if (billing_type.startsWith("facebook")) {
    return "facebook";
  }
  if (billing_type === "googleCampaignManager") {
    return "google";
  }
  if (billing_type.startsWith("hubspot")) {
    return "hubspot";
  }
  if (billing_type.startsWith("sfmc")) {
    return "sfmc";
  }
  return billing_type;
};

export const useEntitlements = (enabled: boolean) => {
  const { data: orgData, isLoading: loadingOrg } = useBillingOrganizationQuery({}, { enabled });
  const { data: organizationDestinationType, isLoading: loadingDestinationTypes } = useOrganizationDestinationTypesQuery(
    {},
    { enabled },
  );

  const organization = orgData?.getBillingOrganization;
  const entitlements = {
    destinations: 1,
    preimumDestinations: 0,
    ...organization?.plan?.entitlements,
    ...organization?.entitlements,
  };

  let billableDistinations = 0;
  let freeDistinations = 0;
  let totalDestinations = 0;
  let preimumDestinations = 0;
  const billedDestinations: string[] = [];
  organizationDestinationType?.getOrganizationDestinationTypes.forEach((dest) => {
    if (!dest) {
      return;
    }

    const billing_type = getBillingType(dest.type);

    if (freeDestinations.includes(billing_type)) {
      freeDistinations += 1;
    } else if (!billedDestinations.includes(billing_type)) {
      billedDestinations.push(billing_type);
      billableDistinations += 1;
    }
    if (premiumDestinations.includes(billing_type)) {
      preimumDestinations += 1;
    }
    totalDestinations += 1;
  });

  const destinationOverage = billableDistinations > entitlements?.destinations && entitlements?.destinations !== -1;
  const premiumOverage = preimumDestinations > entitlements?.preimumDestinations;
  const overage = destinationOverage || premiumOverage;
  return {
    isLoading: loadingOrg || loadingDestinationTypes,
    data: {
      organization,
      organizationDestinationType,
      overage: {
        destinationOverage,
        premiumOverage,
        overage,
      },
      entitlements,
      destinations: {
        billableDistinations,
        freeDistinations,
        totalDestinations,
        preimumDestinations,
      },
    },
  };
};
