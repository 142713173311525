import { ReactElement } from "react";

import { SensitiveField } from "src/ui/sensitive-field";

import { FormProps } from "../form";

export default function ConfigurationForm({
  config,
  setConfig,
  hideSecret = false,
}: Readonly<FormProps>): ReactElement<any, any> {
  return (
    <>
      <SensitiveField
        hideSecret={hideSecret}
        label="API Key"
        value={config?.apiKey || ""}
        onChange={(apiKey) => setConfig({ ...config, apiKey })}
      />
    </>
  );
}
