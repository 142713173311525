import { useEffect, useState, VFC } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual, isMatch } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Grid, Text } from "theme-ui";
import { isPresent } from "ts-extras";

import { DestinationForm } from "src/components/destinations/destination-form";
import { GitActivity } from "src/components/git/git-activity";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { Schedule, ScheduleManager } from "src/components/schedule";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Runs } from "src/components/syncs/runs";
import { SyncAlerts } from "src/components/syncs/sync-alerts";
import { SyncName } from "src/components/syncs/sync-name";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  SyncRunsQuery,
  useDeleteSyncMutation,
  useExternalSegmentsQuery,
  useResourceLabelsQuery,
  useStartSyncRunMutation,
  useSyncQuery,
  useUpdateSyncMutation,
  useUpdateSyncRequestMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { SquareBadge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { ChevronDownIcon, DotsIcon, ExternalLinkIcon, InfoIcon, PlayIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { PageSpinner, Spinner } from "src/ui/loading";
import { Menu } from "src/ui/menu";
import { Message } from "src/ui/message";
import { Popout } from "src/ui/popout";
import { Tabs } from "src/ui/tabs";
import { Toggle } from "src/ui/toggle";
import { useDestination } from "src/utils/destinations";
import { QueryType } from "src/utils/models";
import { useSource } from "src/utils/sources";
import { SyncStatus, syncStatusIsTerminal } from "src/utils/syncs";
import { useQueryString } from "src/utils/use-query-string";

import { WarehouseSyncLogs } from "../../components/syncs/warehouse-sync-logs";

enum Tab {
  RUNS = "Runs",
  CONFIGURATION = "Configuration",
  SCHEDULE = "Schedule",
  ALERTS = "Alerts",
  GIT_ACTIVITY = "Git Activity",
  WAREHOUSE_SYNC_LOGS = "Sync Logs",
}

export const Sync: VFC = () => {
  const { appEnableResourceTags } = useFlags();
  const { user } = useUser();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToasts();
  const [schedule, setSchedule] = useState<any>();
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.RUNS);
  const [updatingSchedule, setUpdatingSchedule] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const [cancelledSyncId, setCancelledSyncId] = useState<string>();
  const [showRun, setShowRun] = useState(true);
  const [runs, setRuns] = useState<SyncRunsQuery["sync_requests"] | undefined>();

  const {
    data: syncData,
    isLoading: syncLoading,
    refetch: refetchSync,
  } = useSyncQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      onSuccess: (data) => {
        const runs = data?.syncs?.[0]?.sync_requests;
        if (runs) {
          setRuns(runs);
        }
      },
    },
  );
  const { data: syncLabels } = useResourceLabelsQuery({ resource: "sync" });

  const { mutateAsync: updateSync, isLoading: isSyncUpdating } = useUpdateSyncMutation();
  const { mutateAsync: forceRun } = useStartSyncRunMutation();
  const { mutateAsync: cancelSyncRequest } = useUpdateSyncRequestMutation();
  const { mutateAsync: deleteSync } = useDeleteSyncMutation();

  const sync = syncData?.syncs?.[0];
  const latestRun = runs?.filter((run) => run.sync_attempts?.length > 0)[0];
  // Active runs might not have a planner_type set yet, so use the first defined one
  const plannerType = runs?.find((run) => run.planner_type)?.planner_type;
  const syncAttempt = latestRun?.sync_attempts?.[0];
  const syncAlerts = sync?.sync_alerts;
  const model = sync?.segment;
  const usesSyncTemplate = sync?.sync_template_id;
  const labels = sync?.tags ?? {};

  const {
    data: { destination, definition: destinationDefinition },
    loading: destinationLoading,
  } = useDestination(sync?.destination?.id, { pause: !sync });

  const { data: source, loading: sourceLoading } = useSource(model?.connection?.id, { pause: !sync });

  const { data: externalSegmentsData } = useExternalSegmentsQuery({ syncId: sync?.id }, { enabled: !!sync });
  // We used to support multiple external segments per sync, where only one
  // would be valid at a time. When there is more than one, we only care about
  // the most recent, active one.
  const externalSegment = externalSegmentsData?.external_segments?.sort((a, b) => {
    return a.created_at > b.created_at ? -1 : 1;
  })?.[0];

  const toggleSyncPause = async (enabled: boolean) => {
    if (!id) {
      return;
    }

    try {
      await updateSync({
        id,
        object: {
          schedule_paused: !enabled,
        },
      });

      addToast(`Sync ${enabled ? "enabled" : "disabled"}!`, {
        appearance: "success",
      });
    } catch (error) {
      addToast(`Sync could not be ${enabled ? "enabled" : "disabled"}. ${error.message}`, { appearance: "error" });
    }

    refetchSync();
  };

  const startRun = async (resync = false) => {
    const { startSyncRun } = await forceRun({
      id: Number(id),
      full_resync: resync,
    });

    // Only render a popup message if we actually scheduled a sync.
    const scheduled = startSyncRun?.scheduled;
    analytics.track("Sync Manually Started", {
      sync_id: id,
      destination_type: destinationDefinition?.name,
      schedule_type: schedule?.type,
      source_type: model?.connection?.type,
    });

    if (scheduled) {
      if (resync) {
        addToast("Resync will begin shortly.", {
          appearance: "success",
        });
      } else {
        addToast("Manual run will begin shortly.", {
          appearance: "success",
        });
      }
    }

    refetchSync();
  };

  const cancelRun = async () => {
    setCancelling(true);
    setCancelledSyncId(latestRun?.id);
    cancelSyncRequest({
      id: latestRun?.id,
      object: {
        trigger_cancel: true,
      },
    });

    refetchSync();
  };

  const TABS = [
    Tab.RUNS,
    Tab.CONFIGURATION,
    !usesSyncTemplate && Tab.SCHEDULE,
    !usesSyncTemplate && Tab.ALERTS,
    model?.git_sync_metadata && Tab.GIT_ACTIVITY,
    source?.definition?.supportsInWarehouseDiffing && Tab.WAREHOUSE_SYNC_LOGS,
  ].filter(isPresent);

  const running = syncAttempt?.status === SyncStatus.ACTIVE;

  const {
    data: { autorun },
  } = useQueryString();

  useEffect(() => {
    let autoRunTimeout: number | undefined | null;
    if (sync && autorun) {
      autoRunTimeout = window.setTimeout(() => {
        startRun();
      }, 400);
    }
    return () => {
      if (autoRunTimeout) {
        clearTimeout(autoRunTimeout);
        autoRunTimeout = null;
      }
    };
  }, [autorun, sync?.id]);

  useEffect(() => {
    if (sync?.schedule || sync?.schedule === null) {
      setSchedule(sync?.schedule ? sync?.schedule : { type: "manual" });
    }
  }, [sync?.schedule]);

  useEffect(() => {
    setEnabled(!sync?.schedule_paused);
  }, [sync?.schedule_paused]);

  useEffect(() => {
    // if the latest run id no longer match the cancelled id then it means cancel is successful.
    if (syncAttempt?.status === SyncStatus.CANCELLED || cancelledSyncId !== latestRun?.id) {
      setCancelling(false);
    }
  }, [syncAttempt?.status, latestRun?.id, cancelledSyncId]);

  useEffect(() => {
    if (syncStatusIsTerminal(syncAttempt?.status as SyncStatus)) {
      setShowRun(true);
    }
  }, [syncAttempt?.status]);

  const onUpdate = () => {
    analytics.track("Sync Edited", {
      sync_id: id,
      destination_type: destinationDefinition?.name,
      schedule_type: schedule?.type,
      source_type: model?.connection?.type,
    });

    addToast("Sync updated successfully!", {
      appearance: "success",
    });

    refetchSync();
  };

  const updateConfig = async (config) => {
    if (!id) {
      return;
    }

    await updateSync({
      id,
      object: {
        config: { ...config, configVersion: sync?.config?.configVersion },
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });
    onUpdate();
  };

  const updateSchedule = async () => {
    if (!id) {
      return;
    }

    setUpdatingSchedule(true);
    await updateSync({
      id,
      object: {
        updated_by: user?.id != null ? String(user?.id) : undefined,
        schedule: schedule?.type === "manual" ? null : schedule,
      },
    });
    setUpdatingSchedule(false);
    onUpdate();
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    if (!id) {
      return;
    }

    try {
      await updateSync({
        id: id,
        object: {
          tags: labels,
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      addToast(`Label update failure. ${error.message}`, { appearance: "error", autoDismiss: false });
    }
  };

  const hasPrimaryKeyIssue =
    model?.query_type === QueryType.Visual
      ? Boolean(model?.parent?.columns?.length && !model?.parent?.columns.some((c) => c.name === model?.parent?.primary_key))
      : Boolean(model?.columns?.length && !model?.columns.some((c) => c.name === model?.primary_key));

  if (syncLoading || destinationLoading || !destination || !destinationDefinition || sourceLoading) {
    return <PageSpinner />;
  }

  if (!syncLoading && !sync) {
    return <Warning title="Sync not found" />;
  }

  return (
    <>
      <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update] }]}>
        <Page crumbs={[{ label: "Syncs", link: "/syncs" }, { label: "Sync" }]}>
          <Row sx={{ justifyContent: "space-between", mb: 5, width: "100%", borderBottom: "small", pb: 2 }}>
            <SyncName
              destination={destination}
              destinationDefinition={destinationDefinition}
              model={model}
              source={source}
              sync={sync}
            />
            <Row gap={4}>
              <Permission>
                <Toggle
                  label={enabled ? "Enabled" : "Disabled"}
                  value={enabled}
                  onChange={(value) => {
                    setEnabled(value);
                    toggleSyncPause(value);
                  }}
                />
                <Menu
                  disabled={running}
                  options={[
                    {
                      label: "Resync full query",
                      onClick: () => {
                        setShowRun(false);
                        startRun(true);
                      },
                    },
                    {
                      label: "Delete sync",
                      variant: "danger",
                      onClick: () => setDeleting(true),
                    },
                  ]}
                >
                  <DotsIcon />
                </Menu>
                {running || cancelling ? (
                  <Button
                    disabled={cancelling}
                    iconBefore={<Spinner color={cancelling ? "primary" : "red"} size={14} />}
                    variant={cancelling ? "secondary" : "redOutline"}
                    onClick={cancelRun}
                  >
                    {cancelling ? "Canceling..." : "Cancel run"}
                  </Button>
                ) : (
                  <Button
                    disabled={!showRun}
                    iconBefore={<PlayIcon size={14} />}
                    variant="secondary"
                    onClick={() => {
                      setShowRun(false);
                      startRun(false);
                    }}
                  >
                    Run
                  </Button>
                )}
              </Permission>
            </Row>
          </Row>

          <Row gap={8} sx={{ alignSelf: "flex-start", alignItems: "flex-start", mb: 6 }}>
            <Column sx={{ pr: 10 }}>
              <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Sync ID</Text>
              <Text sx={{ textTransform: "capitalize", fontWeight: "semi" }}>{sync?.id}</Text>
            </Column>
            <Column sx={{ pl: 3, pr: 10, borderLeft: "small" }}>
              <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Schedule</Text>
              <Schedule schedule={sync?.schedule} />
            </Column>
            <Column sx={{ pl: 3, pr: 10, borderLeft: "small" }}>
              <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Slug</Text>
              <DisplaySlug currentSlug={sync?.slug} />
            </Column>
            {sync?.config?.mode && (
              <Column sx={{ pl: 3, borderLeft: "small" }}>
                <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Mode</Text>
                <Text sx={{ textTransform: "capitalize", fontWeight: "semi" }}>{sync?.config?.mode}</Text>
              </Column>
            )}
            {appEnableResourceTags && (
              <Row sx={{ alignItems: "center", pl: 4, ml: 4, height: "100%", borderLeft: "small" }}>
                <Popout
                  content={({ close }) => (
                    <>
                      <Labels labels={sync?.tags} sx={{ maxWidth: "200px" }} />
                      <Button
                        sx={{ mt: 4 }}
                        variant="secondary"
                        onClick={() => {
                          setIsEditLabelModalOpen(true);
                          close();
                        }}
                      >
                        Edit labels
                      </Button>
                    </>
                  )}
                  contentSx={{ p: 3, minWidth: "90px" }}
                >
                  <Text sx={{ mr: 1 }}>Labels</Text>
                  <SquareBadge>{Object.keys(sync?.tags || {}).length}</SquareBadge>
                  <ChevronDownIcon size={16} sx={{ ml: 2 }} />
                </Popout>
              </Row>
            )}
          </Row>

          <Grid gap={8} sx={{ width: "100%" }}>
            <Tabs setTab={(tab) => setTab(tab as Tab)} tab={tab} tabs={TABS} />

            {tab === Tab.RUNS && (
              <>
                {hasPrimaryKeyIssue && (
                  <Message sx={{ width: "100%", maxWidth: "100%" }} variant="warning">
                    <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                      <Field label="Looks like your primary key is set to an undefined column.">
                        As a result, your syncs may fail or undefined behavior may occur. Go to your model and make sure you
                        have your primary key set to a valid column in your model.
                      </Field>
                      <Column sx={{ flexShrink: 0, px: 2 }}>
                        <Link
                          to={
                            model?.query_type === QueryType.Visual
                              ? `/audiences/setup/parent-models/${model?.parent?.id}`
                              : `/models/${model?.id}`
                          }
                        >
                          <Button variant="dark">Go to your model</Button>
                        </Link>
                      </Column>
                    </Row>
                  </Message>
                )}

                {id && <Runs plannerType={plannerType ?? undefined} setRuns={setRuns} syncId={id} />}
              </>
            )}

            {tab === Tab.CONFIGURATION && !usesSyncTemplate && model && source?.definition && (
              <DestinationForm
                destination={destination}
                destinationDefinition={destinationDefinition}
                externalSegment={externalSegment}
                model={model}
                slug={destinationDefinition.type}
                sourceDefinition={source.definition}
                sync={sync}
                onSubmit={updateConfig}
              />
            )}

            {tab === Tab.CONFIGURATION && usesSyncTemplate && (
              <Row
                sx={{
                  mt: -4,
                  justifyContent: "space-between",
                  alignItems: "center",
                  maxWidth: "800px",
                  p: 4,
                  borderRadius: 1,
                  bg: "base.1",
                  color: "base.7",
                  fontWeight: "semi",
                }}
              >
                <Row sx={{ alignItems: "center" }}>
                  <InfoIcon />
                  <Text sx={{ ml: 3 }}>This sync uses a shared configuration template</Text>
                </Row>
                <Row sx={{ alignItems: "center" }}>
                  <Link
                    sx={{ display: "flex", color: "inherit", alignItems: "center" }}
                    to={`/audiences/setup/sync-templates/${sync?.sync_template_id}`}
                  >
                    <Text sx={{ mr: 4 }}>Edit the template</Text>
                    <ExternalLinkIcon color="base.4" size={14} />
                  </Link>
                </Row>
              </Row>
            )}

            {tab === Tab.SCHEDULE && (
              <Row sx={{ width: "100%", justifyContent: "space-between" }}>
                <Column mr={8} sx={{ width: "100%" }}>
                  <ScheduleManager schedule={schedule} setSchedule={setSchedule} />
                  <Message sx={{ mt: 12, maxWidth: "100%" }}>
                    <Text>
                      You can also trigger this sync via Airflow. The ID for this sync is: <strong>{id}</strong>.
                    </Text>
                    <Text sx={{ mt: 3 }}>
                      For more information view our{" "}
                      <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/integrations/airflow`}>
                        Airflow Operator docs
                      </Link>
                      . If you need an API key you can create one <Link to="/settings/api-keys">here.</Link>
                    </Text>
                  </Message>
                </Column>
                <SidebarForm
                  hideCompliance
                  hideInviteTeammate
                  hideSendMessage
                  buttons={[
                    <Permission key={0}>
                      <Button
                        disabled={
                          isEqual(schedule, sync?.schedule) ||
                          (sync?.schedule === null && isMatch(schedule, { type: "manual" }))
                        }
                        loading={updatingSchedule}
                        sx={{ width: "100%" }}
                        onClick={() => updateSchedule()}
                      >
                        Save
                      </Button>
                    </Permission>,
                  ]}
                  docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/schedule-sync-ui/`}
                  invite="If you need help setting up this sync"
                  name="scheduling syncs"
                />
              </Row>
            )}

            {tab === Tab.ALERTS && (
              <SyncAlerts
                alerts={syncAlerts}
                rowThresholdAttempted={sync?.row_threshold_attempted}
                rowThresholdTotal={sync?.row_threshold_total}
                syncId={id}
              />
            )}

            {tab === Tab.GIT_ACTIVITY && <GitActivity id={id} />}

            {tab === Tab.WAREHOUSE_SYNC_LOGS && (
              <WarehouseSyncLogs
                config={sync?.warehouse_history_config}
                id={id}
                source={source ?? undefined}
                userId={user?.id != null ? String(user?.id) : undefined}
              />
            )}
          </Grid>
        </Page>
      </PermissionProvider>

      <EditLabels
        description="You can label syncs that have similar properties"
        existingLabelOptions={syncLabels?.resource_tag_values}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={labels}
        loading={isSyncUpdating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sync"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={async () => {
          if (!id) {
            return;
          }

          await deleteSync({
            id,
          });

          analytics.track("Sync Deleted", {
            sync_id: id,
            destination_type: destinationDefinition?.name,
            schedule_type: schedule?.type,
            source_type: model?.connection?.type,
          });

          navigate("/syncs");
        }}
      />
    </>
  );
};
